import Base from "../../../layout/adm/base";
import { All } from "../../../section/admin/Database";

export default function AllDatabase(){

    return(
        <Base>
           <All />
        </Base>
    )
}