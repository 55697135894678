import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/Kits";
import { ButtonWithBg } from "../../components/Kits/Buttons";
import { userActions } from "../../redux/actions";
// import { userActions } from '../../redux/actions/user.actions'
import { uiConstants } from "../../redux/constants";
import { authLogic } from "../../utils";
import { useToaster } from "../../contexts/toaster";
import { requestForToken, onMessageListener } from "../../firebaseNotifications/firebase";

export default function Register() {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.UI.loading)
    const token = useSelector((state) => state.auth.token)
    const navigate = useNavigate()
    const toaster = useToaster()
    const [userToken, setUserToken] = useState("")
  
    let initialState = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    }
    const [newUser, setNewUser] = useState(initialState)
  
    const handleSubmit = (e) => {
      e.preventDefault()
      console.log('newUser ===>', newUser)
  
      dispatch(userActions.register(newUser, navigate, toaster))
    }
  
    useEffect(() => {
      if (authLogic.isAuthenticated(token) === true) {
        return navigate('/adm/survey-console', { replace: true })
      } 
    }, [])
  
    const handleChange = (e) => {
      const { name, value } = e.target
      setNewUser({ ...newUser, [name]: value })
    }

    

    useEffect(()=>{
      requestForToken(setUserToken)
    }, [])

    useEffect(()=>{
        setNewUser({...newUser, notificationToken: userToken})
    }, [userToken])

    // console.log("requestForToken", )'

    // console.log('userToken', userToken)
    // console.log('userToken newUser', newUser)

  return (
    <div className="sm:h-[100vh] py-[20px] sm:py-0 flex">
      <div className="h-full bg-white w-full lg:w-[50%] flex items-center justify-center">
        <div className="w-[90%] mx-auto px-4">
          <h1 className="text-[30px] font-medium font-ibm-plex-sans leading-[24px] text-primary-blue-500 mb-4">
            Welcome to Terros
          </h1>
          <span className="text-[16px] leading-[24px] text-black text-opacity-50">
            Login to your account
          </span>
          <form className="mt-[70px]" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1  sm:grid-cols-2 gap-8">
                <div className="mb-[30px]">
                    <Input
                        label={"Firstname"}
                        placeholder={"Doe"}
                        type={"text"}
                        name={"firstName"}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[30px]">
                    <Input
                        label={"Lastname"}
                        placeholder={"John"}
                        type={"text"}
                        name={"lastName"}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[30px]">
                    <Input
                        label={"E-mail"}
                        placeholder={"doejohn@gmail.com"}
                        type={"email"}
                        name={"email"}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[30px]">
                    <Input
                        label={"Phone"}
                        placeholder={"+2250700000000"}
                        type={"text"}
                        name={"phone"}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-[30px]">
                    <Input
                    label={"Password"}
                    placeholder={"**********"}
                    type={"password"}
                    name={"password"}
                    onChange={handleChange}
                    />
                </div>
                <div className="mb-[30px]">
                    <Input
                    label={"Confirm password"}
                    placeholder={"**********"}
                    type={"password"}
                    name={"confirmPassword"}
                    onChange={handleChange}
                    />
                </div>
            </div>
            
            <div>
              <ButtonWithBg title={ loading ? "Loading..." : "Sign Up"} type="sumbit" bg={"primary"} />
            </div>
            <p className="text-[14px] leading-[24px] text-black text-opacity-50 mt-[30px]">
              {"You have already an account ? "}
              <Link
                to="/"
                className="text-opacity-100 text-secondary-green-500"
              >
                Sign In
              </Link>
            </p>
          </form>
        </div>
      </div>
      <div className="h-full bg-gray-200 flex-1 overflow-hidden relative hidden lg:block">
        <img
          src="/images/illustration-login.png"
          alt="img"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
}
