import { useEffect, useState } from "react";
import { SelectField, Textarea, InputRadio, Input, ButtonWithBg, InputSearch } from "../../components/Kits";
import http from "../../http-common";
import { retirerId, getSurveyId } from "../../utils/retireId";
import { formatNameSurvey } from "../../utils/formatNameSurvey";
import { FaChevronDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

const convertFormatDate = (dateInitial) => {
    if(dateInitial && dateInitial?.includes('/')){
        const [jour, mois, annee] = dateInitial?.split('/');
        return `${annee}-${mois}-${jour}`;
    }  else {
        return dateInitial
    }
};

export default function UpdateSurveyForm({
    project,
    getSd,
    initialValues,
    setInitialValues,
    datasForSource,
    externalProjectData,
    typeSurveyExternal = false
}){
    const [defaultExeternalProjectData, setDefaultExternalProjectData]= useState(null)
    const [showOptions, setShowOptions] = useState(false)
    const [loadSearch, setLoadSearch] = useState(false)
    const [load, setLoad] = useState(false)
    const [elToSearch, setElToSearch] = useState('')
    const [surveyFound, setSurveyFound] = useState([])
    const [showDataFound, setShowDataFound] = useState(false)

    function toggleOptions (){
        setShowOptions(!showOptions)
    }

    useEffect(() => {
        if(project?.configuration?.useExternalProject){
            let keys = project?.configuration?.externalProject?.surveyDatasKeysUsed
            let srv =  typeSurveyExternal ? {
                id: getSd?.survey?.externalProjectData?.id,
                ...getSd?.survey?.externalProjectData?.surveyDatas
            } : getSd?.survey?.externalProjectData
            let formatResponseData = srv ? {
                value: srv?.id,
                name: keys?.map(k => srv[k]?.value)?.join(" ")
            } : null
            
            setDefaultExternalProjectData(formatResponseData)
        }
    }, [getSd])

    function handleCheckboxGroup(fieldName,value){
        if(initialValues[fieldName]?.includes(value)) {
            let tmp = initialValues[fieldName]?.filter(x => x !== value)
            setInitialValues({...initialValues, [fieldName]: tmp })
        } else {
            setInitialValues({...initialValues, [fieldName]: [...initialValues[fieldName] ,value] })
        }
    }

    console.log("getSd update", getSd)
    console.log("getSd update tt", project?.configuration?.externalProject?.surveyDatasKeysUsed)

    async function searchData (){
        setLoad(true)
        let data = {
            externalProjectId: project?.configuration?.externalProject?.id,
            value: elToSearch
        }
        await http.post('/search-external-project-surveydatas', data).then((response) => {
            console.log('searchData', response)
            let keys = project?.configuration?.externalProject?.surveyDatasKeysUsed
            let formatResponseData = response.data.data.map(el => (
                {
                    value: el.id,
                    name: keys?.map(k => el?.survey?.surveyDatas[k]?.value)?.join(" ")
                }
            ))
            setSurveyFound(formatResponseData)
            setLoadSearch(false)
            setLoad(false)
            setShowDataFound(true)
        }).catch((err) => {
            console.log('error', err)
            setLoadSearch(false)
            setLoad(false)
        })
    }

    useEffect(() => {
        if(!elToSearch){
            setShowDataFound(false)
        }
    }, [elToSearch])

    console.log('defaultExeternalProjectData', defaultExeternalProjectData)


    return (
        <>
            <div onClick={() => setShowOptions(false)} className='grid grid-cols-2 gap-4'>
            {
                project?.configuration?.useExternalProject && (
                    <div className="mb-[30px] col-span-2">
                        <div className="relative w-full z-20">
                            <span className="text-[14px]">
                                {project?.configuration?.externalProject?.fieldName}
                            </span>
                            <div 
                                className="flex items-center cursor-pointer border bg-white border-black border-opacity-10 rounded-[6px] h-[50px] w-full focus:outline-none px-4 text-[14px] text-black"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    toggleOptions()
                                    setElToSearch('')
                                }}
                            >
                                <span className="flex-1 truncate">
                                    {
                                        defaultExeternalProjectData ? defaultExeternalProjectData.name : 'Sélectionner'
                                    }
                                </span>
                                <FaChevronDown />
                            </div>
                            {
                                showOptions && (
                                    <div  
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }} 
                                        className="absolute top-[105%] w-full border bg-white p-3 shadow-lg rounded-md overflow-hidden"
                                    >
                                        <div className="rounded-[6px] h-[40px] px-2 overflow-hidden bg-white flex items-center gap-2 border relative z-40">
                                            <FiSearch className='text-[20px]' />
                                            <input
                                                type='search'
                                                placeholder="Rechercher..."
                                                className="block h-full w-full focus:outline-none text-[14px] text-black placeholder:text-black placeholder:text-opacity-50"
                                                onChange={(e) => {
                                                    setElToSearch(e.target.value)
                                                    setLoadSearch(true)
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="px-1 py-1 h-[230px] overflow-x-hidden">
                                            {   (!showDataFound || !elToSearch) &&
                                                externalProjectData?.map((l, k) => (
                                                    <div 
                                                        className={`text-black group flex rounded-md items-center w-full px-2 py-2 text-[12px] hover:bg-[#00c28818] cursor-pointer`}
                                                        onClick={() => {
                                                            setInitialValues({...initialValues, [formatNameSurvey(project?.configuration?.externalProject?.fieldName)]: l.value })
                                                            setDefaultExternalProjectData(l)
                                                            toggleOptions()
                                                        }}
                                                    >
                                                        {
                                                            l.name
                                                        }
                                                    </div>
                                                ))
                                            }
                                            {   (showDataFound && elToSearch) && (
                                                    <>
                                                        {   surveyFound?.length > 0 ?
                                                            surveyFound?.map((l, k) => (
                                                                <div 
                                                                    className={`text-black group flex rounded-md items-center w-full px-2 py-2 text-[12px] hover:bg-[#00c28818] cursor-pointer`}
                                                                    onClick={() => {
                                                                        setInitialValues({...initialValues, [formatNameSurvey(project?.configuration?.externalProject?.fieldName)]: l.value })
                                                                        setDefaultExternalProjectData(l)
                                                                        toggleOptions()
                                                                    }}
                                                                >
                                                                    {
                                                                        l.name
                                                                    }
                                                                </div>
                                                            )) : (
                                                                <span className="text-black text-[14px] h-full flex items-center justify-center text-center">
                                                                    Aucune resultat trouvé pour <br/> cette recherche
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                                )
                                                
                                            }
                                        </div>
                                        {
                                            (loadSearch && elToSearch) && (
                                                <div className="absolute top-0 left-0 h-full w-full bg-primary-blue-500/90 flex items-center justify-center text-center">
                                                    <span className="text-white text-[14px] w-[70%]">
                                                        {load ? "Recherche en cours veuillez patienter svp..." : "Taper la touche ENTRER de votre clavier pour éffectuer la recherche"}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                        {/* <SelectField
                            label={project?.configuration?.externalProject?.fieldName}
                            smallLab={true}
                            // value={getSurveyId(initialValues?.[formatNameSurvey(project?.configuration?.externalProject?.fieldName)])}
                            value={initialValues?.[formatNameSurvey(project?.configuration?.externalProject?.fieldName)]}
                            options={externalProjectData}
                            // options={datasForSource[field?.name]?.map(x => (
                            //     {
                            //         value: x?.id,
                            //         name: x?.content
                            //     }
                            // ))}
                            onChange={(e) => {
                                setInitialValues({...initialValues, [formatNameSurvey(project?.configuration?.externalProject?.fieldName)]: e.target.value })
                            }}
                        />  */}
                    </div>
                )
            }
                {project?.form?.fields?.map((field, index) => {
                    if (field?.name !== "status") {
                        return (
                            <div key={index} className={`pb-2 ${field?.type === 'textarea' ? 'col-span-2' : 'col-span-1'} `}>
                                {
                                    field?.type === 'select' ?
                                    <div className="mb-[30px]">
                                        <SelectField
                                            label={field?.label}
                                            smallLab={true}
                                            value={initialValues?.[field?.name]}
                                            options= {
                                                field?.values?.map(x => (
                                                    {
                                                        value: x?.value,
                                                        name: x?.label
                                                    }
                                                ))
                                            }
                                            onChange={(e) => {
                                                setInitialValues({...initialValues, [field?.name]: e.target.value })
                                            }}
                                        />
                                    </div>
                                    :
                                    field?.type === 'select-data-source' ? 
                                    <div className="mb-[30px]">
                                        <SelectField
                                            label={field?.label}
                                            smallLab={true}
                                            value={getSurveyId(initialValues?.[field?.name])}
                                            options={datasForSource[field?.name]?.map(x => (
                                                {
                                                    value: x?.id,
                                                    name: x?.content
                                                }
                                            ))}
                                            onChange={(e) => {
                                                setInitialValues({...initialValues, [field?.name]: e.target.value })
                                            }}
                                        />
                                    </div>
                                    :
                                    field?.type === 'radio-group' || field?.type === 'checkbox-group' ?
                                    <div className="mb-[30px]">
                                        <span className="block text-[14px] text-black">
                                            {field?.label}
                                        </span>
                                        {
                                            field?.values?.map(x => (
                                                <InputRadio 
                                                    label={x?.label}
                                                    value={x?.value}
                                                    name={field?.name}
                                                    type={field?.type === 'checkbox-group' ? 'checkbox' : 'radio'}
                                                    checked={ field?.type === 'checkbox-group' ?
                                                        initialValues?.[field?.name]?.includes(x?.value)
                                                        : 
                                                        initialValues?.[field?.name] === x?.value
                                                    }
                                                    onChange={(e) => {
                                                        field?.type === 'checkbox-group' ? (
                                                            handleCheckboxGroup(field?.name, e.target.value)
                                                            // setInitialValues({...initialValues, [field?.name]: [...initialValues[field?.name] ,e.target.value] })
                                                        ) : (
                                                            setInitialValues({...initialValues, [field?.name]: e.target.value })
                                                        )
                                                        
                                                    }}
                                                />
                                            ))
                                        }
                                    </div>
                                    :
                                    field?.type === 'textarea' ?
                                    <div className="mb-[30px]">
                                        <Textarea 
                                            label={field?.label}
                                            smallLab={true}
                                            type={'text'}
                                            value={initialValues?.[field?.name]}
                                            onChange={(e) => {
                                                setInitialValues({...initialValues, [field?.name]: e.target.value })
                                            }}
                                        />
                                    </div>
                                    :
                                    field?.type === 'date' ? 
                                    <div className="mb-[30px]">
                                        <Input 
                                            label={field?.label}
                                            type={field?.type}
                                            value={convertFormatDate(initialValues?.[field?.name])}
                                            smallLab={true}
                                            onChange={(e) => {
                                                console.log('eeeee', e)
                                                setInitialValues({...initialValues, [field?.name]: e.target.value })
                                            }}
                                        />
                                    </div>
                                    :
                                    <div className="mb-[30px]">
                                        <Input 
                                            label={field?.label}
                                            type={field?.type}
                                            value={initialValues?.[field?.name]}
                                            smallLab={true}
                                            onChange={(e) => {
                                                setInitialValues({...initialValues, [field?.name]: e.target.value })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        );
                    }
                })}
            </div>
        </>
    )
}
