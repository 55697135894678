import moduleMappingService from "../../services/moduleMapping.service";
import { moduleMappingConstants, uiConstants } from "../constants";
import { toast } from "react-toastify";


const getDataMap = () => (dispatch) => {
  console.log("moduleMappingConstants")
  dispatch({ type: uiConstants.LOADING_UI });
  moduleMappingService
    .getAll()
    .then((response) => {
      console.log('response bbbbbb====>', response)
      dispatch({
        type: moduleMappingConstants.RETRIEVE_DATASMAP,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      if (response.data !== undefined) {
        dispatch({
          type: uiConstants.SET_ERRORS,
          payload: response.data.message,
        });
      }
    });
};


export const moduleMappingActions = {
  getDataMap,
};
