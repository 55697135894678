export default function convertToCSV(data, headers, filename) {
  const separator = ','; // Le séparateur de colonnes
  const arraySeparator = '-'; // Séparateur pour les tableaux
  const newline = '\n'; // Le caractère de nouvelle ligne
  let csv = '';

  // Ajout des en-têtes de colonne
  headers.forEach((header, index) => {
    csv += header.label;
    if (index < headers.length - 1) {
      csv += separator;
    }
  });
  csv += newline;

  // Ajout des données
  data.forEach((row, index) => {
    headers.forEach((header, i) => {
      const value = row[header.key];
      if (Array.isArray(value)) {
        // Si la valeur est un tableau d'objets, convertissez-le en chaîne JSON
        csv += value.map(obj => JSON.stringify(obj)).join(arraySeparator);
      } else {
        csv += value !== undefined ? value : '';
      }
      if (i < headers.length - 1) {
        csv += separator;
      }
    });
    if (index < data.length - 1) {
      csv += newline;
    }
  });

  // Création d'un objet Blob à partir de la chaîne CSV
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  // Création d'un lien de téléchargement
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', filename);

  // Ajout du lien à la page et déclenchement automatique du téléchargement
  document.body.appendChild(link);
  link.click();

  // Suppression du lien de la page
  document.body.removeChild(link);

  return csv;
}

// export default function convertToCSV(data, headers, filename) {
//     const separator = ','; // Le séparateur de colonnes
//     const newline = '\n'; // Le caractère de nouvelle ligne
//     let csv = '';
  
//     // Ajout des en-têtes de colonne
//     headers.forEach((header, index) => {
//       csv += header.label;
//       if (index < headers.length - 1) {
//         csv += separator;
//       }
//     });
//     csv += newline;
  
//     // Ajout des données
//     data.forEach((row, index) => {
//       headers.forEach((header, i) => {
//         const value = row[header.key] !== undefined ? row[header.key] : '';
//         csv += value;
//         if (i < headers.length - 1) {
//           csv += separator;
//         }
//       });
//       if (index < data.length - 1) {
//         csv += newline;
//       }
//     });
  
//     // Création d'un objet Blob à partir de la chaîne CSV
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
//     // Création d'un lien de téléchargement
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.setAttribute('href', url);
//     link.setAttribute('download', filename);
  
//     // Ajout du lien à la page et déclenchement automatique du téléchargement
//     document.body.appendChild(link);
//     link.click();
  
//     // Suppression du lien de la page
//     document.body.removeChild(link);
  
//     return csv;
//   }