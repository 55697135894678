import Base from "../../../layout/adm/base";
import Mapps from "../../../section/admin/DataHub/Mapps";

export default function MapViews(){

    return(
        <Base withoutPadd={true}>
            <Mapps />
        </Base>
    )
}