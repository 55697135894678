import http from '../http-common'

const getAll = () => {
  return http.get('/billing-accounts')
}

const get = (id) => {
  return http.get(`/billing-accounts/${id}`)
}

const create = (data) => {
  return http.post('/billing-accounts', data)
}

const update = (id, data) => {
  return http.put(`/billing-accounts/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/billing-accounts/${id}`)
}

const bindBillingAddressToBillingAccount = (data) => {
  return http.put('/bind-billing-address-to-billing-account', data)
}

// const bindPaymentMethodToBillingAccount = (data) => {
//   return http.put('/billing-accounts/bind-payment-method-to-billing-account', data)
// }

const bindPaymentMethodToBillingAccount = (data) => {
  return http.put(`/billing-accounts/bind-payment-method/${data?.billingAccountId}`, {paymentMethodeId : data?.paymentMethodeId})
}

const addStripeTokenToBillingAccount = (data) => {
  return http.post(`/add-stripe-token-to-billing-account`, data)
}

const addAdresssBillingAccount = (data) => {
  return http.post(`/billing-address`, data)
}

const getAllBillingAddress = () => {
  return http.get('/billing-address')
}


const billingAccountService = {
  getAll,
  get,
  create,
  update,
  remove,
  addStripeTokenToBillingAccount,
  addAdresssBillingAccount,
  getAllBillingAddress,
  bindBillingAddressToBillingAccount,
  bindPaymentMethodToBillingAccount
}

export default billingAccountService
