import { Disclosure } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag, SelectField } from '../../../../../components/Kits'
import {BsChevronRight} from 'react-icons/bs'
import { useState, useEffect } from 'react'
import { projectActions } from '../../../../../redux/actions'
import { useDispatch, useSelector } from "react-redux";
import { useToaster } from '../../../../../contexts/toaster'
import { useLocation, useNavigate } from "react-router";
import http from '../../../../../http-common'

export default function GeneralInfo({item, t, project, projectId, accessControl}){
   
    const [isLoading, setIsLoading] = useState(false)
    const toaster = useToaster()
    const navigate = useNavigate();
    const location = useLocation()
    const [isConf, setIsConf] = useState(false)
    const projectTypes = useSelector((state) => state.projectTypes);

    const user = useSelector((state) => state.user);
    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])
   


    const dispatch = useDispatch();

    

    const handleSubmitProject = async (e) => {
        e.preventDefault();
        if(isLoading===false){
            setIsLoading(true)
            await http.put(`/projects/${projectId}/`, itemData)
            .then(function (response) {
                if(response.status === 200){
                    setIsLoading(false)
                    toaster.show({
                        title: 'Success !',
                        message: `Your project has modified successfully`,
                        greedy: true,
                        type: 'success'
                    });
                    window.location.reload()
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                toaster.show({
                    title: 'Oups !',
                    message: error,
                    greedy: true,
                    type: 'error'
                });
            });
        }

        // console.log("itemData====>", itemData);
        // dispatch(projectActions.update(item?.id, itemData, setIsLoading, toaster, isConf));
    };



    return(
        <Disclosure defaultOpen={true} className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
            {({ open }) => (
                <div className={`rounded-lg overflow-hidden } `}>
                    <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                        <div className={`flex items-center justify-between gap-3 w-full`}>
                            <span className={`
                                text-[15px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                            }`}>
                                {t("project:generalInformation")}
                            </span>
                            <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                        <form onSubmit={(e) => handleSubmitProject(e)} className={`col-span-1`}>
                        
                            <div className="mb-[30px] mt-[20px]">
                                <Input 
                                    label={t("project:name")}
                                    placeholder={"ex: project name"}
                                    value={itemData?.name}
                                    onChange={(e)=> setItemData({...itemData, name:e.target.value})}
                                    disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    type={'text'}
                                    smallLab={true}
                                />
                            </div>
                            <div className="mb-[30px]">
                                <Textarea 
                                    label={t("project:description")}
                                    smallLab={true}
                                    value={itemData?.description}
                                    onChange={(e)=> setItemData({...itemData, description:e.target.value})}
                                    placeholder={"ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                                    type={'text'}
                                    disabled={!accessControl?.maintainer && !accessControl?.owner}
                                />
                            </div>
                            {
                                (accessControl?.maintainer || accessControl?.owner) && (
                                    <div className='flex justify-end mb-[10px]'>
                                        <ButtonWithBg 
                                            title={isLoading ? t("common:inprogress") : t('common:update')}
                                            type='submit'
                                            bg={'primary'}
                                            isSmall={true}
                                        />
                                    </div>
                                )
                            }
                            
                        </form>
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}