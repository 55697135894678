import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { Heading, Paragraph } from "../../../../components/Kits";
import Configuration from "./Configuration";
import SurveyMonitoring from "./SurveyMonitoring";
import SurveyData from "./SurveyData";
import Billing from "./Billing";
import ProjectCompletion from "./ProjectCompletion";
import { useSelector, useDispatch } from "react-redux";
import http from "../../../../http-common";
import { useTranslation } from "react-i18next";
import DetailsSkeletons from "../../../../components/Skeletons/DetailsSkeletons";
import { objectToQueryString } from "../../../../utils/table-manager";
import { controlAccess } from "../../../../utils/controlAccess";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const dataPage = [
    { name: '10', id : 10 },
    { name: '20', id : 20 },
    { name: '30', id : 30 },
    { name: '40', id : 40 },
    { name: '50', id : 50 },
    { name: '100', id : 100 },
    { name: '150', id : 150 }
]

export default function ProjectDetails(){
    const { t } = useTranslation(["common", "project", "form"]);
    const dispatch = useDispatch
    const location = useLocation();
    const { item } = location.state;
    const projects = useSelector((state) => state.projects);
    const user = useSelector((state) => state.user)
    const [reload, setReload] = useState(false)
    // let proj = projects.find(x => x?.id == item?.id)
    const [datasForSource, setDataForSource] = useState(null)
    const [externalProjectData, setExternalProjectData] = useState([])
    const [project, setProject] = useState(null)
    const [surveyData, setSurveyData] = useState(null)
    const [surveyDataWithOutLimit, setSurveyDataWithOutLimit] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoadedSurvey, setIsLoadedSurvey] = useState(false)
    const [elementToList, setElementToList] = useState(dataPage[0])
    const [filterByStatus, setFilterByStatus] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [elToSearch, setElToSearch] = useState('')
    const [pagination, setPagination] = useState({
        pageSize: elementToList?.id,
        total : 0,
        totalCount: 0
    });
    const [externalProject, setExternalProject] = useState(null)
    const [currentPageExternalProjectData, setCurrentPageExternalProjectData] = useState(1);
    const [isLoadedExternalProjectData, setIsLoadedExternalProjectData] = useState(false)
    const [paginationExternalProjectData, setPaginationExternalProjectData] = useState({
        pageSize: 20,
        total : 0,
        totalCount: 0
    });
    const [paginationExport, setPaginationExport] = useState({
        total : 0,
        totalCount: 0,
        pageSize: 500
    });

    const [filterValues, setFilterValues] = useState({
        agentIds: null,
        statusIds: null,
        numbers: '',
        createdAt: ''
    })

    const resetFilter = () => {
        setFilterValues({
            agentIds: null,
            statusIds: null,
            numbers: '',
            createdAt: ''
        })
    }
    

    function nextPage() {
        // Vérifier dab si on peut faire suivant
        
        if(currentPage < pagination.total){
            console.log('next')
            setCurrentPage(currentPage + 1)
        }
    }

    function prevPage() {
        // Vérifier dab si on peut faire suivant
        if(currentPage > 1){
            setCurrentPage(currentPage - 1)
        }
    }
    // // console.log('projects', projects.find(x => x?.id == item?.id))

    const getProjet = async () => {
        setIsLoaded(true)
        await http.get(`/projects/${item.id}`).then(function(response){
            console.log("response getContrat", response)
            if(response.status === 200){
                setProject(response.data.data)
                setIsLoaded(false)
            }
        })
    }

    const getSurveyData = async () => {
        setIsLoadedSurvey(true)
        const qs = objectToQueryString({
            page : currentPage,
            limit : elementToList?.id,
            projectId: item?.id,
            searchField: elToSearch ?? null,
            statusIds: filterValues?.statusIds?.map(x => x?.value),
            agentIds: filterValues?.agentIds?.map(x => x?.value),
            numbers: filterValues?.numbers,
            createdAt: filterValues?.createdAt ?? null
        });

        const qsWithoutLimit = objectToQueryString({
            page : 1,
            limit : 500,
            projectId: item?.id,

            // searchField: elToSearch ?? null,
            // statusId: filterValues?.statusId,
            // agentId: filterValues?.agentId,
            // number: filterValues?.number,
            // createdAt: filterValues?.createdAt ?? null
        });

        await http.get(`/find-surveydatas-by-project${qs}`).then(function(response){
            console.log("response getSurveyData", response)
            if(response.status === 200){
                setSurveyData(response.data.data)
                setPagination(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                setIsLoadedSurvey(false)
            }
        })

        await http.get(`/find-surveydatas-by-project${qsWithoutLimit}`).then(function(response){
            console.log("response getSurveyData 000", response)
            if(response.status === 200){
                setSurveyDataWithOutLimit(response.data.data)
                setPaginationExport(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                // setPagination(prev => { return {...prev, total : response.data.totalPage}})
                // setIsLoadedSurvey(false)
            }
        })
    }

    useEffect(() => {
        getProjet()
    }, [])
    useEffect(() => {
        getSurveyData()
    }, [currentPage, elementToList, filterByStatus])

    // ------- Get Info Of External project ---------------
    const getExternalProjectSurvey = async (id) => {
        setIsLoadedExternalProjectData(true)

        const qs = objectToQueryString({
            page : currentPageExternalProjectData,
            limit : 20,
            projectId: id,
            statusId: null
        });

        await http.get(`/find-surveydatas-by-project${qs}`)
        .then(function(response){
            console.log("response survey external project", response)
            if(response.status === 200){
                let keys = project?.configuration?.externalProject?.surveyDatasKeysUsed
                let formatResponseData = response.data.data.map(el => (
                    {
                        value: el.id,
                        name: keys?.map(k => el?.survey[k]?.value)?.join(" ")
                    }
                ))
                let tmp = externalProjectData
                setExternalProjectData([...tmp, ...formatResponseData])
                setPaginationExternalProjectData(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                setIsLoadedExternalProjectData(false)
            }
        }).catch(err => {
            setIsLoadedExternalProjectData(false)
        })
    }

    const getExternalProject = async (id) => {
        await http.get(`/projects/${id}`).then(function(response){
            console.log("response oooo", response)
            if(response.status === 200){
                setExternalProject(response.data.data)
            }
        })
    }

    useEffect(() => {
        if(project?.configuration?.useExternalProject) {
            getExternalProjectSurvey(project?.configuration?.externalProject?.id)
            getExternalProject(project?.configuration?.externalProject?.id)
        }
    }, [project])

    // ------------------------------------------------------------ GET DATA SOURCE

    const formatData = (data) => {
        return data.map((item) => {
            // Get values from object
            const values = Object.values(item?.dataSource);
            // Join values with ' - '
            const content = values.join(' - ');
            // Return formatted object
            return { content, id: item?.id };
        });
    };


    let dataSourcesToLoad = []
    useEffect(() => {

        project?.form?.fields?.forEach(element => {
            if(element?.type === 'select-data-source') {
                console.log('element', element)
                dataSourcesToLoad.push({name: element?.name, sourceId: element?.values?.[0]?.id})
            }
        });

        console.log('dataSourcesToLoad', dataSourcesToLoad)

    }, [project])

    // Fonction asynchrone pour effectuer la requête HTTP
    const fetchData = async (id) => {
        try {
            const response = await http.get(`/datas-sources-by-form-data-source-id/${id}`);

            let createArray = response?.data?.data?.map((el) => (
                {
                    dataSource: el.dataSource,
                    id: el.id
                }
            ))
            let finalArray = formatData(createArray)
            // let finalArray = []
            return finalArray;
        } catch (error) {
            console.error(`Erreur lors de la requête pour ${id}:`, error);
            return null;
        }
    };

    // Fonction principale pour traiter chaque élément dans dataSourcesToLoad
    const processDataSources = async () => {
        const promises = dataSourcesToLoad.map(dataSource => fetchData(dataSource.sourceId));

        try {
            // Attendre que toutes les promesses soient résolues
            const results = await Promise.all(promises);

            // Construire l'objet résultant
            const ggg = dataSourcesToLoad.reduce((acc, dataSource, index) => {
                const { name } = dataSource;
                acc[name] = results[index];
                return acc;
            }, {});

            setDataForSource(ggg)
        } catch (error) {
            console.error('Erreur lors de l\'exécution de Promise.all:', error);
        }
    };

    useEffect(() => {
        if(dataSourcesToLoad?.length > 0){
            processDataSources();
        }
    }, [dataSourcesToLoad])

    console.log("project", project)


    // {"surveyDatas":{"text-1710614872432-0":"SALIMATA","date-1710615008008-0":"01/01/1974","select-1710614967225-0":"Homme","textarea-1710614864477-0":"YARBANGA","select-1710615063258-0":"e14a7356-fd75-4079-8ab2-2030415e9624","number-1710614901289-0":707603070,"text-1710614948708-0":"","select-1710615046329-0":"f4b27d72-2f0f-4e93-b0c1-4d8be6ff2d95","source":"From export files","select-1710615090070-0":"c97eb89a-80cf-497b-bf44-041eaff647a9"},"datasMap":{"id":null,"mappings":[]},"userLocation":null}


    return(
        <div>
            {   isLoaded ? <DetailsSkeletons /> :
                <>
                    <div className="max-w-[40%]">
                        { 
                            item?.number && 
                            <span className="text-[14px] text-primary-blue-500 font-bold block mb-1">{`#ID: ${item?.number}`}</span>
                        }
                        <Heading 
                            title={project?.name} 
                            mb={"10px"}
                        />
                        <Paragraph 
                            content={project?.description}
                            limit={"limit-3"}
                        />
                    </div>
                    <div className="mt-[50px]">
                        <Tab.Group>
                            <Tab.List className="flex gap-2 overflow-y-hidden scroll-v-hide">
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                        "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                        "focus:outline-none",
                                        selected
                                            ? "bg-primary-blue-500 text-white"
                                            : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                        )
                                    }
                                >
                                    {t("project:Configuring")}
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                        "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                        "focus:outline-none",
                                        selected
                                            ? "bg-primary-blue-500 text-white"
                                            : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                        )
                                    }
                                >
                                    {t("project:surveymonitoring")}
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                        "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                        "focus:outline-none",
                                        selected
                                            ? "bg-primary-blue-500 text-white"
                                            : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                        )
                                    }
                                >
                                    {t("project:surveydata")}
                                </Tab>
                                {/* <Tab
                                    className={({ selected }) =>
                                        classNames(
                                        "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                        "focus:outline-none",
                                        selected
                                            ? "bg-primary-blue-500 text-white"
                                            : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                        )
                                    }
                                >
                                    {t("project:billingandpayment")}
                                </Tab> */}
                                {
                                    (controlAccess(project?.projectUsers, user)?.owner || controlAccess(project?.projectUsers, user)?.maintainer) &&
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                            "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                            "focus:outline-none",
                                            selected
                                                ? "bg-primary-blue-500 text-white"
                                                : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                            )
                                        }
                                    >
                                        {t("project:projectcompletion")}
                                    </Tab>
                                }
                            </Tab.List>
                            <Tab.Panels className="mt-2 pt-[30px]">
                                <Tab.Panel className={classNames("focus:outline-none")}>
                                    <Configuration project={project}  reload={reload} setReload={setReload} t={t} accessControl={controlAccess(project?.projectUsers, user)} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames("focus:outline-none")}>
                                    <SurveyMonitoring  project={project} t={t} />
                                </Tab.Panel>
                                <Tab.Panel className={classNames("focus:outline-none")}>
                                    <SurveyData 
                                        item={item}  
                                        t={t} 
                                        project={project} 
                                        surveyData={surveyData} 
                                        setSurveyData={setSurveyData}
                                        surveyDataWithOutLimit={surveyDataWithOutLimit} 
                                        getSurveyData={getSurveyData} 
                                        nextPage={nextPage} 
                                        prevPage={prevPage} 
                                        pagination={pagination} 
                                        currentPage={currentPage} 
                                        isLoadedSurvey={isLoadedSurvey} 
                                        setElementToList={setElementToList} 
                                        elementToList={elementToList} 
                                        setCurrentPage={setCurrentPage} 
                                        dataPage={dataPage} 
                                        filterByStatus={filterByStatus}
                                        setFilterByStatus={setFilterByStatus}
                                        accessControl={controlAccess(project?.projectUsers, user)}
                                        datasForSource={datasForSource}
                                        externalProject={externalProject}
                                        externalProjectData={externalProjectData}
                                        elToSearch={elToSearch}
                                        setElToSearch={setElToSearch}
                                        filterValues={filterValues}
                                        setFilterValues={setFilterValues}
                                        paginationExport={paginationExport}
                                    />
                                </Tab.Panel>
                                {/* <Tab.Panel className={classNames("focus:outline-none")}>
                                    <Billing  t={t} project={project} />
                                </Tab.Panel> */}

                                {
                                    (controlAccess(project?.projectUsers, user)?.owner || controlAccess(project?.projectUsers, user)?.maintainer) &&
                                    <Tab.Panel className={classNames("focus:outline-none")}>
                                        <ProjectCompletion  
                                            t={t} 
                                            project={project}
                                            accessControl={controlAccess(project?.projectUsers, user)}
                                        />
                                    </Tab.Panel>
                                }
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </>
                
            }
        </div>
    )
}

