function filterAndReplaceNames(alpha) {
    let namesToFilter = ["Project Owner", "Field Worker", "Data Controler", "Billing & Payment"];

    if(alpha){
        return alpha
        .filter(item => namesToFilter.includes(item.name))
        .map(item => {
            // Remplacer les noms spécifiques
            switch (item.name) {
            case "Field Worker":
                return { ...item, name: "Field Agent" };
            case "Data Controler":
                return { ...item, name: "Office Agent" };
            case "Billing & Payment":
                return { ...item, name: "Billing Agent" };
            default:
                return item;
            }
        });
    } else {
        return []
    }
    
}

export {
    filterAndReplaceNames
}