import { Link } from "react-router-dom";

export default function Footer(){

    return(
        <footer className="bg-[#0C111D] max-w-[1440px] mx-auto lg:px-[80px] px-[16px] pt-[64px] py-[30px] relative">
            <div className="absolute top-0 left-0 h-full w-full">
                <img 
                    src="/images/home/bg-pattern.png"
                    alt="bg-pattern"
                    className="h-full w-full object-cover opacity-20"
                />
            </div>
            <div className="relative z-20">
                <div className="mb-[40px]">
                    <div className="h-[40px] w-[100px] bg-white"></div>
                    <span className="lg:text-[18px] text-[16px] text-[#94969C] leading-[30px] font-[400] block max-w-[500px] mt-4">
                        {`Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.`}
                    </span>
                </div>
                <div className="sm:flex items-center justify-between">
                    <div className="flex items-end gap-3">
                        <Link to={`/privacy-policy`}>
                            <span className="underline text-[#94969C] text-[16px] !mb-0">{`Privacy policy`}</span>
                        </Link>
                        <span className="block h-[5px] w-[5px] rounded-full bg-[#94969C]"></span>
                        <Link to={`/`} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9455 22L10.396 14.0901L3.44886 22H0.509766L9.09209 12.2311L0.509766 0H8.05571L13.286 7.45502L19.8393 0H22.7784L14.5943 9.31648L23.4914 22H15.9455ZM19.2185 19.77H17.2398L4.71811 2.23H6.6971L11.7121 9.25316L12.5793 10.4719L19.2185 19.77Z" fill="#85888E"/>
                            </svg>
                        </Link>
                        <Link to={`/`} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z" fill="#85888E"/>
                            </svg>
                        </Link>
                        <Link to={`/`} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_23_3255)">
                                    <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z" fill="#85888E"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_23_3255">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </Link>
                    </div>
                    <span className="text-[#94969C] text-[16px] block sm:mt-0 mt-4">
                        &copy; 2023 Mael124. All rights reserved.
                    </span>
                </div>
            </div>  
        </footer>
    )
}