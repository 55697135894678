export default function Input({label, type, placeholder, smallLab, handleChange, ...props}){

    // absolute left-4 -top-3 px-2
    return(
        <label className="relative block">
            <span className={`block z-20 bg-white absolute left-4 -top-3 px-2  min-w-[80px] ${smallLab ? "text-[14px]" : "text-[16px]" }`}>
                {label}
            </span>
            <input
                className="block border bg-white border-black disabled:bg-gray-100 border-opacity-10 rounded-[6px] h-[50px] w-full focus:outline-none px-4 text-[14px] text-black placeholder:text-black placeholder:text-opacity-50"
                placeholder={placeholder}
                type={type}
                {...props}
            />
        </label>
    )
}