import { appSettingConstants } from '../constants'

const initialState = null

export default function (userLang = initialState, action) {
  const { type, payload } = action
  if (type === appSettingConstants.RETRIEVE_USER_LANG) {
    return payload
  } else if(type === appSettingConstants.UPDATE_LANG){
    return payload
  }
  else {
    return userLang
  }
}
