import { Link } from "react-router-dom";
import { ButtonWithBg } from "../components/Kits";

export default function ActivatedAccount(){

    return (
        <div className="h-screen flex items-center justify-center p-6">
            <div className="w-[450px] text-center">
                <h4 className="font-bold text-[25px] font-ibm-plex-sans mb-4">
                    {`Compte activé avec succès`}
                </h4>
                <p className="block text-center mb-4 text-black text-opacity-50">
                    {`Veuillez cliqué sur le bouton ci-dessous pour vous connecter`}
                </p>
                <Link to={'/'}>
                    <ButtonWithBg
                        isSmall 
                        title={"Connexion"} 
                        type="button" 
                        bg={"primary"} 
                    />
                </Link>
            </div>
        </div>
    )
}