const checkSelectValues = (data) => {
    const selectElements = data.filter(
      (item) => item.type === 'select' && item.className.includes('select-data-source')
    );
  
    let allValuesSelected = selectElements.every((selectElement) =>
      selectElement.values.some((value) => value.selected === true)
    );

    // let allValuesSelecteds = selectElements.some((selectElement) =>
    //   selectElement.values.every((value) => value.selected === true)
    // );

    const atMostOneValueSelected = selectElements.every(
        (selectElement) =>
          selectElement.values.filter((value) => value.selected === true).length <= 1
    );

    const res = allValuesSelected && atMostOneValueSelected
  
    return res;
};

function processArray(arr) {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
  
      if (item.type === 'select' && item.className.includes('select-data-source')) {
        item.type = 'select-data-source';
  
        if (item.values && Array.isArray(item.values)) {
          item.values = item.values.filter(value => value.selected);
        }
      }
    }
  
    return arr;
}

export {
    checkSelectValues,
    processArray
}