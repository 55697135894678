import Base from "../../../layout/adm/base";
import { ProjectList } from "../../../section/admin/projects";

export default function AllProject(){

    return(
        <Base>
            <ProjectList />
        </Base>
    )
}