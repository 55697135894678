const formatDateIntl = (alpha) => {
    const date = new Date(alpha);
  
    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: 'short',
    }).format(date);
    return formattedDate;
};

const formatDate = (alpha) => {
    const date = new Date(alpha);
  
    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(date);
    return formattedDate;
};

const formatDateToSend = (alpha) => {
  let date = new Date(alpha)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export {
    formatDateIntl,
    formatDate,
    formatDateToSend
}