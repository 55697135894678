import Base from "../../../layout/adm/base";
import { NewProject } from "../../../section/admin/projects";

export default function CreateProject(){

    return(
        <Base>
            <NewProject />
        </Base>
    )
}