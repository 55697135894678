export default function Textarea({label, type, placeholder,smallLab, ...props}){

    return(
        <label className="relative">
            <span className={`block bg-white absolute left-4 -top-3 px-2 min-w-[80px] ${smallLab ? "text-[14px]" : "text-[16px]"}`}>
                {label}
            </span>
            <textarea
                className="block border bg-white border-black border-opacity-10 rounded-[6px] h-[250px] w-full focus:outline-none px-4 py-4 text-[14px] text-black  placeholder:text-black placeholder:text-opacity-50"
                placeholder={placeholder}
                type={type}
                {...props}
            ></textarea>
        </label>
    )
}