import { projectRolesConstants } from '../constants'

const initialState = []

export default function (roles = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case projectRolesConstants.RETRIEVE_PROJECT_ROLES:
      return payload
    default:
      return roles
  }
}
