import { billingAccountConstants } from '../constants'

const initialState = []

export default function(billingAccounts = initialState, action){
  const { type, payload } = action

  switch (type) {
    case billingAccountConstants.CREATE_BILLINGACCOUNT:
      // console.log('response billing payload', [
      //   payload, 
      //   ...billingAccounts
      // ])
      return [
        payload, 
        ...billingAccounts
      ]
    case billingAccountConstants.RETRIEVE_BILLINGACCOUNTS:
      return payload
    case billingAccountConstants.UPDATE_BILLINGACCOUNT:
      return billingAccounts.map((billingAccount) => {
        console.log('aaaaannn billing', payload)
        if (billingAccount.id === payload.id) {
          console.log('aaaaa billing', payload)
          return {
            ...billingAccount,
            ...payload,
          }
        } else {
          console.log('bbbbbb billing')
          return billingAccount
        }
      })
    case billingAccountConstants.DELETE_BILLINGACCOUNT:
      return billingAccounts.filter(({ id }) => id !== payload.id)
    default:
      return billingAccounts
  }
}
