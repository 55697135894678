import Base from "../../../layout/adm/base";
import Details from "../../../section/admin/Database/Details";

export default function DetailsDatabases(){

    return(
        <Base>
            <Details />
        </Base>
    )
}