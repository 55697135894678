export default function DetailsSkeletons(){

    return (
        <div className="animate-pulse">
            <div className="max-w-[40%] mb-[50px]">
                <div className="h-4 bg-primary-blue-500 bg-opacity-10 rounded-[2px]"></div>
                <div className="my-[16px] space-y-[6px]">
                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[2px]"></div>
                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[2px]"></div>
                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[2px]"></div>
                </div>
            </div>
            <div className="flex gap-2 overflow-y-hidden scroll-v-hide">
                <div className="h-[30px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[220px]"></div>
                <div className="h-[30px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[220px]"></div>
                <div className="h-[30px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[220px]"></div>
                <div className="h-[30px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[220px]"></div>
                <div className="h-[30px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[220px]"></div>
            </div>
            <div className="grid lg:grid-cols-3 gap-8  pt-[30px]">
                <div className="">
                    <ul>
                        <li  className="flex items-center justify-between py-4 border-b">
                            <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                            <div className="h-[20px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[150px]"></div>
                        </li>
                        <li  className="flex items-center justify-between py-4 border-b">
                            <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                            <div className="h-[20px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[150px]"></div>
                        </li>
                        <li  className="flex items-center justify-between py-4 border-b">
                            <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                            <div className="h-[20px] bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[150px]"></div>
                        </li>
                    </ul>
                    <div className="mt-[30px] lg:block hidden">
                        <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] mb-4"></div>
                        <ul>
                            <li className="flex items-center mb-[33px] justify-between gap-4">
                                <div className="flex items-center gap-2">
                                    <div className={`h-[20px] w-[20px] rounded-full border-2 border-primary-blue-500 border-opacity-10 flex items-center justify-center relative after:absolute after:h-[36px] after:w-[1px] after:bg-primary-blue-500 after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}></div>
                                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                                </div>
                            </li>
                            <li className="flex items-center mb-[33px] justify-between gap-4">
                                <div className="flex items-center gap-2">
                                    <div className={`h-[20px] w-[20px] rounded-full border-2 border-primary-blue-500 border-opacity-10 flex items-center justify-center relative after:absolute after:h-[36px] after:w-[1px] after:bg-primary-blue-500 after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}></div>
                                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                                </div>
                            </li>
                            <li className="flex items-center mb-[33px] justify-between gap-4">
                                <div className="flex items-center gap-2">
                                    <div className={`h-[20px] w-[20px] rounded-full border-2 border-primary-blue-500 border-opacity-10 flex items-center justify-center relative after:absolute after:h-[36px] after:w-[1px] after:bg-primary-blue-500 after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}></div>
                                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                                </div>
                            </li>
                            <li className="flex items-center mb-[33px] justify-between gap-4">
                                <div className="flex items-center gap-2">
                                    <div className={`h-[20px] w-[20px] rounded-full border-2 border-primary-blue-500 border-opacity-10 flex items-center justify-center relative after:absolute after:h-[36px] after:w-[1px] after:bg-primary-blue-500 after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}></div>
                                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                                </div>
                            </li>
                            <li className="flex items-center mb-[33px] justify-between gap-4">
                                <div className="flex items-center gap-2">
                                    <div className={`h-[20px] w-[20px] rounded-full border-2 border-primary-blue-500 border-opacity-10 flex items-center justify-center relative`}></div>
                                    <div className="h-2 bg-primary-blue-500 bg-opacity-10 rounded-[6px] w-[120px]"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="lg:col-span-2">
                    <div className='h-[60px] w-full bg-primary-blue-500 bg-opacity-10 rounded-[6px] mb-3'></div>
                    <div className='h-[60px] w-full bg-primary-blue-500 bg-opacity-10 rounded-[6px] mb-3'></div>
                    <div className='h-[60px] w-full bg-primary-blue-500 bg-opacity-10 rounded-[6px] mb-3'></div>
                    <div className='h-[60px] w-full bg-primary-blue-500 bg-opacity-10 rounded-[6px] mb-3'></div>
                    <div className='h-[60px] w-full bg-primary-blue-500 bg-opacity-10 rounded-[6px] mb-3'></div>
                </div>
            </div>
        </div>
    )
}