import { combineReducers } from "redux";
import accountTypeReducer from "./account.type.reducer";
import authReducer from "./auth.reducer";
import formReducer from "./form.reducer";
import formTypeReducer from "./form.type.reducer";
import projectReducer from "./project.reducer";
import projectRolesReducer from "./project.roles.reducer";
import projectStatusReducer from "./project.status.reducer";
import projectUserReducer from "./project.user.reducer";
import uiReducer from "./ui.reducer";
import userReducer from "./user.reducer";
import userRoleReducer from "./user.role.reducer";
import usersReducer from "./users.reducer";
import userSearchReducer from "./user.search.reducer";
import datasMapStatusReducer from "./datasMap.status.reducer";
import surveyDatasRequestedReducer from "./surveyDatasRequested.reducer";
import moduleMappingReducer from "./moduleMapping.reducer"
import billingAccountReducer from "./billingAccount.reducer";
import billingTypeReducer from "./billing.type.reducer";
import billingAddressReducer from "./billingAddress.reducer";
import projectTypesReducer from "./project.types.reducer";
import databaseSourcesReducer from './databaseSource.reducer'
import paymentsMethodReducer from "./paymentsMethod.reducer";
import appSettingAreaReducer from "./appSettingArea.reducer";
import appSettingLangReducer from "./appSettingLang.reducer";
import userLangReducer from "./userLang.reducer";
import userStudyAreaReducer from "./userStudyArea.reducer";

export default combineReducers({
  projects: projectReducer,
  auth: authReducer,
  user: userReducer,
  forms: formReducer,
  roles: userRoleReducer,
  projectStatus: projectStatusReducer,
  projectRoles: projectRolesReducer,
  projectTypes: projectTypesReducer,
  projectUsers: projectUserReducer,
  users: usersReducer,
  formTypes: formTypeReducer,
  billingTypes: billingTypeReducer,
  accountTypes: accountTypeReducer,
  usersSearchList: userSearchReducer,
  surveyDatasStatus: datasMapStatusReducer,
  surveyDatasRequested: surveyDatasRequestedReducer,
  UI: uiReducer,
  // moduleMappingData: moduleMappingReducer,
  billingAccounts : billingAccountReducer,
  billingAddress : billingAddressReducer,
  databaseSources : databaseSourcesReducer,
  paymentMethods: paymentsMethodReducer,
  languageOptions: appSettingLangReducer,
  areaUnitOptions: appSettingAreaReducer,
  userLang: userLangReducer,
  userStudyArea: userStudyAreaReducer
});
