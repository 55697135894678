import { toast } from "react-toastify";
import { formService } from "../../services";
import { formConstants, uiConstants } from "../constants";

const create = (data, navigate, toaster, setLoading, setCanSubmit) => (dispatch) => {
  setLoading(true)
  if (data.fields.length === 0) {
    dispatch({
      type: uiConstants.SET_ERRORS,
      payload: "Form fields required",
    });
    toaster.show({
      title: 'Oups error !',
      message: `Form fields required`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
  } else {
    dispatch({
      type: uiConstants.CLEAR_SUCCESS,
    });
    dispatch({ type: uiConstants.LOADING_UI });
    formService
      .create(data)
      .then((response) => {
        toaster.show({
          title: 'Success !',
          message: `Form successfully created`,
          greedy: true,
          type: 'success'
        });
        setLoading(false)
        dispatch({
          type: formConstants.CREATE_FORMS,
          payload: response.data.data,
        });
        // toast.success(response.data.message)
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
        navigate("/adm/forms/all", { replace: false });
        setCanSubmit(false)
      })
      .catch((response) => {
        // toast.error(response.response.data.message);
        toaster.show({
          title: 'Oups error !',
          message: `${response.response.data.message}`,
          greedy: true,
          type: 'error'
        });
        setLoading(false)
        setCanSubmit(false)
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  }
};

const forms = () => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    formService
      .getAll()
      .then((response) => {
        dispatch({
          type: formConstants.RETRIEVE_FORMS,
          payload: response.data.data,
        });
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .then(() => {
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .catch((response) => {
        toast.error(response.data.message);
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  };
};

export const updateForms = (id, data, toaster, setLoading, setCanSubmit, navigate) => async (dispatch) => {
  setLoading(true)
  try {
    const res = await formService.update(id, data);
    if(res.status === 200){
      console.log("res ====>", res)
      toaster.show({
        title: 'Success !',
        message: `Form successfully updated`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      dispatch({
        type: formConstants.UPDATE_FORMS,
        payload: res.data.data,
      });
      navigate("/adm/forms/all", { replace: false });
      setCanSubmit(false)
    }
    

    return Promise.resolve(res.data.data);
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    setCanSubmit(false)
    return Promise.reject(err);
  }
};

export const deleteForms = (id, toaster, setIsLoading, toggleModal) => async (dispatch) => {
  setIsLoading(true)
  try {
    const res = await formService.remove(id);
    if(res.status === 200){
      toaster.show({
        title: 'Success !',
        message: `Form successfully deleted`,
        greedy: true,
        type: 'success'
      });
      setIsLoading(false)
      toggleModal()
    }
    dispatch({
      type: formConstants.DELETE_FORMS,
      payload: { id },
    });
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setIsLoading(false)
    return Promise.reject(err);
  }
};

export const formActions = {
  create,
  forms,
  deleteForms,
  updateForms
};
