import { useState, Fragment, createRef, useEffect, } from "react";
import {Transition, Listbox, Dialog } from '@headlessui/react';
import { Input,Textarea, SelectField, InputRadio, Heading, Paragraph,ButtonWithBg,Tag } from "../../../components/Kits";
import { AiOutlineInfoCircle , AiOutlineClose, AiOutlinePlus, AiOutlineDelete} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft, BsCheckLg } from 'react-icons/bs'
import { Modal } from "../../../components/Modals";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useToaster } from "../../../contexts/toaster";
import {
    databaseSourceActions
  } from "../../../redux/actions";
  import { checkSelectValues, processArray } from "../../../utils/formatFormsData";
import { useTranslation } from "react-i18next";
window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");


export default function NewDatabase(){
    const { t } = useTranslation(["common", "project", "form", "dataSource"]);
    const formInitialState = {
        name: "",
        description: "",
        fields: [],
    };
    const navigate = useNavigate();
    const toaster = useToaster()
    const [formBuild, setFormBuild] = useState(formInitialState);
    const [formBuilder, setFotmBuilder] = useState(null);
    const [loading, setLoading] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const databaseSources = useSelector((state) => state.databaseSources);



    const dispatch = useDispatch();

    const [options, setOptions] = useState({
        dataType: "json",
        disabledActionButtons: ["data", "clear", "save"],
        disableFields: ["autocomplete", "header", "hidden", "paragraph", "button"],
        editOnAdd: true,
        onAddField: function (fieldId, field) {
            setFormBuild((prevState) => ({
              name: prevState.name,
              description: prevState.description,
            }));
        },
        // inputSets: [
        //     {
        //         label: 'Select data source',
        //         name: 'select-data-source', // optional - one will be generated from the label if name not supplied
        //         showHeader: false, // optional - Use the label as the header for this set of inputs
        //         icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-database" viewBox="0 0 16 16">
        //             <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z"/>
        //         </svg>`,
        //         id: 'block-select-data-source',
        //         fields: [
        //             {
        //                 type: 'select',
        //                 label: 'Select data source',
        //                 className: 'form-control select-data-source',
        //                 values: databaseSources.map((data, idx) => (
        //                     {
        //                         id: data.id,
        //                         label: data?.name,
        //                         // value: `option-${idx + 1}`,
        //                         selected: false,
                                
        //                     }
        //                 )),
        //             }
        //         ]
        //     }
        // ]
      });

      

    let fb = createRef();

  
    useEffect(() => {
      setFotmBuilder($(fb.current).formBuilder(options));
    }, []);
  
    //Submit form creation
    const handleSubmitForm = () => {
        console.log("fffff", formBuild);
        dispatch(databaseSourceActions.create(formBuild, navigate, toaster, setLoading, setCanSubmit));
    };

    // const getData = () => {
    //     setFormBuild({
    //         ...formBuild,
    //         fields: JSON.parse(formBuilder.actions.getData('json')),
    //     })
    //     setCanSubmit(true)
    // }

    const getData = () => {
        const data = JSON.parse(formBuilder.actions.getData('json'))

        if(checkSelectValues(data)){
            setFormBuild({
                ...formBuild,
                fields: processArray(data),
            })
            setCanSubmit(true)
        }else{
            toaster.show({
                title: 'Oups error !',
                message: `Assurez vous d'avoir selection une seule option pour chaque champs de type select data source contenant des données dynamiques`,
                greedy: true,
                type: 'error'
            });
            setCanSubmit(false)
        }
    }

    useEffect(()=>{
        if(canSubmit === true){
            handleSubmitForm()
        }
    }, [canSubmit])
   
    return(
        <div>
            <div className="max-w-[40%] mb-[50px]">
                <Heading title={t("dataSource:createnewdatasource")} mb={"10px"} />
            </div>
            <form onSubmit={(e)=> e.preventDefault()}>
                <span className="text-[16px] text-black mb-[10px] block">{t("dataSource:datasourceinformation")}</span>
                <div className="bg-white px-4 pt-8 pb-4  rounded-[6px] mb-[50px]">
                    <div className="gap-4">
                        <div className="mb-[30px]">
                            <Input 
                                label={t("dataSource:datasourcename")}
                                smallLab={true}
                                placeholder={"ex: Database source name"}
                                type={'text'}
                                value={formBuild.name}
                                onChange={(e) =>{
                                    setFormBuild({
                                        ...formBuild,
                                        name: e.target.value,
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="mb-[30px] mt-[10px]">
                        <Textarea 
                            label={'Description'}
                            smallLab={true}
                            placeholder={"ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                            type={'text'}
                            value={formBuild.description}
                            onChange={(e) =>{
                                setFormBuild({
                                    ...formBuild,
                                    description: e.target.value,
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="mb-8">
                    <span className="text-[16px] text-black mb-[10px] block">{t("form:standardformfields")}:</span>
                    <div className="h-full w-full" id="fb-editor" ref={fb} />
                </div>
                <div className="flex items-center gap-4">
                    <ButtonWithBg 
                        title={t("common:cancel")}
                        type='button'
                        bg={'primary-opacity'}
                        isSmall={true}
                        onClick={()=> {
                            setFormBuild(formInitialState)
                            formBuilder.actions.clearFields()
                        }}
                    />
                    <ButtonWithBg 
                        title={loading ? t("common:inprogress") : t("common:save")}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        form="handleSubmitForm"
                        onClick={() => {getData()}}
                    />
                </div>
                
            </form>
        </div>
    )
}