import { userConstants } from '../constants'

const initialState = []

export default function (usersSearchList = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case userConstants.SEARCH_USER:
      usersSearchList.push(payload)
      return usersSearchList
    default:
      return usersSearchList
  }
}
