import { appSettingConstants } from '../constants'

const initialState = []

export default function (settingsLang = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case appSettingConstants.RETRIEVE_LANG:
      return payload
    default:
      return settingsLang
  }
}

