import React, { createRef, useEffect, useState, Fragment } from "react";
import $ from "jquery";
import {Transition, Listbox, Dialog } from '@headlessui/react';
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag,SelectField, FormikSelect } from "../../../components/Kits";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft, BsCheckLg } from 'react-icons/bs'
import { Modal } from "../../../components/Modals";
import { useDispatch, useSelector } from "react-redux";
import { useToaster } from "../../../contexts/toaster";
import { BiCurrentLocation } from 'react-icons/bi'
import {
    formActions,
    formTypesActions,
    projectActions,
    projectRolesActions,
    userActions,
    billingAccountActions
} from '../../../redux/actions'
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import http from "../../../http-common";
import { formatDateToSend } from "../../../utils/format-date";
import { filterAndReplaceNames } from "../../../utils/filterAndReplaceNames";
import ViewForm from "./modals/ViewForm";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");

export default function NewProject(){
    const formTypes = useSelector((state) => state.formTypes);
    const projectRoles = filterAndReplaceNames(useSelector((state) => state.projectRoles));
    const projectTypes = useSelector((state) => state.projectTypes);
    const projects = useSelector((state) => state.projects);
    const { t } = useTranslation(["common", "project", "form"]);
    const data = [
        { name: 'All', id : 'all' },
        { name: 'Private', id: 'Private' },
        { name: 'Public', id: 'Public' }
    ]
    
    const toaster = useToaster()
    const [selected, setSelected] = useState(data[0])
    const [step, setStep] = useState(0)
    const [showBoxSearch, setShowBoxSearch] = useState(false)
    const [searchContent, setSearchContent] = useState()
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()

    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    const navigate = useNavigate();
    const formInitialState = {
        formTypeId: "private",
        name: "",
        description: "",
        fields: [],
    };

    console.log('projectTypes', projectTypes)

    const projectStructureInitialState = {
        name: "",
        description: "",
        formId: "",
        billingAccountId: "",
        projectUsers: [],
        startDate: "",
        endDate: "",
        executeZone: "anywhere",
        executeFrom: "Mobile",
        activeLocationExecution: false,
        isImpacting: false,
        projectTypeId: projectTypes[0]?.id
    };

    const projectUserInitialState = {
        firstname: "",
        lastname: "",
        phone: "",
        password: "",
        email: "",
        role: "",
    };
    const [useExternalProject, setUseExternalProject] = useState(false)
    const [configuration, setConfiguration] = useState({
        fieldName: '',
        id: '',
        surveyDatasKeysUsed: []
    })

    const [projectUser, setProjectUser] = useState(projectUserInitialState);
    const [projectStructure, setProjectStructure] = useState(
        projectStructureInitialState
    );
    const [formBuild, setFormBuild] = useState(formInitialState);
    const [formBuilder, setFotmBuilder] = useState(null);
    //   const billingAccounts = useSelector((state) => state.billingAccounts);
    const [searchUser, setSearchUser] = useState({ email: "" });
    const [roleSelected, setRoleSelected]= useState({userId: '', role:[]})
    const [resultSearch, setResultSearch] = useState(null)
    const [loading, setLoading] = useState(false)
    const [getGroupData, setGetGroupData] = useState(false)
    const [isCompleteStep, setIsCompleteStep]= useState(false)
    //   const forms = useSelector((state) => state.forms);
    const [getFields, setGetFields] = useState()
    const [usersFiltered, setUsersFiltered] = useState([]);
    const user = useSelector((state) => state.user);
    let users = useSelector((state) =>
        state.projectUsers.filter((u) => u.id !== user.userId)
    );

    const [options, setOptions] = useState({
        dataType: "json",
        disabledActionButtons: ["data", "clear", "save"],
        disableFields: ["autocomplete", "header", "hidden", "paragraph"],
        editOnAdd: true,
        // prepend: true,
        // fieldRemoveWarn: true,
        onAddField: function(fieldId, field) {
        console.log(this);
        setFormBuild((prevState) => ({
            ...formBuild,
            fields: [...prevState.fields, { fieldId: field }],
        }));
        },
        onRemoveField: function(fieldId, field) {
        console.log("d");
        },
    });

    const usersSearchList = useSelector((state) => state.usersSearchList);

    const [projectUsersSelected, setProjectUsersSelected] = useState([]);
    const [countChar, setCountChar] = useState(0);
    const dispatch = useDispatch();  
    let fb = createRef();

    // const [forms, setForms] = useState([])
    const forms = useSelector((state) => state.forms)
    const [billingAccounts, setBillingAccounts] = useState([])

    console.log('billingAccounts', billingAccounts)

    async function refreshed(what) {
        await http.get( what === "forms" ? `/forms` : 
        what === "billingAccounts" ? `/billing-accounts` : 
        `/${what}?limit=2000&columns=["name"]&populates=[]`)
        .then(function (response) {
            if(response.status === 200) {
                what === "billingAccounts" ? setBillingAccounts(response.data.data) :
                console.log("")
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    };

    // useEffect(() => {
    //     refreshed("forms")
    // }, [])

    useEffect(() => {
        refreshed("billingAccounts")
    }, [])

    useEffect(() => {
        dispatch(formActions.forms());
        dispatch(projectRolesActions.projectRoles());
        dispatch(userActions.plateFormUsers());
        dispatch(billingAccountActions.getAllBillingAccount());

        setFormBuild({
        ...formBuild,
        formTypeId: formTypes.find((f) => {
            if (f.name.toLowerCase() === "private") return f.name;
        }),
        });
        setProjectUsersSelected([...projectUsersSelected, user]);
    }, []);

    useEffect(() => {
        setFotmBuilder($(fb.current).formBuilder(options));
    }, []);

    useEffect(()=>{
        if(projectStructure.startDate && projectStructure.endDate  && projectStructure.executeZone && projectStructure.executeFrom){
            setIsCompleteStep(true)
        }
    }, [projectStructure])


    const removeOrAddUserOnProject = (userSearch, idx, key) => {
        if (key !== 0) {
        const userFiltered = projectUsersSelected.find((u) => u.userId === userSearch.id);

        console.log('first userFiltered', userFiltered)
        console.log('first user', user)
        console.log('first user w', projectUsersSelected)
        console.log('first user u', userSearch)
        
        if (userFiltered === undefined) {
            setProjectUsersSelected([...projectUsersSelected, {...userSearch, userId:userSearch.id}]);
            setProjectStructure((prevState) => ({
            ...prevState,
            projectUsers: [...prevState.projectUsers, { userId: userSearch.id }],
            }));
        } else {
            if(userFiltered?.userId === user?.userId){
                toaster.show({
                    title: 'Infos !',
                    message: `You can't add your own account to the project`,
                    greedy: true,
                    type: 'info'
                });
            }else{
                setProjectUsersSelected(
                    projectUsersSelected.filter((u) => u.userId != userSearch.id)
                );
                setProjectStructure((prevState) => ({
                    ...prevState,
                    projectUsers: prevState.projectUsers.filter((u) => u.id != userSearch.id),
                }));
            }
            
        }
        
        }
    };

    console.log('projectUsersSelected', projectUsersSelected)
  
    const isBindProjectOk = () => {
        if(useExternalProject){
            if(configuration.fieldName && configuration.id && configuration.surveyDatasKeysUsed.length > 0){
                return true
            } else {
                return false
            }
        }else{
            return true
        }
    }

  const handleSubmitProject = async () => {
    // e.preventDefault();
    console.log("projectStructure", projectStructure);
    let dataToSend 
    if(useExternalProject){
        dataToSend = {
            ...projectStructure,
            startDate: projectStructure.startDate ? formatDateToSend(projectStructure.startDate) : '',
            endDate: projectStructure.endDate ? formatDateToSend(projectStructure.endDate) : '',
            configuration: {
                useExternalProject: true,
                externalProject: {
                    ...configuration,
                    fieldName: configuration?.fieldName[0]?.toLowerCase() + configuration?.fieldName?.slice(1)
                }
            }
        }
    } else {
        dataToSend = {
            ...projectStructure,
            startDate: projectStructure.startDate ? formatDateToSend(projectStructure.startDate) : '',
            endDate: projectStructure.endDate ? formatDateToSend(projectStructure.endDate) : '',
        }
    }

    if(projectStructure.name){
        if(isBindProjectOk()){
            if(loading===false){
                dispatch(projectActions.create(dataToSend, navigate, toaster, setLoading));
            }
        } else{
            toaster.show({
                title: 'Error !',
                message: `Veuillez vérifiez que tous les champs de l'étape 3 ont été bien rempli`,
                greedy: true,
                type: 'error'
            });
            setLoading(false)
        }
    }else{
        toaster.show({
            title: 'Error !',
            message: `The name field can't be empty, please complete this field`,
            greedy: true,
            type: 'error'
        });
        setLoading(false)
    }
  };

  const handleSearchUser = (e) => {
      console.log('launch')
    e.preventDefault();

    dispatch(userActions.searchUser(searchUser, setResultSearch));
  };

  useEffect(()=>{
    if(searchUser?.email == ''){
        setResultSearch(null)
    }
  }, [searchUser])

  const [groupedData, setGroupedData] = useState([]);


  useEffect(()=>{
        setGroupedData(projectStructure?.projectUsers?.reduce((acc, curr) => {
            const { userId } = curr;
            if (!acc[userId]) {
              acc[userId] = [];
            }
            acc[userId].push({role: projectRoles?.filter(x => x?.id === curr?.roleId)[0]});
            return acc;
          }, {}))
  }, [getGroupData])

    const handleChooseSurveyDatasKeysUsed = (name) => {
        // Vérifier si l'élément est déjà présent dans le tableau
        const isAlreadySelected = configuration.surveyDatasKeysUsed.includes(name);

        // Mettre à jour le tableau en conséquence
        if (isAlreadySelected) {
        const updatedKeysUsed = configuration.surveyDatasKeysUsed.filter(
            (key) => key !== name
        );
        setConfiguration({
            ...configuration,
            surveyDatasKeysUsed: updatedKeysUsed,
        });
        } else {
        setConfiguration({
            ...configuration,
            surveyDatasKeysUsed: [...configuration.surveyDatasKeysUsed, name],
        });
        }
    };

    const StepOne = () => {
        return(
            <div>
                <span className="text-[16px] text-black">{t("project:step")} 1 : {t("project:generalInformation")}</span>
                <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                    <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                    />
                </div>
                {/* <div className="mb-[30px]">
                    <SelectField 
                        label={'Project type'}
                        type={'number'}
                        smallLab={true}
                        options= {[
                            {
                                // value: '---------',
                                name: '--------------'
                            },
                            ...projectTypes?.map(x => (
                                {
                                    value: x?.id,
                                    name: x?.name
                                }
                            ))
                        ]}
                        value={projectStructure.projectTypeId}
                        onChange={(e) =>{
                            setProjectStructure((prevState) => ({
                                ...prevState,
                                projectTypeId: e.target.value,
                            }))
                        }}
                    />
                </div> */}
                <div className="mb-[30px]">
                    <Input 
                        label={t('project:name')}
                        placeholder={"ex: project name"}
                        type={'text'}
                        smallLab={true}
                        maxLength="100"
                        value={projectStructure.name}
                        onChange={(e) =>{
                            setProjectStructure((prevState) => ({
                                ...prevState,
                                name: e.target.value,
                            }))
                            e.preventDefault()
                            setCountChar(e.target.value.length);
                        }}
                    />
                    <span className="text-[14px] text-black block text-right mt-[5px]">
                        {countChar} / 100
                    </span>
                </div>
                <div className="mb-[30px]">
                    <Textarea 
                        label={t('project:descriptioon')}
                        placeholder={"ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                        type={'text'}
                        smallLab={true}
                        value={projectStructure.description}
                        onChange={(e) =>{
                            setProjectStructure((prevState) => ({
                              ...prevState,
                              description: e.target.value,
                            }))
                            e.preventDefault()
                        }}
                    />
                </div>
                <div className="flex items-center justify-between">
                    &nbsp;
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 text-white flex items-center gap-1`}
                        onClick={()=> setStep( step + 1)}
                    >
                        <span>{t("common:next")}</span>
                        <BsChevronRight />
                    </button>
                </div>
            </div>
        )
    }

    const StepTwo = () => {
        return(
            <div>
                <span className="text-[16px] text-black">{t("project:step")} 2 :  {t("project:selectform")}</span>
                <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                    <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                    />
                </div>
                <div className=" items-center justify-between gap-4 mb-[30px] hidden">
                    <div className="flex items-center gap-4">
                        <div className="flex justify-between md:justify-start items-center gap-3">
                            <span className="text-[14px] block text-[#000]  font-poppins font-regular">Filter: </span>
                            <Listbox value={selected} onChange={setSelected}>
                                <div className="relative">
                                    <Listbox.Button className="inline-flex h-[40px] min-w-[98px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-tertiary-gray-500 focus:outline-none">
                                        <span className="block text-[14px] text-[#000]  mr-4 font-title font-medium">{selected.name}</span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute left-0 w-48 mt-2 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {data?.map((item, itemIdx) => (
                                                <Listbox.Option
                                                    key={itemIdx}
                                                    className={({ active }) =>
                                                        `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                            active ? 'bg-[#00c28818] text-myGreen' : 'text-black text-opacity-50'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block text-[14px] font-poppins ${
                                                                    selected ? 'font-normal' : 'font-normal'
                                                                }`}
                                                            >
                                                                {item.name}
                                                            </span>
                                                            
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        {/*<ButtonWithBg 
                            title={'New form'}
                            type='button'
                            bg={'secondary-opactity'}
                            isSmall={true}
                        />*/}
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-[30px]">
                {
                    forms?.map((form, idx) =>(
                        <label>
                            <input 
                                type='radio' 
                                name="form" 
                                value={form?.id} 
                                onChange={(e) =>{ setProjectStructure((prevState) => ({
                                        ...prevState,
                                        formId: form.id,
                                    }));
                                }} 
                                checked={projectStructure.formId === form?.id ? true : false}
                                className="input-radio hidden" 
                            />
                            <div className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between border relative form-card">
                                <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[30px] w-[30px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                    <BsCheckLg className='text-white text-[14px]' />
                                </div>
                                <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                                    {form?.name}
                                </h4>
                                <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4">
                                    {form?.description}
                                </p>
                                <div className="flex items-center justify-between gap-4">
                                    <div 
                                        className="flex items-center gap-2" 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setGetFields(form)
                                            setModalMode("view_field")
                                            toggleModal()
                                        }}
                                    >
                                        <span className="text-primary-blue-500 text-[14px] cursor-pointer">{t("project:seefields")}</span>
                                    </div>
                                    <Tag 
                                        title={t("project:private")}
                                        color={'text-[#6BCB77]'}
                                        bg={'bg-[#f0faf1]'}
                                    />
                                </div>
                            </div>
                        </label>
                    ))
                }
                </div>
                <div className="flex items-center justify-between">
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 bg-opacity-10 text-primary-blue-500 flex items-center gap-1`}
                        onClick={()=> setStep( step - 1)}
                    >
                        <BsChevronLeft />
                        <span>{t("common:previous")}</span>
                    </button>
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 text-white flex items-center gap-1`}
                        onClick={()=> setStep( step + 1)}
                    >
                        <span>{t("common:next")}</span>
                        <BsChevronRight />
                    </button>
                </div>
            </div>
        )
    }

    const StepThree = () => {
        return(
            <div>
                <span className="text-[16px] text-black">{t("project:step")} 3 : {t("project:bindproject")}</span>
                <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                    <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                    />
                </div>
                {/*  */}
                <div className="mb-[30px]">
                    <span className="block text-[14px]">
                        {t("project:wouldyouliketouseanexternalproject")}
                    </span>
                    <div className="flex items-center gap-[40px]">
                        <InputRadio 
                            label={t('common:no')}
                            name="useExternalProject"
                            smallLab={true}
                            checked={useExternalProject === false ? true : false }
                            onChange={() => {
                                setUseExternalProject(false)
                                setConfiguration((prevState) => ({
                                    ...prevState,
                                    fieldName: '',
                                    id: ''
                                }))
                            }}
                        />
                        <InputRadio 
                            label={t('common:yes')}
                            name="useExternalProject"
                            smallLab={true}
                            checked={useExternalProject === true ? true : false }
                            onChange={() => setUseExternalProject(true)}
                        />
                    </div>
                    {
                        useExternalProject && 
                        <div className="pt-[50px] flex gap-[30px]">
                            <div className="mb-[30px] flex-1">
                                <Input 
                                    label={t("project:fieldname")}
                                    placeholder={"ex: project name"}
                                    type={'text'}
                                    smallLab={true}
                                    value={configuration.fieldName}
                                    onChange={(e) =>{
                                        const value = e.target.value;

                                        // Regex pour vérifier les caractères spéciaux et les chiffres
                                        const invalidFirstCharRegex = /^[!@#\$%\^\&*\)\(+=._\-0-9]/;

                                        // Regex pour vérifier les lettres et les chiffres seulement
                                        const validCharRegex = /^[a-zA-Z0-9]*$/;

                                        // Si le premier caractère est un caractère spécial ou un chiffre, ne pas mettre à jour l'état
                                        if (value.length === 1 && invalidFirstCharRegex.test(value)) {
                                            return;
                                        }

                                        if (!validCharRegex.test(value)) {
                                            return;
                                        }

                                        setConfiguration((prevState) => ({
                                            ...prevState,
                                            fieldName: e.target.value,
                                        }))
                                        e.preventDefault()
                                        setCountChar(e.target.value.length);
                                    }}
                                />
                            </div>
                            <div className="mb-[30px] flex-1">
                                <SelectField 
                                    label={t('project:project')}
                                    smallLab={true}
                                    options= {[
                                        {
                                            value: '',
                                            name: '--------------'
                                        },
                                        ...projects?.map(x => (
                                            {
                                                value: x?.id,
                                                name: x?.name
                                            }
                                        ))
                                    ]}
                                    value={configuration.id}
                                    onChange={(e) =>{
                                        setConfiguration((prevState) => ({
                                            ...prevState,
                                            id: e.target.value,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        configuration.id && <>
                            <span className="block text-[14px] mb-[30px]">
                                {`${t("project:selectthefieldyouwishtohighlight")}:`}
                            </span>
                            {
                                projects.find(x => x?.id === configuration?.id)?.form ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {
                                            projects.find(x => x?.id === configuration?.id)?.form?.fields?.map((el, index) => (
                                                <div 
                                                    key={index} 
                                                    className={`border rounded-lg flex items-center gap-2 px-4 py-3 cursor-pointer hover:bg-secondary-green-500/10 hover:border-secondary-green-500 transition-all ease-linear duration-200
                                                    ${configuration.surveyDatasKeysUsed.includes(el?.name) ? 'bg-secondary-green-500/10 border-secondary-green-500' : 'border-gray-100' }`}
                                                    onClick={()=>handleChooseSurveyDatasKeysUsed(el?.name)}
                                                >
                                                    <div className={`h-[20px] w-[20px] border rounded shrink-0 flex items-center justify-center
                                                        ${configuration.surveyDatasKeysUsed.includes(el?.name) ? 'bg-secondary-green-500/10 border-secondary-green-500' : 'border-gray-300'}`}
                                                    >
                                                        { configuration.surveyDatasKeysUsed.includes(el?.name) && <div className="h-[15px] w-[15px] rounded bg-secondary-green-500"></div>}
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[14px]">{el?.label}</h4>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="px-4 py-8 border border-dashed rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4 justify-center">
                                        <Paragraph 
                                            content={"No form is associated with this project. Please select another or add a form to the project. "}
                                        />
                                    </div>
                                )
                            }
                        </>
                    }
                </div>
                <div className="flex items-center justify-between">
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 bg-opacity-10 text-primary-blue-500 flex items-center gap-1`}
                        onClick={()=> setStep( step - 1)}
                    >
                        <BsChevronLeft />
                        <span>{t("common:previous")}</span>
                    </button>
                    <button 
                        type="submit" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 text-white flex items-center gap-1`}
                        onClick={()=> setStep( step + 1)}
                    >
                        <span>{t("common:next")}</span>
                        <BsChevronRight />
                    </button>
                </div>
            </div>
        )
    }

    const StepFour = () => {
        return(
            <div>
                <span className="text-[16px] text-black">{t("project:step")} 3 : {t("project:buildteam")}</span>
                <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                    <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                    />
                </div>
                <form onSubmit={handleSearchUser} className="mb-[30px] w-[450px] relative">
                    <Input 
                        label={t("project:selectuser")}
                        placeholder={"ex: @mael124"}
                        type={'search'}
                        smallLab={true}
                        value={searchUser.email}
                        onChange={(e)=> {
                            setShowBoxSearch(true)
                            // setSearchContent(e.target.value)
                            setSearchUser({ email: e.target.value })
                        }}
                    />
                    
                    {
                        (showBoxSearch && resultSearch && searchUser.email !== '') &&
                        <ul className="w-full border rounded-[6px] h-[150px] absolute top-[120%] left-0 bg-white overflow-x-hidden z-30">
                            <li 
                                onClick={(e) => {
                                    removeOrAddUserOnProject(resultSearch)
                                    setSearchUser({email: ''})
                                    setShowBoxSearch(false)
                                    setResultSearch(null)
                                }}
                                className="flex items-center gap-2 p-2 border-b border-dashed cursor-pointer">
                                <div className="h-[40px] w-[40px] rounded-full overflow-hidden">
                                    <img
                                        src="/images/avatar.png"
                                        alt="profil"
                                        className="h-full w-full object-cover" 
                                    />
                                </div>
                                <div>
                                    <h4 className="text-[14px] text-black leading-[10px]">{resultSearch?.firstName +" "+ resultSearch?.lastName}</h4>
                                    <span className="text-[12px] text-black text-opacity-50">{resultSearch?.email}</span>
                                </div>
                            </li>
                                
                        </ul>
                    }
                </form>
                <div className='relative bg-white rounded-[6px] border border-[#EFEFEF]  scrollbar mb-[30px] '>
                    <div className="w-full">
                        <table className="w-full text-[#484964]">
                            <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                                <tr className=" text-left text-opacity-90 h-[60px]">
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        Account
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        Role
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={`relative divide-y`}>
                                {
                                    projectUsersSelected?.map((user, idx) =>(
                                        <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] w-[500px]">
                                                <div className="flex items-center gap-2 py-2">
                                                    <div className="h-[40px] w-[40px] rounded-full overflow-hidden">
                                                        <img
                                                            src="/images/avatar.png"
                                                            alt="profil"
                                                            className="h-full w-full object-cover"
                                                        />
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[14px] text-black leading-[10px]">{user?.firstName +" "+ user?.lastName}</h4>
                                                        <span className="text-[12px] text-black text-opacity-50">{user?.email}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] align-middle w-[300px]">
                                                {
                                                    idx === 0 ?
                                                    <div className="focus:outline-none w-full bg-tertiary-gray-500 h-[40px] rounded-[6px] px-2 flex items-center justify-center">
                                                        {t("project:projectowner")}
                                                    </div>

                                                    :
                                                    <>
                                                    <FormikSelect 
                                                        name={user.id}
                                                        options= {projectRoles?.filter(x => x?.id !== 'byIhVMF5lVxU529Wlpkg')?.map((role, idx) =>(
                                                            {
                                                                label: t(`project:${role?.name?.toLowerCase()?.replaceAll(" ", "")}`),
                                                                value: role.id
                                                            }
                                                        ))}
                                                        isMulti
                                                        onChange={(e) => {
                                                            let findIndex = projectStructure.projectUsers.findIndex(
                                                            (u) => u.userId === user.id
                                                            );
                                                            let projectUser = projectStructure.projectUsers[findIndex];
                                                            setGetGroupData(!getGroupData)
                                                            projectUser.roles = e.map(option => option?.value);
                                                            // projectUser.roleId = e.value;
                                                            projectStructure.projectUsers[findIndex] = projectUser;
                                                            
                                                        }}
                                                        value={
                                                            projectRoles?.filter(item => projectStructure?.projectUsers[idx-1]?.roles?.includes(item?.id))?.map((role)=>(
                                                                {
                                                                    label: t(`project:${role?.name?.toLowerCase()?.replaceAll(" ", "")}`),
                                                                    value: role.id
                                                                }
                                                            ))
                                                        }

                                                        // defaultValue={
                                                        //     projectRoles.filter(item => data1.includes(item.id))
                                                        // }
                                                    />
                                                    </>
                                                }
                                                
                                            </td>
                                            <td className="px-4 py-2   whitespace-nowrap">
                                                { idx > 0 &&
                                                    <div className="w-[150px]">
                                                        <button 
                                                            onClick={() =>
                                                                removeOrAddUserOnProject(user, null, idx)
                                                            }
                                                            className="bg-white border border-red-500 px-3 py-2 rounded-[6px] text-[12px] font-[500] flex items-center gap-6">
                                                            <span className="text-red-500 ">{t("project:removemember")}</span>
                                                        </button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 bg-opacity-10 text-primary-blue-500 flex items-center gap-1`}
                        onClick={()=> setStep( step - 1)}
                    >
                        <BsChevronLeft />
                        <span>{t("common:previous")}</span>
                    </button>
                    <button 
                        type="submit" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 text-white flex items-center gap-1`}
                        onClick={()=> setStep( step + 1)}
                    >
                        <span>{t("common:next")}</span>
                        <BsChevronRight />
                    </button>
                </div>
            </div>
        )
    }

    console.log("new Date().toISOString()", new Date().toISOString().split("T")[0])

    const StepFive = () => {
        return(
            <form>
                <span className="text-[16px] text-black">{t("project:step")} 4 : {t("project:definerules")}</span>
                <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                    <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                    />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-[30px]">
                        <Input 
                            label={t('project:surveystartdate')}
                            placeholder={"ex: project name"}
                            type={'date'}
                            smallLab={true}
                            min={new Date().toISOString().split("T")[0]}
                            value={projectStructure.startDate}
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                  ...prevState,
                                  startDate: e.target.value,
                                }));
                            }}
                        />
                    </div>
                    <div className="mb-[30px]">
                        <Input 
                            label={t('project:surevyenddate')}
                            type={'date'}
                            smallLab={true}
                            value={projectStructure.endDate}
                            min={projectStructure.startDate}
                            disabled={projectStructure.startDate ? false : true}
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                  ...prevState,
                                  endDate: e.target.value,
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-3 mb-[30px]">
                    <div className="">
                        <span className="block text-[14px]">
                            {t("project:wheretoexecutesurvey")}
                        </span>
                        <InputRadio 
                            label={t('project:anywhere')}
                            value="anywhere"
                            name="venue"
                            smallLab={true}
                            checked={projectStructure.executeZone === 'anywhere' ? true : false }
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                ...prevState,
                                executeZone: e.target.value,
                                }));
                            }}
                        />
                        <InputRadio 
                            label={t('project:precisearea')}
                            value="precise_area"
                            name="venue"
                            smallLab={true}
                            disabled={true}
                            // checked={projectStructure.executeZone === 'precise_area' ? true : false }
                            // onChange={(e) => {
                            //     setProjectStructure((prevState) => ({
                            //     ...prevState,
                            //     executeZone: e.target.value,
                            //     }));
                            // }}
                        />
                    </div>
                    <div className="">
                        <span className="block text-[14px]">
                            {t('project:activateLocation')}
                        </span>
                        <InputRadio 
                            label={t('common:no')}
                            name="activateLocation"
                            smallLab={true}
                            checked={projectStructure.activeLocationExecution === false ? true : false }
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                ...prevState,
                                activeLocationExecution: false,
                                }));
                            }}
                        />
                        <InputRadio 
                            label={t('common:yes')}
                            name= 'activeArea'
                            name="activateLocation"
                            smallLab={true}
                            checked={projectStructure.activeLocationExecution === true ? true : false }
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                ...prevState,
                                activeLocationExecution: true,
                                }));
                            }}
                        />
                    </div>
                    <div className="">
                        <span className="block text-[14px]">
                            {t('project:surveyexecutablefrom')}
                        </span>
                        <InputRadio 
                            label={'Web'}
                            value="Web"
                            name="platform"
                            smallLab={true}
                            disabled={true}
                            // checked={projectStructure.executeFrom === "Web" ? true : false }
                            // onChange={(e) => {
                            //     setProjectStructure((prevState) => ({
                            //     ...prevState,
                            //     executeFrom: e.target.value,
                            //     }));
                            // }}
                        />
                        <InputRadio 
                            label={'Mobile'}
                            value="Mobile"
                            name="platform"
                            smallLab={true}
                            checked={projectStructure.executeFrom === "Mobile" ? true : false }
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                ...prevState,
                                executeFrom: e.target.value,
                                }));
                            }}
                        />
                    </div>
                    <div className="col-span-2 mt-[30px]">
                        <span className="block text-[14px]">
                            {t('project:doeschange')}
                        </span>
                        <InputRadio 
                            label={t('common:no')}
                            name= 'needFormalValidate'
                            smallLab={true}
                            checked={projectStructure.isImpacting === false ? true : false }
                            onChange={(e) => {
                                setProjectStructure((prevState) => ({
                                ...prevState,
                                isImpacting: false,
                                }));
                            }}
                        />
                        <InputRadio 
                            label={t('common:yes')}
                            name= 'needFormalValidate'
                            disabled={true}
                            smallLab={true}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 bg-opacity-10 text-primary-blue-500 flex items-center gap-1`}
                        onClick={()=> setStep( step - 1)}
                    >
                        <BsChevronLeft />
                        <span>{t("common:previous")}</span>
                    </button>
                    <button 
                        type="submit" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 text-white flex items-center gap-1`}
                        onClick={()=> setStep( step + 1)}
                    >
                        <span>{t("common:next")}</span>
                        <BsChevronRight />
                    </button>
                </div>
            </form>
        )
    }

    const StepSix = () => {
        return(
            <form>
                <span className="text-[16px] text-black">{t("project:step")} 5 :  {t("project:addapaiementmethodforbilling")}</span>
                <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                    <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                    />
                </div>
                <div className="items-center hidden justify-end mb-[30px]">
                    <ButtonWithBg 
                        title={t('project:addnewpayment')}
                        type='button'
                        bg={'secondary-opactity'}
                        isSmall={true}
                        onClick={()=>{
                            setModalMode("add_payment")
                            toggleModal()
                        }}
                    />
                </div>
                <div className="grid grid-cols-3 gap-4 mb-[30px]">
                    {
                        billingAccounts?.filter(x => x?.status === 'Active')?.map((el, index) => (
                            <label key={index}>
                                <input 
                                    type='radio' 
                                    name="paymentMethod" 
                                    value={el?.id} 
                                    onChange={(e) =>{ setProjectStructure((prevState) => ({
                                        ...prevState,
                                        billingAccountId: el?.id,
                                    }));
                                }} 
                                checked={projectStructure.billingAccountId === el?.id ? true : false}
                                    className="input-radio hidden" 
                                />
                                <div className="bg-white rounded-[6px] p-[15px] border relative form-card cursor-pointer">
                                    <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[30px] w-[30px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                        <BsCheckLg className='text-white text-[14px]' />
                                    </div>
                                    <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-[65px]">
                                        {el?.name}
                                    </h4>
                                    {
                                        el?.billingAccountType?.name?.toLowerCase().includes('pb') ? (
                                            <div className="flex items-center justify-between gap-4">
                                                <div className="flex items-center gap-2">
                                                    <span className="text-primary-blue-500 text-[14px] cursor-pointer">{new Date(el?.startDate).toLocaleDateString()}</span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <span className="text-primary-blue-500 text-[14px] cursor-pointer">{new Date(el?.endDate).toLocaleDateString()}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-between gap-4">
                                                <div className="flex items-center gap-2">
                                                    <span className="text-primary-blue-500 text-[14px] cursor-pointer">{"..."+ el?.accountNumber?.split("-")[2]}</span>
                                                </div>
                                                <Tag 
                                                    title={'Visa'}
                                                    color={'text-[#6BCB77]'}
                                                    bg={'bg-[#f0faf1]'}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </label>
                        ))
                    }
                    
                </div>
                <div className="flex items-center justify-between">
                    <button 
                        type="button" 
                        className={`border-none font-normal w-fit px-4 h-[40px] text-[14px] rounded-[6px] bg-primary-blue-500 bg-opacity-10 text-primary-blue-500 flex items-center gap-1`}
                        onClick={()=> setStep( step - 1)}
                    >
                        <BsChevronLeft />
                        <span>{t("common:previous")}</span>
                    </button>
                    &nbsp;
                </div>
            </form>
        )
    }

    const fieldGroups =[
        StepOne(),
        StepTwo(),
        StepThree(),
        StepFour(),
        StepFive(),
        StepSix(),
    ]

    return(
        <div>
            <div className="max-w-[40%]">
                <Heading title={t("common:createproject")} mb={"10px"} />
            </div>
            <div className="flex items-center justify-between gap-4 mt-[40px]">
                <ul className="flex items-center gap-[40px]">
                    {
                        fieldGroups?.map((k,i) =>(
                            <li onClick={()=> setStep(i)} className={`h-[30px] w-[30px] cursor-pointer rounded-full ${step === i ? "border-secondary-green-500 text-secondary-green-500" : ((i === 0 && projectStructure.name ==='') || (i === 1 && step > 1  && projectStructure.formId ==='') || (i === 2 && !isBindProjectOk()) || (i===4 && step > 4 && isCompleteStep===false)) ? "border-red-500 text-red-500" : step > i ? "bg-secondary-green-500 text-white" : ""} ${step === i ? "after:bg-secondary-green-500" : i === 0 && projectStructure.name ==='' ? "after:bg-red-500" : step > i ? "after:bg-secondary-green-500" : "after:bg-black after:bg-opacity-10"} bg-white border  flex items-center justify-center text-[14px] relative last:after:hidden after:absolute after:h-[1px] after:w-[40px]  after:-right-[40px] after:top-1/2 after:-translate-y-1/2`}>
                                {  step > i ? 
                                    <>
                                        {((i===0 && projectStructure.name ==='') || (i === 1 && projectStructure.formId ==='') || (i===2 && !isBindProjectOk()) || (i===4 && isCompleteStep===false)) ? i+1 : <BsCheckLg className="text-[10px]" />}
                                    </>
                                    
                                    :
                                    
                                    i + 1
                                }
                            </li>
                        ))
                    }
                </ul>
                <div className="flex items-center gap-3">
                    <ButtonWithBg 
                        title={t("common:cancel")}
                        type='button'
                        bg={'primary-opacity'}
                        isSmall={true}
                        // disabled = {(projectStructure.description=='' && projectStructure.endDate=='' && projectStructure.executeFrom=='' && projectStructure.executeZone=='' && projectStructure.formId=='' && projectStructure.projectUsers.length==0 && projectStructure.startDate=='') ? true : false}
                        onClick={()=> {
                            setProjectStructure(projectStructureInitialState)
                            setStep(0)
                        }}
                    />
                    <ButtonWithBg 
                        title={loading ? t("common:inprogress") : t("common:save")}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        onClick={()=> handleSubmitProject()}
                    />
                </div>
            </div>
            <div className="bg-white p-4 rounded-[6px] mt-[40px]">
                {
                    fieldGroups[step]
                }
            </div>

            <ViewForm 
                isOpen={isOpen}
                toggleModal={toggleModal}
                getFields={getFields}
                t={t}
            />
        </div>
    )
}


