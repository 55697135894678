
export default function TableSkeleton({columns, length}){
    const arr1 = Array.from(new Array(length))
    const arr = Array.from(new Array(columns - 1))
    
    return(
        <>
            {
                arr1.map(index => (
                    <tr key={index} className="animate-pulse text-left border-b border-dashed border-[#EFEFEF]">
                        { arr.map((x,k) =>(
                            <td key={k} className="px-4">
                                <div className={`h-4 bg-gray-100 rounded-[2px]`}></div>
                            </td>
                        ))}
                        <td className='px-4 py-2'>
                            <div className="bg-gray-100 h-[30px] lg:w-2/3 rounded-[2px]"></div>
                        </td>
                    </tr>
                ))
            }
        </> 
    )
}