import { Link } from "react-router-dom";

export default function StartForFree(){

    return(
        <div className="max-w-[1440px] mx-auto lg:px-[112px] px-[16px] lg:py-[96px] py-[64px] relative">
            <div className="bg-[#F9FAFB] lg:p-[64px] p-[30px] rounded-2xl text-center">
                <h2 className="text-[#101828] lg:text-[30px] text-[24px] lg:leading-[38px] leading-[34px] font-[600]">
                    {`Start your 30-day free trial`}
                </h2>
                <p className="mt-[16px] lg:text-[20px] text-[16px] text-[#475467] lg:leading-[30px] leading-[26px] font-[400] mb-[40px]">
                    {`Join over 4,000+ startups already growing with Untitled.`}
                </p>
                <Link to={`/login`}>
                    <button className="bg-primary-blue-500 px-[16px] py-[10px] rounded-md text-white text-[14px]">
                        {`Get started`}
                    </button>
                </Link>
            </div>
        </div>
    )
}