import Base from "../../../layout/adm/base";
import BillingAddress from "../../../section/admin/BillingAccount/BillingAddress";

export default function AllBillingAddress(){

    return(
        <Base>
            <BillingAddress />
        </Base>
    )
}