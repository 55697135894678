import { countries } from "../constantes/countries"

const getCountryname = (alpha) => {
    let countryName = countries?.find(x => x.value === alpha)?.name
    
    return countryName ?? alpha
}

export {
    getCountryname
}