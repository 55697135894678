
export default function InputRadio({label, placeholder,smallLab, type, ...props}){

    return(
        <label className="relative block mt-2">
            <input
                className="inputRadio hidden"
                type={type ? type : 'radio'}
                {...props}
            />
            <div className="flex items-center gap-2 custom-radio">
                <div className="h-[20px] w-[20px] rounded-full border rd-check transition-all ease-out duration-150"></div>
                <span className={`${smallLab ? "text-[14px]" : "text-[16px]"} text-black`}>
                    {label}
                </span>
            </div>
        </label>
    )
}