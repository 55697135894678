export default function PrivacyPolicy(){

    return(
        <div className="max-w-[1024px] mx-auto py-[40px]">
            <h1 className="text-[30px] text-[#101828] font-[900] underline mb-[30px]">
                Politique de confidentialité 
            </h1>
            <h2 className="text-[20px] font-[900] text-[#101828] mb-4">
                Introduction
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`
                    Terros est une plateforme dédiée à la réalisation d'enquêtes de terrain et à la cartographie des parcelles en utilisant la technologie GPS. Elle permet par exemple aux coopératives agricoles de recenser leurs membres, et ensuite de cartographier leurs parcelles respectives. La présente politique de confidentialité vise à informer nos utilisateurs sur la manière dont vos données personnelles sont collectées, utilisées, stockées, protégées et éventuellement partagées.
                `}
            </p>
            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                Collecte des données personnelles
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`
                    Nous collectons les données personnelles suivantes vous concernant lorsque vous utilisez notre plateforme digitale :
                `}
            </p>
            <ul className="list-disc px-[20px] text-[16px] text-[#475467] space-y-2 my-4">
                <li>
                    Votre nom et prénoms
                </li>
                <li>
                    Votre numéro de téléphone
                </li>
                <li>
                    Votre adresse électronique
                </li>
                <li>
                    Des informations de paiement
                </li>
                <li>
                    Votre localisation
                </li>
            </ul>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                Utilisation des données personnelles
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`
                    Nous utilisons les données personnelles que nous collectons pour les finalités suivantes :
                `}
            </p>
            <ul className="list-disc px-[20px] text-[16px] my-4 space-y-2">
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Création d'un compte</span> : nous utilisons vos données personnelles pour créer votre compte sur notre plateforme. Ces données sont nécessaires pour que vous puissiez utiliser nos services.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Communication</span> : nous utilisons vos données personnelles pour communiquer avec vous, par exemple pour vous envoyer des notifications ou répondre à vos questions. Ces données nous permettent de vous fournir un service client de qualité.
                </li>
                <li className="text-[#475467]"> 
                    <span className="font-bold text-[#101828]">Analyse </span>: nous utilisons vos données personnelles pour analyser votre utilisation de notre plateforme, afin d'améliorer nos services. Ces données nous permettent de mieux comprendre vos besoins et de vous proposer des services plus adaptés.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Paiement des factures </span>: nous utilisons vos informations de paiement pour réaliser la facturation mensuelle pour l’utilisation de nos services.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Localisation </span>: votre localisation est utilisée lorsque vous réalisez des enquêtes de terrain, et en particulier la cartographie.
                </li>
            </ul>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                Partage des données personnelles
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Nous ne partageons vos données personnelles avec des tiers que dans les cas suivants :`}
            </p>
            <ul className="list-disc px-[20px] text-[16px] my-4 space-y-2">
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Avec nos prestataires de services </span> : nous partageons vos données personnelles avec nos prestataires de services, tels que nos fournisseurs d'hébergement, nos prestataires de paiement, etc., afin qu'ils puissent nous fournir leurs services. Ces prestataires sont tenus de respecter la confidentialité de vos données personnelles.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Avec votre consentement </span> : nous pouvons partager vos données personnelles avec des tiers avec votre consentement, tel que lorsque vous choisissez de partager votre contenu sur les réseaux sociaux.
                </li>
            </ul>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                Transfert des données personnelles
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Vos données personnelles peuvent être transférées à des destinataires situés dans des pays autres que le vôtre. Dans ce cas, nous nous assurons que ces destinataires appliquent des mesures de protection adéquates pour garantir la sécurité de vos données personnelles.`}
            </p>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                Sécurité des données personnelles
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Nous mettons en place des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données personnelles contre la perte, l'utilisation abusive, la divulgation non autorisée, la modification ou la destruction.`}
            </p>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                Vos droits
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Vous disposez des droits suivants sur vos données personnelles :`}
            </p>
            <ul className="list-disc px-[20px] text-[16px] my-4 space-y-2">
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Le droit d'accès</span> : vous avez le droit d'obtenir des informations sur les données personnelles que nous détenons à votre sujet.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Le droit de rectification</span> : vous avez le droit de demander la rectification des données personnelles inexactes ou incomplètes que nous détenons à votre sujet.
                </li>
                <li className="text-[#475467]"> 
                    <span className="font-bold text-[#101828]">Le droit à l'effacement </span>: vous avez le droit de demander l'effacement de vos données personnelles, sous réserve de certaines exceptions.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Le droit à la limitation du traitement </span>: vous avez le droit de demander la limitation du traitement de vos données personnelles, sous réserve de certaines exceptions.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Le droit à la portabilité des données </span>: vous avez le droit de recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine.
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Le droit d'opposition </span>: vous avez le droit de vous opposer au traitement de vos données personnelles, sous réserve de certaines exceptions.
                </li>
            </ul>

            <p className="text-[16px] text-[#475467]">
                {`Vous pouvez exercer ces droits en nous contactant à l'adresse suivante :`}
            </p>
            <ul className="list-disc px-[20px] text-[16px] my-4 space-y-2">
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Email</span> : <span className="text-primary-blue-500">aristide.yao@betterby.tech</span>
                </li>
                <li className="text-[#475467]">
                    <span className="font-bold text-[#101828]">Adresse géographique</span> : {`92HG+PV, Riviéra Bonoumin, Abidjan Côte d’Ivoire`}
                </li>
            </ul>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                {`Modifications de la politique de confidentialité`}
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur notre plateforme.`}
            </p>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                {`Exclusions`}
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Les entités qui utilisent cette plateforme Terros sont responsables de la collecte et du traitement des données personnelles de leurs propres clients. Terros n'a aucun contrôle sur ces données personnelles.`}
            </p>

            <h2 className="text-[20px] font-[900] text-[#101828] mt-[30px] mb-4">
                {`Cookies`}
            </h2>
            <p className="text-[16px] text-[#475467]">
                {`Terros utilise des cookies pour collecter des informations sur votre utilisation de la plateforme. Ces informations sont utilisées pour améliorer nos services`}
            </p>
        </div>
    )
}