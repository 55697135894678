import PropTypes from 'prop-types';
import React, { useState, useContext, createContext, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import {
  formActions,
  formTypesActions,
  moduleMappingActions,
  projectActions,
  projectRolesActions,
  surveyDatasStatusActions,
  userActions,
  billingAccountActions,
  billingTypesActions,
  billingAddressActions,
} from "../redux/actions";
import { projectStatusActions } from "../redux/actions/project.status.actions";

const LoadDataContext = createContext();

function ProvideLoadData({ children }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(projectActions.projects());
        dispatch(billingAccountActions.getAllBillingAccount());
        dispatch(billingAddressActions.getAllBillingAddress());
        dispatch(projectStatusActions.status());
        dispatch(formTypesActions.getTypes());
        dispatch(billingTypesActions.getTypes());
        dispatch(surveyDatasStatusActions.getAll());
        dispatch(formActions.forms());
        dispatch(userActions.plateFormUsers());
        dispatch(moduleMappingActions.getDataMap());
    }, []);

  return (
    <LoadDataContext.Provider
      value={{
        
      }}
    >
      {children}
    </LoadDataContext.Provider>
  );
}

export const useLoadData = () => useContext(LoadDataContext);

export default ProvideLoadData
ProvideLoadData.propTypes = {
  children: PropTypes.any.isRequired
};