import { surveyDatasRequestedConstants } from "../constants";
import { projectConstants } from "../constants";

const initialState = [];

export default function(surveyDatasRequesteds = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case surveyDatasRequestedConstants.CREATE_SURVEY_DATAS_REQUESTED:
      if (surveyDatasRequesteds.length === 0) {
        return [...surveyDatasRequesteds, payload];
      }
      return surveyDatasRequesteds.map((surveyDataRequested) => {
        if (surveyDataRequested.uid === payload.uid) {
          return {
            ...surveyDataRequested,
            ...payload,
          };
        } else {
          return [...surveyDatasRequesteds, payload];
        }
      });

    case surveyDatasRequestedConstants.RETRIEVE_SURVEY_DATAS_REQUESTED:
      return payload;
    case surveyDatasRequestedConstants.UPDATE_SURVEY_DATAS_REQUESTED:
      return surveyDatasRequesteds.map((surveyDataRequested) => {
        if (surveyDataRequested.uid === payload.uid) {
          return {
            ...surveyDataRequested,
            ...payload,
          };
        } else {
          return surveyDataRequested;
        }
      });
    case surveyDatasRequestedConstants.DELETE_SURVEY_DATAS_REQUESTED:
      return surveyDatasRequesteds.filter(({ id }) => id !== payload.id);
    default:
      return surveyDatasRequesteds;
  }
}
