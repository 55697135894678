export const formatStringRenameExport = (array, externalProjectField) => {
    console.log('array', array, externalProjectField)
    if(externalProjectField){
        const planteurElements = array.filter(item => item.startsWith(`${externalProjectField}_`)).map(item => item.replace(`${externalProjectField}_`, ''));

        const otherElements = array.filter(item => !item.startsWith(`${externalProjectField}_`));

        const planteurString = `${externalProjectField}=${planteurElements.join(';')}`;

        const finalString = [...otherElements, planteurString].join(',');
        console.log('finalString externalProjectField', finalString)
        return finalString
    } else {
        const finalString = array.join(',');
        console.log('finalString ', finalString)
        return finalString
    }
    
}