import { InputSearch, Heading, Paragraph, ButtonWithBg, Input, Tag } from "../../../components/Kits";
import { Link } from "react-router-dom";
import Table from "./Table";
import { Modal } from "../../../components/Modals";
import { useState, useMemo, useEffect, Fragment } from "react";
import {Transition,  Listbox } from '@headlessui/react';
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsCheckLg } from 'react-icons/bs'
import { useToaster } from "../../../contexts/toaster";
import { BiCurrentLocation } from 'react-icons/bi'
import ReactDOM from 'react-dom';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from "react-redux";

import {
    paymentsMethodActions
} from '../../../redux/actions'
import { useNavigate } from "react-router";
import InvoiceTable from "../../../components/tables/InvoiceTable";
import { useTranslation } from "react-i18next";
import TablePaymentMethod from "./TablePaymentMethod";

const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
    );
  
    return options;
  };

export default function PaymentsMethods(){
    const { t } = useTranslation(["common", "project", "form", "billing"]);
    const toaster = useToaster()
    const dispatch = useDispatch();  
    const paymentMethods = useSelector((state) => state.paymentMethods)
    const [isLoading, setLoading] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const [modalMode, setModalMode] = useState('')
    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }
    const [isCompleted, setIsCompleted] = useState(false)


    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const options = useOptions();
      
        const handleSubmit = async (event) => {
            event.preventDefault();
            // setLoading(true)

            const cardName = event.target.cardName.value;
        
            if (!stripe || !elements) {
                // setLoading(false)
                return;
            }
    
            const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement), {
                name: cardName,
            });
            if (error) {
            console.error(error);
            // setLoading(false)
            } else {
                console.log(token);
                let data = {
                    // billingAccountId : billingId,
                    tokenCard : token.id
                }
                dispatch(paymentsMethodActions.create(data, toaster, setLoading, toggleModal, setIsCompleted, isCompleted))
            }
        };

        return (
            <form onSubmit={(event) => handleSubmit(event)}>
                {/* Champ pour le nom de la carte */}
                <label className="relative block mb-[30px]">
                    <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                        {t("billing:cardname")}
                    </span>
                    <input
                    type="text"
                    id="cardName"
                    name="cardName"
                    placeholder="John Doe"  // Remplacez cela par la valeur par défaut souhaitée
                    className="w-full p-2 border rounded"
                    />
                </label>
                <label className="relative block mb-[30px]">
                    <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                        {t("billing:cardnumber")}
                    </span>
                    <CardNumberElement
                        options={options}
                        onReady={() => {
                        console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                        console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                        console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                        console.log("CardNumberElement [focus]");
                        }}
                    />
                </label>
                <label className="relative block mb-[30px]">
                
                <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                    {t("billing:expirein")}
                </span>
                <CardExpiryElement
                    options={options}
                    onReady={() => {
                    console.log("CardNumberElement [ready]");
                    }}
                    onChange={event => {
                    console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                    console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                    console.log("CardNumberElement [focus]");
                    }}
                />
                </label>
                <label className="relative block mb-[30px]">
                <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                    {"CVC"}
                </span>
                <CardCvcElement
                    options={options}
                    onReady={() => {
                    console.log("CardNumberElement [ready]");
                    }}
                    onChange={event => {
                    console.log("CardNumberElement [change]", event);
                    }}
                    onBlur={() => {
                    console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                    console.log("CardNumberElement [focus]");
                    }}
                />
                </label>
                <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                    <ButtonWithBg 
                        title={isLoading ? t("common:inprogress") : t("common:add")}
                        type='submit'
                        bg={'primary'}
                        isSmall={true}
                        disabled={!stripe}
                    />
                </div>
            </form>
        );
      };
      
      const stripePromise = loadStripe('pk_test_51LFyQqB8kO7JvbAYjcTUWCRo1WuA3Aot2bdvhIJrTyLbEbuyNlwQoQvspxQ09oGb9lWhpZEAiGaJTIH4dapbsmv600t3fYW9Kp');

    return(
        <>
            <div className="flex items-center justify-between mb-[50px]">
                <div className="max-w-[40%]">
                    <Heading title={t("billing:paymentsmethods")} mb={"10px"} />
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <div className="w-[300px]">
                        <InputSearch 
                            placeholder="Search..."
                        />
                    </div>
                </div>
                <ButtonWithBg 
                    title={t("billing:newpaymentmethod")}
                    type='button'
                    bg={'primary'}
                    isSmall={true}
                    onClick={()=> {
                        setIsCompleted(true)
                        setModalMode('add-payment')
                        toggleModal()
                    } }
                />
            </div>
            
            {/* Table */}
            <TablePaymentMethod
                list={paymentMethods} 
                setModalMode={setModalMode} 
                toggleModal={toggleModal}
                t={t}
            />
            {/* End table */}

            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal} resetOverflow>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode === 'add-payment' ? t("billing:addyourvisacarddetails") :
                            null
                        }
                    </h4>
                    <div 
                        onClick={()=>{
                            toggleModal() 
                        }}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                
                {
                    modalMode === "add-payment" &&
                    <div className="p-6">
                        <Elements stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </div>  
                }
            </Modal>
        </>
    )
}