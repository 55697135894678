import { Menu, Transition, Dialog, Disclosure, Listbox } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tag } from '../../../components/Kits';
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import { isUserAccepted } from '../../../utils/checkAcceptanceProjectStatus';


export default function Table({
    projects, 
    t, 
    loadingProject, 
    length,
    user,
    toggleOpenAcceptanceModal,
    setProject
}){


    return(
        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6'>
            <div className="w-full">
                <table className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[60px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"#ID"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:name")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:description")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Status"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:surveystartdate")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:surevyenddate")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                        {   loadingProject ? <TableSkeleton length={length}  columns={6} /> 
                            :
                            <>
                                {    projects?.map((item, idx) =>(
                                    <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                        <td className="px-4 py-1 whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                            <div className='min-w-[150px]'>
                                                {item?.number ? item?.number : '---------'}
                                            </div>
                                        </td>
                                        <td className="px-4 py-1 whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                            <div className='min-w-[150px]'>
                                                {item?.name}
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 whitespace-nowrap truncate lg:whitespace-normal lowercase text-[12px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                            <div className='limit-3 min-w-[250px]'>
                                                {item?.description}
                                            </div>
                                        </td>
                                        <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                            {
                                                isUserAccepted(item, user?.userId) ? (
                                                    <Tag
                                                        title={'Pending'}
                                                        color={"text-[#FFA63A]"}
                                                        bg={"bg-[#FFE6B0]"}
                                                    />
                                                ) : (
                                                    <Tag
                                                        title={item?.status?.name}
                                                        color={
                                                            item?.status?.name === 'Completed' ? "text-[#0B449A]" : 
                                                            item?.status?.name === 'Running' ? "text-[#6BCB77]" : 
                                                            "text-[#000]"
                                                        }
                                                        bg={
                                                            item?.status?.name === 'Completed' ? "bg-[#e7ecf5]" : 
                                                            item?.status?.name === 'Running' ? "bg-[#f0faf1]" : 
                                                            "bg-[#F6F7FB]"
                                                        }
                                                    />
                                                )
                                            }
                                            
                                        </td>
                                        <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                            {item?.startDate ? new Date(item?.startDate.split("T")[0]).toLocaleDateString().replaceAll("/", "-") : '-------------'}
                                        </td>
                                        <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                            {item?.endDate ? new Date(item?.endDate.split("T")[0]).toLocaleDateString().replaceAll("/", "-") : '-------------'}
                                        </td>
                                        <td className="px-4 py-2   whitespace-nowrap">
                                            {
                                                isUserAccepted(item, user?.userId) ? (
                                                    <div 
                                                        className="w-[150px] cursor-pointer"
                                                        onClick={() => {
                                                            setProject(item)
                                                            toggleOpenAcceptanceModal()
                                                        }} 
                                                    >
                                                        <button className="bg-primary-blue-500 px-3 py-2 w-full text-center rounded-[4px] text-[12px] font-[500] flex items-center justify-center gap-6">
                                                            <span className="text-[#fff] ">{'Accept project'}</span>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <Link to={"/adm/projects/details-projects"} state={{ item: item }}>
                                                        <div className="w-[150px]">
                                                            <button className="bg-tertiary-gray-500 w-full text-center px-3 py-2 rounded-[4px] text-[12px] font-[500] flex items-center justify-center gap-6">
                                                                <span className="text-[#000] ">{t("common:viewdetails")}</span>
                                                            </button>
                                                        </div>
                                                    </Link>
                                                )
                                            }
                                            
                                        </td>
                                    </tr>
                                ))}
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}