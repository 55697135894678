export const userConstants = {
  CREATE_USERS: 'CREATE_USERS',
  RETRIEVE_USERS: 'RETRIEVE_USERS',
  UPDATE_USERS: 'UPDATE_USERS',
  RETRIEVE_USER: 'RETRIEVE_USER',
  DELETE_USERS: 'DELETE_USERS',
  DELETE_ALL_USERS: 'DELETE_ALL_USERS',
  RETRIEVE_ROLES: 'RETRIEVE_ROLES',
  AUTH_USER: 'AUTH_USER',
  SEARCH_USER: 'SEARCH_USER',

}
