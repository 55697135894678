import axios from "axios";

const http = axios.create({
  // baseURL: "https://us-central1-agritech-survey-preprod-env.cloudfunctions.net/api/v1",

  // baseURL: "https://us-central1-agritech-survey-dev.cloudfunctions.net/api/v1",

  baseURL: "https://us-central1-agritech-survey-mapping.cloudfunctions.net/api/v1",

});


// yaoaristide.master@gmail.com
// 0123456

export default http;

