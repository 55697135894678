
export function controlAccess(projectUsers, user){

    let foundUser = projectUsers?.find(x => x?.userId === user?.userId)
    let getUserRole = foundUser?.roles?.map(x => x?.name)

    let maintainer = getUserRole?.includes("Data Controler")
    let owner = getUserRole?.includes("Project Owner")

    return {maintainer,  owner}
}