/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'

import * as echarts from 'echarts';



export default function Bar({options, grids}) {

  let chart = useRef(null)
  let [chartEl, setChartEl] = useState(chart)
  
  const option = {
    tooltip: {
        trigger: 'axis',
    },
    grid: {
      top:'10%',
      left: grids ? "8%" : '4%',
      right: grids ? "8%" : '4%',
      bottom: grids ? "10%" : '4%',
      containLabel: false,
    },
    xAxis: {
      type: 'category',
      data: options?.map(x => x.date)
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: options?.map(x => x.value),
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }
    ]
  };

  useEffect(() => {

    

    if (!chartEl.current) {
        chartEl.setOption(option)
    }
    else {
        setChartEl(echarts.init(chart.current))
    }
}, [chartEl,option])


  return (
    <div  ref={chart} className='h-full w-full'></div>
  )
}
