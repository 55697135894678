import { paymentsMethodConstants } from '../constants'

const initialState = []

// eslint-disable-next-line import/no-anonymous-default-export
export default function(paymentMethod = initialState, action){
  const { type, payload } = action

  switch (type) {
    case paymentsMethodConstants.CREATE_PAYMENTSMETHOD:
      console.log(payload)
      return [payload, ...paymentMethod]
    case paymentsMethodConstants.RETRIEVE_PAYMENTSMETHOD:
      return payload
    case paymentsMethodConstants.UPDATE_PAYMENTSMETHOD:
      return paymentMethod.map((paymentMeth) => {
        if (paymentMeth.id === payload.id) {
          return {
            ...paymentMeth,
            ...payload,
          }
        } else {
          return paymentMeth
        }
      })
    case paymentsMethodConstants.DELETE_PAYMENTSMETHOD:
      return paymentMethod.filter(({ id }) => id !== payload.id)
    default:
      return paymentMethod
  }
}
