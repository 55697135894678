import { appSettingConstants } from '../constants'

const initialState = []

export default function (settingsAreas = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case appSettingConstants.RETRIEVE_DATA_AREAS:
      return payload
    default:
      return settingsAreas
  }
}

