export default function CardBlockSkeleton({isProject, length}){
    const arr = Array.from(new Array(length))

    console.log('arr', arr)
    return(
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-[20px]">
            {
                arr.map(index => (
                    <div key={index} className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between animate-pulse">
                        <div class="h-4 bg-gray-100 rounded-[2px]"></div>
                        <div className="my-[16px] space-y-[6px]">
                            <div class="h-2 bg-gray-100 rounded-[2px]"></div>
                            <div class="h-2 bg-gray-100 rounded-[2px]"></div>
                            <div class="h-2 bg-gray-100 rounded-[2px]"></div>
                        </div>
                        <div className="flex items-center justify-between gap-4">
                            <div className="flex items-center">
                                {   isProject &&
                                    <>
                                        <div className="border border-white bg-gray-100 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3"></div>
                                        <div className="border border-white bg-gray-100 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3"></div>
                                        <div className="border border-white bg-gray-100 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3"></div>
                                    </>
                                }
                            </div>
                            <div className="bg-gray-100 h-[25px] w-[100px] rounded-[6px]"></div>
                        </div>
                    </div>
                ))
            }
            
        </div>
    )
}