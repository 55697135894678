import { Tag, ButtonWithBg, Heading, Paragraph, Input, SelectField } from "../../../components/Kits";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { useSelector, useDispatch } from "react-redux";
import {
    accountTypesActions,
    userActions,
    appSettingActions
} from '../../../redux/actions'
import { useEffect, useState } from "react";
import { useToaster } from "../../../contexts/toaster";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}


export default function Profil(){
    const { t } = useTranslation(["common", "manageAccount"]);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const accountTypes = useSelector((state) => state.accountTypes)
    const areaUnitOptions = useSelector((state) => state.areaUnitOptions)
    const languageOptions = useSelector((state) => state.languageOptions)
    const userStudyArea = useSelector((state) => state.userStudyArea)
    const userLang = useSelector((state) => state.userLang)
    const [airesUnity, setAiresUnity] = useState([])
    const [perimetreUnity, setPerimetreUnity] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdateLang, setIsUpdateLang] = useState(false)
    const toaster = useToaster()
    const [userInfo, setUserInfo] = useState({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone
    })
    const [userInfoResetPwd, setUserInfoResetPwd] = useState({
        email: '',
        oldPassword: '',
        newPassword: '',
    })

    const [userAppSettingArea, setUserAppSettingArea] = useState({
        // userId: user?.userId,
        area: userStudyArea?.area,
        perimeter: userStudyArea?.perimeter,
    })

    const [userApplang, setUserApplang] = useState({
        // userId: user?.userId,
        lang: userLang?.lang,
    })

    const [errorsResetPwdField, setErrorsResetPwdField] = useState([])
    let emptyResetPwdFieldKey = []

    useEffect(() => {
        setAiresUnity(areaUnitOptions?.areas)
        setPerimetreUnity(areaUnitOptions?.perimeters)
    }, [areaUnitOptions])

    useEffect(()=>{
        setUserInfo({
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phone: user?.phone,
        })
    }, [user])

    useEffect(() => {
        dispatch(accountTypesActions.getTypes());
    }, []);

    const handleUpdatedUser = (e) => {
        e.preventDefault()
        dispatch(userActions.updateUser(userInfo, setIsLoading, toaster));
    }
    
    
    const handleUpdatedPassword = (e) => {
        e.preventDefault()
        emptyResetPwdFieldKey = Object.entries(userInfoResetPwd).filter(([key]) => userInfoResetPwd[key] === '').map(([key]) => key)
        setErrorsResetPwdField(emptyResetPwdFieldKey)

        if(emptyResetPwdFieldKey.length === 0){
            dispatch(userActions.resetPaswordByUser(userInfoResetPwd, setIsLoading, toaster));
        }
    }

    const handleUpdatedAppSetting = (e) => {
        e.preventDefault()
        dispatch(appSettingActions.updateAppsAreas(userAppSettingArea, toaster, setIsLoading));
    }

    const handleUpdatedAppLang = (e) => {
        e.preventDefault()
        dispatch(appSettingActions.updateAppLang(userApplang, toaster, setIsUpdateLang));
    }
    
    return(
        <>
            <div className="flex items-center justify-between mb-[40px]">
                <div className="max-w-[40%]">
                    <Heading title={t("common:Manageaccount")} mb={"10px"} />
                </div>
            </div>
            <div className="lg:w-[70%]">
                <Tab.Group>
                    <Tab.List className="inline-flex gap-2 overflow-y-hidden">
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                "focus:outline-none",
                                selected
                                    ? "bg-primary-blue-500 text-white"
                                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                )
                            }
                        >
                            {t("manageAccount:Generalinformation")}
                        </Tab>
                        { accountTypes?.find(x => x?.id === user?.accountDetails?.type)?.name=='Organisation' && 
                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                    "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                    "focus:outline-none",
                                    selected
                                        ? "bg-primary-blue-500 text-white"
                                        : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                    )
                                }
                            >
                                {t("manageAccount:Companyinfos")}
                            </Tab>
                        }
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                "focus:outline-none",
                                selected
                                    ? "bg-primary-blue-500 text-white"
                                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                )
                            }
                        >
                            {t("manageAccount:Changepassword")}
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                "focus:outline-none",
                                selected
                                    ? "bg-primary-blue-500 text-white"
                                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                )
                            }
                        >
                            {t("manageAccount:Setting")}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className="mt-2 pt-[30px]">
                        <Tab.Panel className={classNames("focus:outline-none")}>
                            <form onSubmit={(e) => handleUpdatedUser(e)} className="bg-white rounded-[6px] overflow-hidden p-4">
                                <span className="text-[14px] text-black">General information</span>
                                <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-[30px]">
                                    <Input 
                                        label={t("manageAccount:Name")}
                                        placeholder={""}
                                        type={'text'}
                                        value={userInfo?.firstName}
                                        onChange={(e)=> setUserInfo({...userInfo, firstName:e.target.value})}
                                        smallLab={true}
                                    />
                                    <Input 
                                        label={t("manageAccount:Lastname")}
                                        placeholder={""}
                                        type={'text'}
                                        value={userInfo?.lastName}
                                        onChange={(e)=> setUserInfo({...userInfo, lastName:e.target.value})}
                                        smallLab={true}
                                    />
                                    <Input 
                                        label={t("manageAccount:Addressemail")}
                                        placeholder={""}
                                        type={'email'}
                                        value={userInfo?.email}
                                        onChange={(e)=> setUserInfo({...userInfo, email:e.target.value})}
                                        smallLab={true}

                                    />
                                    <Input 
                                        label={t("manageAccount:Phonenumber")}
                                        placeholder={""}
                                        type={'text'}
                                        value={userInfo?.phone}
                                        onChange={(e)=> setUserInfo({...userInfo, phone:e.target.value})}
                                        smallLab={true}
                                    />
                                </div>
                                <div className='flex justify-end mt-[30px]'>
                                    <ButtonWithBg 
                                        title={isLoading ? t("common:inprogress") : t("manageAccount:Saveupdate")}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                    />
                                </div>
                            </form>
                        </Tab.Panel>
                        { accountTypes?.find(x => x?.id === user?.accountDetails?.type)?.name=='Organisation' &&
                            <Tab.Panel className={classNames("focus:outline-none")}>
                                <form onSubmit={(e) => console.log('first')} className="bg-white rounded-[6px] overflow-hidden p-4">
                                    <span className="text-[14px] text-black">{t("manageAccount:Companyinfos")}</span>
                                        <div className='mt-[40px] border-t pt-[30px]'>
                                            <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-[30px]">
                                                <Input 
                                                    label={t("manageAccount:CompanyName")}
                                                    placeholder={""}
                                                    type={'text'}
                                                    value={user?.accountDetails?.name}
                                                    smallLab={true}
                                                />
                                                <Input 
                                                    label={t("manageAccount:Address")}
                                                    placeholder={""}
                                                    value={user?.accountDetails?.address}
                                                    type={'text'}
                                                    smallLab={true}
                                                />
                                            </div>
                                        </div>
                                    <div className='flex justify-end mt-[30px]'>
                                        <ButtonWithBg 
                                            title={isLoading ? t("common:inprogress") : t("manageAccount:Saveupdate")}
                                            type='submit'
                                            bg={'primary'}
                                            isSmall={true}
                                        />
                                    </div>
                                </form>
                            </Tab.Panel>
                        }
                        <Tab.Panel className={classNames("focus:outline-none")}>
                            <form onSubmit={(e) => handleUpdatedPassword(e)} className="bg-white rounded-[6px] overflow-hidden p-4">
                                <span className="text-[14px] text-black">{t("manageAccount:Changepassword")}</span>
                                <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-[30px]">
                                    <div className="col-span-2">
                                        <Input 
                                            label={t("manageAccount:Addressemail")}
                                            placeholder={""}
                                            type={'email'}
                                            value={userInfoResetPwd?.email}
                                            onChange={(e)=> setUserInfoResetPwd({...userInfoResetPwd, email:e.target.value})}
                                            smallLab={true}
                                        />
                                        <span className='text-[12px] text-red-500'>
                                            {
                                                errorsResetPwdField?.includes("email") && "**This field is required"
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <Input 
                                            label={t("manageAccount:Oldpassword")}
                                            placeholder={"********"}
                                            type={'password'}
                                            value={userInfoResetPwd?.oldPassword}
                                            onChange={(e)=> setUserInfoResetPwd({...userInfoResetPwd, oldPassword:e.target.value})}
                                            smallLab={true}
                                        />
                                        <span className='text-[12px] text-red-500'>
                                            {
                                                errorsResetPwdField?.includes("oldPassword") && "**This field is required"
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <Input 
                                            label={t("manageAccount:Newpassword")}
                                            placeholder={"********"}
                                            type={'password'}
                                            value={userInfoResetPwd?.newPassword}
                                            onChange={(e)=> setUserInfoResetPwd({...userInfoResetPwd, newPassword:e.target.value})}
                                            smallLab={true}
                                        />
                                        <span className='text-[12px] text-red-500'>
                                            {
                                                errorsResetPwdField?.includes("newPassword") && "**This field is required"
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className='flex justify-end mt-[30px]'>
                                    <ButtonWithBg 
                                        title={isLoading ? t("common:inprogress") : t("manageAccount:Saveupdate")}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                    />
                                </div>
                            </form>
                        </Tab.Panel>
                        <Tab.Panel className={classNames("focus:outline-none")}>
                            <>
                                <form onSubmit={(e) => handleUpdatedAppSetting(e)} className="bg-white rounded-[6px] overflow-hidden p-4">
                                    <span className="text-[14px] text-black">{t("manageAccount:Setting")}</span>
                                    <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-[30px]">
                                        <div className="col-span-2">
                                            <div className="mb-[30px]">
                                                <SelectField 
                                                    label={t("manageAccount:Surface")}
                                                    type={'number'}
                                                    smallLab={true}
                                                    options= {[
                                                        ...airesUnity?.map(x => (
                                                            {
                                                                value: x?.value,
                                                                name: `${x?.name}`
                                                            }
                                                        ))
                                                    ]}
                                                    value={userAppSettingArea.area}
                                                    onChange={(e) => setUserAppSettingArea({...userAppSettingArea, area: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="mb-[30px]">
                                                <SelectField 
                                                    label={t("manageAccount:Perimeter")}
                                                    type={'number'}
                                                    smallLab={true}
                                                    options= {[
                                                        ...perimetreUnity?.map(x => (
                                                            {
                                                                value: x?.value,
                                                                name: `${x?.name}`
                                                            }
                                                        ))
                                                    ]}
                                                    value={userAppSettingArea.perimeter}
                                                    onChange={(e) => setUserAppSettingArea({...userAppSettingArea, perimeter: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-[30px]'>
                                        <ButtonWithBg 
                                            title={isLoading ? t("common:inprogress") : t("manageAccount:Saveupdate")}
                                            type='submit'
                                            bg={'primary'}
                                            isSmall={true}
                                        />
                                    </div>
                                </form>
                                <form onSubmit={(e) => handleUpdatedAppLang(e)} className="bg-white rounded-[6px] overflow-hidden p-4 mt-[30px] block">
                                    <span className="text-[14px] text-black">{t("manageAccount:LanguageSetting")}</span>
                                    <div className="grid grid-cols-2 gap-x-4 gap-y-8 mt-[30px]">
                                        <div className="col-span-2">
                                            <div className="mb-[30px]">
                                                <SelectField 
                                                    label={'Sélectionner une langue'}
                                                    type={'number'}
                                                    smallLab={true}
                                                    options= {[
                                                        ...languageOptions?.map(x => (
                                                            {
                                                                value: x?.value,
                                                                name: x?.name
                                                            }
                                                        ))
                                                    ]}
                                                    value={userApplang.lang}
                                                    onChange={(e) => setUserApplang({...userApplang, lang: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-[30px]'>
                                        <ButtonWithBg 
                                            title={isUpdateLang ? t("common:inprogress") : t("manageAccount:Saveupdate")}
                                            type='submit'
                                            bg={'primary'}
                                            isSmall={true}
                                        />
                                    </div>
                                </form>
                            </>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    )
}