import Modal from "./Modal"
import { AiOutlineClose } from "react-icons/ai"
import { ButtonWithBg } from "../Kits"
import { useSelector } from "react-redux"

export default function ModalUserInfo({
    user,
    openModal,
    closeModal,
    t
}){
    const roles = useSelector((state) => state?.projectRoles)
    function getRole(el){
        const res = roles.find(x => x.id === el)?.name

        return res
    }

    return (
        <Modal show={openModal} toggleModal={closeModal} resetOverflow>
            <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
            <h4>
                {t("project:agentinformation")}
            </h4>
            <div 
                onClick={()=>{
                    closeModal() 
                }}
                className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                <AiOutlineClose className="text-[20px] font-bold text-red-500" />
            </div>
            </div>
            <div className="max-h-[400px] overflow-x-hidden">
                <div className='grid grid-cols-2 gap-4 p-6'>
                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{`Nom`}</span>
                        <span className='block text-[14px] text-black capitalize'>
                            {user?.firstName ? user?.firstName : 'Aucun'}
                        </span>
                    </div>
                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{`Prénom(s)`}</span>
                        <span className='block text-[14px] text-black capitalize'>
                            {user?.lastName ? user?.lastName : 'Aucun'}
                        </span>
                    </div>
                    <div className='pb-2 '>
                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{`E-mail`}</span>
                        <span className='block text-[14px] text-black capitalize'>
                            {user?.email ? user?.email : 'Aucun'}
                        </span>
                    </div>
                    <div className='pb-2 '>
                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{`Téléphone`}</span>
                        <span className='block text-[14px] text-black capitalize'>
                            {user?.phone ? user?.phone : 'Aucun'}
                        </span>
                    </div>
                    {/* <div className='pb-2 col-span-2'>
                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{`Rôle(s)`}</span>
                        <span className='block text-[14px] text-black capitalize'>
                            {user?.roles ? user?.roles?.map(el => getRole(el))  : 'Aucun'}
                        </span>
                    </div> */}
                </div>
                <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-4'>
                    <ButtonWithBg 
                        title={t("common:close")}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        onClick={() => closeModal()}
                    />
                </div>
            </div>
        </Modal>
    )
}