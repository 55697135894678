import React from "react";
import ReactDOM from "react-dom/client";
import './styles/global.css';
import App from './pages/App'
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-loading-skeleton/dist/skeleton.css";
import ToasterProvider from './contexts/toaster'
import { Notifications } from './components/Kits'
import "./i18n";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ToasterProvider>
      <App />
      <Notifications />
    </ToasterProvider>
  </Provider>
);
