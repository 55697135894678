import { useState, Fragment, useEffect } from "react";
import { Tag, ButtonWithBg } from "../../../../components/Kits";
import { Menu, Transition, Listbox, Disclosure, Dialog } from '@headlessui/react'
import { Bar } from "../../../../components/Charts";
import { Modal } from "../../../../components/Modals";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import moment from 'moment'; 


export default function SurveyMonitoring({item, t, project}){
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
    const [itemData, setItemData]= useState(project)
    const [itemsSurv, setItemsSurv] = useState(project?.surveyDatas)


    useEffect(()=>{
        setItemData(project)
        setItemsSurv(project?.surveyDatas)
    }, [project])


    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    const data = [
        { name: 'surveydata', id : 'surveydata' }
    ]
    
    const dataView = [
        { name: 'Graph', id : 'graph' },
    ]

    const dataAg = [
        { name: 'aggregateddata', id : 'Agregateddata' }
    ]
    
    const dataUser = [
        { name: t(`project:All`), id : null },
        ...project?.projectUsers?.map((user, idx) => (
            { 
                name: user?.firstName +" "+ user?.lastName, 
                id : user?.userId 
            }
        ))
        
    ]

    const NotifStates = [
        { name: 'all', id : 'null' },
        { name: 'Send', id : 'send' },
    ]

    const [selectedSurvey, setSelectedSurvey] = useState(data[0])
    const [selectedView, setSelectedView] = useState(dataView[0])
    const [selectedData, setSelectedData] = useState(dataAg[0])
    const [selectedUser, setSelectedUser] = useState(dataUser[0])
    const [notificationsState, setNotificationsState] = useState(NotifStates[0])
    const today = moment(itemData?.startDate)
    const [startedDate,setStartedDate] = useState(today.format('YYYY/MM/DD').replaceAll("/","-"))
    const [endedDate,setEndedDate] = useState(today.add(7, 'days').format('YYYY/MM/DD').replaceAll("/","-"))
    

    const dateList = [];
    for(let current = new Date(startedDate); current <= new Date(endedDate); current?.setDate(current?.getDate() + 1)) {
    dateList?.push(new Date(current)?.toISOString()?.split("T")[0]);
    }
    console.log("dateList", dateList);

    console.log('startedDate', startedDate)
    console.log('endedDate', endedDate)


    const optionsGraph = [
        {
            date: '2022-12-10',
            value: 200
        },
        {
            date: '2022-12-10',
            value: 240
        },
        {
            date: '2022-12-10',
            value: 300
        },
        {
            date: '2022-12-11',
            value: 250
        },
        {
            date: '2022-12-12',
            value: 100
        },
        {
            date: '2022-12-13',
            value: 160
        },
        {
            date: '2022-12-14',
            value: 200
        },
        {
            date: '2022-12-15',
            value: 300
        },
        {
            date: '2022-12-16',
            value: 150
        },
    ]

    useEffect(() => {
        if(selectedUser.id !== null) {
            setItemsSurv(
                project?.surveyDatas?.filter(x => x?.user?.id === selectedUser?.id && x.createdAt >= new Date(startedDate).getTime() && x.createdAt <= new Date(endedDate).getTime()))
        } else{
            setItemsSurv(project?.surveyDatas.filter(x => x.createdAt >= new Date(startedDate).getTime() && x.createdAt <= new Date(endedDate).getTime()))
        }
        
    }, [selectedUser, startedDate, endedDate]);

    let [groupItems, setGroupItems] = useState([])

    function groupItemsByDate(items) {
        console.log("itemsitems", items)
        items?.sort((a, b) => b?.createdAt - a?.createdAt);
        const groupedItems = items?.reduce((acc, item) => {
          const year = new Date(item?.createdAt)?.toISOString()?.split("T")[0];

          console.log("year", year)
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push(item);
          return acc;
        }, {});

        console.log("groupedItems", groupedItems)
      
        setGroupItems(groupedItems);
    }
    
    useEffect(()=>{
        groupItemsByDate(itemsSurv)
    }, [itemsSurv])

    console.log('groupItems', groupItems)

    const dataOpt = dateList?.map(year => (
        {
            date: year,
            value: groupItems ?  groupItems[year]?.length : 0
        }
    ))


    console.log('selectedUser', selectedUser)

    return(
        <>
            <div className="grid grid-cols-2 gap-8">
                <div className="p-4 rounded-[6px] bg-white">
                    <h4 className="text-[16px] font-ibm-plex-sans font-semibold text-black">{t("project:aboutproject")}</h4>
                    <ul>
                        <li className="flex items-center justify-between py-3 border-b">
                            <span className="text-[14px] text-black text-opacity-50">{t("project:surveyexecutionstatus")}:</span>
                            <Tag 
                                title={t(`project:${project?.status?.name}`)}
                                color={
                                    project?.status?.name == 'Completed' ? "text-[#0B449A]" : 
                                    project?.status?.name == 'Running' ? "text-[#6BCB77]" : 
                                    "text-[#000]"
                                }
                                bg={
                                    project?.status?.name == 'Completed' ? "bg-[#e7ecf5]" : 
                                    project?.status?.name == 'Running' ? "bg-[#f0faf1]" : 
                                    "bg-[#F6F7FB]"
                                }
                            />
                        </li>
                        <li className="flex items-center justify-between py-3 border-b">
                            <span className="text-[14px] text-black text-opacity-50">{t("project:surveystartdate")}:</span>
                            <span className="text-[14px] text-black">{new Date(project?.startDate?.split("T")[0]).toLocaleDateString().replaceAll("/", "-")}</span>
                        </li>
                        <li className="flex items-center justify-between py-3">
                            <span className="text-[14px] text-black text-opacity-50">{t("project:surevyenddate")}:</span>
                            <span className="text-[14px] text-black">{new Date(project?.endDate?.split("T")[0]).toLocaleDateString().replaceAll("/", "-")}</span>
                        </li>
                    </ul>
                </div>
                <div className="p-4 rounded-[6px] bg-white">
                    <h4 className="text-[16px] font-ibm-plex-sans font-semibold text-black">{t("project:surveyagents")}</h4>
                    <ul>
                        <li className="flex items-center justify-between py-3 border-b">
                            <span className="text-[14px] text-black text-opacity-50">{t("project:ongroundagents")}:</span>
                            <div className="cursor-pointer">
                                <Tag 
                                    title={project?.projectUsers?.length < 10 ? `0${project?.projectUsers?.length}` : project?.projectUsers?.length}
                                    color={'text-secondary-green-500'}
                                    bg={'bg-secondary-green-500 bg-opacity-10'}
                                    onClick={()=>{
                                        setModalMode("onGroundAg")
                                        toggleModal()
                                    }}
                                />
                            </div>
                        </li>
                        <li className="flex items-center justify-between py-3 border-b">
                            <span className="text-[14px] text-black text-opacity-50">{t("project:agentswhoaccepted")}:</span>
                            <div className="cursor-pointer">
                                <Tag 
                                    title={project?.projectUsers?.filter(x => x?.acceptanceStatus?.name == 'Accept')?.length < 10 ? `0${project?.projectUsers?.filter(x => x?.acceptanceStatus?.name == 'Accept')?.length}` : project?.projectUsers?.filter(x => x?.acceptanceStatus?.name == 'Accept')?.length}
                                    color={'text-secondary-green-500'}
                                    bg={'bg-secondary-green-500 bg-opacity-10'}
                                    onClick={()=>{
                                        setModalMode("AgentAccepted")
                                        toggleModal()
                                    }}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="mt-[30px]">
                <h4 className="text-[16px] font-ibm-plex-sans font-semibold text-black">{t("project:aggregatedsurveydata")}</h4>
                <div className="flex items-start justify-between">
                    <div>
                        <div className="flex lg:items-center lg:flex-row flex-col gap-4 mt-[30px]">
                            <div className="flex items-center gap-2">
                                <span className="text-[14px] text-black text-opacity-50">{t("project:surveyagents")}:</span>
                                <Listbox value={selectedUser} onChange={setSelectedUser}>
                                    <div className="relative">
                                        <Listbox.Button className="inline-flex h-[40px] min-w-[98px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            <span className="block text-[14px] text-[#676D7C] mr-8 font-medium">{selectedUser.name}</span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute left-0 w-48 mt-2 z-50 bg-white rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {dataUser?.map((item, itemIdx) => (
                                                    <Listbox.Option
                                                        key={itemIdx}
                                                        className={({ active }) =>
                                                            `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                                active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                            }`
                                                        }
                                                        value={item}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={`block text-[14px] limit-1 ${
                                                                        selected ? 'font-normal' : 'font-normal'
                                                                    }`}
                                                                >
                                                                    {item.name}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="text-[14px] text-black text-opacity-50">{t("project:periodefrom")}:</span>
                                <input
                                    type='date'
                                    value={startedDate}
                                    min={itemData?.startDate}
                                    onChange={(e) => setStartedDate(e.target.value)}
                                    className='focus:outline-none h-[40px] bg-white rounded-[6px] text-[14px] border text-center pl-2 pr-4'
                                />
                                <span className="text-[14px] text-black text-opacity-50">{t("project:to")}:</span>
                                <input
                                    type='date'
                                    value={endedDate}
                                    min={startedDate}
                                    max={itemData?.endDate}
                                    onChange={(e) => setEndedDate(e.target.value)}
                                    className='focus:outline-none h-[40px] bg-white rounded-[6px] text-[14px] border text-center pl-2 pr-4'
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" items-center gap-4 hidden">
                        <ButtonWithBg 
                            title={'See raw data'}
                            type='button'
                            bg={'secondary-opactity'}
                            isSmall={true}
                        />
                        <ButtonWithBg 
                            title={'Export data'}
                            type='button'
                            bg={'primary'}
                            isSmall={true}
                        />
                    </div>
                </div>
                <div className="h-[500px] w-full">
                    <Bar options={dataOpt} />
                </div>
            </div>
            <div className="mt-[50px] hidden">
                <h4 className="text-[16px] font-ibm-plex-sans font-semibold text-black">Notifications & Issues</h4>
                <div className="flex items-center gap-2 my-[20px]">
                    <span className="text-[14px] text-black text-opacity-50">Filter:</span>
                    <Listbox value={notificationsState} onChange={setNotificationsState}>
                        <div className="relative">
                            <Listbox.Button className="inline-flex h-[40px] min-w-[98px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <span className="block text-[14px] text-[#676D7C] mr-8 font-medium">{notificationsState.name}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute left-0 w-48 mt-2 z-50 bg-white rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {NotifStates?.map((item, itemIdx) => (
                                        <Listbox.Option
                                            key={itemIdx}
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                    active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                }`
                                            }
                                            value={item}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block text-[14px] ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {item.name}
                                                    </span>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
                <div tabIndex="0" className='relative bg-white rounded-[6px] pb-[100px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6'>
                    <div className="w-full">
                        <table className="w-full text-[#484964]">
                            <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                                <tr className=" text-left text-opacity-90 h-[40px]">
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        N
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        Sender
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Receiver"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Notifications & Issues"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Date"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={`relative divide-y`}>
                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                        {"01"}
                                    </td>
                                    <td className="px-4 py-3 lowercase whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {"Zongo Ousmane"}
                                    </td>
                                    <td className="px-4 py-3 lowercase whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {"Aristide Yao"}
                                    </td>
                                    <td className="px-4 py-3 lowercase text-[12px] text-[#000] text-opacity-50  font-[500] align-middle max-w-[20rem]">
                                        {"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
                                    </td>
                                    <td className="px-4 py-3 lowercase whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {"10-10-2022"}
                                    </td>
                                    <td className="px-4 py-2 whitespace-nowrap">
                                        <div className="">
                                            <Menu as="div" className="relative inline-block text-left">
                                                <Menu.Button className="text-sm ">
                                                    <div className="border px-3 py-2 rounded-[4px] text-[12px] font-poppins font-[500] flex items-center gap-6">
                                                        <span className="text-[#676D7C] ">Actions</span>
                                                        <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute z-20 -right-4 w-32  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="px-1 py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                            } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                    >
                                                                        {"Menu 01"}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                            } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                    >
                                                                        {"Menu 02"}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} toggleModal={toggleModal}>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode === 'onGroundAg' ? t("project:ongroundagents") :
                            modalMode === 'AgentAccepted' ? t("project:agentswhoaccepted") :
                            modalMode === 'AgentStarted' ? "Agents who started" : null
                        }
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                <div className="p-6 max-h-[400px] overflow-x-hidden">
                    {
                        modalMode === 'onGroundAg' &&
                        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible pb-[100px]  scrollbar mb-6'>
                            <div className="w-full">
                                <table className="w-full text-[#484964]">
                                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                                        <tr className=" text-left text-opacity-90 h-[40px]">
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                N
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                {t("project:membername")}
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                               {t("project:invitationaccepted")}
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                {t("project:activemember")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={`relative divide-y`}>
                                        {
                                            itemData?.projectUsers?.map((user, idx) =>(
                                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        {idx + 1 < 10 ? `0${idx+1}` : idx+1}
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <div className="flex items-center gap-2 py-2">
                                                            <div className="h-[30px] w-[30px] flex-shrink-0 rounded-full overflow-hidden">
                                                                <img
                                                                    src="/images/avatar.png"
                                                                    alt="profil"
                                                                    className="h-full w-full object-cover"
                                                                />
                                                            </div>
                                                            <div>
                                                                <h4 className="text-[12px] text-black whitespace-nowrap  leading-[15px]">{user?.firstName +" "+ user?.lastName}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <span className={`${user?.acceptanceStatus?.name == 'Accept' ? 'text-secondary-green-500' : 'text-red-500' }`}>
                                                            {user?.acceptanceStatus?.name == 'Accept' ? "Yes" : "No"}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <span className='text-red-500'>
                                                            {"No"}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        modalMode === 'AgentAccepted' &&
                        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible pb-[100px]  scrollbar mb-6'>
                            <div className="w-full">
                                <table className="w-full text-[#484964]">
                                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                                        <tr className=" text-left text-opacity-90 h-[40px]">
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                N
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                {t("project:membername")}
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                               {t("project:invitationaccepted")}
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                {t("project:activemember")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={`relative divide-y`}>
                                        {
                                            itemData?.projectUsers?.filter(x => x?.acceptanceStatus?.name == 'Accept')?.map((user, idx) =>(
                                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        {idx + 1 < 10 ? `0${idx+1}` : idx+1}
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <div className="flex items-center gap-2 py-2">
                                                            <div className="h-[30px] w-[30px] flex-shrink-0 rounded-full overflow-hidden">
                                                                <img
                                                                    src="/images/avatar.png"
                                                                    alt="profil"
                                                                    className="h-full w-full object-cover"
                                                                />
                                                            </div>
                                                            <div>
                                                                <h4 className="text-[12px] text-black whitespace-nowrap  leading-[15px]">{user?.firstName +" "+ user?.lastName}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <span className={`${user?.acceptanceStatus?.name == 'Accept' ? 'text-secondary-green-500' : 'text-red-500' }`}>
                                                            {user?.acceptanceStatus?.name == 'Accept' ? "Yes" : "No"}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <span className='text-red-500'>
                                                            {"No"}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        modalMode === 'AgentStarted' &&
                        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible pb-[100px]  scrollbar mb-6'>
                            <div className="w-full">
                                <table className="w-full text-[#484964]">
                                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                                        <tr className=" text-left text-opacity-90 h-[40px]">
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                N
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                {t("project:membername")}
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                               {t("project:invitationaccepted")}
                                            </th>
                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                {t("project:activemember")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={`relative divide-y`}>
                                        {
                                            itemData?.projectUsers?.map((user, idx) =>(
                                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        {idx + 1 < 10 ? `0${idx+1}` : idx+1}
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <div className="flex items-center gap-2 py-2">
                                                            <div className="h-[30px] w-[30px] flex-shrink-0 rounded-full overflow-hidden">
                                                                <img
                                                                    src="/images/avatar.png"
                                                                    alt="profil"
                                                                    className="h-full w-full object-cover"
                                                                />
                                                            </div>
                                                            <div>
                                                                <h4 className="text-[12px] text-black whitespace-nowrap  leading-[15px]">{user?.firstName +" "+ user?.lastName}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <span className={`${user?.acceptanceStatus?.name == 'Accept' ? 'text-secondary-green-500' : 'text-red-500' }`}>
                                                            {user?.acceptanceStatus?.name == 'Accept' ? "Yes" : "No"}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                        <span className='text-red-500'>
                                                            {"No"}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}