import { Link } from "react-router-dom";
import { Tag } from "../Kits";
import { useState, useEffect, Fragment } from 'react'
import { Menu, Transition, Listbox, Tab } from '@headlessui/react'
import { BsThreeDotsVertical } from "react-icons/bs"
import { useTranslation } from "react-i18next";

export default function FormCard({item, setGetId,toggleModal, ...rest}){
    const { t } = useTranslation(["common", "project", "form"]); 

    return (
        <div {...rest} className="relative bg-white rounded-[6px] p-[15px] h-full cursor-pointer">
            <Link  to='/adm/forms/details-form' state={{ item: item }}>
                <div className=" h-full flex flex-col justify-between">
                    { 
                        item?.number && 
                        <span className="text-[12px] text-primary-blue-500 font-bold block mb-1">{`#ID: ${item?.number}`}</span>
                    }
                    <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4 max-w-[80%]">
                        {item?.name}
                    </h4>
                    <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4">
                        {item?.description}
                    </p>
                    <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-2">
                            &nbsp;
                        </div>
                        <Tag 
                            title={t(`form:${item?.formType?.name}`)}
                            color={item?.formType?.name==='Private' ? 'text-[#6BCB77]' : "text-[#0B449A]"}
                            bg={item?.formType?.name==='Private' ? 'bg-[#f0faf1]' : "bg-[#e7ecf5]"}
                        />
                    </div>
                </div>
            </Link>
            {
                toggleModal && (
                    <>
                        {item?.projects?.length > 0 ? null :
                            <div onClick={(e) => e.stopPropagation()} className='absolute top-2 right-2'>
                                <Menu as="div" className="relative">
                                    <div>
                                        <Menu.Button className="h-[30px] w-[30px] bg-gray-100  rounded-full  focus:outline-none flex items-center justify-center">
                                            <BsThreeDotsVertical  className='text-[14px]'/>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                    <Menu.Items className="absolute z-50 overflow-hidden right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setGetId(item?.id)
                                                    toggleModal()
                                                }}
                                                className={`${
                                                active ? 'bg-neutral-variant-xl-400 ' : ''
                                                } group flex w-full rounded-md items-center px-2 py-2 text-[14px] text-neutral-variant-l-700 hover:bg-red-100 hover:text-red-500 transition-all ease-out duration-200`}
                                            >
                                            
                                                {"Supprimer ce formulaire"}
                                            </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        }
                    </>
                )
            }
        </div>
    )
}