import Base from "../../../layout/adm/base";
import { NewForm } from "../../../section/admin/forms";

export default function CreateForm(){

    return(
        <Base>
            <NewForm />
        </Base>
    )
}