import { useSelector } from "react-redux";
import { ButtonWithBg, FormikSelect } from "../../components/Kits";
import { useTranslation } from "react-i18next";

export default function Filter({
    toggleModal,
    project,
    filterValues,
    setFilterValues,
    getSurveyData
}){
    const { t } = useTranslation(["common", "project", "form"]);
    const surveyDatasStatus = useSelector((state) => state.surveyDatasStatus);

    let optionsAgents = [
        // {
        //     value: null,
        //     label: 'Tout'
        // },
        ...project?.projectUsers?.filter(user => user.roles.some(role => role.name === 'Field Worker' || role.name === 'Project Owner'))?.map(x => (
            {
                value: x?.userId,
                label: x?.firstName +" "+ x?.lastName
            }
        ))
    ]

    let optionsStatus = [
        // {
        //     value: null,
        //     label: 'Tout'
        // },
        ...surveyDatasStatus?.map((item) => (
            {
                value: item?.id,
                label:  t(`project:${item?.name?.replaceAll(" ", "")}`)
            }
        ))
    ]
    

    return (
        <div>
            <div className="p-6 h-[400px] space-y-[20px] overflow-x-hidden">
                <div className="flex items-center gap-[20px]">
                    <span className="shrink-0 whitespace-nowrap block w-[200px]">Agent</span>
                    <div className="block min-h-[45px] flex-1">
                    <FormikSelect 
                        options= {optionsAgents}
                        isMulti
                        defaultValue={filterValues?.agentIds}
                        onChange={(e) => {
                            console.log('formik log', e)
                            setFilterValues((prevState) => ({
                              ...prevState,
                              agentIds: e
                            }))
                        }}
                    />
                    </div>
                </div>
                <div className="flex items-center gap-[20px]">
                    <span className="shrink-0 whitespace-nowrap block w-[200px]">Status</span>
                    <div className="block min-h-[45px] flex-1">
                        <FormikSelect 
                            options= {optionsStatus}
                            isMulti
                            defaultValue={filterValues?.statusIds}
                            onChange={(e) => {
                                setFilterValues((prevState) => ({
                                ...prevState,
                                statusIds: e
                                }))
                            }}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-[20px]">
                    <div>
                        <span className="shrink-0 whitespace-nowrap block w-[200px]">Identifiant</span>
                        {/* <span className="shrink-0 text-[12px] block w-[200px]">Pour faire la recherche sur plusieurs identifiants veuillez les separez par une virgugle.</span> */}
                    </div>
                   
                    <input
                        className="block border bg-white border-black disabled:bg-gray-100 border-opacity-10 rounded-[6px] h-[45px] flex-1 focus:outline-none px-4 text-[14px] text-black placeholder:text-black placeholder:text-opacity-50"
                        type={'text'}
                        value={filterValues?.numbers}
                        onChange={(e) => {
                            setFilterValues((prevState) => ({
                              ...prevState,
                              numbers: e.target.value
                            }))
                        }}
                    />
                </div>
                <div className="flex items-center gap-[20px]">
                    <span className="shrink-0 whitespace-nowrap block w-[200px]">Date de création</span>
                    <input
                        className="block border bg-white border-black disabled:bg-gray-100 border-opacity-10 rounded-[6px] h-[45px] flex-1 focus:outline-none px-4 text-[14px] text-black placeholder:text-black placeholder:text-opacity-50"
                        type={'date'}
                        value={filterValues?.createdAt}
                        onChange={(e) => {
                            // const dateObject = new Date(e.target.value);
                            // const timeStamp = dateObject.getTime();
                            setFilterValues((prevState) => ({
                              ...prevState,
                              createdAt: e.target.value
                            }))
                        }}
                    />
                </div>
            </div>
            <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-x-2'>
                {/* <ButtonWithBg
                    title={'Réinitialiser'}
                    type='button'
                    bg={'gray'}
                    isSmall={true}
                    onClick={() => {
                        setFilterValues({
                            agentId: null,
                            statusId: null,
                            number: '',
                            createdAt: ''
                        });
                    }}
                /> */}
                <ButtonWithBg 
                    title={'Chercher'}
                    type='button'
                    bg={'primary'}
                    isSmall={true}
                    onClick={() => {
                        getSurveyData()
                        toggleModal()
                    }}
                />
            </div>
        </div>
    )
}