// const countries = [
//     {
//         "value": "AF",
//         "group": "A",
//         "name": "Afghanistan"
//     },
//     {
//         "value": "AL",
//         "group": "A",
//         "name": "Albania"
//     },
//     {
//         "value": "DZ",
//         "group": "A",
//         "name": "Algeria"
//     },
//     {
//         "value": "AD",
//         "group": "A",
//         "name": "Andorra"
//     },
//     {
//         "value": "AO",
//         "group": "A",
//         "name": "Angola"
//     },
//     {
//         "value": "AR",
//         "group": "A",
//         "name": "Argentina"
//     },
//     {
//         "value": "AM",
//         "group": "A",
//         "name": "Armenia"
//     },
//     {
//         "value": "AW",
//         "group": "A",
//         "name": "Aruba"
//     },
//     {
//         "value": "AU",
//         "group": "A",
//         "name": "Australia"
//     },
//     {
//         "value": "AT",
//         "group": "A",
//         "name": "Austria"
//     },
//     {
//         "value": "AZ",
//         "group": "A",
//         "name": "Azerbaijan"
//     },
//     {
//         "value": "BH",
//         "group": "B",
//         "name": "Bahrain"
//     },
//     {
//         "value": "BD",
//         "group": "B",
//         "name": "Bangladesh"
//     },
//     {
//         "value": "BY",
//         "group": "B",
//         "name": "Belarus"
//     },
//     {
//         "value": "BE",
//         "group": "B",
//         "name": "Belgium"
//     },
//     {
//         "value": "BZ",
//         "group": "B",
//         "name": "Belize"
//     },
//     {
//         "value": "BJ",
//         "group": "B",
//         "name": "Benin"
//     },
//     {
//         "value": "BT",
//         "group": "B",
//         "name": "Bhutan"
//     },
//     {
//         "value": "BO",
//         "group": "B",
//         "name": "Bolivia"
//     },
//     {
//         "value": "BA",
//         "group": "B",
//         "name": "Bosnia And Herzegovina"
//     },
//     {
//         "value": "BW",
//         "group": "B",
//         "name": "Botswana"
//     },
//     {
//         "value": "BR",
//         "group": "B",
//         "name": "Brazil"
//     },
//     {
//     "value": "IO",
//     "group": "B",
//     "name": "British Indian Ocean Territory"
//     },
//     {
//     "value": "BN",
//     "group": "B",
//     "name": "Brunei Darussalam"
//     },
//     {
//     "value": "BG",
//     "group": "B",
//     "name": "Bulgaria"
//     },
//     {
//     "value": "BF",
//     "group": "B",
//     "name": "Burkina Faso"
//     },
//     {
//     "value": "BI",
//     "group": "B",
//     "name": "Burundi"
//     },
//     {
//     "value": "KH",
//     "group": "C",
//     "name": "Cambodia"
//     },
//     {
//     "value": "CM",
//     "group": "C",
//     "name": "Cameroon"
//     },
//     {
//     "value": "CV",
//     "group": "C",
//     "name": "Cape Verde"
//     },
//     {
//     "value": "CF",
//     "group": "C",
//     "name": "Central African Republic"
//     },
//     {
//     "value": "TD",
//     "group": "C",
//     "name": "Chad"
//     },
//     {
//     "value": "CL",
//     "group": "C",
//     "name": "Chile"
//     },
//     {
//     "value": "CN",
//     "group": "C",
//     "name": "China"
//     },
//     {
//     "value": "CO",
//     "group": "C",
//     "name": "Colombia"
//     },
//     {
//     "value": "KM",
//     "group": "C",
//     "name": "Comoros"
//     },
//     {
//     "value": "CG",
//     "group": "C",
//     "name": "Congo"
//     },
//     {
//     "value": "CD",
//     "group": "C",
//     "name": "Congo, Democratic Republic"
//     },
//     {
//     "value": "CK",
//     "group": "C",
//     "name": "Cook Islands"
//     },
//     {
//     "value": "CR",
//     "group": "C",
//     "name": "Costa Rica"
//     },
//     {
//     "value": "CI",
//     "group": "C",
//     "name": "Cote D'Ivoire"
//     },
//     {
//     "value": "HR",
//     "group": "C",
//     "name": "Croatia"
//     },
//     {
//     "value": "CU",
//     "group": "C",
//     "name": "Cuba"
//     },
//     {
//     "value": "CY",
//     "group": "C",
//     "name": "Cyprus"
//     },
//     {
//     "value": "CZ",
//     "group": "C",
//     "name": "Czech Republic"
//     },
//     {
//     "value": "DK",
//     "group": "D",
//     "name": "Denmark"
//     },
//     {
//     "value": "DJ",
//     "group": "D",
//     "name": "Djibouti"
//     },
//     {
//     "value": "EC",
//     "group": "E",
//     "name": "Ecuador"
//     },
//     {
//     "value": "EG",
//     "group": "E",
//     "name": "Egypt"
//     },
//     {
//     "value": "SV",
//     "group": "E",
//     "name": "El Salvador"
//     },
//     {
//     "value": "GQ",
//     "group": "E",
//     "name": "Equatorial Guinea"
//     },
//     {
//     "value": "ER",
//     "group": "E",
//     "name": "Eritrea"
//     },
//     {
//     "value": "EE",
//     "group": "E",
//     "name": "Estonia"
//     },
//     {
//     "value": "ET",
//     "group": "E",
//     "name": "Ethiopia"
//     },
//     {
//     "value": "FK",
//     "group": "F",
//     "name": "Falkland Islands (Malvinas)"
//     },
//     {
//     "value": "FO",
//     "group": "F",
//     "name": "Faroe Islands"
//     },
//     {
//     "value": "FJ",
//     "group": "F",
//     "name": "Fiji"
//     },
//     {
//     "value": "FI",
//     "group": "F",
//     "name": "Finland"
//     },
//     {
//     "value": "FR",
//     "group": "F",
//     "name": "France"
//     },
//     {
//     "value": "PF",
//     "group": "F",
//     "name": "French Polynesia"
//     },
//     {
//     "value": "GA",
//     "group": "G",
//     "name": "Gabon"
//     },
//     {
//     "value": "GM",
//     "group": "G",
//     "name": "Gambia"
//     },
//     {
//     "value": "GE",
//     "group": "G",
//     "name": "Georgia"
//     },
//     {
//     "value": "DE",
//     "group": "G",
//     "name": "Germany"
//     },
//     {
//     "value": "GH",
//     "group": "G",
//     "name": "Ghana"
//     },
//     {
//     "value": "GI",
//     "group": "G",
//     "name": "Gibraltar"
//     },
//     {
//     "value": "GR",
//     "group": "G",
//     "name": "Greece"
//     },
//     {
//     "value": "GL",
//     "group": "G",
//     "name": "Greenland"
//     },
//     {
//     "value": "GT",
//     "group": "G",
//     "name": "Guatemala"
//     },
//     {
//     "value": "GN",
//     "group": "G",
//     "name": "Guinea"
//     },
//     {
//     "value": "GW",
//     "group": "G",
//     "name": "Guinea-Bissau"
//     },
//     {
//     "value": "GY",
//     "group": "G",
//     "name": "Guyana"
//     },
//     {
//     "value": "HT",
//     "group": "H",
//     "name": "Haiti"
//     },
//     {
//     "value": "HN",
//     "group": "H",
//     "name": "Honduras"
//     },
//     {
//     "value": "HK",
//     "group": "H",
//     "name": "Hong Kong"
//     },
//     {
//     "value": "HU",
//     "group": "H",
//     "name": "Hungary"
//     },
//     {
//     "value": "IS",
//     "group": "I",
//     "name": "Iceland"
//     },
//     {
//     "value": "IN",
//     "group": "I",
//     "name": "India"
//     },
//     {
//     "value": "ID",
//     "group": "I",
//     "name": "Indonesia"
//     },
//     {
//     "value": "IR",
//     "group": "I",
//     "name": "Iran, Islamic Republic Of"
//     },
//     {
//     "value": "IQ",
//     "group": "I",
//     "name": "Iraq"
//     },
//     {
//     "value": "IE",
//     "group": "I",
//     "name": "Ireland"
//     },
//     {
//     "value": "IL",
//     "group": "I",
//     "name": "Israel"
//     },
//     {
//     "value": "IT",
//     "group": "I",
//     "name": "Italy"
//     },
//     {
//     "value": "JP",
//     "group": "J",
//     "name": "Japan"
//     },
//     {
//     "value": "JO",
//     "group": "J",
//     "name": "Jordan"
//     },
//     {
//     "value": "KP",
//     "group": "K",
//     "name": "KP"
//     },
//     {
//     "value": "KE",
//     "group": "K",
//     "name": "Kenya"
//     },
//     {
//     "value": "KI",
//     "group": "K",
//     "name": "Kiribati"
//     },
//     {
//     "value": "KR",
//     "group": "K",
//     "name": "Korea"
//     },
//     {
//     "value": "KW",
//     "group": "K",
//     "name": "Kuwait"
//     },
//     {
//     "value": "KG",
//     "group": "K",
//     "name": "Kyrgyzstan"
//     },
//     {
//     "value": "LA",
//     "group": "L",
//     "name": "Lao People's Democratic Republic"
//     },
//     {
//     "value": "LV",
//     "group": "L",
//     "name": "Latvia"
//     },
//     {
//     "value": "LB",
//     "group": "L",
//     "name": "Lebanon"
//     },
//     {
//     "value": "LS",
//     "group": "L",
//     "name": "Lesotho"
//     },
//     {
//     "value": "LR",
//     "group": "L",
//     "name": "Liberia"
//     },
//     {
//     "value": "LY",
//     "group": "L",
//     "name": "Libyan Arab Jamahiriya"
//     },
//     {
//     "value": "LI",
//     "group": "L",
//     "name": "Liechtenstein"
//     },
//     {
//     "value": "LT",
//     "group": "L",
//     "name": "Lithuania"
//     },
//     {
//     "value": "LU",
//     "group": "L",
//     "name": "Luxembourg"
//     },
//     {
//     "value": "MO",
//     "group": "M",
//     "name": "Macao"
//     },
//     {
//     "value": "MK",
//     "group": "M",
//     "name": "Macedonia"
//     },
//     {
//     "value": "MG",
//     "group": "M",
//     "name": "Madagascar"
//     },
//     {
//     "value": "MW",
//     "group": "M",
//     "name": "Malawi"
//     },
//     {
//     "value": "MY",
//     "group": "M",
//     "name": "Malaysia"
//     },
//     {
//     "value": "MV",
//     "group": "M",
//     "name": "Maldives"
//     },
//     {
//     "value": "ML",
//     "group": "M",
//     "name": "Mali"
//     },
//     {
//     "value": "MT",
//     "group": "M",
//     "name": "Malta"
//     },
//     {
//     "value": "MH",
//     "group": "M",
//     "name": "Marshall Islands"
//     },
//     {
//     "value": "MR",
//     "group": "M",
//     "name": "Mauritania"
//     },
//     {
//     "value": "MU",
//     "group": "M",
//     "name": "Mauritius"
//     },
//     {
//     "value": "MX",
//     "group": "M",
//     "name": "Mexico"
//     },
//     {
//     "value": "FM",
//     "group": "M",
//     "name": "Micronesia, Federated States Of"
//     },
//     {
//     "value": "MD",
//     "group": "M",
//     "name": "Moldova"
//     },
//     {
//     "value": "MC",
//     "group": "M",
//     "name": "Monaco"
//     },
//     {
//     "value": "MN",
//     "group": "M",
//     "name": "Mongolia"
//     },
//     {
//     "value": "ME",
//     "group": "M",
//     "name": "Montenegro"
//     },
//     {
//     "value": "MA",
//     "group": "M",
//     "name": "Morocco"
//     },
//     {
//     "value": "MZ",
//     "group": "M",
//     "name": "Mozambique"
//     },
//     {
//     "value": "NA",
//     "group": "N",
//     "name": "Namibia"
//     },
//     {
//     "value": "NP",
//     "group": "N",
//     "name": "Nepal"
//     },
//     {
//     "value": "NL",
//     "group": "N",
//     "name": "Netherlands"
//     },
//     {
//     "value": "NC",
//     "group": "N",
//     "name": "New Caledonia"
//     },
//     {
//     "value": "NZ",
//     "group": "N",
//     "name": "New Zealand"
//     },
//     {
//     "value": "NI",
//     "group": "N",
//     "name": "Nicaragua"
//     },
//     {
//     "value": "NE",
//     "group": "N",
//     "name": "Niger"
//     },
//     {
//     "value": "NG",
//     "group": "N",
//     "name": "Nigeria"
//     },
//     {
//     "value": "NU",
//     "group": "N",
//     "name": "Niue"
//     },
//     {
//     "value": "NF",
//     "group": "N",
//     "name": "Norfolk Island"
//     },
//     {
//     "value": "NO",
//     "group": "N",
//     "name": "Norway"
//     },
//     {
//     "value": "OM",
//     "group": "A",
//     "name": "Oman"
//     },
//     {
//     "value": "PK",
//     "group": "P",
//     "name": "Pakistan"
//     },
//     {
//     "value": "PA",
//     "group": "P",
//     "name": "Panama"
//     },
//     {
//     "value": "PG",
//     "group": "P",
//     "name": "Papua New Guinea"
//     },
//     {
//     "value": "PY",
//     "group": "P",
//     "name": "Paraguay"
//     },
//     {
//     "value": "PE",
//     "group": "P",
//     "name": "Peru"
//     },
//     {
//     "value": "PH",
//     "group": "P",
//     "name": "Philippines"
//     },
//     {
//     "value": "PL",
//     "group": "P",
//     "name": "Poland"
//     },
//     {
//     "value": "PT",
//     "group": "P",
//     "name": "Portugal"
//     },
//     {
//     "value": "QA",
//     "group": "A",
//     "name": "Qatar"
//     },
//     {
//     "value": "RO",
//     "group": "R",
//     "name": "Romania"
//     },
//     {
//     "value": "RU",
//     "group": "R",
//     "name": "Russian Federation"
//     },
//     {
//     "value": "RW",
//     "group": "R",
//     "name": "Rwanda"
//     },
//     {
//     "value": "WS",
//     "group": "S",
//     "name": "Samoa"
//     },
//     {
//     "value": "SM",
//     "group": "S",
//     "name": "San Marino"
//     },
//     {
//     "value": "ST",
//     "group": "S",
//     "name": "Sao Tome And Principe"
//     },
//     {
//     "value": "SA",
//     "group": "S",
//     "name": "Saudi Arabia"
//     },
//     {
//     "value": "SN",
//     "group": "S",
//     "name": "Senegal"
//     },
//     {
//     "value": "RS",
//     "group": "S",
//     "name": "Serbia"
//     },
//     {
//     "value": "SC",
//     "group": "S",
//     "name": "Seychelles"
//     },
//     {
//     "value": "SL",
//     "group": "S",
//     "name": "Sierra Leone"
//     },
//     {
//     "value": "SG",
//     "group": "S",
//     "name": "Singapore"
//     },
//     {
//     "value": "SK",
//     "group": "S",
//     "name": "Slovakia"
//     },
//     {
//     "value": "SI",
//     "group": "S",
//     "name": "Slovenia"
//     },
//     {
//     "value": "SB",
//     "group": "S",
//     "name": "Solomon Islands"
//     },
//     {
//     "value": "SO",
//     "group": "S",
//     "name": "Somalia"
//     },
//     {
//     "value": "ZA",
//     "group": "S",
//     "name": "South Africa"
//     },
//     {
//     "value": "ES",
//     "group": "S",
//     "name": "Spain"
//     },
//     {
//     "value": "LK",
//     "group": "S",
//     "name": "Sri Lanka"
//     },
//     {
//     "value": "SD",
//     "group": "S",
//     "name": "Sudan"
//     },
//     {
//     "value": "SR",
//     "group": "S",
//     "name": "Suriname"
//     },
//     {
//     "value": "SZ",
//     "group": "S",
//     "name": "Swaziland"
//     },
//     {
//     "value": "SE",
//     "group": "S",
//     "name": "Sweden"
//     },
//     {
//     "value": "CH",
//     "group": "S",
//     "name": "Switzerland"
//     },
//     {
//     "value": "SY",
//     "group": "S",
//     "name": "Syrian Arab Republic"
//     },
//     {
//     "value": "TW",
//     "group": "T",
//     "name": "Taiwan"
//     },
//     {
//     "value": "TJ",
//     "group": "T",
//     "name": "Tajikistan"
//     },
//     {
//     "value": "TZ",
//     "group": "T",
//     "name": "Tanzania"
//     },
//     {
//     "value": "TH",
//     "group": "T",
//     "name": "Thailand"
//     },
//     {
//     "value": "TL",
//     "group": "T",
//     "name": "Timor-Leste"
//     },
//     {
//     "value": "TG",
//     "group": "T",
//     "name": "Togo"
//     },
//     {
//     "value": "TK",
//     "group": "T",
//     "name": "Tokelau"
//     },
//     {
//     "value": "TO",
//     "group": "T",
//     "name": "Tonga"
//     },
//     {
//     "value": "TN",
//     "group": "T",
//     "name": "Tunisia"
//     },
//     {
//     "value": "TR",
//     "group": "T",
//     "name": "Turkey"
//     },
//     {
//     "value": "TM",
//     "group": "T",
//     "name": "Turkmenistan"
//     },
//     {
//     "value": "TV",
//     "group": "T",
//     "name": "Tuvalu"
//     },
//     {
//     "value": "UG",
//     "group": "U",
//     "name": "Uganda"
//     },
//     {
//     "value": "UA",
//     "group": "U",
//     "name": "Ukraine"
//     },
//     {
//     "value": "AE",
//     "group": "U",
//     "name": "United Arab Emirates"
//     },
//     {
//     "value": "GB",
//     "group": "U",
//     "name": "United Kingdom"
//     },
//     {
//     "value": "US",
//     "group": "U",
//     "name": "United States"
//     },
//     {
//     "value": "UY",
//     "group": "U",
//     "name": "Uruguay"
//     },
//     {
//     "value": "VU",
//     "group": "V",
//     "name": "Vanuatu"
//     },
//     {
//     "value": "VE",
//     "group": "V",
//     "name": "Venezuela"
//     },
//     {
//     "value": "VN",
//     "group": "V",
//     "name": "Viet Nam"
//     },
//     {
//     "value": "WF",
//     "group": "W",
//     "name": "Wallis And Futuna"
//     },
//     {
//     "value": "YE",
//     "group": "Y",
//     "name": "Yemen"
//     },
//     {
//     "value": "ZM",
//     "group": "Z",
//     "name": "Zambia"
//     },
//     {
//     "value": "ZW",
//     "group": "Z",
//     "name": "Zimbabwe"
//     }
// ]

const countries = [

    {
    "value": "BF",
    "group": "B",
    "name": "Burkina Faso"
    },
    {
    "value": "CI",
    "group": "C",
    "name": "Cote D'Ivoire"
    },
    {
    "value": "GH",
    "group": "G",
    "name": "Ghana"
    },
    {
    "value": "GN",
    "group": "G",
    "name": "Guinea"
    },
    {
    "value": "LR",
    "group": "L",
    "name": "Liberia"
    },
    {
    "value": "ML",
    "group": "M",
    "name": "Mali"
    },
]

export {
    countries
}