import { Menu, Transition, Dialog, Disclosure, Listbox } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Tag } from '../../../components/Kits';
import { Link } from "react-router-dom";


export default function Table({
    list, 
    t, 
    setModalMode, 
    toggleModal, 
    setAddressInfos, 
    setBillingId, 
    selected, 
    setProjects,
    setInvoices,
    setBillingAccount,
    setIsCompleted,
    setIsUpdated,
    setIsProgressStep,
    setItemData
}){

    console.log('list', list)

    return(
        <div tabIndex="0" className='relative pb-[100px] bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6 mt-[30px]'>
            <div className="w-full">
                <table  className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[60px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"N"}
                            </th>
                            {   selected.name.includes('PB') &&
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                    {"ID"}
                                </th>
                            }
                            <th className={`whitespace-nowrap  px-4 font-[500]  ${selected.name.includes('PB') ? "text-[#000] text-opacity-50" : "text-primary-blue-500"} text-[14px]`}>
                                {"Nom"}
                            </th>
                            {!selected.name.includes('PB') &&
                                <>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        Email
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        {"Telephone"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        {"Payment Method"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        {"Adresse de facturation"}
                                    </th>
                                </>
                            }
                            {   selected.name.includes('PB') &&
                                <>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Total Projet"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Date de debut"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Date de fin"}
                                    </th>
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        {"Solde Projet"}
                                    </th>
                                </>
                            }
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Statut"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Projects"}
                            </th>
                            { !selected.name.includes('PB') && <>
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                    {"Invoices Payments"}
                                </th>
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                    Action
                                </th>
                            </>
                            }

                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                        {   list?.length > 0 ?
                            list?.map((item, idx) => (
                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                    <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {idx + 1}
                                    </td>
                                    {   selected.name.includes('PB') &&
                                        <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                            {item?.id}
                                        </td>
                                    }
                                    <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {item?.name}
                                    </td>
                                    {   !selected.name.includes('PB') &&
                                        <>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {item?.email}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {item?.phone}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {item?.paymentMethodeId || item?.paymentMethode?.id ? "VISA - CARD" : '-------'}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {   item?.billingAddressId ?
                                                    <span  
                                                        className='text-primary-blue-500 cursor-pointer'
                                                        onClick={() => {
                                                            setAddressInfos(item?.billingAddress)
                                                            setItemData()
                                                            setModalMode("view_address")
                                                            toggleModal()
                                                        }}
                                                    >
                                                        {"View more"}
                                                    </span>
                                                    :
                                                    <span  
                                                        className='cursor-pointer'
                                                    >
                                                        {"Aucune Adresse"}
                                                    </span>
                                                }
                                            </td>
                                        </>
                                    }
                                    {   selected.name.includes('PB') &&
                                        <>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {'-----------'}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {new Date(item?.startDate).toLocaleDateString()}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {new Date(item?.endDate).toLocaleDateString()}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                                {'-------------'}
                                            </td>
                                        </>
                                    }
                                    <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        <Tag
                                            title={item?.status}
                                            color={
                                                item?.status === 'Inactive' ? "text-[#FF0505]" : 
                                                "text-[#000]"
                                            }
                                            bg={
                                                item?.status === 'Inactive' ? "bg-[#ff05052d]" : 
                                                "bg-[#F6F7FB]"
                                            }
                                        />
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {item?.projects?.length > 0 ? ( 
                                            <span  
                                                className='text-primary-blue-500 cursor-pointer'
                                                onClick={() => {
                                                    setProjects(item?.projects)
                                                    setModalMode("view_project")
                                                    toggleModal()
                                                }}
                                            >
                                                {"View more"}
                                            </span>
                                        ) : (
                                            <span  
                                                className='text-gray-500 cursor-not-allowed'
                                            >
                                                {"Aucun projet"}
                                            </span>
                                        )
                                        }
                                    </td>
                                    { !selected.name.includes('PB') && <>
                                        <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                            {item?.invoices?.length > 0 ? ( 
                                                <span  
                                                    className='text-primary-blue-500 cursor-pointer'
                                                    onClick={() => {
                                                        setInvoices(item?.invoices)
                                                        setModalMode("view_invoice")
                                                        toggleModal()
                                                    }}
                                                >
                                                    {"View more"}
                                                </span>
                                            ) : (
                                                <span  
                                                    className='text-gray-500 cursor-not-allowed'
                                                >
                                                    {"Aucun projet"}
                                                </span>
                                            )
                                            }
                                        </td>
                                        <td className="px-4 py-2   whitespace-nowrap">
                                        
                                            <Menu as="div" className="relative inline-block text-left">
                                                <Menu.Button disabled={(item?.billingAddress?.length > 0 && item?.tokenCard) ?true : false} className={`text-sm ${(item?.billingAddress?.length > 0 && item?.tokenCard) ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                    <div className="border px-3 py-2 rounded-[4px] text-[12px] font-poppins font-[500] flex items-center gap-6">
                                                        <span className="text-[#676D7C] ">Actions</span>
                                                        <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute z-20 right-0 min-w-[140px]  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="px-1 py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => {
                                                                            setBillingId(item?.id)
                                                                            setItemData({
                                                                                name: item?.name,
                                                                                email: item?.email,
                                                                                phone: item?.phone
                                                                            })
                                                                            setModalMode("update_info_account")
                                                                            toggleModal()
                                                                        }}
                                                                        className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                            } cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                    >
                                                                        {"Update account info"}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                            {
                                                                (item?.projectCount === 0) &&
                                                                <>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => {
                                                                                    setIsCompleted(true)
                                                                                    setIsUpdated(true)
                                                                                    setIsProgressStep(false)
                                                                                    setBillingId(item?.id)
                                                                                    setBillingAccount(item)
                                                                                    setModalMode("choose_payment_method")
                                                                                    toggleModal()
                                                                                }}
                                                                                className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                    } cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                            >
                                                                                {item?.paymentMethodeId ? "Modifier la carte visa" : "Ajouter une carte visa"}
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => {
                                                                                    setIsCompleted(true)
                                                                                    setIsUpdated(true)
                                                                                    setIsProgressStep(false)
                                                                                    setBillingId(item?.id)
                                                                                    setModalMode("choose_billing_address")
                                                                                    toggleModal()
                                                                                }}
                                                                                // disabled={(item?.projects?.length > 0 || item?.invoices?.length > 0) ? true : false}
                                                                                className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                    } cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                            >
                                                                                {item?.billingAddressId ? "Modifier l'adresse" : "Ajouter une adresse de facturation"}
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button
                                                                                onClick={() => {
                                                                                    setBillingId(item?.id)
                                                                                    setModalMode("delete")
                                                                                    toggleModal()
                                                                                }}
                                                                                // disabled={(item?.projects?.length > 0 || item?.invoices?.length > 0) ? true : false}
                                                                                className={`${active ? 'bg-red-100 text-red-500' : 'text-black'
                                                                                    } cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                            >
                                                                                {"Delete"}
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                </>
                                                            }
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </td>
                                    </>
                                    }
                                </tr>
                            ))
                            :
                            <tr className='text-left border-b  border-[#EFEFEF]'>
                                <td className='text-center py-10 text-[14px] text-black' colSpan={10}> {"No data found"} </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
