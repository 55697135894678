import { Link } from "react-router-dom";
import { Tag } from "../../../components/Kits";
import { useState, useEffect, Fragment } from 'react'
import { Menu, Transition, Listbox, Tab } from '@headlessui/react'
import { BsThreeDotsVertical } from "react-icons/bs"
import CardBlockSkeleton from "../../../components/Skeletons/CardBlock";
import { FormCard } from "../../../components/Cards";

export default function BlockView({forms, setGetId, toggleModal, t, loadingForms, length}){

    return(
        <>
        { loadingForms ?
            <CardBlockSkeleton isProject={false}  length={length}/>
            :
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {
                    forms?.map((item, idx) => (
                        <FormCard 
                            key={idx}
                            item={item}
                            // handleDeleteForm={handleDeleteForm}
                            setGetId={setGetId}
                            toggleModal={toggleModal}
                        />
                    ))
                }
            </div>
        }
        </>
    )
}