import PropTypes from 'prop-types';
import React, { useState, useContext, createContext } from 'react';

let nextId = 1;
const ToasterContext = createContext();

function ProvideToaster({ children }) {
  const DEFAULTS = {
    type: 'success',
    title: '',
    message: '',
    duration: 4000,
    delay: 0,
    timer: null,
    persistent: false,
    greedy: false,
    showCloseButton: true
  };

  const [notifications, setNotifications] = useState([]);

  const show = (notification) => {
    const _notification = { ...DEFAULTS, ...notification, id: nextId++ };

    setTimeout(() => {
      if (!_notification.persistent) {
        _notification.timer = setTimeout(
          () => hide(_notification),
          _notification.duration
        );
      }

      setNotifications((prevState) =>
        _notification.greedy ? [_notification] : [...prevState, _notification]
      );
    }, _notification.delay);
  };

  const updateNotification = (notificationToUpdate) => {
    setNotifications([
      ...notifications.map((item) =>
        item.id !== notificationToUpdate.id
          ? item
          : { ...item, ...notificationToUpdate }
      )
    ]);
  };

  const stopTimer = (notification) => {
    clearTimeout(notification.timer);
  };

  const resetTimer = (notification) => {
    if (!notification.persistent) {
      const _notification = { ...notification };
      _notification.timer = setTimeout(
        () => hide(_notification),
        _notification.duration
      );

      updateNotification(_notification);
    }
  };

  const hide = (notificationToHide) => {
    stopTimer(notificationToHide);

    setNotifications((prevState) =>
      prevState.filter(
        (notification) => notification.id !== notificationToHide.id
      )
    );
  };

  return (
    <ToasterContext.Provider
      value={{
        notifications,
        show,
        hide,
        resetTimer,
        stopTimer
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
}

export const useToaster = () => useContext(ToasterContext);

export default ProvideToaster
ProvideToaster.propTypes = {
  children: PropTypes.any.isRequired
};