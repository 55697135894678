// export function formatNameSurvey(alpha){
//     console.log('alphaalpha', alpha)
//     let text = String(alpha).toLocaleLowerCase().replaceAll(" ", "-")

//     return text
// }

export function formatNameSurvey(alpha) {
    if (!alpha) return '';
    
    const firstChar = alpha.charAt(0).toLowerCase();
    const restOfString = alpha.slice(1);

    const formattedString = firstChar + restOfString;

    return formattedString.replaceAll(" ", "-");
}