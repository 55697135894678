import { Disclosure } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag } from '../../../../components/Kits'
import {BsChevronRight} from 'react-icons/bs'
import { useState, useEffect, Fragment } from 'react'
import { projectActions } from '../../../../redux/actions'
import { useDispatch, useSelector } from "react-redux";
import { useToaster } from '../../../../contexts/toaster'
import { useLocation, useNavigate } from "react-router";
import Modal from '../../../../components/Modals/Modal'
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import http from '../../../../http-common'


export default function ProjectCompletion({item, t, project, accessControl}){
    console.log('item', project)
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const projectStatus = useSelector((state) => state.projectStatus);
    const [isLoading, setIsLoading] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [modalMode, setModalMode] = useState('')
    const toaster = useToaster()
    const [projectNumber, setProjectNumber] = useState('')
    
    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])

    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    const [isConf, setIsConf] = useState(false)

    
    const dispatch = useDispatch();
    const handleSubmitProject = async () => {
        // e.preventDefault();
        if(isLoading===false){
            dispatch(projectActions.update(project?.id, itemData, setIsLoading, toaster, isConf));
            // setIsLoading(true)
            // await http.put(`/projects/${project?.id}/`, itemData)
            // .then(function (response) {
            //     if(response.status === 200){
            //         setIsLoading(false)
            //         toaster.show({
            //             title: 'Success !',
            //             message: `Your project has modified successfully`,
            //             greedy: true,
            //             type: 'success'
            //         });
            //         window.location.reload()
            //     }
            // })
            // .catch(function (error) {
            //     setIsLoading(false)
            //     toaster.show({
            //         title: 'Oups !',
            //         message: error,
            //         greedy: true,
            //         type: 'error'
            //     });
            // });
        }

        // console.log("itemData====>", itemData);
        // dispatch(projectActions.update(item?.id, itemData, setIsLoading, toaster, isConf));
      };

    const handleDeleteProject = async (id) => {
        if(isLoading===false){
            dispatch(projectActions.remove(id, toaster, navigate, setIsDeleted, projectNumber));
            // setIsLoading(true)
            // await http.delete(`/projects/${id}`)
            // .then(function (response) {
            //     if(response.status === 200){
            //         setIsLoading(false)
            //         toaster.show({
            //             title: 'Success !',
            //             message: `Project successfully deleted`,
            //             greedy: true,
            //             type: 'success'
            //         });
            //         navigate("/adm/projects/all", { replace: false });
            //     }
            // })
            // .catch(function (error) {
            //     setIsLoading(false)
            //     toaster.show({
            //         title: 'Oups !',
            //         message: error,
            //         greedy: true,
            //         type: 'error'
            //     });
            // });
        }

        // dispatch(projectActions.remove(id, toaster, navigate, setIsDeleted));
    }

    const handleUpdateProjectSTatus = async (alpha) => {
        // e.preventDefault();
        console.log("itemData====>", itemData);
        let foundStatusId = projectStatus?.find(x => x?.name === alpha)?.id

        if(foundStatusId){
            if(isLoading===false){
                dispatch(projectActions.updateProjectStatus(project?.id, {statusId : foundStatusId}, setIsLoading, toaster, isConf));
                // setIsLoading(true)
                // await http.put(`/projects/update-status/${project?.id}/`, {statusId : foundStatusId})
                // .then(function (response) {
                //     if(response.status === 200){
                //         if(alpha === 'Completed'){
                //             handleSubmitProject()
                //         } else{
                //             setIsLoading(false)
                //             toaster.show({
                //                 title: 'Success !',
                //                 message: `Your project has modified successfully`,
                //                 greedy: true,
                //                 type: 'success'
                //             });
                //             window.location.reload()
                //         }
                //     }
                // })
                // .catch(function (error) {
                //     setIsLoading(false)
                //     toaster.show({
                //         title: 'Oups !',
                //         message: error,
                //         greedy: true,
                //         type: 'error'
                //     });
                // });
            }

            // dispatch(projectActions.updateProjectStatus(item?.id, {statusId : foundStatusId}, setIsLoading, toaster, isConf));
        }
      };


    return(
        <>
            <div className='bg-white p-4 rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
                <span className="text-[18px] text-black font-bold">{t("project:completingtheproject")}</span>
                <ul className='my-[30px]'>
                    <li className="flex items-center justify-between py-3 border-b">
                        <span className="text-[14px] text-black text-opacity-50">{t("project:surveyexecutionstatus")}:</span>
                        <Tag 
                            title={t(`project:${project?.status?.name}`)}
                            color={
                                project?.status?.name === 'Completed' ? "text-[#0B449A]" : 
                                project?.status?.name === 'Running' ? "text-[#6BCB77]" : 
                                "text-[#000]"
                            }
                            bg={
                                project?.status?.name === 'Completed' ? "bg-[#e7ecf5]" : 
                                project?.status?.name === 'Running' ? "bg-[#f0faf1]" : 
                                "bg-[#F6F7FB]"
                            }
                        />
                    </li>
                    <li className="flex items-center justify-between py-3">
                        <span className="text-[14px] text-black text-opacity-50">{t("project:surevyenddate")}:</span>
                        <span className="text-[14px] text-black">{new Date(project?.endDate?.split("T")[0]).toLocaleDateString().replaceAll("/", "-")}</span>
                    </li>
                </ul>
                <p className=' text-[16px]'>
                    {
                        project?.status?.name === 'Running' ? t("project:textwhenrunning"):
                        project?.status?.name === 'Completed' ? t("project:textwhencompleted") :
                        project?.status?.name === 'Created' ? t("project:textwhencreated") :
                        project?.status?.name === 'Archived' ? t("project:textwhenarchived") :
                        null
                    }
                </p>
                <div className="flex items-center gap-3 mt-[30px]">
                    {
                        (project?.status?.name == 'Running' || project?.status?.name == 'Configuring') &&
                        <ButtonWithBg 
                            title={t("project:completenow")}
                            type='button'
                            bg={'primary-opacity'}
                            isSmall={true}
                            onClick={()=>{
                                setModalMode('completeNow')
                                setItemData({...itemData, endDate: new Date().toISOString().split("T")[0]})
                                toggleModal()
                            }}
                        />
                    }
                    {   project?.status?.name !== 'Archived' &&
                        <ButtonWithBg 
                            title={
                                (project?.status?.name == 'Running' || project?.status?.name == 'Configuring') ? t("project:changeenddate") :
                                project?.status?.name == 'Completed' ? t("project:extendtheenddate") :
                                project?.status?.name == 'Created' ? t("project:changeenddate") :
                                null
                            }
                            type='button'
                            bg={'primary'}
                            isSmall={true}
                            onClick={()=>{
                                (project?.status?.name == 'Running' || project?.status?.name == 'Configuring') ? setModalMode('changeEndDAte') :
                                project?.status?.name == 'Completed' ? setModalMode('ExtendEndDate') :
                                project?.status?.name == 'Created' ? setModalMode('changeEndDAte') : setModalMode('')
                                toggleModal()
                            }}
                        />
                    }
                </div>
            </div>  

            <Disclosure defaultOpen={true} className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
                {({ open }) => (
                    <div className={`rounded-lg overflow-hidden } `}>
                        <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                            <div className={`flex items-center justify-between gap-3 w-full`}>
                                <span className="text-[18px] text-black font-bold">
                                    {t("project:advancedactions")}
                                </span>
                                <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                            <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[30px]">
                                <h4 className='text-[16px] mb-[20px] font-bold'>{t("project:archivetheproject")}</h4>
                                <p className=' text-[14px]'>
                                    {"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                                </p>
                                <div className="flex items-center gap-3 mt-[30px]">
                                    <ButtonWithBg 
                                        title={t("project:archive")}
                                        type='button'
                                        bg={'primary'}
                                        isSmall={true}
                                        onClick={()=>{
                                            setModalMode('archiveProject')
                                            toggleModal()
                                        }}
                                    />
                                </div>
                            </div>
                            {/* {
                                accessControl?.owner && (
                                    <div className="bg-[#fcf1ef] p-4 border rounded-[6px] mt-[15px] mb-[50px]">
                                        <h4 className='text-[16px] mb-[20px] font-bold text-red-500'>{t("project:deletetheproject")}</h4>
                                        <p className=' text-[14px]'>
                                            {"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                                        </p>
                                        <div className="flex items-center gap-3 mt-[30px]">
                                            <ButtonWithBg 
                                                title={t("common:delete")}
                                                type='button'
                                                bg={'red'}
                                                isSmall={true}
                                                onClick={()=>{
                                                    setModalMode('deleteProject')
                                                    toggleModal()
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            } */}
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>

            <Modal show={isOpen} toggleModal={toggleModal} resetOverflow>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode == 'completeNow' ? t("project:completingtheproject") :
                            modalMode == 'changeEndDAte' ? t("project:changeenddate") :
                            modalMode == 'ExtendEndDate' ? t("project:extendtheenddate") :
                            modalMode == 'deleteProject' ? t("project:deletetheproject") :
                            modalMode == 'archiveProject' ? t("project:archivetheproject") :
                            null
                        }
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                {  modalMode === 'completeNow' &&
                    <>
                        <div className="p-6">
                            <p className='text-[14px] text-black text-opacity-70'>
                                {"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an"}
                            </p>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("project:completenow")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => {
                                    // handleSubmitProject()
                                    handleUpdateProjectSTatus("Completed")
                                }}
                            />
                        </div>
                    </>
                }
                {  modalMode === 'archiveProject' &&
                    <>
                        <div className="p-6">
                            <p className='text-[14px] text-black text-opacity-70'>
                                {"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an"}
                            </p>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("project:archive")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => {
                                    handleUpdateProjectSTatus("Archived")
                                }}
                            />
                        </div>
                    </>
                }
                {/* {  modalMode === 'deleteProject' &&
                    <>
                        <div className="p-6">
                            <h4 className='text-[16px] font-bold mb-4'>
                                {t("project:attentionthisactionisirreversible")}
                            </h4>
                            <p className='text-[14px] text-black text-opacity-70'>
                                {t("project:thedeletionofthisprojectwillalsoleadtothedeletionofitsdata")}
                            </p>
                            <p className='text-[14px] text-black text-opacity-70 mt-[10px]'>
                                {`Entrez les données suivantes pour confirmer:`}
                            </p>
                            <p className='text-[14px] text-black font-[500] mt-[5px] mb-[10px] bg-gray-100 w-fit py-[4px] px-[10px] rounded-md'>
                                {project?.number}
                            </p>
                            <div className='w-[50%]'>
                                <Input 
                                    type={'text'}
                                    value={projectNumber}
                                    onChange={(e)=>{
                                        setProjectNumber(e.target.value)
                                    }}
                                    smallLab={true}
                                />
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isDeleted ? t("common:inprogress") : t("common:delete")}
                                type='submit'
                                bg={'red'}
                                isSmall={true}
                                onClick={() => handleDeleteProject(project?.id)}
                                disabled={projectNumber !== project?.number}
                            />
                        </div>
                    </>
                } */}
                {  modalMode === 'changeEndDAte' &&
                    <>
                        <div className="p-6">
                            <Input 
                                label={t('project:surevyenddate')}
                                type={'date'}
                                value={itemData?.endDate}
                                min={itemData?.startDate}
                                onChange={(e)=>{
                                    setItemData({...itemData, endDate:e.target.value})
                                }}
                                smallLab={true}
                            />
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("project:savechange")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => handleSubmitProject()}
                            />
                        </div>
                    </>
                }
                {  modalMode == 'ExtendEndDate' &&
                    <>
                        <div className="p-6">
                        <Input 
                            label={t('project:surevyenddate')}
                            type={'date'}
                            value={itemData?.endDate}
                            min={new Date().toISOString().split("T")[0]}
                            onChange={(e)=>{
                                setItemData({...itemData, endDate:e.target.value})
                            }}
                            smallLab={true}
                        />
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("project:savechange")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => handleSubmitProject()}
                            />
                        </div>
                    </>
                }
                
            </Modal>
        </>
    )
}