import { GoogleMap, useLoadScript, Marker, Polygon, Polyline, InfoWindow } from "@react-google-maps/api"
import { useState, useEffect, Fragment } from "react";
import { BsCheckLg, } from "react-icons/bs";
import { useLocation } from "react-router";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { FiDownloadCloud } from 'react-icons/fi'
import { Modal } from "../../../components/Modals";
import { ButtonWithBg, FormikSelect } from "../../../components/Kits";
import { useTranslation } from "react-i18next";
import { handleClick, handleClickForUniq } from "../../../utils/downloadGeolocation";
import { Menu, Transition } from '@headlessui/react'
import { getAreaOfPolygon, getPathLength } from "../../../utils/geolibCalcul";
import { useSelector } from "react-redux";
import { MdLocationOn, MdOutlineLocationOff } from "react-icons/md";
import { FaInfo, FaMap } from "react-icons/fa";
import { formatDataSurvey } from "../../../utils/formatSurveyInfo";
import { retirerId } from "../../../utils/retireId";
import { formatNameSurvey } from "../../../utils/formatNameSurvey";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";


export default function LocationMappModal({state, project, externalProject, withUpdateOption}){
    const location = useLocation();
    const { t } = useTranslation(["common", "project", "form"]);
    const { coordinates, type, centerDataMapps, srv } = state;
    const [selectedMarker, setSelectedMarker] = useState(null);
    const userStudyArea = useSelector((state) => state.userStudyArea)
    const [delimiter, setDelimiter] = useState('continue')
    const [showMapType, setShowMapType] = useState(false)
    const [mapType, setMapType] = useState('roadmap')
    const [showMdFilter, setShowMdFilter] = useState(false)

    const [showCenter, setShowCenter] = useState(true)
    const [fieldsInfoToShow, setFieldInfoToShow] = useState([])

    const handleFieldsToShow = (el) => {
        let foundEl = fieldsInfoToShow.find(x => x.value === el.value)
        if(foundEl){
            let tmp = fieldsInfoToShow.filter(x => x.value !== el.value)
            setFieldInfoToShow(tmp)
        } else {
            setFieldInfoToShow([...fieldsInfoToShow, el])
        }
    }





    const baseFields = [
        {
            label: `Id (Mapping)`,
            value: `number`,
        },
        {
            label: `Date de création`,
            value: `createdAt`,
        },
        {
            label: `Agent`,
            value: `agent`,
        },
        {
            label: `Status de la saisie`,
            value: `status`,
        }
    ]

    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    const handleMarkerClick = (marker) => {
      setSelectedMarker(marker);
    };

    {/** --------------- Start Params to export file  */}
    const [downloadInfo, setDownloadInfo] = useState({
        type: "geolocation",
        format: 'gpx',
        from: 1,
        to: 1,
        fileName: []
    });
    console.log('downloadInfo', downloadInfo)

        // useEffect(() => {
        // setDownloadInfo({
        //     ...downloadInfo,
        //     to: coordinates?.length
        // })
        // }, [state])

    {/** --------------- End Params to export file  */}

        {/** ------ Start Format Data For gpxTrack , GpxWaypoint and kml file */}

        const [formatDataGeolocation, setFormatDataGeolocation] = useState([])
        useEffect(()=>{
            let data 
            data = [state]
            setFormatDataGeolocation([data])
    
            console.log('dataMapps 8888 daa', data)
    
        }, [state, downloadInfo])
    
        {/** ------ Start Format Data For gpxTrack , GpxWaypoint and kml file */}

    console.log('formatDataGeolocation', formatDataGeolocation)

    const center = type === 'userLocation' ? {lat: parseFloat(coordinates?.latitude), lng: parseFloat(coordinates?.longitude)} : {lat: parseFloat(coordinates?.[0]?.latitude), lng: parseFloat(coordinates?.[0]?.longitude)}

    const paths = (type !== 'userLocation' && coordinates?.length >= 1) ? [...coordinates?.map((data) =>(
        { lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude)}
    ))] : ''
      
    const options = {
        fillColor: "#0b449a2d",
        fillOpacity: 1,
        strokeColor: "#0B449A",
        strokeOpacity: 1,
        strokeWeight: 4,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }
    const optionsSatellite = {
        fillColor: "#0b449a2d",
        fillOpacity: 1,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 4,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }

    const {isLoaded} = useLoadScript({
        // googleMapsApiKey : 'AIzaSyDCaVGNPuQLr9RxlwwVNLzRzIojUpxq_Cs'
        googleMapsApiKey : 'AIzaSyD5N5H922bRZG_OuPBAv18tM9xNqiMpzRg'
    })

    const centerOptions = {
        icon: {
          url: '/images/red-flag.png',
          scaledSize: { width: 24, height: 24 }
        }
    };
    const markerOptions = {
        icon: {
          url: '/images/pin.png',
          scaledSize: { width: 24, height: 24 }
        }
    };

    const markerStartPoint = {
        icon: {
          url: '/images/start-pin.png',
          scaledSize: { width: 24, height: 24 }
        }
    };
    const markerEndPoint = {
        icon: {
          url: '/images/end-pin.png',
          scaledSize: { width: 24, height: 24 }
        }
    };

    if(!isLoaded) return <div>Loading mapp...</div>

    return (
        <Mapp 
            type={type}
            coordinates={coordinates}
            centerDataMapps={centerDataMapps}
            center={center}
            mapType={mapType}
            handleMarkerClick={handleMarkerClick}
            paths={paths}
            options={options}
            optionsSatellite={optionsSatellite}
            showCenter={showCenter}
            centerOptions={centerOptions}
            fieldsInfoToShow={fieldsInfoToShow}
            srv={srv}
            delimiter={delimiter}
            markerOptions={markerOptions}
            markerStartPoint={markerStartPoint}
            markerEndPoint={markerEndPoint}
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
            setShowMdFilter={setShowMdFilter}
            showMdFilter={showMdFilter}
            setMapType={setMapType}
            setShowMapType={setShowMapType}
            showMapType={showMapType}
            setDelimiter={setDelimiter}
            setShowCenter={setShowCenter}
            project={project}
            handleFieldsToShow={handleFieldsToShow}
            baseFields={baseFields}
            userStudyArea={userStudyArea}
            downloadInfo={downloadInfo}
            setDownloadInfo={setDownloadInfo}
            externalProject={externalProject}
            formatDataGeolocation={formatDataGeolocation}
            t={t}
        />
    )
    
}


function Mapp ({
    type,
    coordinates,
    centerDataMapps,
    center,
    mapType,
    handleMarkerClick,
    paths,
    options,
    optionsSatellite,
    showCenter,
    centerOptions,
    fieldsInfoToShow,
    srv,
    delimiter,
    markerOptions,
    markerStartPoint,
    markerEndPoint,
    selectedMarker,
    setSelectedMarker,
    setShowMdFilter,
    showMdFilter,
    setMapType,
    setShowMapType,
    showMapType,
    setDelimiter,
    setShowCenter,
    project,
    handleFieldsToShow,
    baseFields,
    userStudyArea,
    downloadInfo,
    setDownloadInfo,
    externalProject,
    formatDataGeolocation,
    t
}) {
    const [showTextLabel, setShowTextLabel] = useState(false)
    
    return(
        <>
            <div className="relative">
                <GoogleMap 
                    zoom={(type !== 'userLocation' && coordinates?.length > 1) ? 18 : 10}
                    animation= {window.google.maps.Animation.DROP}
                    center={ centerDataMapps ? centerDataMapps : center }
                    mapContainerStyle={{
                        width: "100%",
                        height: "400px"
                    }}
                    options={{
                        mapTypeId: mapType,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControlOptions: {
                            position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
                        },
                    }}
                >
                    {
                        type === 'userLocation' ? 
                        <Marker 
                            position={center} 
                            onClick={() => {
                                handleMarkerClick(center)
                                setShowTextLabel(false)
                            }}
                        />
                        :
                        (type !== 'userLocation' && coordinates?.length === 1) ?
                        <Marker 
                            position={center} 
                            onClick={() => {
                                handleMarkerClick(center)
                                setShowTextLabel(false)
                            }}
                        />
                        :
                        <>
                            {
                                delimiter !== 'startAndEndPoints' && (
                                    <Polygon
                                        // onLoad={onLoad}
                                        paths={paths}
                                        options={mapType === 'roadmap' ? options : optionsSatellite}
                                    />
                                )
                            }
                            
                            {
                                (showCenter && centerDataMapps) && 
                                <Marker
                                    position={centerDataMapps}
                                    options={centerOptions}
                                    onClick={() => {
                                        handleMarkerClick(centerDataMapps)
                                        setShowTextLabel(true)
                                    }}
                                />
                            }
                            {
                                (fieldsInfoToShow?.length > 0 && centerDataMapps) && 
                                <InfoWindow
                                    position={centerDataMapps}
                                >
                                <div className="space-y-[10px]">
                                    {
                                        fieldsInfoToShow?.map(field => {
                                            let newObjectSrv = formatDataSurvey(srv)
                                            console.log('fieldsInfoToShow', fieldsInfoToShow)
                                            console.log('fieldsInfoToShow newObjectSrv', fieldsInfoToShow)
                                            return (
                                                <p className="block">
                                                    <span className="!font-[600] text-black">{field?.label}: </span>
                                                    <span>{retirerId(newObjectSrv[formatNameSurvey(field?.value)]?.value)}</span>
                                                </p>
                                            )
                                            
                                        })
                                    }
                                </div>
                                </InfoWindow>
                            }
                            { delimiter === 'continueWitCoords' && paths?.map((path, index) => (
                                <Marker
                                    key={index}
                                    position={path}
                                    options={markerOptions}
                                    onClick={() => {
                                        handleMarkerClick(path)
                                        setShowTextLabel(false)
                                    }}
                                />
                                
                            ))}
                            { delimiter === 'startAndEndPoints' && 
                                <>
                                    <Polyline
                                        path={paths}
                                        options={mapType === 'roadmap' ? options : optionsSatellite}
                                    />
                                    <Marker
                                        position={paths[0]}
                                        options={markerStartPoint}
                                        onClick={() => {
                                            handleMarkerClick(paths[0])
                                            setShowTextLabel(false)
                                        }}
                                    />
                                    <Marker
                                        position={paths[paths?.length - 1]}
                                        options={markerEndPoint}
                                        onClick={() => {
                                            handleMarkerClick(paths[paths?.length - 1])
                                            setShowTextLabel(false)
                                        }}
                                    />
                                    
                                </>
                                
                            }
                        </>
                    }
                    {selectedMarker && (
                        <InfoWindow
                            position={selectedMarker}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div className="font-bold"> {showTextLabel && 'Center'} <br/><br/>Lat : {selectedMarker.lat}, Lng : {selectedMarker.lng}</div>
                        </InfoWindow>
                    )}
                </GoogleMap>
                <div className={`absolute bottom-[3%]  left-[3%] flex items-center gap-1`}>
                    <div onClick={() => setShowMdFilter(!showMdFilter)} className="flex justify-center cursor-pointer items-center bg-primary-blue-500 h-[40px] w-[40px] rounded-full text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <FiDownloadCloud className="text-[16px]" />
                    </div>
                    <div className="relative bg-white rounded-full cursor-pointer group">
                        <div className="relative h-[40px] w-[40px] bg-white rounded-full cursor-pointer z-40 flex items-center justify-center">
                            <FaMap />
                        </div>
                        <div 
                            onClick={() => {
                                setMapType('roadmap')
                                setShowMapType(!showMapType)
                            }} 
                            className={`absolute top-0 left-0 h-[40px] w-[40px] bg-white border ${mapType === 'roadmap' ? "border-blue-500 border-2" : ''} rounded-full cursor-pointer transition-transform duration-200 ease-out  group-hover:translate-y-[-110%]`}
                        >
                            <img 
                                src="/images/roadmap.png"
                                alt="icon"
                                className="h-full w-full object-cover rounded-full"
                            />
                        </div>
                        <div 
                            onClick={() => {
                                setMapType("satellite")
                                setShowMapType(!showMapType)
                            }}
                            className={`absolute top-0 left-0 h-[40px] w-[40px] bg-white border ${mapType === 'satellite' ? "border-blue-500 border-2" : ''} rounded-full cursor-pointer transition-transform duration-200 ease-out group-hover:translate-y-[-220%]`}
                        >
                            <img 
                                src="/images/satellite.png"
                                alt="icon"
                                className="h-full w-full object-cover rounded-full"
                            />
                        </div>
                    </div>
                    <div className="relative bg-white rounded-full cursor-pointer group">
                        <div 
                            className="relative h-[40px] w-[40px] bg-white rounded-full cursor-pointer z-40 flex items-center justify-center"
                        >
                            <MdLocationOn className="text-[20px]" />
                        </div>
                        <div className="absolute bottom-[100%] w-[300px] left-0 bg-white p-[10px] rounded-lg space-y-[10px] group-hover:block hidden">
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${delimiter === 'continue' ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setDelimiter('continue')}
                            >
                                <span className="flex items-center gap-2">
                                    {"Délimitation continue"}
                                </span>
                            </span>
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${delimiter === 'continueWitCoords' ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setDelimiter('continueWitCoords')}
                            >
                                <span className="flex items-center gap-2">
                                    {"Délimitation continue avec coordonnées"}
                                </span>
                            </span>
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${delimiter === 'startAndEndPoints' ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setDelimiter('startAndEndPoints')}
                            >
                                <span className="flex items-center gap-2">
                                    {" Points de départ et d’arrivée"}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="relative bg-white rounded-full cursor-pointer group">
                        <div 
                            className="relative h-[40px] w-[40px] bg-white rounded-full cursor-pointer z-40 flex items-center justify-center"
                        >
                            <FaInfo />
                        </div>
                        <div className="absolute z-50 shadow-[0px_0px_20px_-4px_rgba(0,0,0,0.1)] bottom-[100%] w-[200px] left-0 bg-white p-[10px] rounded-lg space-y-[10px] group-hover:block hidden">
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${showCenter ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setShowCenter(!showCenter)}
                            >
                                Centre
                            </span>
                            {
                                project?.form?.fields?.map((el, idx) =>(
                                    <span 
                                        key={idx}
                                        className={`block text-[14px] text-black font-[400] cursor-pointer rounded-md ${fieldsInfoToShow.find(x => x.value === el.name) ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px]`}
                                        onClick={() => handleFieldsToShow({value: el.name, label: el.label})}
                                    >
                                        {el.label}
                                    </span>
                                ))
                            }
                            {
                                project?.configuration?.useExternalProject && (
                                    <span 
                                        className={`block text-[14px] text-black font-[400] cursor-pointer rounded-md ${fieldsInfoToShow.find(x => x.value === project?.configuration?.externalProject?.fieldName) ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px]`}
                                        onClick={() => {
                                            handleFieldsToShow({
                                                label: project?.configuration?.externalProject?.fieldName,
                                                value: project?.configuration?.externalProject?.fieldName
                                            })
                                        }}
                                    >
                                        {project?.configuration?.externalProject?.fieldName}
                                    </span>
                                )
                            }
                            {
                                baseFields?.filter(x => x.value !== 'status')?.map((el, idx) =>(
                                    <span 
                                        key={idx}
                                        className={`block text-[14px] text-black font-[400] cursor-pointer rounded-md ${fieldsInfoToShow.find(x => x.value === el.value) ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px]`}
                                        onClick={() => handleFieldsToShow(el)}
                                    >
                                        {el.label}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* {
                    withUpdateOption && (
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-3'>
                            <div 
                                className="h-[40px] w-[40px] bg-primary-blue-500/10 rounded-lg cursor-pointer"
                                onClick={() => }
                            >

                            </div>
                            <ButtonWithBg 
                                title={t("project:savechange")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                // disabled={isUpdatingSurvey}
                                onClick={() => {}}
                            />
                        </div>
                    )
                } */}
                
                {
                    (type === 'polygone' || type === 'continuous') && (
                        <div className="absolute top-[3%] left-[3%] bg-white px-6 py-2 rounded-lg shadow-[0px_0px_20px_-4px_rgba(0,0,0,0.1)]">
                            <span className="text-[14px] text-black block border-b py-2 border-dashed">
                                Aire : <span className="text-primary-blue-500 font-bold">{getAreaOfPolygon(paths, userStudyArea?.area)} {userStudyArea?.area}</span>
                            </span>
                            <span className="text-[14px] text-black block py-2">
                                Périmètre : <span className="text-primary-blue-500 font-bold">{getPathLength(paths, userStudyArea?.perimeter)} {userStudyArea?.perimeter}</span>
                            </span>
                        </div>
                    )
                }
                {
                    showMdFilter &&
                    <div className={`absolute top-0 ${showMdFilter ? 'right-0' : '-right-full'} transition-all duration-150 ease-out  h-full w-[300px] bg-white shadow-[0px_0px_20px_-4px_rgba(0,0,0,0.1)] p-4`}>
                        <h4 className="text-[14px] mb-[10px]">Exporter la donnée</h4>
                        <form>
                            <div className="mb-[20px]">
                                <label className="text-[14px] text-black text-opacity-50">{t("project:formattoexport")}</label>
                                <select 
                                    value={downloadInfo?.format} 
                                    onChange={(e)=>{
                                        setDownloadInfo({
                                            ...downloadInfo,
                                            format: e.target.value
                                        })
                                    }}
                                    className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[12px] px-1">
                                    <option value='gpx'>GPX Waypoint</option>
                                    <option value='kml'>KML</option>
                                    <option value='gpxTrack'>GPX Track</option>
                                </select>
                            </div>
                            <div className="mb-[30px]">
                                <label className="text-[14px] text-black text-opacity-50">{`Nommage des fichiers`}</label>
                                <FormikSelect
                                    isMulti
                                    options= {project?.configuration?.useExternalProject ? [
                                        ...baseFields,
                                        ...project?.form?.fields?.map(k => (
                                            {
                                                label: k?.label,
                                                value: k?.name
                                            }
                                        )),
                                        ...externalProject.form.fields.map(el => (
                                            {
                                                label: `${el.label} (${capitalizeFirstLetter(project?.configuration?.externalProject?.fieldName)})`,
                                                value: `${project?.configuration?.externalProject?.fieldName}_${el.name}`
                                            }
                                        ))
                                    ] : [
                                        ...baseFields,
                                        ...project?.form?.fields?.map(k => (
                                            {
                                                label: k?.label,
                                                value: k?.name
                                            }
                                        ))
                                    ]
                                    }
                                    value={downloadInfo.fileName}
                                    onChange={(e) => {
                                        console.log('formik log', e)
                                        setDownloadInfo({...downloadInfo, fileName: e})
                                    }}
                                />
                            </div>
                        </form>
                        <div className='flex justify-end gap-x-3 mb-[10px] px-6 pt-3 border-t absolute bottom-0 w-full'>
                            <ButtonWithBg 
                                title={t('common:close')}
                                type='button'
                                bg={'gray'}
                                isSmall={true}
                                onClick={() => {setShowMdFilter(!showMdFilter)}}
                            />
                            <ButtonWithBg 
                                title={t('project:export')}
                                type='button'
                                bg={'primary'}
                                isSmall={true}
                                disabled={downloadInfo.fileName.length < 1}
                                onClick={() => {
                                    handleClick(formatDataGeolocation, downloadInfo.format, downloadInfo.fileName, project)
                                }}
                            />
                        </div>
                    </div>
                }
                
            </div>
        </>
    )
}

