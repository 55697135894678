import { accountTypeConstants as constants } from '../constants'

const initialState = []

export default function (accountTypes = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case constants.RETRIEVE_ACCOUNT_TYPES:
      return payload
    default:
      return accountTypes
  }
}
