// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyD6D7LKwkUJgKq2bqVMC-vvCYlBkX1a5CI",
  authDomain: "agritech-survey-preprod-env.firebaseapp.com",
  databaseURL:
    "https://agritech-survey-preprod-env-default-rtdb.firebaseio.com",
  projectId: "agritech-survey-preprod-env",
  storageBucket: "agritech-survey-preprod-env.appspot.com",
  messagingSenderId: "281725325768",
  appId: "1:281725325768:web:f59270faf80627ad9670fd",
  measurementId: "G-XK8QWHCML6",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = (setUserToken) => {
  return getToken(messaging, {
    vapidKey: `BOeV08vLBSZyXqTvdTtESqFRn81mKKUATUt94HjnSSKrKiKbYnsADCkl8lxgEJOnbRNr1epJBbBQP46R0O0NlIw`,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setUserToken(currentToken)
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
