import React, { useEffect, useState } from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../redux/actions'
import { accountTypesActions } from '../../redux/actions/account.type.actions'
import { useNavigate } from 'react-router-dom'
import { Input, ButtonWithBg, InputRadio, Paragraph } from "../../components/Kits";
import { useToaster } from '../../contexts/toaster'
import http from "../../http-common";
import { authLogic } from "../../utils";

export default function ResetPwd(){
    const [data, setData] = useState({
        password: '',
        confirmPassword: ''
    })
    const [tokenReset, setTokenReset] = useState(null)
    const [loading, setLoading] = useState(false)
    const toaster = useToaster()
    const token = useSelector((state) => state.auth.token);
  
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        console.log('data', data)
        e.preventDefault();
        if(data?.confirmPassword !== data?.password){
            toaster.show({
                title: 'Oups !',
                message: 'les 2 mots de passes ne sont pas identiques',
                greedy: true,
                type: 'error'
            });
        }else if(data?.confirmPassword === '' || data?.password === ''){
            toaster.show({
                title: 'Oups !',
                message: 'Veuillez remplir tous les champs',
                greedy: true,
                type: 'error'
            });
        } else {
            if(loading === false){
            setLoading(true)
            await http.put(`/reset-password-jwt/${tokenReset}`, data)
                .then(function (response) {
                    if(response.status === 200){
                        setLoading(false)
                        toaster.show({
                            title: 'Success !',
                            message: "Password succesfully updated",
                            greedy: true,
                            type: 'success'
                        });
                        navigate("/", { replace: true });
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log("error", error)
                    toaster.show({
                        title: 'Oups !',
                        message: 'Something was wrong, please restart',
                        greedy: true,
                        type: 'error'
                    });
                }); 
            }
        }
      };
    
      useEffect(() => {
        if (authLogic.isAuthenticated(token) === true) {
          return navigate("/adm/survey-console", { replace: true });
        }
      }, []);

      useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const tokenJwt = urlParams.get('token');
            setTokenReset(tokenJwt)
      }, [])

    return (
        <div className="flex items-center justify-center p-6 h-screen">
            <div className='w-[400px]'>
                <div className="flex items-center h-[50px] justify-center gap-[8px] uppercase font-bold mb-[30px]">
                    <img 
                        src={'/logo/terros-logotype-green.png'}
                        alt='logo'
                        className="h-full w-full object-contain"
                    />
                </div>
                <div className='text-center mb-[50px]'>
                    <h2 className='font-bold text-[25px] font-ibm-plex-sans mb-2'>Reset your password</h2>
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting "}
                    />
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="mb-[30px]">
                        <Input
                            label={"New password"}
                            smallLab={true}
                            placeholder={"***********"}
                            type={"password"}
                            name={"password"}
                            onChange={(e) =>
                                setData({ ...data, password: e.target.value })
                            }
                        />
                    </div>
                    <div className="mb-[30px]">
                        <Input
                            label={"Confirm password"}
                            smallLab={true}
                            placeholder={"***********"}
                            type={"password"}
                            name={"password"}
                            onChange={(e) =>
                                setData({ ...data, confirmPassword: e.target.value })
                            }
                        />
                    </div>
                    <ButtonWithBg title={ loading ? "Loading..." : "Reset password"} type="sumbit" bg={"primary"} />
                </form>
            </div>
        </div>
    )
}