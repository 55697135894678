import Base from "../../../layout/adm/base";
import { NewDatabase } from "../../../section/admin/Database";

export default function CreateDatabaseSource(){

    return(
        <Base>
            <NewDatabase />
        </Base>
    )
}