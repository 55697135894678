import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { InputSearch, Heading, Paragraph, ButtonWithBg, Input, Tag } from "../../../components/Kits";
import { Link } from "react-router-dom";
import Table from "./Table";
import { Modal } from "../../../components/Modals";
import { useState, useMemo, useEffect, Fragment } from "react";
import {Transition,  Listbox } from '@headlessui/react';
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsCheckLg } from 'react-icons/bs'
import { useToaster } from "../../../contexts/toaster";
import { BiCurrentLocation } from 'react-icons/bi'
import ReactDOM from 'react-dom';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from "react-redux";

import {
    billingAccountActions,
    billingAddressActions,
    paymentsMethodActions
} from '../../../redux/actions'
import { useNavigate } from "react-router";
import InvoiceTable from "../../../components/tables/InvoiceTable";
import { useTranslation } from "react-i18next";
import { getCountryname } from '../../../utils/getCountryName';
import ComboboxSelectCountry from '../../../components/Kits/Form/ComboBoxSelect';



const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
    );
  
    return options;
  };

  function getTitle (alpha){
    if(alpha === 'PB'){
        return 'Promo'
    }else{
        return 'Standard'
    }
  }

export default function AccountList(){
    const accountInitialState = {
        name: "",
        email: "",
        phone: ""
    };
    
    const addressInitialState = {
        billingAccountId: "",
        address: "",
        city: "",
        "state": "",
        isUseDefault: false
    }
    const { t } = useTranslation(["common", "project", "form"]);
    const toaster = useToaster()
    const dispatch = useDispatch();  
    const navigate = useNavigate();
    const billingAccounts = useSelector((state) => state.billingAccounts);
    const billingAddress = useSelector((state) => state.billingAddress);
    const billingTypes = useSelector((state) => state.billingTypes);
    const paymentsMethods = useSelector((state) => state.paymentMethods);
    const [billingId, setBillingId] = useState(null)
    const [billingAddressId, setBillingAddressId] = useState(null)
    const [addressInfos, setAddressInfos] = useState(null)
    const [itemData, setItemData] = useState(accountInitialState)
    const [addressInfo, setAddressInfo] = useState(addressInitialState)
    const [projects, setProjects] = useState([])
    const [invoices, setInvoices] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [isProgressStep, setIsProgressStep] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const [modalMode, setModalMode] = useState('')
    const [noPassToNext, setNoPassToNext] = useState(true)
    const [filterBilling, setBillingFilter] = useState([])
    const [billingAccount, setBillingAccount] = useState(null)
    const [paymentMethodId, setPaymentMethodId] = useState(null)
    const toggleModal = ()=>{
        setIsOpen(!isOpen)
        setBillingAddressId(null)
    }

    const data = [
        { name: 'All', id : 'all' },
        { name: 'Configuring', id: 'Configuring' },
        { name: 'Created', id: 'Created' },
    ]
    const [selected, setSelected] = useState(billingTypes.find(x => x?.name.includes('SB')))
    const [errorsAdressInfoField, setErrorsAdressInfoField] = useState([])
    const [errorsBillingDataField, setErrorsBillingDataField] = useState([])
    let emptyAdressInfoKey = []
    let emptyBillingDataKey = []

    const [isCompleted, setIsCompleted] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)

    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (e) => {
      const inputValue = e;
      setItemData({...itemData, phone:e})
  
      // Expression régulière pour vérifier si la valeur est un numéro de téléphone de 13 chiffres
      const regex = /^\d{13}$/;
  
      // Mise à jour de l'état de validité en fonction du résultat de la validation
      setIsValid(regex.test(inputValue));
    };

    console.log('isValid', isValid)

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const options = useOptions();
      
        const handleSubmit = async (event) => {
            event.preventDefault();

            const cardName = event.target.cardName.value;
        
            if (!stripe || !elements) {
              return;
            }
    
            const { token, error } = await stripe.createToken(elements.getElement(CardNumberElement), {
                name: cardName,
            });
            if (error) {
            console.error(error);
            } else {
                console.log(token);
                let data = {
                    // billingAccountId : billingId,
                    tokenCard : token.id
                }
                dispatch(paymentsMethodActions.create(data, toaster, setLoading, toggleModal, setIsCompleted, isCompleted, setModalMode, setPaymentMethodId))
            }
        };

      
        return (
            <form onSubmit={(event) => handleSubmit(event)}>
                <label className="relative block mb-[30px]">
                    <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                        {"Cardholder Name"}
                    </span>
                    <input
                        type="text"
                        id="cardName"
                        name="cardName"
                        placeholder="John Doe"  // Remplacez cela par la valeur par défaut souhaitée
                        className="w-full p-2 border rounded"
                    />
                </label>
                <label className="relative block mb-[30px]">
                    <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                        {"Card Number"}
                    </span>
                    <CardNumberElement
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }}
                    />
                </label>
                <label className="relative block mb-[30px]">
                    <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                        {"Expiration date"}
                    </span>
                    <CardExpiryElement
                        options={options}
                        onReady={() => {
                        console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                        console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                        console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                        console.log("CardNumberElement [focus]");
                        }}
                    />
                </label>
                <label className="relative block mb-[30px]">
                    <span className={`block bg-white whitespace-nowrap absolute left-4 -top-3 px-2 min-w-[80px] text-[14px]`}>
                        {"CVC"}
                    </span>
                    <CardCvcElement
                        options={options}
                        onReady={() => {
                        console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                        console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                        console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                        console.log("CardNumberElement [focus]");
                        }}
                    />
                </label>
                <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-3'>
                    <ButtonWithBg 
                        title={"Retour"}
                        type='button'
                        bg={'primary-opacity'}
                        isSmall={true}
                        onClick={()=> {setModalMode("choose_payment_method")}}
                    />
                    <ButtonWithBg 
                        title={isLoading ? "in progress..." : "ajouter"}
                        type='submit'
                        bg={'primary'}
                        isSmall={true}
                        disabled={!stripe}
                    />
                </div>
            </form>
        );
      };
      
      const stripePromise = loadStripe('pk_test_51LFyQqB8kO7JvbAYjcTUWCRo1WuA3Aot2bdvhIJrTyLbEbuyNlwQoQvspxQ09oGb9lWhpZEAiGaJTIH4dapbsmv600t3fYW9Kp');

    const handleSubmitBillingAccount = async (e) => {
        e.preventDefault();
        emptyBillingDataKey = Object.entries(itemData).filter(([key]) => itemData[key] === '').map(([key]) => key)
        setErrorsBillingDataField(emptyBillingDataKey)

        if(emptyBillingDataKey.length === 0 && isValid){
            if(modalMode === 'add_account'){
                await dispatch(billingAccountActions.create(itemData, navigate, toaster, setLoading, setBillingId, setModalMode));
            }else{
                await dispatch(billingAccountActions.update(billingId, itemData, setLoading, toaster, toggleModal, setBillingFilter));
            }
            setItemData(accountInitialState)
        }
      };

      const handleAddBillingAdress = (e, alpha) => {
        e.preventDefault()
        if(alpha === 'create'){
            console.log('dfsbvsfbdsb', addressInfo)
            emptyAdressInfoKey = Object.entries(addressInfo).filter(([key]) => key !== 'company' && addressInfo[key] === '').map(([key]) => key)
            setErrorsAdressInfoField(emptyAdressInfoKey)
            if (emptyAdressInfoKey.length === 0) {
                // dispatch(billingAccountActions.addAdresssBillingAccount(addressInfo, setLoading, toaster, setModalMode, toggleModal))
                dispatch(billingAddressActions.create(addressInfo, toaster, setLoading, toggleModal, setModalMode, setIsCompleted, isCompleted, setBillingAddressId))
                setAddressInfo(addressInitialState)
            }
        } else {
            let data = {
                billingAccountId : billingId,
                billingAddressId : billingAddressId
            }
            dispatch(billingAccountActions.bindBillingAddressToBillingAccount(data, setLoading, toaster, setModalMode, toggleModal))
            setAddressInfo(addressInitialState)
        }
      }

      useEffect(() => {
        setAddressInfo({...addressInfo, billingAccountId: billingId})
    }, [billingId]);

    useEffect(()=>{
        dispatch(billingAccountActions.getAllBillingAccount())
        dispatch(billingAddressActions.getAllBillingAddress());
    }, [])

    useEffect(()=>{
        setBillingFilter(
            billingAccounts?.filter(x => x?.billingAccountTypeId === selected?.id || x?.billingAccountType?.id === selected?.id)
        )
    },[selected, billingAccounts])

    const handleAddBillingPaymentMethod = (e) => {
        e.preventDefault()
        if(paymentMethodId){
            let data = {
                billingAccountId : billingId,
                paymentMethodeId : paymentMethodId
            }
            dispatch(billingAccountActions.bindPaymentMethodToBillingAccount(data, setLoading, toaster, setModalMode, toggleModal, setIsCompleted, isCompleted))
            setPaymentMethodId(null)
        } else {
            setLoading(false)
            toaster.show({
            title: 'Oups error !',
            message: `Please select a payment method`,
            greedy: true,
            type: 'error'
            });
        }
    }

    //-------------- Delete billing account--------------- //
    function deleteBillingAccount(){
        if(isDeleted === false){
            dispatch(billingAccountActions.remove(billingId, toaster, setIsDeleted, toggleModal))
        }
    }

    console.log('selected', selected)

    return(
        <div>
            <div className="flex items-center justify-between mb-[50px]">
                <div className="max-w-[40%]">
                    <Heading title={"Billing Account"} mb={"10px"} />
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <div className="w-[300px]">
                        <InputSearch 
                            placeholder="Search projects, forms..."
                        />
                    </div>
                    <div className="flex justify-between md:justify-start items-center gap-3">
                        <span className="text-[14px] block text-[#000]  font-poppins font-regular">Type: </span>
                        <Listbox value={selected} onChange={setSelected}>
                            <div className="relative">
                                <Listbox.Button className="inline-flex h-[40px] min-w-[98px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none">
                                    <span className="block text-[14px] text-[#000]  mr-4 font-title font-medium">{getTitle(selected?.name)}</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute left-0 w-48 mt-2 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {billingTypes?.map((item, itemIdx) => (
                                            <Listbox.Option
                                                key={itemIdx}
                                                className={({ active }) =>
                                                    `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                        active ? 'bg-[#00c28818] text-myGreen' : 'text-black text-opacity-50'
                                                    }`
                                                }
                                                value={item}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`block text-[14px] font-poppins ${
                                                                selected ? 'font-normal' : 'font-normal'
                                                            }`}
                                                        >
                                                            {getTitle(item.name)}
                                                        </span>
                                                        
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                </div>
                {
                    !selected?.name?.toLowerCase()?.includes('pb') &&
                    <ButtonWithBg 
                        title={"Add account"}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        onClick={()=>{
                            setModalMode("add_account")
                            setIsCompleted(false)
                            setIsUpdated(false)
                            setIsProgressStep(true)
                            toggleModal()
                        }}
                    />
                }
            </div>

            <Table 
                list={filterBilling} 
                setModalMode={setModalMode} 
                toggleModal={toggleModal} 
                setAddressInfos={setAddressInfos} 
                setBillingId={setBillingId} 
                selected={selected} 
                setProjects={setProjects}
                setInvoices={setInvoices}
                setBillingAccount={setBillingAccount}
                setIsCompleted={setIsCompleted}
                setIsUpdated={setIsUpdated}
                setIsProgressStep={setIsProgressStep}
                setItemData={setItemData}
            />
            
            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal} resetOverflow>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode === 'add_account' ? `${isProgressStep ? 'Step 1 :' : ''} Ajouter un nouveau compte` :
                            modalMode === 'card_info' ? `${isProgressStep ? 'Step 2 :' : ''} Ajouter les informations de votre carte visa` :
                            modalMode === 'choose_payment_method' ? `${isProgressStep ? 'Step 2 :' : ''} Choisir une méthode de payement` :
                            modalMode === 'billing_adress' ? `${isProgressStep ? 'Step 3 :' : ''} Créer une adresse de facturation` :
                            modalMode === 'choose_billing_address' ? `${isProgressStep ? 'Step 3 :' : ''} Choisir une adresse de facturation` :
                            modalMode === 'view_address' ? "Adresse de facturation" :
                            modalMode === 'view_project' ? "Projects" :
                            modalMode === 'view_invoice' ? "Invoices" :
                            modalMode === 'delete' ? "Delete billing account" :
                            modalMode === 'update_info_account' ? "Update account info" :
                            null
                        }
                    </h4>
                    <div 
                        onClick={()=>{
                            toggleModal() 
                            setErrorsAdressInfoField([])
                            setErrorsBillingDataField([])
                        }}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                {  (modalMode === 'add_account' || modalMode === 'update_info_account') &&
                    <form onSubmit={(e) => handleSubmitBillingAccount(e)}>
                        <div className="max-h-[400px]">
                            <div className="p-6">
                                <Input 
                                    label={'Name'}
                                    type={'text'}
                                    value={itemData?.name}
                                    onChange={(e)=>{
                                        setItemData({...itemData, name:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsBillingDataField?.includes("name") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                <Input 
                                    label={'Email'}
                                    type={'email'}
                                    value={itemData?.email}
                                    onChange={(e)=>{
                                        setItemData({...itemData, email:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsBillingDataField?.includes("email") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                <div className='relative'>
                                    <span className={`block z-20 bg-white absolute left-4 -top-3 px-2  min-w-[80px] text-[14px]`}>
                                        {"Phone"}
                                    </span>
                                    <PhoneInput
                                        country={'ci'}
                                        onlyCountries={['ci']}
                                        placeholder={'Entrez votre numéro'}
                                        autoFormat={false}
                                        countryCodeEditable={false}
                                        value={itemData?.phone}
                                        onChange={(e)=>{
                                            console.log('e---->', e)
                                            handleInputChange(e)
                                        }}
                                    />
                                </div>
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsBillingDataField?.includes("phone") && "**This field is required"
                                    }
                                </span>
                                {
                                    !isValid && 
                                    <span className='text-[12px] text-red-500'>
                                        {
                                            "**Invalid number, must contain 10 digits"
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            {
                                modalMode === 'add_account' ?
                                <ButtonWithBg 
                                    title={isLoading ? "in progress..." : "ajouter"}
                                    type='submit'
                                    bg={'primary'}
                                    isSmall={true}
                                />
                                :
                                <ButtonWithBg 
                                    title={isLoading ? "in progress..." : "Update"}
                                    type='submit'
                                    bg={'primary'}
                                    isSmall={true}
                                />
                            }
                            
                        </div>
                    </form>
                }
                {  modalMode === 'billing_adress' &&
                    <form onSubmit={(e) => handleAddBillingAdress(e, 'create')}>
                        <div className="max-h-[400px] overflow-x-hidden">
                            <div className="p-6">
                                <Input 
                                    label={'address'}
                                    type={'text'}
                                    value={addressInfo?.address}
                                    onChange={(e)=>{
                                        setAddressInfo({...addressInfo, address:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("address") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                <Input 
                                    label={'city'}
                                    type={'text'}
                                    value={addressInfo?.city}
                                    onChange={(e)=>{
                                        setAddressInfo({...addressInfo, city:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("city") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                {/* <Input 
                                    label={'state'}
                                    type={'text'}
                                    value={addressInfo?.state}
                                    onChange={(e)=>{
                                        setAddressInfo({...addressInfo, state:e.target.value})
                                    }}
                                    smallLab={true}
                                /> */}
                                <ComboboxSelectCountry 
                                    label={`Country`}
                                    setAddressInfo={setAddressInfo}
                                    addressInfo={addressInfo}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("state") && "**This field is required"
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-2'>
                            <ButtonWithBg 
                                title={"Retour"}
                                type='button'
                                bg={'primary-opacity'}
                                isSmall={true}
                                onClick={()=> {setModalMode("choose_billing_address")}}
                            />
                            <ButtonWithBg 
                                title={isLoading ? "in progress..." : "ajouter"}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                            />
                        </div>
                    </form>
                }
                {
                    modalMode === 'choose_billing_address' &&
                    <form onSubmit={(e) => handleAddBillingAdress(e, 'choose')}>
                        <div className="max-h-[400px] overflow-x-hidden">
                            <div className="grid grid-cols-2 gap-4 mb-[30px] p-6">
                                {
                                    billingAddress?.map((billingAdd, idx) =>(
                                        <label className="cursor-pointer">
                                            <input 
                                                type='radio' 
                                                name="form" 
                                                value={billingAdd?.id} 
                                                onChange={(e) =>{ 
                                                    setBillingAddressId(billingAdd?.id)
                                                }} 
                                                checked={billingAdd?.id === billingAddressId ? true : false}
                                                className="input-radio hidden" 
                                            />
                                            <div className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between border relative form-card">
                                                <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[20px] w-[20px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                                    <BsCheckLg className='text-white text-[12px]' />
                                                </div>
                                                <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                                                    {billingAdd?.address}
                                                </h4>
                                                <p className="text-[12px] text-black text-opacity-50">
                                                    {`${billingAdd?.city} / ${getCountryname(billingAdd?.state)}`}

                                                </p>
                                            </div>
                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-2'>
                            <ButtonWithBg 
                                title={"New billing address"}
                                type='button'
                                bg={'primary-opacity'}
                                isSmall={true}
                                onClick={()=> {
                                    setModalMode("billing_adress")
                                    setIsCompleted(false)
                                }}
                            />
                            {
                                isUpdated ? (
                                    <ButtonWithBg 
                                        title={isLoading ? "in progress..." : "ajouter"}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                        onClick={() => setIsCompleted(true)}
                                    />
                                ) : (
                                    <ButtonWithBg 
                                        title={isLoading ? "in progress..." : "ajouter"}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                    />
                                )
                            }
                        </div>
                    </form>
                }
                {
                    modalMode==="card_info" &&
                    <div className="p-6">
                        <Elements stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </div>  
                }
                {
                    modalMode === 'choose_payment_method' && 
                    <form onSubmit={(e) => {handleAddBillingPaymentMethod(e)}}>
                        <div className="max-h-[400px] overflow-x-hidden">
                            <div className="grid grid-cols-2 gap-4 mb-[30px] p-6">
                                {
                                    paymentsMethods.map((method, idx) => (
                                        <label key={idx} className="cursor-pointer">
                                            <input 
                                                type='radio' 
                                                name="form" 
                                                value={method?.id} 
                                                onChange={(e) =>{ 
                                                    setPaymentMethodId(method?.id)
                                                }} 
                                                checked={method?.id === paymentMethodId ? true : false}
                                                className="input-radio hidden" 
                                            />
                                            <div className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between border relative form-card">
                                                <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[20px] w-[20px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                                    <BsCheckLg className='text-white text-[12px]' />
                                                </div>
                                                <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                                                    {method?.cardholderName}
                                                </h4>
                                                <p className="text-[12px] text-black text-opacity-50">
                                                    {`Expiration date: ${method?.expMonth} / ${method?.expYear}`}
                                                </p>
                                            </div>
                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-2'>
                            <ButtonWithBg 
                                title={"New payment method"}
                                type='button'
                                bg={'primary-opacity'}
                                isSmall={true}
                                onClick={()=> {
                                    setModalMode("card_info")
                                    setIsCompleted(false)
                                }}
                            />
                            {
                                isUpdated ? (
                                    <ButtonWithBg 
                                        title={isLoading ? "in progress..." : "ajouter"}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                        onClick={() => setIsCompleted(true)}
                                    />
                                ) : (
                                    <ButtonWithBg 
                                        title={isLoading ? "in progress..." : "ajouter"}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                    />
                                )
                            }
                            
                        </div>
                    </form>
                }
                {
                    modalMode === "view_address" &&
                    <div className="max-h-[400px] overflow-x-hidden p-6">
                        {/* {  */}
                            {/* addressInfos?.map((el, idx) =>( */}
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>{"Address"}:</span>
                                    <span className='block text-[14px] text-black'>{addressInfos?.address}</span>
                                </div>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>{"City"}:</span>
                                    <span className='block text-[14px] text-black'>{addressInfos?.city}</span>
                                </div>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>{"State"}:</span>
                                    <span className='block text-[14px] text-black'>{getCountryname(addressInfos?.state)}</span>
                                </div>
                            </div>
                            {/* )) */}
                        {/* } */}
                    </div>
                }
                {
                    modalMode === "view_project" && 
                    <div className="max-h-[400px] overflow-x-hidden p-6">
                        <div className="grid lg:grid-cols-2 gap-4">
                            {
                                projects?.map((item, idx)=>(
                                <Link key={idx} to={ item?.status?.name !== "Configuring" ? "/adm/projects/details-projects" : "/adm/projects/config-project"} state={{ item: item }}>
                                    <div className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between border">
                                        <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                                            {item?.name}
                                        </h4>
                                        <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4 lowercase">
                                            {
                                            item?.description
                                            }
                                        </p>
                                        <div className="flex items-center justify-between gap-4">
                                            <div className="flex items-center gap-2">
                                            {
                                                item?.projectUsers?.length == 0 ? 
                                                <span className="text-[12px] text-black text-opacity-50">
                                                Aucun member
                                                </span>
                                                :
                                                <>
                                                <div className="flex items-center">
                                                    { item?.projectUsers?.length > 0 &&
                                                    <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                                        <img
                                                        src="/images/user_default.png"
                                                        alt="member"
                                                        className="h-full w-full object-cover"
                                                        />
                                                    </div>
                                                    }
                                                    { item?.projectUsers?.length > 1 &&
                                                    <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                                    <img
                                                        src="/images/woman.png"
                                                        alt="member"
                                                        className="h-full w-full object-cover"
                                                    />
                                                    </div>
                                                    }
                                                    { item?.projectUsers?.length > 2 &&
                                                    <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                                        <img
                                                        src="/images/user_default.png"
                                                        alt="member"
                                                        className="h-full w-full object-cover"
                                                        />
                                                    </div>
                                                    }
                                                </div>
                                                { item?.projectUsers?.length > 3 &&
                                                    <span className="text-[12px] text-black text-opacity-50">
                                                    and {item?.projectUsers?.length - 3} others
                                                    </span>
                                                }
                                                
                                                </>
                                            }
                                            </div>
                                            <Tag
                                                title={t(`project:${item?.status?.name}`)}
                                                color={
                                                    item?.status?.name == 'Completed' ? "text-[#0B449A]" : 
                                                    item?.status?.name == 'Running' ? "text-[#6BCB77]" : 
                                                    "text-[#000]"
                                                }
                                                bg={
                                                    item?.status?.name == 'Completed' ? "bg-[#e7ecf5]" : 
                                                    item?.status?.name == 'Running' ? "bg-[#f0faf1]" : 
                                                    "bg-[#F6F7FB]"
                                                }
                                            />
                                        </div>
                                        <div className="flex items-center justify-between gap-4 mt-4">
                                            <span className="text-[12px] text-black">{new Date(item?.startDate).toLocaleDateString()}</span>
                                            <span className="text-[12px] text-black">{new Date(item?.endDate).toLocaleDateString()}</span>
                                        </div>
                                    </div>
                                </Link>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    modalMode === 'view_invoice' &&
                    <div className="px-6">
                        <InvoiceTable invoices={invoices} />
                    </div>
                }
                {
                    modalMode === 'delete' &&
                    <div className="max-h-[400px] overflow-x-hidden">
                        <div className="p-6">
                            <h4 className='text-[16px] font-bold mb-4'>
                                {`Attention this action is irreversible`}
                            </h4>
                            <p className='text-[14px] text-black text-opacity-70'>
                                {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}
                            </p>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isDeleted ? 'Deleting...' : 'Delete'}
                                type='submit'
                                bg={'red'}
                                isSmall={true}
                                onClick={() => deleteBillingAccount()}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </div>
    )
}