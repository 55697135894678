import { uiConstants } from '../constants'

const initialState = {
  loading: false,
  errors: null,
  success: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case uiConstants.SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    case uiConstants.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      }
    case uiConstants.LOADING_UI:
      return {
        ...state,
        loading: true,
      }
    case uiConstants.SET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    case uiConstants.CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: null,
      }
    default:
      return state
  }
}
