import http from '../http-common'

// const signup = (newUser) => {
//   return http.post('/signup', newUser)
// }

const signup = (newUser) => {
  return http.post('/signup-with-jwt', newUser)
}

// const signin = (credentials) => {
//   return http.post('/signin', credentials)
// }

const signin = (credentials) => {
  return http.post('/signin-with-jwt', credentials)
}

const user = () => {
  return http.get('/user')
}

const getUsers = () => {
  return http.get('/users')
}

const completeRegistration = (data) => {
  return http.post('/complete-registration', data)
}

const confirmAccount = () => {
  return http.get('/confirm-account')
}

const createProjectUser = (data) => {
  return http.post('/teams', data)
}

const getProjectUsers = () => {
  return http.get('/teams')
}

const searchUserByEmail = (email) => {
  return http.post('/user/search-user-by-email', email)
}

const updateUserDetails = (data) => {
  return http.post('/user/update-details', data)
}

const resetPaswordByUser = (data) => {
  return http.post('/reset-password-by-user', data)
}

const authService = {
  signin,
  signup,
  user,
  completeRegistration,
  confirmAccount,
  createProjectUser,
  getProjectUsers,
  getUsers,
  searchUserByEmail,
  updateUserDetails,
  resetPaswordByUser
}
export default authService
