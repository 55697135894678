import { appSettingService } from "../../services";
import { uiConstants, appSettingConstants as constants } from "../constants";

const getAppsAreas = () => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    appSettingService
      .getAppsArea()
      .then((response) => {
        dispatch({
          type: constants.RETRIEVE_DATA_AREAS,
          payload: response.data.data,
        });
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .catch((response) => {
        console.error(response);
        if (response.data !== undefined) {
          dispatch({
            type: uiConstants.SET_ERRORS,
            payload: response.data.message,
          });
        }
      });
  };
};

// const updateAppsAreas = (data, id, setLoading) => {
//   setLoading(true)
//   return (dispatch) => {
//     dispatch({
//       type: uiConstants.LOADING_UI,
//     });
//     appSettingService
//       .updateAppArea()
//       .then((response) => {
//         dispatch({
//           type: constants.RETRIEVE_DATA_AREAS,
//           payload: response.data.data,
//         });
//         dispatch({ type: uiConstants.CLEAR_ERRORS });
//       })
//       .catch((response) => {
//         console.error(response);
//         if (response.data !== undefined) {
//           dispatch({
//             type: uiConstants.SET_ERRORS,
//             payload: response.data.message,
//           });
//         }
//       });
//   };
// };

const updateAppsAreas = (data, toaster, setLoading) => async (dispatch) => {
  setLoading(true)
  try {
    const res = await appSettingService.updateAppArea(data);
    if(res.status === 200){
      console.log("res ====>", res)
      toaster.show({
        title: 'Success !',
        message: `App setting successfully updated`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      dispatch({
        type: constants.UPDATE_DATA_AREAS,
        payload: res.data.data,
      });
    }
    
    return Promise.resolve(res.data.data);
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    return Promise.reject(err);
  }
};

const getAppLang = () => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    appSettingService
      .getLang()
      .then((response) => {
        dispatch({
          type: constants.RETRIEVE_LANG,
          payload: response.data.data,
        });
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .catch((response) => {
        console.error(response);
        if (response.data !== undefined) {
          dispatch({
            type: uiConstants.SET_ERRORS,
            payload: response.data.message,
          });
        }
      });
  };
};

const updateAppLang = (data, toaster, setLoading, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    const res = await appSettingService.updateLang(data);
    if(res.status === 200){
      console.log("res ====>", res)
      toaster.show({
        title: 'Success !',
        message: `Language successfully updated`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      if(toggleModal){
        toggleModal()
      }
      dispatch({
        type: constants.UPDATE_LANG,
        payload: res.data.data,
      });
    }
    
    return Promise.resolve(res.data.data);
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    return Promise.reject(err);
  }
};

export const appSettingActions = { 
  getAppsAreas ,
  getAppLang,
  updateAppsAreas,
  updateAppLang
};
