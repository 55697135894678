import React, { Suspense } from 'react'
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import { Login, RegisterPage, AccountVerified, ForgetPwd, ResetPassword } from './auth';
import { 
  SurveyConsole, 
  AllProject,
  CreateProject, 
  AllForms, 
  CreateForm, 
  ProjectOverview , 
  All, 
  ManageAccount, 
  UpdaetForm, 
  ConfProject, 
  LocationData, 
  MapViews, 
  AllBilling,
  AllBillingAddress,
  AllDatabase,
  CreateDatabaseSource,
  DetailsDatabases,
  AllPaymentsMethod
} from './adm';
import {authLogic, PrivateRoute } from '../utils';
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import CompleteAccount from './CompleteAccount';
import ActivatedAccount from './Activated';
import ProvideLoadData from '../contexts/loadData';
import Home from './home';
import PrivacyPolicy from './privacy-policy';
// import '../console'


export default function App() {
  const token = useSelector((state) => state.auth.token);
  if (token) {
    const { exp: expireDate } = jwtDecode(token);
    if (expireDate * 1000 > new Date().getTime()) {
      authLogic.setAxiosToken(token);
    }
  }

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/home" element={<Home />} />
          <Route path="/activated-account" element={<ActivatedAccount />} />
          <Route path="/forget-password" element={<ForgetPwd />} />
          <Route path="/complete-registration" element={ <PrivateRoute children={<CompleteAccount />} />} />
          <Route path="/verify-account" element={ <PrivateRoute children={<AccountVerified />} />} />
          <Route path="/adm/survey-console" element={ <PrivateRoute children={<SurveyConsole />} />} />
          <Route path="/adm/projects/all" element={<PrivateRoute children={<AllProject />} />} />
          <Route path="/adm/projects/create-project" element={<PrivateRoute children={<CreateProject />} />} />
          <Route path="/adm/data-location/:projectId/:fieldId/:el" element={<PrivateRoute children={<LocationData />} />} />
          <Route path="/adm/projects/config-project" element={<PrivateRoute children={<ConfProject />} />} />
          <Route path="/adm/projects/details-projects" element={<PrivateRoute children={<ProjectOverview />} />} />
          <Route path="/adm/data-hub/all" element={<PrivateRoute children={<All />} />} />
          <Route path="/adm/data-hub/map-view" element={<PrivateRoute children={<MapViews />} />} />
          <Route path="/adm/account/profil" element={<PrivateRoute children={<ManageAccount />} />} />
          <Route path="/adm/forms/all" element={<PrivateRoute children={<AllForms />} />} />
          <Route path="/adm/forms/create-form" element={<PrivateRoute children={<CreateForm />} />} />
          <Route path="/adm/forms/details-form" element={<PrivateRoute children={<UpdaetForm />} />} />
          <Route path="/adm/billing-account/all" element={<PrivateRoute children={<AllBilling />} />} />
          <Route path="/adm/address-billing/all" element={<PrivateRoute children={<AllBillingAddress />} />} />
          <Route path="/adm/databse_source/all" element={<PrivateRoute children={<AllDatabase />} />} />
          <Route path="/adm/databse_source/add" element={<PrivateRoute children={<CreateDatabaseSource />} />} />
          <Route path="/adm/databse_source/details" element={<PrivateRoute children={<DetailsDatabases />} />} />
          <Route path="/adm/payments-method/all" element={<PrivateRoute children={<AllPaymentsMethod />} />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
    
  )
}
