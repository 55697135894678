import { userConstants } from '../constants'

const initialState = {
  token: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case userConstants.AUTH_USER:
      return {
        token: action.token,
      }
    case userConstants.AUTH_LOGOUT:
      return {
        token: null,
      }
    default:
      return state
  }
}
