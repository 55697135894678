import { userConstants } from '../constants'

const initialState = []

export default function (projectUsers = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case userConstants.CREATE_USERS:
      return [payload, ...projectUsers]
    case userConstants.RETRIEVE_USERS:
      return payload
    default:
      return projectUsers
  }
}
