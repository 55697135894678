export default function SelectField({label, smallLab, options, ...props}){

    return(
        <label className="relative">
            <span className={`block bg-white z-20 absolute left-4 -top-3 px-2 min-w-[80px] ${smallLab ? "text-[14px]" : "text-[16px]" }`}>
                {label}
            </span>
            <select
                className="block border bg-white border-black border-opacity-10 rounded-[6px] h-[50px] w-full focus:outline-none px-4 text-[14px] text-black  placeholder:text-black placeholder:text-opacity-50"
                {...props}
            >
                {
                    options?.map((x,k) =>(
                        <option key={k} value={x?.value}>{x?.name}</option>
                    ))
                }
            </select>
        </label>
    )
}