import Base from "../../../layout/adm/base";
import { DetailsForm } from "../../../section/admin/forms";

export default function UpdaetForm(){

    return(
        <Base>
            <DetailsForm />
        </Base>
    )
}