import { paymentsMethodConstants, uiConstants } from "../constants";
import paymentsMethodService from "../../services/paymentsMethod.service";

const create = (data, toaster, setLoading, toggleModal, setIsCompleted, isCompleted, setModalMode, setPaymentMethodId) => (dispatch) => {
  setLoading(true)
  dispatch({ type: uiConstants.LOADING_UI });
  paymentsMethodService
    .create(data)
    .then((response) => {
      console.log('response payment method', response)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: paymentsMethodConstants.CREATE_PAYMENTSMETHOD,
        payload: response.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your billing address has created successfully"`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      if(isCompleted){
        toggleModal()
        // setIsCompleted(false)
      }else{
        setModalMode("choose_payment_method")
        setPaymentMethodId(response.data.data.id)
        // setIsCompleted(false)
      }
      
    })
    .catch((response) => {
      // toast.error(response.response.data.message);
      console.log('response', response)
      toaster.show({
        title: 'Oups error !',
        message: `${response.message}`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const getAllPaymentsMethod = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  paymentsMethodService
    .getAll()
    .then((response) => {
      console.log('response ekvqek====>', response)
      dispatch({
        type: paymentsMethodConstants.RETRIEVE_PAYMENTSMETHOD,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      if (response.data !== undefined) {
        dispatch({
          type: uiConstants.SET_ERRORS,
          payload: response.data.message,
        });
      }
    });
};

const update = (id, data, setLoading, toaster, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    const res = await paymentsMethodService.update(id, data);
    console.log('resres', res)
    if(res.status === 200){
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: paymentsMethodConstants.UPDATE_PAYMENTSMETHOD,
        payload: res.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleModal()
    }
    return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
};

const deletedPaymentMethod = (id, setLoading,  toaster, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    const res = await paymentsMethodService.remove(id);
    if(res.status === 200){
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: paymentsMethodConstants.DELETE_PAYMENTSMETHOD,
        payload: {id},
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has deleted successfully`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleModal()
    }
    return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
}

export const paymentsMethodActions = {
  create,
  getAllPaymentsMethod,
  update,
  deletedPaymentMethod
};
