import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/Kits";
import { ButtonWithBg } from "../../components/Kits/Buttons";
import { userActions } from "../../redux/actions";
// import { userActions } from '../../redux/actions/user.actions'
import { uiConstants } from "../../redux/constants";
import { authLogic } from "../../utils";

export default function VerifyAcc() {
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.UI.loading)
    const token = useSelector((state) => state.auth.token)
    const navigate = useNavigate()
    
  
    useEffect(() => {
      dispatch(userActions.AccountVerified(navigate))
    }, [])
  


  return (
    <div className="h-[100vh] flex justify-center items-center bg-tertiary-gray-500">
        <div className="w-[450px] text-center bg-white px-4 pt-8 pb-8 rounded-[6px] overflow-hidden">
          <div className="h-[100px] w-[100px] mx-auto mb-2">
            <img 
              src="/images/confetti.gif"
              className="h-full w-full"
            />
          </div>
          <h2 className='font-bold text-[25px] font-ibm-plex-sans my-4'>Congratulations &#128525;&#128079;</h2>
          <span className="block text-center mb-4 text-black text-opacity-50">
            Your account is created but is not confirmed, please check your email
            to process to the confirmation !
          </span>
          <ButtonWithBg isSmall title={"Ok, thanks"} type="button" bg={"primary"} onClick={() => authLogic.logout()} />
        </div>
    </div>
  );
}
