import { Link, useLocation, useNavigate } from "react-router-dom";
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,SelectField, FormikSelect} from "../../../components/Kits";
import { useEffect, useState, Fragment } from "react";
import { Modal } from "../../../components/Modals";
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from "react-redux";
import {
    databaseSourceActions
} from "../../../redux/actions";
import { databaseSourceService } from "../../../services";
import { useToaster } from "../../../contexts/toaster";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { BsThreeDotsVertical } from "react-icons/bs"
import { useTranslation } from "react-i18next";

export default function Details(){
    const { t } = useTranslation(["common", "dataSource"]);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toaster = useToaster()
    let { item } = location.state;
    const databaseSources = useSelector((state) => state.databaseSources);
    let dataBaseItem = databaseSources?.find(x => x?.id === item?.id)
    const formInitialState = {
        name: dataBaseItem?.name,
        description: dataBaseItem?.description,
        fields: dataBaseItem?.fields,
    };
    const [formBuild, setFormBuild] = useState(formInitialState);
    let [isOpen, setIsOpen] = useState(false)
    let [isLoaded, setIsLoaded] = useState(true)
    let [isLoading, setIsLoading] = useState(false)
    const [fieldsValues, setFieldsValue] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [element, setElement] = useState(null)
    const [modalMode, setModalMode] = useState('')

    // console.log('element', item)

    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    const handleSubmitForm = () => {
        let dataToSend = {
            formDataSourceId: item?.id,
            dataSource: fieldsValues
        }
        dispatch(databaseSourceActions.createData(dataToSend, toaster, setLoading, toggleModal, dataSource, setDataSource));
    };

    const handleUpdateData = () => {
        let dataToSend = {
            formDataSourceId: item?.id,
            dataSource: {
                ...element?.dataSource,
                ...fieldsValues
            }
        }
        dispatch(databaseSourceActions.updateData(element?.id, dataToSend, toaster, setLoading, toggleModal, setDataSource));
    };

    const handleDeleteData = () => {
        dispatch(databaseSourceActions.deleteData(element?.id, toaster, setIsLoading, toggleModal, dataSource, setDataSource));
    };

    const handleUpdateInfoGeneral = () => {
        dispatch(databaseSourceActions.updateDatabase(item?.id, formBuild, toaster, setLoading, toggleModal));
    };

    const handleDeleteFormDataSources = () => {
        dispatch(databaseSourceActions.deleteDataBase(item?.id, toaster, setIsLoading, navigate));
    };

    function getDataSource(){
        setIsLoaded(true)
        databaseSourceService.getDataSourceById(item?.id)
        .then(response => {
            setDataSource(response.data.data)
            setIsLoaded(false)
        })
        .catch((err) =>{
            setIsLoaded(false)
            toaster.show({
                title: 'Oups error !',
                message: `Error lors de la récuperation des données`,
                greedy: true,
                type: 'error'
              });
        })
    }

    useEffect(()=>{
        getDataSource()
    }, [])

    async function getDataSourceOption(id){
        let resToReturn = []
        await databaseSourceService.getDataSourceById(id)
        .then(response => {
            resToReturn = response.data.data
        })
        .catch((err) =>{
            toaster.show({
                title: 'Oups error !',
                message: `Error lors de la récuperation des données`,
                greedy: true,
                type: 'error'
              });
        })

        console.log('resToReturn', resToReturn)

        return resToReturn
    }

    console.log("dataSource", dataSource)


    return(
        <>
            <div className="max-w-[40%] mb-[50px] flex items-start justify-between gap-4">
                <div className="flex-1">
                    <Heading title={dataBaseItem?.name} mb={"10px"} />
                    <Paragraph 
                        content={dataBaseItem?.description}
                        limit={"limit-3"}
                    />
                </div>
                <div onClick={(e) => e.stopPropagation()} className=''>
                    <Menu as="div" className="relative">
                        <div>
                            <Menu.Button className="h-[30px] w-[30px] bg-gray-200  rounded-full  focus:outline-none flex items-center justify-center">
                                <BsThreeDotsVertical  className='text-[14px]'/>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                        <Menu.Items className="absolute z-50 overflow-hidden right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-1">
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    onClick={() => {
                                        setModalMode('update_general_info')
                                        toggleModal()
                                    }}
                                    className={`${
                                    active ? 'bg-neutral-variant-xl-400 ' : ''
                                    } group flex w-full rounded-md items-center px-2 py-2 text-[14px] text-neutral-variant-l-700 hover:bg-[#00c28818]  hover:text-myGreen transition-all ease-out duration-200`}
                                >
                                
                                    {t("common:update")}
                                </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    onClick={() => {
                                        setModalMode('delete_form_data_source')
                                        toggleModal()
                                    }}
                                    className={`${
                                    active ? 'bg-neutral-variant-xl-400 ' : ''
                                    } group flex w-full rounded-md items-center px-2 py-2 text-[14px] text-neutral-variant-l-700 hover:bg-red-100 hover:text-red-500 transition-all ease-out duration-200`}
                                >
                                
                                    {t("common:delete")}
                                </button>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className=" items-center gap-2 my-[20px]">
                    <span className="text-[18px] text-black">
                        {t("dataSource:datalist")}
                    </span>
                </div>
                <div className="flex items-center gap-4">
                    <ButtonWithBg 
                        title={t("dataSource:newdata")}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        onClick={()=>{
                            setModalMode('add')
                            toggleModal()
                        }}
                    />
                </div>
            </div>

            {/* List data */}
            <div tabIndex="0" className='relative bg-white pb-[100px] rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6 mt-[20px]'>
                <div className="w-full">
                    <table className="w-full text-[#484964]">
                        <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                            <tr className=" text-left text-opacity-90 h-[40px]">
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  w-[100px]'>
                                    N
                                </th>
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  w-[100px]'>
                                    Identifiant
                                </th>
                                {dataBaseItem?.fields?.map((data, index) => {
                                    return (
                                        <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]'>
                                            {data?.label}
                                        </th>
                                    );
                                })}
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]'>
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody className={`relative divide-y`}>
                            {
                                isLoaded ? <TableSkeleton length={10}  columns={3} />  :
                                <>
                                    {
                                        dataSource !== undefined &&
                                        dataSource?.length > 0 &&
                                        dataSource?.map((el, idx) => {
                                            return (
                                                <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                                    <td className="px-4 py-1  text-[14px] text-[#000] text-opacity-50  font-[500] ">
                                                        {idx + 1 < 10 ? `0${idx+1}` : idx+1 }
                                                    </td>
                                                    <td className="px-4 py-1 whitespace-nowrap text-[14px] text-[#000] text-opacity-50  font-[500] ">
                                                        {el?.number}
                                                    </td>
                                                    {
                                                        dataBaseItem.fields?.map((field, index) => {
                                                            return (
                                                                <td key={index} className="px-4 py-3 capitalize  text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                                                    <div className="min-w-[100px] max-w-[200px]">
                                                                        {el ? el?.dataSource[field?.name] : ''}
                                                                    </div>
                                                                </td>
                                                            );
                                                        })
                                                    }
                                                    <td className="px-4 py-2 whitespace-nowrap">
                                                        <div className="flex justify-end">
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <Menu.Button className="text-sm ">
                                                                    <div className="border px-3 py-2 rounded-[4px] text-[12px] font-poppins font-[500] flex items-center gap-6">
                                                                        <span className="text-[#676D7C] ">Actions</span>
                                                                        <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                    </div>
                                                                </Menu.Button>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute z-20 right-0 w-[140px]  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="px-1 py-1">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setElement(el)
                                                                                            setModalMode('update')
                                                                                            toggleModal()
                                                                                        }}
                                                                                        className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                            } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                                    >
                                                                                        {t("common:update")}
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setElement(el)
                                                                                            setModalMode('delete')
                                                                                            toggleModal()
                                                                                        }}
                                                                                        className={`${active ? 'bg-red-50 text-red-500' : 'text-black'
                                                                                            } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                                    >
                                                                                        {t("common:delete")}
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </>
                            }
                            
                        </tbody>
                    </table>
                </div>
            </div>
            {/* end list data */}

            {/* modal add data */}
            <Modal show={isOpen} toggleModal={toggleModal}>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode === 'add' ? t("dataSource:addnewdata") :
                            modalMode === 'update' ? t("dataSource:updatedata") : 
                            modalMode === 'update_general_info' ? t("dataSource:updategeneralinfo") : 
                            t("common:delete")
                        }
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                {   modalMode === 'add' &&
                    <>
                        <div className="p-6 max-h-[400px] overflow-x-hidden">
                            {
                                item?.fields?.map((field, k) =>(
                                    field?.type === 'select' ?
                                        <div className="mb-[30px]">
                                            <SelectField 
                                                label={field?.label}
                                                type={'number'}
                                                smallLab={true}
                                                required={field.required}
                                                options= {
                                                    field?.values?.map(x => (
                                                        {
                                                            value: x?.value,
                                                            name: x?.label
                                                        }
                                                    ))
                                                }
                                                onChange={(e) =>{
                                                    setFieldsValue({
                                                        ...fieldsValues,
                                                        [field.name]: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    :
                                    field?.type === 'select-data-source' ?
                                        <div className="mb-[30px]">
                                            <SelectField 
                                                label={field?.label}
                                                type={'number'}
                                                smallLab={true}
                                                required={field.required}
                                                options= {
                                                    field?.values?.map(x => (
                                                        getDataSourceOption(x?.id)
                                                    ))
                                                }
                                                onChange={(e) =>{
                                                    setFieldsValue({
                                                        ...fieldsValues,
                                                        [field.name]: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    :
                                    field?.type === 'radio-group' || field?.type === 'checkbox-group' ?
                                    <div className="mb-[30px]">
                                        <span className="block text-[14px] text-black">
                                            {field?.label}
                                        </span>
                                        {
                                            field?.values?.map(x => (
                                                <InputRadio 
                                                    label={x?.label}
                                                    value={x?.value}
                                                    required={field.required}
                                                    name={field?.name}
                                                    onChange={(e) =>{
                                                        setFieldsValue({
                                                            ...fieldsValues,
                                                            [field.name]: e.target.value,
                                                        })
                                                    }}
                                                />
                                            ))
                                        }
                                    </div>
                                    :
                                    field?.type === 'textarea' ?
                                    <div className="mb-[30px]">
                                        <Textarea 
                                            label={field?.label}
                                            smallLab={true}
                                            type={'text'}
                                            required={field.required}
                                            onChange={(e) =>{
                                                setFieldsValue({
                                                    ...fieldsValues,
                                                    [field.name]: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    :
                                    <div className="mb-[30px]">
                                        <Input 
                                            label={field?.label}
                                            type={field?.type}
                                            smallLab={true}
                                            required={field.required}
                                            onChange={(e) =>{
                                                setFieldsValue({
                                                    ...fieldsValues,
                                                    [field.name]: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={loading ? t("common:inprogress") : t("common:save")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={()=> handleSubmitForm()}
                            />
                        </div>
                    </>
                }
                {   modalMode === 'update' &&
                    <>
                        <div className="p-6 max-h-[400px] overflow-x-hidden">
                            {
                                item?.fields?.map((field, k) =>(
                                    field?.type === 'select' ?
                                        <div className="mb-[30px]">
                                            <SelectField 
                                                label={field?.label}
                                                type={'number'}
                                                smallLab={true}
                                                required={field.required}
                                                defaultValue={element?.dataSource[field.name]}
                                                options= {
                                                    field?.values?.map(x => (
                                                        {
                                                            value: x?.value,
                                                            name: x?.label
                                                        }
                                                    ))
                                                }
                                                onChange={(e) =>{
                                                    setFieldsValue({
                                                        ...fieldsValues,
                                                        [field.name]: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    :
                                    field?.type === 'select-data-source' ?
                                        <div className="mb-[30px]">
                                            <SelectField 
                                                label={field?.label}
                                                type={'number'}
                                                smallLab={true}
                                                required={field.required}
                                                defaultValue={element?.dataSource[field.name]}
                                                options= {
                                                    field?.values?.map(x => (
                                                        getDataSourceOption(x?.id)
                                                    ))
                                                }
                                                onChange={(e) =>{
                                                    setFieldsValue({
                                                        ...fieldsValues,
                                                        [field.name]: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    :
                                    field?.type === 'radio-group' || field?.type === 'checkbox-group' ?
                                    <div className="mb-[30px]">
                                        <span className="block text-[14px] text-black">
                                            {field?.label}
                                        </span>
                                        {
                                            field?.values?.map(x => (
                                                <InputRadio 
                                                    label={x?.label}
                                                    defaultValue={element?.dataSource[field.name]}
                                                    required={field.required}
                                                    name={field?.name}
                                                    onChange={(e) =>{
                                                        setFieldsValue({
                                                            ...fieldsValues,
                                                            [field.name]: e.target.value,
                                                        })
                                                    }}
                                                />
                                            ))
                                        }
                                    </div>
                                    :
                                    field?.type === 'textarea' ?
                                    <div className="mb-[30px]">
                                        <Textarea 
                                            label={field?.label}
                                            smallLab={true}
                                            type={'text'}
                                            defaultValue={element?.dataSource[field.name]}
                                            required={field.required}
                                            onChange={(e) =>{
                                                setFieldsValue({
                                                    ...fieldsValues,
                                                    [field.name]: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                    :
                                    <div className="mb-[30px]">
                                        <Input 
                                            label={field?.label}
                                            type={field?.type}
                                            smallLab={true}
                                            defaultValue={element?.dataSource[field.name]}
                                            required={field.required}
                                            onChange={(e) =>{
                                                setFieldsValue({
                                                    ...fieldsValues,
                                                    [field.name]: e.target.value,
                                                })
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={loading ? t("common:inprogress") : t("common:save")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={()=> handleUpdateData()}
                            />
                        </div>
                    </>
                }
                {
                    modalMode === 'delete' &&
                    <div className="max-h-[400px] overflow-x-hidden">
                        <div className="p-6">
                            <h4 className='text-[16px] font-bold mb-4'>
                                {t("dataSource:attentionthisactionisirreversible")}
                            </h4>
                            <p className='text-[14px] text-black text-opacity-70'>
                                {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}
                            </p>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("common:delete")}
                                disabled={isLoading}
                                type='submit'
                                bg={'red'}
                                isSmall={true}
                                onClick={() => {handleDeleteData()}}
                            />
                        </div>
                    </div>
                }
                {
                    modalMode === 'delete_form_data_source' &&
                    <div className="max-h-[400px] overflow-x-hidden">
                        <div className="p-6">
                            <h4 className='text-[16px] font-bold mb-4'>
                                {t("dataSource:attentionthisactionisirreversible")}
                            </h4>
                            <p className='text-[14px] text-black text-opacity-70'>
                                {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}
                            </p>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("common:delete")}
                                disabled={isLoading}
                                type='submit'
                                bg={'red'}
                                isSmall={true}
                                onClick={() => {handleDeleteFormDataSources()}}
                            />
                        </div>
                    </div>
                }
                {
                    modalMode === 'update_general_info' &&
                    <div className="max-h-[400px] overflow-x-hidden">
                        <div className="p-6">
                            <div className="mb-[30px]">
                                <Input 
                                    label={t("dataSource:datasourcename")}
                                    smallLab={true}
                                    placeholder={"ex: Database source name"}
                                    type={'text'}
                                    value={formBuild.name}
                                    onChange={(e) =>{
                                        setFormBuild({
                                            ...formBuild,
                                            name: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className="mb-[30px] mt-[10px]">
                                <Textarea 
                                    label={'Description'}
                                    smallLab={true}
                                    placeholder={"ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                                    type={'text'}
                                    value={formBuild.description}
                                    onChange={(e) =>{
                                        setFormBuild({
                                            ...formBuild,
                                            description: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                               title={loading ? t("common:inprogress") : t("common:save")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={()=> handleUpdateInfoGeneral()}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </>
    )
}