import { userConstants } from '../constants'

const initialState = null

export default function (user = initialState, action) {
  const { type, payload } = action
  if (type === userConstants.RETRIEVE_USER) {
    return payload
  } else if(type === userConstants.UPDATE_USERS){
    return payload
  }
  else {
    return user
  }
}
