import { Transition } from '@headlessui/react';
import NotificationSnackbar from './NotificationSnackbar';
// import { useToaster } from '@contexts/toaster';
import { useToaster } from '../../contexts/toaster';

export default function Notifications() {
  const toaster = useToaster();

  return (
    <div className="fixed top-0 z-[1203] flex flex-col space-y-1 -translate-x-1/2 left-1/2">
      {toaster.notifications.map((notification) => (
        <Transition
          key={notification.id}
          appear
          show
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <NotificationSnackbar notification={notification} />
        </Transition>
      ))}
    </div>
  );
}