import { FiSearch } from 'react-icons/fi'

export default function InputSearch({...props}){

    return(
        <div className="rounded-[6px] h-[50px] px-2 overflow-hidden bg-white flex items-center gap-2">
            <FiSearch className='text-[20px]' />
            <input
                type='search'
                {...props}
                className="block h-full w-full focus:outline-none text-[14px] text-black placeholder:text-black placeholder:text-opacity-50"
            />
        </div>
    )
}