import { useState, Fragment, useEffect, useRef } from "react";
import { Tag, ButtonWithBg, Heading, Paragraph } from "../../../components/Kits";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { Modal } from "../../../components/Modals";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight} from 'react-icons/bs'
import { useSelector, useDispatch } from "react-redux";
import Table from "./Table";
import http from "../../../http-common";
import { objectToQueryString } from "../../../utils/table-manager";
import { BiCurrentLocation, BiFilterAlt, BiRefresh} from 'react-icons/bi'
import { retirerId } from "../../../utils/retireId";
import { formatNameSurvey } from "../../../utils/formatNameSurvey";
import { FaCheck } from "react-icons/fa";
import Filter from "../../../layout/filter/Filter";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function DataMappOption({
    setIsLoadingData, 
    setIsDataLoadMore, 
    isDataLoadMore, 
    setTotalData,
    setDataCount,
    setProject, 
    setDrawerType, 
    setDataMapps, 
    setElementSelected,
    setPaginationExport
}){

    const [selectedProject, setSelectedProject] = useState(null)
    const [selectedFieldMapp, setSelectedFieldMapp] = useState(null)
    const [selectedEntrie, setSelectedEntrie] = useState()
    let [isOpen, setIsOpen] = useState(false)
    const [entries, setEntrises] = useState([])
    const [viewModuleMapping, setViewModuleMapping] = useState(false)

    const [projectsApplications, setProjectApplication] = useState([])
    const [surveyData, setSurveyData] = useState([])
    const [dataMappingMod, setDataMappingMod] = useState([])
    const [loadingProject, setLoadingProject] = useState(false)
    const [loadingSurveys, setLoadingSurveys] = useState(false)
    const [loadingDataMapping, setLoadingDataMapping] = useState(false)
    // const [breakRequest, setBreakRequest] = useState(false)
    const breakRequestRef = useRef(false);
    const abortControllerRef = useRef(null);

    const [filterValues, setFilterValues] = useState({
        agentIds: null,
        statusIds: null,
        numbers: '',
        createdAt: ''
    })

    const resetFilter = () => {
        setFilterValues({
            agentIds: null,
            statusIds: null,
            numbers: '',
            createdAt: ''
        })
    }

    const DATA_SIZE = 50


    useEffect(()=>{
        setSelectedProject(projectsApplications[0])
    }, [projectsApplications])

    useEffect(() => {
        setProject(selectedProject)
    }, [selectedProject])

    const refreshProjects = async () => {
        try {
          const qs = objectToQueryString({
              page : 1,
              limit : 10000,
          });
    
          if(loadingProject === false){
            setLoadingProject(true)
            await http.get(`/projects${qs}`).then(function(response){
              console.log("response 0000", response)
              if(response.status === 200){
                const tmp = response.data.data.filter(el => el?.form?.useMapping === true)
                // console.log('project tmp', tmp)
                setProjectApplication(tmp);
                setLoadingProject(false)
              }
            })
          }                                 
        } catch (err) {
            console.log(err)
        }
    };

    const refresh = () => {
        refreshProjects();
    }

    useEffect(() => {
        refresh();
    }, [])

    const loadMoreSurvey = async (totalPage) => {
        setIsDataLoadMore(true);
    
        for (let page = 2; page <= totalPage; page++) {
          if (breakRequestRef.current) {
            break; // Arrête la boucle si breakRequestRef est activé
          }
    
          const qs = objectToQueryString({
            page,
            limit: DATA_SIZE,
            projectId: selectedProject?.id,
            statusIds: filterValues?.statusIds?.map(x => x?.value),
            agentIds: filterValues?.agentIds?.map(x => x?.value),
            numbers: filterValues?.numbers,
            createdAt: filterValues?.createdAt ?? null,
          });
    
          try {
            const response = await http.get(`/find-surveydatas-by-project${qs}`, {
              signal: abortControllerRef.current?.signal, // Passe le signal d'annulation ici aussi
            });
    
            if (response.status === 200) {
              setSurveyData((prevData) => [...prevData, ...response.data.data]);
              setDataCount((prev) => prev + DATA_SIZE);
            }
          } catch (error) {
            if (error.name === 'CanceledError') {
              console.log(`Requête annulée dans loadMoreSurvey page ${page}`);
            } else {
              console.error(`Erreur dans loadMoreSurvey page ${page}`, error);
            }
            break;
          }
        }
    
        setIsDataLoadMore(false);
      };

    const getSurveyData = async () => {
        // 1. Annule la requête en cours si elle existe
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
    
        // 2. Crée un nouvel AbortController
        const controller = new AbortController();
        abortControllerRef.current = controller;
    
        // 3. Réinitialiser l'état pour charger les nouvelles données
        breakRequestRef.current = true;
        setDataCount(0);
        setSurveyData([]);
        setLoadingSurveys(true);
        setIsLoadingData(true);
    
        const qs = objectToQueryString({
          page: 1,
          limit: DATA_SIZE,
          projectId: selectedProject?.id,
          statusIds: filterValues?.statusIds?.map(x => x?.value),
          agentIds: filterValues?.agentIds?.map(x => x?.value),
          numbers: filterValues?.numbers,
          createdAt: filterValues?.createdAt ?? null,
        });
    
        try {
          const response = await http.get(`/find-surveydatas-by-project${qs}`, {
            signal: controller.signal, // Passe le signal d'annulation ici
          });
    
          if (response.status === 200) {
            breakRequestRef.current = false;
            setSurveyData(response.data.data);
            setTotalData(response.data?.totalCount);
            setDataCount((prev) => prev + DATA_SIZE);
            setPaginationExport(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
    
            if (response.data?.totalPage > 1) {
              loadMoreSurvey(response.data?.totalPage); // Charger plus de données
            }
          }
        } catch (error) {
          if (error.name === 'CanceledError') {
            console.log('Requête annulée dans getSurveyData');
          } else {
            console.error('Erreur dans getSurveyData', error);
          }
        } finally {
          setLoadingSurveys(false);
          setIsLoadingData(false);
        }
      };

    useEffect(() => {
        if(selectedProject) {
            getSurveyData()
        }
    }, [selectedProject])

    console.log('surveyData load', surveyData)


    const refreshDataMapping = async () => {
        try {
          const qs = objectToQueryString({
              page : 1,
              limit : 10000,
          });
    
          if(loadingDataMapping === false){
            setLoadingDataMapping(true)
            await http.get(`/datas-map${qs}`).then(function(response){
              if(response.status === 200){
                setDataMappingMod(response.data.data);
                setLoadingDataMapping(false)
              }
            })
          }                                 
        } catch (err) {
            console.log(err)
        }
    };


    const refreshData = () => {
        refreshDataMapping();
    }

    useEffect(() => {
        refreshData();
    }, [])

    function toCamelCase(str) {
        return str?.toLowerCase()?.replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr?.toUpperCase());
    }

    useEffect(() => {
        setSelectedFieldMapp(null)
        setSelectedEntrie(null)
    }, [selectedProject, viewModuleMapping]);

    

    useEffect(()=>{
        setDataMapps([])
        setSelectedEntrie(null)
        console.log("srv ttt", surveyData)
        if(selectedFieldMapp && selectedFieldMapp?.name !== 'userLocation' && viewModuleMapping === false){
            setEntrises([
                {
                    id: 0,
                    label: 'All',
                    data: surveyData?.map((item) =>(
                            {
                                ...item?.datasMap?.mapps?.find((map) => map?.name === selectedFieldMapp?.name),
                                surveyInfos: item
                            }
                        )
                      )
                },
                ...surveyData?.map((srv, idx) =>{
                    let formatLabel

                    if(
                        selectedProject?.configuration?.externalProject?.fieldName && 
                        retirerId(srv?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value)
                    ){
                        if(srv?.survey?.externalProjectData){
                            let keys = selectedProject?.configuration?.externalProject?.surveyDatasKeysUsed
                            let arrayData = srv?.survey?.externalProjectData
                            formatLabel = keys?.map(k => arrayData[k]?.value)?.join(" ")
                        } else {
                            formatLabel = retirerId(srv?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value)
                        }
                    } else  {
                        formatLabel = selectedProject?.form?.fields?.map(el => (
                            retirerId(srv.survey[el?.name]?.value)
                        ))
                    }

                    return ({
                        id: idx + 1,
                        label: formatLabel,
                        data: srv?.datasMap?.mapps?.filter((map) => map?.name === selectedFieldMapp?.name)?.map((map) => (
                            {...map, surveyInfos: srv}
                        )),
                            
                    })
                })

                // ...surveyData?.map((srv, idx) =>(
                //     {
                //         id: idx + 1,
                //         label: (selectedProject?.configuration?.externalProject?.fieldName && retirerId(srv?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value)) ? 
                //             retirerId(srv?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value)
                //             : selectedProject?.form?.fields?.map(el => (
                //                 retirerId(srv.survey[el?.name]?.value)
                //             )),
                //         data: srv?.datasMap?.mapps?.filter((map) => map?.name === selectedFieldMapp?.name)?.map((map) => (
                //             {...map, surveyInfos: srv}
                //         )),
                            
                //     }
                // ))
            ])
        } else if(selectedFieldMapp && selectedFieldMapp?.name === 'userLocation' && viewModuleMapping === false){
            // console.log('userLocate')
            setEntrises([
                {
                    id: 0,
                    label: 'All',
                    data: surveyData?.map((item) =>(
                       {
                        type: 'userLocation',
                        name: 'User Location',
                        coordinates: item?.userLocation,
                        surveyInfos: item
                       }
                    ))
                },
                ...surveyData?.map((srv, idx) =>(
                    {
                        id: idx + 1,
                        label: srv?.user?.firstName + " " + srv?.user?.lastName,
                        // label: 'Entrie '+ parseFloat(idx+ 1),
                        // label: selectedProject?.form?.fields?.map(el => (
                        //     retirerId(srv.survey[el?.name]?.value) !== 'undefined' ? retirerId(srv.survey[el?.name]?.value)+" " : ''
                        // )),
                        data: [
                            {
                                type: 'userLocation',
                                name: 'User Location',
                                coordinates: srv?.userLocation,
                                surveyInfos: srv
                            }
                        ]
                    }
                ))
            ])
        } else if(viewModuleMapping){
            setEntrises([
                {
                    id: 0,
                    label: 'All',
                    data: dataMappingMod?.map((item) =>(
                       {
                            type: toCamelCase(item?.mappingType),
                            name: item?.name,
                            coordinates: item?.mappings,
                            mappingCenter: item?.mappingCenter,
                            surveyInfos: item
                       }
                    ))
                },
                ...dataMappingMod?.map((item, idx) =>(
                    {
                        id: idx + 1,
                        label: item?.name,
                        data: [
                            {
                                type: toCamelCase(item?.mappingType),
                                name: item?.name,
                                coordinates: item?.mappings,
                                mappingCenter: item?.mappingCenter,
                                surveyInfos: item
                            }
                        ]
                    }
                ))
            ])
        }
    }, [selectedFieldMapp, surveyData, viewModuleMapping])

    useEffect(()=>{
        setSelectedEntrie(entries[0])
    }, [entries])

    const [newData, setNewData] = useState([])
    const [selectSurvey, setSelectSurvey] = useState(false)
    const handleSelectSurvey = () => setSelectSurvey(!selectSurvey)
    // console.log('newData', newData)

    useEffect(() =>{
        if(selectedEntrie && selectedEntrie?.id === 0){
            let aaa = selectedEntrie?.data?.map(el => ({
                ...el,
                id: selectedEntrie?.id
            }))
            setNewData(aaa)
        } else {
            let tmp = newData?.filter(el => el?.id !== null && el?.id !== undefined && el?.id !== 0)
            let newAdd = []
            let foundItem = newData?.find(el => el?.id === selectedEntrie?.id)
            if(foundItem && tmp?.length > 1){
                tmp = tmp?.filter(el => el?.id !== selectedEntrie?.id)
            }
            else if(foundItem && tmp?.length == 1){
                tmp = tmp
            }
             else{
                newAdd = selectedEntrie ? selectedEntrie?.data?.map(el => ({
                    ...el,
                    id: selectedEntrie?.id
                })) : []
            }
            
            let aaa = [...tmp, ...newAdd]
            setNewData(aaa)
        }

    }, [selectedEntrie, selectSurvey])

    useEffect(() => {
        setElementSelected(selectedEntrie?.id)

        if(selectedEntrie  && selectedEntrie?.data?.[0]?.type === "continuous"){
            setDataMapps(newData)
            setDrawerType("Polygon")
        } else if(selectedEntrie && selectedEntrie?.data?.[0]?.type === "polygone"){
            setDataMapps(newData)
            setDrawerType("Polygon")
        } else if(selectedEntrie && selectedEntrie?.data?.[0]?.type === "onePosition"){
            setDataMapps(newData)
            setDrawerType("Marker")
        }else if(selectedEntrie && selectedFieldMapp && selectedFieldMapp?.name === 'userLocation'){
            setDataMapps(newData)
            setDrawerType("userLocation")
        } else {
            setDrawerType(null)
        }
    }, [newData])




    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    // console.log('selectedProject', selectedProject)
    // console.log('selectedEntrie====>', selectedEntrie)
    console.log('entries====>', entries)

    return(
        <div className="flex items-center gap-4 absolute top-[20px] left-[60px] z-40">
            <div className=" gap-2">
                <span className="text-[14px] text-black text-opacity-50 hidden">Project:</span>
                <Listbox value={selectedProject} onChange={setSelectedProject}>
                    <div className="relative">
                        <Listbox.Button className="inline-flex h-[40px] w-[300px] justify-between items-center px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 shadow-[0_1px_2px_rgba(60,64,67,0.3)] border">
                            <span className="block text-[14px] whitespace-nowrap text-[#000] mr-8 font-medium overflow-hidden">{selectedProject?.name}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute left-0 w-full scrollbar-v overflow-x-hidden mt-2 z-50 bg-white rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="h-[300px] scrollbar-v overflow-x-hidden">
                                    {projectsApplications?.map((item, itemIdx) => (
                                        <Listbox.Option
                                            key={itemIdx}
                                            onClick={() => {
                                                setViewModuleMapping(false)
                                            }}
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                    active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                } ${selectedProject?.id == item?.id ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'}`
                                            }
                                            value={item}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block text-[14px] whitespace-nowrap truncate  ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {item?.name}
                                                    </span>
                                                    <span className='text-[12px] text-black text-opacity-50'>
                                                        {/* {"status: "} */}
                                                        {/* <span className='text-[12px] text-primary-blue-500 text-opacity-100 font-bold'>{item?.status?.name}</span> */}
                                                        <span className='text-[12px] text-primary-blue-500 text-opacity-100 font-bold'>
                                                            {
                                                                `${item?.status?.name} 
                                                                - ${item?.countSurveys} 
                                                            `} 
                                                            <span className="text-black/50">saisie(e)</span>
                                                        </span>
                                                    </span>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                                <Listbox.Option
                                    onClick={() => {
                                        setViewModuleMapping(true)
                                    }}
                                    className={({ active }) =>
                                        `cursor-pointer select-none relative py-4 pl-4 pr-4 ${
                                            active ? 'bg-primary-blue-500' : 'text-gray-900'
                                        } ${selectedProject?.id == "moduleMapping" ? 'bg-primary-blue-500 !text-white' : 'text-gray-900 bg-gray-50'}`
                                    }
                                    value={{
                                        id: "moduleMapping",
                                        name: "Module Mapping"
                                    }}
                                >
                                    {({ selected }) => (
                                        <div className='flex items-center gap-[4px]'>
                                            <BiCurrentLocation className='text-[25px]' />
                                            <span
                                                className={`block text-[14px] whitespace-nowrap truncate  ${
                                                    selected ? 'font-normal' : 'font-normal'
                                                }`}
                                            >
                                                {"Module mapping"}
                                            </span>
                                        </div>
                                    )}
                                </Listbox.Option>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
            <div className={`gap-2 ${viewModuleMapping ? 'hidden' : 'block'}`}>
                <span className="text-[14px] text-black hidden">Project:</span>
                <Listbox value={selectedFieldMapp} onChange={setSelectedFieldMapp}>
                    <div className="relative">
                        <Listbox.Button className="inline-flex h-[40px] w-[300px] justify-between items-center px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 shadow-[0_1px_2px_rgba(60,64,67,0.3)] border">
                            <span className="block text-[14px] whitespace-nowrap text-[#000] mr-8 font-medium overflow-hidden">{selectedFieldMapp?.label}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            
                            <Listbox.Options className="absolute left-0 w-full h-[300px] scrollbar-v overflow-x-hidden mt-2 z-50 bg-white rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {   selectedProject?.activeLocationExecution &&
                                    <Listbox.Option
                                        className={({ active }) =>
                                            `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                            } ${selectedFieldMapp?.name == 'userLocation' ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'}`
                                        }
                                        value={{
                                            name: 'userLocation',
                                            label: 'User location'
                                        }}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`block text-[14px] whitespace-nowrap truncate  ${
                                                        selected ? 'font-normal' : 'font-normal'
                                                    }`}
                                                >
                                                    {"User Location"}
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                }
                                {selectedProject?.form?.mapps?.map((item, itemIdx) => (
                                    <Listbox.Option
                                        key={itemIdx}
                                        className={({ active }) =>
                                            `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                            } ${selectedFieldMapp?.name === item?.name ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'}`
                                        }
                                        value={item}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`block text-[14px] whitespace-nowrap truncate  ${
                                                        selected ? 'font-normal' : 'font-normal'
                                                    }`}
                                                >
                                                    {item?.label}
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
            <div className=" gap-2">
                <span className="text-[14px] text-black text-opacity-50 hidden">Project:</span>
                <Listbox value={selectedEntrie} onChange={setSelectedEntrie}>
                    <div className="relative">
                        <Listbox.Button className="inline-flex h-[40px] w-[300px] justify-between items-center px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 shadow-[0_1px_2px_rgba(60,64,67,0.3)] border">
                            <span className="block text-[14px] whitespace-nowrap text-[#000] mr-8 font-medium overflow-hidden">{(selectedEntrie && selectedEntrie?.id !== 0) ? newData?.length +" survey selected" : selectedEntrie?.label }</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute space-y-1 left-0 w-full h-[300px] scrollbar-v overflow-x-hidden mt-2 z-50 bg-white rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {entries?.map((item, itemIdx) => (
                                    <Listbox.Option
                                        key={itemIdx}
                                        className={({ active }) =>
                                            `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                            } ${newData?.find(el => el?.id === item?.id) ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'}`
                                        }
                                        value={item}
                                        onClick={() => handleSelectSurvey()}
                                    >
                                        {({ selected }) => (
                                            <div className="flex items-center justify-between">
                                                <span
                                                    className={`block text-[12px] whitespace-nowrap truncate  ${
                                                        selected ? 'font-normal' : 'font-normal'
                                                    }`}
                                                >
                                                    {item?.label}
                                                </span>
                                                {
                                                    newData?.find(el => el?.id === item?.id) && <FaCheck className="text-[12px] text-myGreen" />
                                                }
                                            </div>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
            {
                (dataMappingMod || selectedProject) && (
                    <>
                        <div 
                            onClick={() => {toggleModal()}}
                            className="h-[40px] w-[40px] bg-white shadow-[0_1px_2px_rgba(60,64,67,0.3)] border transition-all ease-out duration-200  cursor-pointer rounded-full flex items-center justify-center"
                        >
                            <BiFilterAlt className='text-[20px]' />
                        </div>
                        <div 
                            onClick={() => {
                                if(viewModuleMapping) {
                                    setDataCount(0)
                                    refreshDataMapping()
                                } else {
                                    setDataCount(0)
                                    getSurveyData()
                                }
                            }}
                            className="h-[40px] w-[40px] bg-white shadow-[0_1px_2px_rgba(60,64,67,0.3)] border hover:bg-primary-blue-500 hover:bg-opacity-20 hover:text-primary-blue-500 transition-all ease-out duration-200  cursor-pointer rounded-full flex items-center justify-center"
                        >
                            <BiRefresh className='text-[24px]' />
                        </div>
                    </>
                )
            }

            <Modal show={isOpen} toggleModal={() =>{toggleModal()}}>
                <Filter
                    toggleModal={toggleModal} 
                    project={selectedProject}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    getSurveyData={getSurveyData}
                />
            </Modal>
        </div>
    )
}
