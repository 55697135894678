import http from '../http-common'

const getAll = () => {
  return http.get('/forms?page=1&limit=1000')
}

const get = (id) => {
  return http.get(`/forms/${id}`)
}

const create = (data) => {
  return http.post('/forms', data)
}

const update = (id, data) => {
  return http.put(`/forms/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/forms/${id}`)
}

const removeAll = () => {
  return http.delete('/forms')
}

const findByTitlte = (title) => {
  return http.get(`/forms?title=${title}`)
}

export const formService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitlte,
}
