import jwtDecode from "jwt-decode";
import http from "../http-common";

const logout = () => {
  delete http.defaults.headers["Authorization"];
  window.localStorage.clear();
  window.location.pathname = "/";
};

const isAuthenticated = (token) => {
  if (token) {
    const { exp: expireDate } = jwtDecode(token);
    if (expireDate * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
};

const setAxiosToken = (token) => {
  if (token) {
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
};

export const authLogic = {
  setAxiosToken,
  logout,
  isAuthenticated,
};
