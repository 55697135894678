import { Header, Partners, Features, Testimonial, Stats, StartForFree, Footer } from "../../section/home";

export default function Home (){

    console.log('console.log = function() {};')

    return (
        <div>
            <Header />
            <Partners />
            <Features />
            <Testimonial />
            <Stats />
            <StartForFree />
            <Footer />
        </div>
    )
}