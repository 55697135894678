import { Disclosure } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag, SelectField } from '../../../../../components/Kits'
import {BsChevronRight} from 'react-icons/bs'
import { useState, useEffect } from 'react'
import { projectActions } from '../../../../../redux/actions'
import { useDispatch, useSelector } from "react-redux";
import { useToaster } from '../../../../../contexts/toaster'
import { useLocation, useNavigate } from "react-router";
import http from '../../../../../http-common'

export default function ConfigProject({item, t, project, projectId, accessControl}){
   
    const [isLoading, setIsLoading] = useState(false)
    const toaster = useToaster()
    const projectTypes = useSelector((state) => state.projectTypes);
    const projects = useSelector((state) => state.projects);

    const user = useSelector((state) => state.user);
    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    const [useExternalProject, setUseExternalProject] = useState(
        project?.configuration?.useExternalProject ? 
            project?.configuration?.useExternalProject 
            : 
            false
        )
      const [configuration, setConfiguration] = useState({
          fieldName: project?.configuration?.externalProject?.fieldName ? project?.configuration?.externalProject?.fieldName : '',
          id: project?.configuration?.externalProject?.id ? project?.configuration?.externalProject?.id : '',
          surveyDatasKeysUsed: project?.configuration?.externalProject?.surveyDatasKeysUsed ? project?.configuration?.externalProject?.surveyDatasKeysUsed : []
      })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )

        setUseExternalProject(project?.configuration?.useExternalProject ? 
            project?.configuration?.useExternalProject 
            : 
            false
        )

        setConfiguration({
            fieldName: project?.configuration?.externalProject?.fieldName ? project?.configuration?.externalProject?.fieldName : '',
            id: project?.configuration?.externalProject?.id ? project?.configuration?.externalProject?.id : '',
            surveyDatasKeysUsed: project?.configuration?.externalProject?.surveyDatasKeysUsed ? project?.configuration?.externalProject?.surveyDatasKeysUsed : []
        })
    },[project])
   

    const isBindProjectOk = () => {
        if(useExternalProject){
            if(configuration.fieldName && configuration.id && configuration.surveyDatasKeysUsed.length > 0){
                return true
            } else {
                return false
            }
        }else{
            return true
        }
    }

    const handleSubmitProject = async (e) => {
        e.preventDefault();

        let dataToSend 
        if(useExternalProject){
            dataToSend = {
                ...itemData,
                configuration: {
                    useExternalProject: true,
                    externalProject: {
                        ...configuration,
                        fieldName: configuration?.fieldName[0]?.toLowerCase() + configuration?.fieldName?.slice(1)
                    }
                }
            }
        } else {
            dataToSend = {
                ...itemData
            }
        }
        if(isBindProjectOk()){
            if(isLoading===false){
                setIsLoading(true)
                await http.put(`/projects/${projectId}/`, dataToSend)
                .then(function (response) {
                    if(response.status === 200){
                        setIsLoading(false)
                        toaster.show({
                            title: 'Success !',
                            message: `Your project has modified successfully`,
                            greedy: true,
                            type: 'success'
                        });
                        window.location.reload()
                    }
                })
                .catch(function (error) {
                    setIsLoading(false)
                    toaster.show({
                        title: 'Oups !',
                        message: error,
                        greedy: true,
                        type: 'error'
                    });
                });
            }
        } else{
            toaster.show({
                title: 'Error !',
                message: `Veuillez vérifiez que tous les champs ont été bien rempli`,
                greedy: true,
                type: 'error'
            });
            setIsLoading(false)
        }
    };


    const handleChooseSurveyDatasKeysUsed = (name) => {
        // Vérifier si l'élément est déjà présent dans le tableau
        const isAlreadySelected = configuration.surveyDatasKeysUsed.includes(name);
    
        // Mettre à jour le tableau en conséquence
        if (isAlreadySelected) {
        const updatedKeysUsed = configuration.surveyDatasKeysUsed.filter(
            (key) => key !== name
        );
        setConfiguration({
            ...configuration,
            surveyDatasKeysUsed: updatedKeysUsed,
        });
        } else {
        setConfiguration({
            ...configuration,
            surveyDatasKeysUsed: [...configuration.surveyDatasKeysUsed, name],
        });
        }
    };



    return(
        <Disclosure className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
            {({ open }) => (
                <div className={`rounded-lg overflow-hidden } `}>
                    <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                        <div className={`flex items-center justify-between gap-3 w-full`}>
                            <span className={`
                                text-[15px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                            }`}>
                                {t("project:bindproject")}
                            </span>
                            <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                        <form onSubmit={(e) => handleSubmitProject(e)} className={`col-span-1`}>
                            <div className="mb-[30px]">
                                <span className="block text-[14px]">
                                    {t("project:wouldyouliketouseanexternalproject")}
                                </span>
                                <div className="flex items-center gap-[40px]">
                                    <InputRadio 
                                        label={t('common:no')}
                                        name="useExternalProject"
                                        smallLab={true}
                                        checked={useExternalProject === false ? true : false }
                                        onChange={() => {
                                            setUseExternalProject(false)
                                            setConfiguration((prevState) => ({
                                                ...prevState,
                                                fieldName: '',
                                                id: ''
                                            }))
                                        }}
                                        disabled={(!accessControl?.maintainer && !accessControl?.owner) || ( project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring')}
                                    />
                                    <InputRadio 
                                        label={t('common:yes')}
                                        name="useExternalProject"
                                        smallLab={true}
                                        checked={useExternalProject === true ? true : false }
                                        onChange={() => setUseExternalProject(true)}
                                        disabled={(!accessControl?.maintainer && !accessControl?.owner) || ( project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring')}
                                    />
                                </div>
                                {
                                    useExternalProject && 
                                    <div className="pt-[50px] flex gap-[30px]">
                                        <div className="mb-[30px] flex-1">
                                            <Input 
                                                label={t("project:fieldname")}
                                                placeholder={"ex: project name"}
                                                type={'text'}
                                                smallLab={true}
                                                value={configuration.fieldName}
                                                onChange={(e) =>{
                                                    const value = e.target.value;

                                                    // Regex pour vérifier les caractères spéciaux et les chiffres
                                                    const invalidFirstCharRegex = /^[!@#\$%\^\&*\)\(+=._\-0-9]/;

                                                    // Regex pour vérifier les lettres et les chiffres seulement
                                                    const validCharRegex = /^[a-zA-Z0-9]*$/;

                                                    // Si le premier caractère est un caractère spécial ou un chiffre, ne pas mettre à jour l'état
                                                    if (value.length === 1 && invalidFirstCharRegex.test(value)) {
                                                        return;
                                                    }

                                                    if (!validCharRegex.test(value)) {
                                                        return;
                                                    }

                                                    setConfiguration((prevState) => ({
                                                        ...prevState,
                                                        fieldName: e.target.value,
                                                    }))
                                                    e.preventDefault()
                                                }}
                                                disabled={(!accessControl?.maintainer && !accessControl?.owner) || ( project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring')}
                                            />
                                        </div>
                                        <div className="mb-[30px] flex-1">
                                            <SelectField 
                                                label={t('project:project')}
                                                smallLab={true}
                                                options= {[
                                                    {
                                                        value: '',
                                                        name: '--------------'
                                                    },
                                                    ...projects?.filter(d => d?.id !== projectId)?.map(x => (
                                                        {
                                                            value: x?.id,
                                                            name: x?.name
                                                        }
                                                    ))
                                                ]}
                                                value={configuration.id}
                                                onChange={(e) =>{
                                                    setConfiguration((prevState) => ({
                                                        ...prevState,
                                                        id: e.target.value,
                                                    }))
                                                }}
                                                disabled={(!accessControl?.maintainer && !accessControl?.owner) || ( project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring')}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    configuration.id && <>
                                        <span className="block text-[14px] mb-[30px]">
                                            {`${t("project:selectthefieldyouwishtohighlight")}:`}
                                        </span>
                                        {
                                            projects.find(x => x?.id === configuration?.id)?.form ? (
                                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                                    {
                                                        projects.find(x => x?.id === configuration?.id)?.form?.fields?.map((el, index) => (
                                                            <div 
                                                                key={index} 
                                                                className={`border rounded-lg flex items-center gap-2 px-4 py-3 cursor-pointer hover:bg-secondary-green-500/10 hover:border-secondary-green-500 transition-all ease-linear duration-200
                                                                ${configuration.surveyDatasKeysUsed.includes(el?.name) ? 'bg-secondary-green-500/10 border-secondary-green-500' : 'border-gray-100' }`}
                                                                onClick={()=> {
                                                                    ((accessControl?.maintainer || accessControl?.owner) && (project?.status?.name === 'Created' && project?.status?.name === 'Configuring')) ? handleChooseSurveyDatasKeysUsed(el?.name) : console.log('noooon')
                                                                }}
                                                            >
                                                                <div className={`h-[20px] w-[20px] border rounded shrink-0 flex items-center justify-center
                                                                    ${configuration.surveyDatasKeysUsed.includes(el?.name) ? 'bg-secondary-green-500/10 border-secondary-green-500' : 'border-gray-300'}`}
                                                                >
                                                                    { configuration.surveyDatasKeysUsed.includes(el?.name) && <div className="h-[15px] w-[15px] rounded bg-secondary-green-500"></div>}
                                                                </div>
                                                                <div>
                                                                    <h4 className="text-[14px]">{el?.label}</h4>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                <div className="px-4 py-8 border border-dashed rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4 justify-center">
                                                    <Paragraph 
                                                        content={"No form is associated with this project. Please select another or add a form to the project. "}
                                                    />
                                                </div>
                                            )
                                        }
                                    </>
                                }
                            </div>
                            {
                                ((accessControl?.maintainer || accessControl?.owner) && (project?.status?.name === 'Created' && project?.status?.name === 'Configuring')) && (
                                    <div className='flex justify-end mb-[10px]'>
                                        <ButtonWithBg 
                                            title={isLoading ? t("common:inprogress") : t('common:update')}
                                            type='submit'
                                            bg={'primary'}
                                            isSmall={true}
                                        />
                                    </div>
                                )
                            }
                            
                        </form>
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}