import { toast } from "react-toastify";
import authService from "../../services/user.service";
import { appSettingService } from "../../services";
import { authLogic } from "../../utils";
import { uiConstants, userConstants, appSettingConstants } from "../constants";



const login = (credentials, navigate, toaster, setErrorText) => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    authService
      .signin(credentials)
      .then((response) => {
        console.log(response);
        toaster.show({
          title: 'Welcome !',
          message: `Connection successful`,
          greedy: true,
          type: 'success'
        });
        const { idToken } = response.data.data;
        authLogic.setAxiosToken(idToken);
        dispatch(actionAuthUser(idToken));
        dispatch(userActions.getUser());
        dispatch(userActions.getUserStudyArea());
        dispatch(userActions.getUserLang());
        dispatch({ type: uiConstants.CLEAR_ERRORS });
        navigate("/adm/survey-console", { replace: true });
        // toast.success(response.data.message);
      })

      .catch((response) => {
        // toast.error(response.data.message);
        dispatch({ type: uiConstants.CLEAR_ERRORS });
        setErrorText(response.response.data.message)
        // toaster.show({
        //   title: 'Oups Error !',
        //   message: `${response.response.data.message}`,
        //   greedy: true,
        //   type: 'error'
        // });
      });
  };
};

const register = (newUser, navigate, toaster) => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    authService
      .signup(newUser)
      .then((response) => {
        console.log(response);
        const { idToken } = response.data.data;
        authLogic.setAxiosToken(idToken);
        dispatch(actionAuthUser(idToken));
        return dispatch(userActions.getUser());
      })
      .then(() => {
        dispatch({ type: uiConstants.CLEAR_ERRORS });
        navigate("/verify-account", { replace: true });
      })
      .catch((response) => {
        console.log(response);
        dispatch({ type: uiConstants.CLEAR_ERRORS });
        // toast.error(response.data.message);
        toaster.show({
            title: 'Oups Error !',
            message: `${response.response.data.message}`,
            greedy: true,
            type: 'error'
        });
      });
  };
};

const getUser = () => {
  return async (dispatch) => {
    dispatch({ type: uiConstants.LOADING_UI });
    await authService
      .user()
      .then((response) => {
        dispatch({
          type: userConstants.RETRIEVE_USER,
          payload: response.data.data,
        });
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      })
      .catch((response) => {
        toast.error(response.message);
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  };
};

const getUserStudyArea = () => {
  return async (dispatch) => {
    dispatch({ type: uiConstants.LOADING_UI });
    await appSettingService
      .getUserStudyArea()
      .then((response) => {
        dispatch({
          type: appSettingConstants.RETRIEVE_USER_STUDY_AREA,
          payload: response.data.data,
        });
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      })
      .catch((response) => {
        toast.error(response.message);
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  };
};

const getUserLang = () => {
  return async (dispatch) => {
    dispatch({ type: uiConstants.LOADING_UI });
    await appSettingService
      .getUserLang()
      .then((response) => {
        dispatch({
          type: appSettingConstants.RETRIEVE_USER_LANG,
          payload: response.data.data,
        });
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      })
      .catch((response) => {
        toast.error(response.message);
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  };
};

const completeRegistration = (data, navigate) => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  authService
    .completeRegistration(data)
    .then((response) => {
      if (response.data.data.registrationIsCompleted === true) {
        navigate("/adm/survey-console");
      }
      dispatch({
        type: userConstants.RETRIEVE_USER,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      toast.error(response.message);
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const AccountVerified = (navigate) => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  authService
    .confirmAccount()
    .then((response) => {
      dispatch({
        type: userConstants.RETRIEVE_USER,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      navigate("/complete-registration", { replace: true });
    })
    .catch((response) => {
      toast.error(response.data.message);
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    });
};

const create = (data) => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  authService
    .createProjectUser(data)
    .then((response) => {
      dispatch({
        type: userConstants.CREATE_USERS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      toast.error(response.message);
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const users = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  authService
    .getProjectUsers()
    .then((response) => {
      dispatch({
        type: userConstants.RETRIEVE_USERS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.LOADING_UI });
    })
    .catch((response) => {
      toast.error(response.message);
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const plateFormUsers = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  authService
    .getUsers()
    .then((response) => {
      dispatch({
        type: userConstants.RETRIEVE_USERS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      toast.error(response.message);
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const searchUser = (email, setResultSearch) => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });

  authService
    .searchUserByEmail(email)
    .then((response) => {
      console.log('resp===>', response)
      setResultSearch(response?.data?.data)
      dispatch({
        type: userConstants.SEARCH_USER,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      toast.error(response.message);
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

function actionAuthUser(token) {
  return {
    type: userConstants.AUTH_USER,
    token: token,
  };
}

const updateUser = (data, setIsLoading, toaster) => async (dispatch) => {
  setIsLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    const res = await authService.updateUserDetails(data);
    if(res.status == 200){
      setIsLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch(userActions.getUser());
      // dispatch({
      //   type: userConstants.UPDATE_USERS,
      //   payload: res.data.data,
      // });
      toaster.show({
        title: 'Success !',
        message: `Your Profile has modified successfully`,
        greedy: true,
        type: 'success'
      });
      // window.location.reload()
    }


    return Promise.resolve(res.data.data);
  } catch (err) {
    setIsLoading(false)
    console.log("err", err)
    // toaster.show({
    //   title: 'Oups error !',
    //   message: `${err.response.data.message}`,
    //   greedy: true,
    //   type: 'error'
    // });
    return Promise.reject(err);
  }
};
const resetPaswordByUser = (data, setIsLoading, toaster) => async (dispatch) => {
  setIsLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    const res = await authService.resetPaswordByUser(data);
    if(res.status == 200){
      setIsLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch(userActions.getUser());
      // dispatch({
      //   type: userConstants.UPDATE_USERS,
      //   payload: res.data.data,
      // });
      toaster.show({
        title: 'Success !',
        message: `Your password has modified successfully`,
        greedy: true,
        type: 'success'
      });
      // window.location.reload()
    }


    return Promise.resolve(res.data.data);
  } catch (err) {
    setIsLoading(false)
    console.log("err", err)
    // toaster.show({
    //   title: 'Oups error !',
    //   message: `${err.response.data.message}`,
    //   greedy: true,
    //   type: 'error'
    // });
    return Promise.reject(err);
  }
};

export const userActions = {
  login,
  register,
  getUser,
  completeRegistration,
  AccountVerified,
  create,
  users,
  plateFormUsers,
  searchUser,
  updateUser,
  resetPaswordByUser,
  getUserLang,
  getUserStudyArea
};
