import projectService from "../../services/project.service";
import { projectConstants, uiConstants } from "../constants";
import { toast } from "react-toastify";
import { getAcceptanceCode } from "../../utils/checkAcceptanceProjectStatus";
import http from "../../http-common";

const projects = (page, limit) => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  projectService
    .getAll()
    .then((response) => {
      console.log('response====>', response)
      dispatch({
        type: projectConstants.RETRIEVE_PROJECTS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      if (response.data !== undefined) {
        dispatch({
          type: uiConstants.SET_ERRORS,
          payload: response.data.message,
        });
      }
    });
};

const acceptProject = (project, userId, setLoading, toaster, toggleOpenAcceptanceModal) => (dispatch) =>{
  setLoading(true)
  
  const code = getAcceptanceCode(project, userId)
  dispatch({ type: uiConstants.LOADING_UI });

  http.get(`/project/acceptance-code/${code}`)
  .then((response) => {
    projectService
    .getAll()
    .then((response) => {
      dispatch({
        type: projectConstants.RETRIEVE_PROJECTS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      toaster.show({
        title: 'Success !',
        message: `Your project has created successfully"`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleOpenAcceptanceModal()
    })
    .catch((response) => {
      toaster.show({
        title: 'Oups error !',
        message: `Something was wrong`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
    });
  })
  .catch((err) => {
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    dispatch({
      type: uiConstants.CLEAR_ERRORS,
    });
  })
}

const rejectProject = (project, userId, setLoading, toaster, toggleOpenAcceptanceModal) => (dispatch) =>{
  setLoading(true)
  
  const code = getAcceptanceCode(project, userId)
  dispatch({ type: uiConstants.LOADING_UI });

  http.get(`/project/reject-code/${code}`)
  .then((response) => {
    projectService
    .getAll()
    .then((response) => {
      dispatch({
        type: projectConstants.RETRIEVE_PROJECTS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      toaster.show({
        title: 'Success !',
        message: `Your project has created successfully"`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleOpenAcceptanceModal()
    })
    .catch((response) => {
      toaster.show({
        title: 'Oups error !',
        message: `Something was wrong`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
    });
  })
  .catch((err) => {
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    dispatch({
      type: uiConstants.CLEAR_ERRORS,
    });
  })
}

const create = (project, navigate, toaster, setLoading) => (dispatch) => {
  setLoading(true)
  dispatch({ type: uiConstants.LOADING_UI });
  projectService
    .create(project)
    .then((response) => {
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: projectConstants.CREATE_PROJECT,
        payload: response.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has created successfully"`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      navigate("/adm/projects/all", { replace: false });
    })
    .catch((response) => {
      // toast.error(response.response.data.message);
      console.log('response', response)
      toaster.show({
        title: 'Oups error !',
        message: `${response.message}`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const update = (id, data, setIsLoading, toaster, isConf, navigate, handleRefresh) => async (dispatch) => {
  setIsLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await projectService.update(id, data);
    if(res.status == 200){
      setIsLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: projectConstants.UPDATE_PROJECT,
        payload: {id: id, ...res.data.data},
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      if(isConf){
        // navigate("/adm/projects/all", { replace: false });
      }else{
        console.log('bebbbbbb')
        window.location.reload()
        console.log('bebebbebe')
      }
      
      // reload()
      // navigate("/adm/projects/all", { replace: true });
      // navigate && 
    }

    // dispatch({
    //   type: projectConstants.UPDATE_PROJECT,
    //   payload: data,
    // });

    return Promise.resolve(res.data.data);
  } catch (err) {
    setIsLoading(false)
    console.log("err", err)
    // toaster.show({
    //   title: 'Oups error !',
    //   message: `${err.response.data.message}`,
    //   greedy: true,
    //   type: 'error'
    // });
    return Promise.reject(err);
  }
};

const updateProjectStatus = (id, data, setIsLoading, toaster, isConf, navigate, handleRefresh) => async (dispatch) => {
  setIsLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await projectService.updateProjectStatus(id, data);
    if(res.status == 200){
      setIsLoading(false)
      console.log('resres', res.data.data)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: projectConstants.UPDATE_PROJECT,
        payload: res.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      if(isConf){
        navigate("/adm/projects/all", { replace: false });
      }else{
        console.log('bebbbbbb')
        window.location.reload()
        console.log('bebebbebe')
      }
      
      // reload()
      // navigate("/adm/projects/all", { replace: true });
      // navigate && 
    }

    // dispatch({
    //   type: projectConstants.UPDATE_PROJECT,
    //   payload: data,
    // });

    return Promise.resolve(res.data.data);
  } catch (err) {
    setIsLoading(false)
    console.log("err", err)
    // toaster.show({
    //   title: 'Oups error !',
    //   message: `${err.response.data.message}`,
    //   greedy: true,
    //   type: 'error'
    // });
    return Promise.reject(err);
  }
};

const remove = (id, toaster, navigate, setIsDeleted, projectNumber) => async (dispatch) => {
  setIsDeleted(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });

    const res = await projectService.remove(id, projectNumber);

    if(res.status == 200){
      toaster.show({
        title: 'Success !',
        message: `Project successfully deleted`,
        greedy: true,
        type: 'success'
      });
      setIsDeleted(false)
      navigate("/adm/projects/all", { replace: false });
    }

    dispatch({
      type: projectConstants.DELETE_PROJECT,
      payload: { id },
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const projectActions = {
  create,
  projects,
  update,
  remove,
  updateProjectStatus,
  acceptProject,
  rejectProject
};
