import { useState } from "react";
import { useToaster } from "../../contexts/toaster";
import http from "../../http-common";
import { ButtonWithBg, FormikSelect, InputRadio, Paragraph, SelectField, Textarea } from "../../components/Kits";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

export default function UpdateSurveyStatus({
    surveyDataRequest,
    setSurveyDataRequest,
    getSurveyData,
    toggleModal,
    getSd,
    project,
    modalMode,

    setDataMapps,
    dataMapps,
    setGetSd,

    externalProjectInfo,
    setExternalProjectInfo
}){
    const toaster = useToaster()
    const { t } = useTranslation(["common", "project", "form"]);
    const surveyDatasStatus = useSelector((state) => state.surveyDatasStatus);
    const [assignNewAgent, setAssignNewAgent] = useState(false)
    const [newAgent, setNewAgent] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(assignNewAgent && !newAgent){
            toaster.show({
                title: 'Oups !',
                message: `Veuillez sélectionner un agent parmi la liste`,
                greedy: true,
                type: 'error'
            });

            return
        }

        if(isLoading===false){
            setIsLoading(true)
            await http.post(`/survey-datas-requested`, surveyDataRequest)
            .then(async function (response) {
                console.log('response survey', response)
                if(response.status === 200){
                    if(assignNewAgent && newAgent){
                        await http.post(`/assign-survey`, {
                            surveyDatasId: surveyDataRequest.surveyDatasId,
                            fieldWorkerId: newAgent
                        }).then((res) => {

                            if(getSurveyData){
                                getSurveyData()
                            }  else if(dataMapps) {
                                let foundEl = dataMapps.findIndex(x => x?.surveyInfos?.id === getSd?.id)

                                let foundSate = surveyDatasStatus?.find(x => x?.id === response?.data?.data?.statusId)

                                console.log('foundSate', foundSate)

                                if(foundEl >= 0) {
                                    const updatedDataMapps = [...dataMapps];
                                    updatedDataMapps[foundEl] = {
                                        ...updatedDataMapps[foundEl],
                                        surveyInfos: {
                                            ...updatedDataMapps[foundEl].surveyInfos,
                                            status: {
                                                ...updatedDataMapps[foundEl].surveyInfos.status,
                                                ...foundSate
                                            }
                                        }
                                    };
                                    setDataMapps(updatedDataMapps);
                                    setGetSd({
                                        ...getSd,
                                        status: {
                                            ...getSd?.status,
                                            ...foundSate
                                        }
                                    })
                                }

                            } else if(externalProjectInfo){
                                setExternalProjectInfo({
                                    ...externalProjectInfo,
                                    statusId: surveyDataRequest?.statusId
                                })
                            } else {
                                console.log('nothing else')
                            }
                            
                            setIsLoading(false)
                            toaster.show({
                                title: 'Success !',
                                message: `Your project has modified successfully`,
                                greedy: true,
                                type: 'success'
                            });
                        }).catch((err) => {
                            toaster.show({
                                title: 'oups !',
                                message: `Une erreur s'est produite lors de l'attribution de la donnée a un nouvel agent`,
                                greedy: true,
                                type: 'error'
                            });
                        })
                    } else {

                        if(getSurveyData){
                            getSurveyData()
                        } else if(dataMapps) {
                            let foundEl = dataMapps.findIndex(x => x?.surveyInfos?.id === getSd?.id)

                            let foundSate = surveyDatasStatus?.find(x => x?.id === response?.data?.data?.statusId)

                            console.log('foundSate', foundSate)

                            if(foundEl >= 0) {
                                const updatedDataMapps = [...dataMapps];
                                
                                updatedDataMapps[foundEl] = {
                                    ...updatedDataMapps[foundEl],
                                    surveyInfos: {
                                        ...updatedDataMapps[foundEl].surveyInfos,
                                        status: {
                                            ...updatedDataMapps[foundEl].surveyInfos.status,
                                            ...foundSate
                                        }
                                    }
                                };
                                setDataMapps(updatedDataMapps);
                                setGetSd({
                                    ...getSd,
                                    status: {
                                        ...getSd?.status,
                                        ...foundSate
                                    }
                                })
                            }

                        } else if(externalProjectInfo){
                            setExternalProjectInfo({
                                ...externalProjectInfo,
                                statusId: surveyDataRequest?.statusId
                            })
                        } else {
                            console.log('nothing else')
                        }

                        setIsLoading(false)

                        toaster.show({
                            title: 'Success !',
                            message: `Your project has modified successfully`,
                            greedy: true,
                            type: 'success'
                        });
                    }
                    
                    toggleModal()
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                toaster.show({
                    title: 'Oups !',
                    message: error,
                    greedy: true,
                    type: 'error'
                });
            });
        }
    };

    return (
        <>
            {
                modalMode==='order' &&
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="p-6 min-h-[450px] overflow-x-hidden">
                        <div className="mb-4 border-b border-dashed border-black/20 pb-4">
                            <span className='block mb-1 text-[14px] text-black text-opacity-50'>Agent Actuel</span>
                            <div className="flex items-center gap-2 py-2">
                                <div>
                                    <h4 className="text-[14px] text-black  leading-[15px]">{getSd?.user?.firstName +" "+ getSd?.user?.lastName}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="mb-[30px]">
                            <span className="text-[16px] text-black block mb-2">
                                {t("project:assignanewagent")} ?
                            </span>
                            <InputRadio 
                                label={t("common:no")}
                                name="assign"
                                checked={assignNewAgent === false ? true : false }
                                onChange={(e) => {
                                    setAssignNewAgent(false)
                                }}
                            />
                            <InputRadio 
                                label={t("common:yes")}
                                name="assign"
                                checked={assignNewAgent === true ? true : false }
                                onChange={(e) => {
                                    setAssignNewAgent(true)
                                }}
                            />
                        </div>
                        {
                            assignNewAgent && (
                                <div className="mb-[30px]">
                                    {/* <SelectField 
                                        label={'Choisir un agent'}
                                        smallLab={true}
                                        options= {[
                                            {
                                                // value: '---------',
                                                name: '--------------'
                                            },
                                            ...project?.projectUsers?.filter(user => user.roles.some(role => role.name === 'Field Worker'))?.map(x => (
                                                {
                                                    value: x?.userId,
                                                    name: x?.firstName +" "+ x?.lastName
                                                }
                                            ))
                                        ]}
                                        onChange={(e) =>{
                                            setNewAgent(e.target.value)
                                        }}
                                    /> */}
                                    <span className="shrink-0 whitespace-nowrap block w-[200px]">Choisir un agent</span>
                                    <div className="block min-h-[45px] flex-1">
                                        <FormikSelect 
                                            options= {project?.projectUsers?.filter(user => user.roles.some(role => role.name === 'Field Worker'))?.map(x => (
                                                {
                                                    value: x?.userId,
                                                    label: x?.firstName +" "+ x?.lastName
                                                }
                                            ))}
                                            onChange={(e) => {
                                                setNewAgent(e.value)
                                            }}
                                        />
                                    </div>
                            </div>
                            )
                        }
                        <Textarea 
                            label={'Order'}
                            placeholder={"ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                            type={'text'}
                            value={surveyDataRequest.description}
                            onChange={(e) =>{
                                setSurveyDataRequest({
                                    ...surveyDataRequest,
                                    description: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-x-2'>
                        <ButtonWithBg 
                            title={t("common:cancel")}
                            type='button'
                            bg={'gray'}
                            isSmall={true}
                            onClick={() => toggleModal()}
                        />  
                        <ButtonWithBg 
                            title={isLoading ? t("common:inprogress") : t("project:updatedatastate")}
                            type='submit'
                            bg={'primary'}
                            isSmall={true}
                        />
                    </div>
                </form>
            }
            {
                modalMode==='otherState' && 
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="p-6 min-h-[250px] overflow-x-hidden">
                        <div className="bg-tertiary-gray-500 p-4 border rounded-[6px] mt-[15px] mb-[50px] flex items-center gap-4">
                            <AiOutlineInfoCircle className="text-[20px] rotate-180 text-black text-opacity-50 flex-shrink-0" />
                            <Paragraph 
                                content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                            />
                        </div>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-x-2'>
                        <ButtonWithBg 
                            title={t("common:cancel")}
                            type='button'
                            bg={'gray'}
                            isSmall={true}
                            onClick={() => toggleModal()}
                        />
                        <ButtonWithBg 
                            title={isLoading ? t("common:inprogress") : t("project:updatedatastate")}
                            type='submit'
                            bg={'primary'}
                            isSmall={true}
                        />
                    </div>
                </form>
            }
        </>
    )
}