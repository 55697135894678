import { retirerId } from "./retireId"

const formatedDataForGeolocationFile = (surveyDataWithOutLimit, fromIndex, ToIndex) => {
    let data 
    data = surveyDataWithOutLimit?.map((srv, idx) =>{
        if (idx >= fromIndex - 1 && idx <= ToIndex - 1) {
            return srv?.datasMap?.mapps?.map((data, i) => (
            {
                name: data?.label,
                coordinates: data?.coordinates,
                type: data?.type
            }
        ))}
    })

    return data
}

const formatedDataForGeolocationFileForModuleMapping = (datasMap, fromIndex, ToIndex) => {
    let data = []
    datasMap?.map((srv, idx) => {
        if (idx >= fromIndex - 1 && idx <= ToIndex - 1) {
            return data.push({
                name: srv?.name,
                coordinates: srv?.mappings,
                type: srv?.mappingType
            })
        }
    })

    return data
}

const formatedDataForCsvAndXlsxFile = (surveyDataWithOutLimit, fromIndex, ToIndex, displayStatusName , project) => {
    console.log('surveyDataWithOutLimit', surveyDataWithOutLimit)
    let data = []
    surveyDataWithOutLimit?.map((survey, idx) => {
        if(idx >= fromIndex - 1 && idx <= ToIndex - 1){
            const dataProps = Object.keys(survey?.survey || {}); // Récupère les propriétés de l'objet "data"
            const dataMappsProps = Object.keys(survey?.survey || {}); // Récupère les propriétés de l'objet "data"

            console.log("dataProps", dataProps)
            const newData = dataProps.reduce((acc, prop) => {
                console.log('prop', prop)
                if(prop !=='status' && prop!=='datasMap' && prop !== 'id'){
                    acc[prop] = retirerId(survey?.survey[prop]?.value); // Ajoute la propriété dynamiquement à l'objet "newData"
                }
                return acc;
            }, {});

            const newDataMapps = transformData(survey?.datasMap?.mapps);
            const fieldNameExternalProject = project?.configuration?.externalProject?.fieldName
            

            // console.log('newData', newData)
            // console.log('newDataMapps------', newDataMapps)

            if(project && project?.configuration?.useExternalProject){
                let aaa = String(fieldNameExternalProject).toLocaleLowerCase().replaceAll(" ", "-")

                // console.log('aaString', aaa)
                data.push({
                    id:survey?.id,
                    created_at : survey?.createdAt ? new Date(survey?.createdAt) : "---------",
                    agent: survey?.user?.lastName +" "+ survey?.user?.firstName,
                    status: displayStatusName(survey?.status?.id),
                    [aaa] : aaa,
                    ...newData, // Ajoute les propriétés de "newData" dynamiquement à l'objet retourné
                    ...newDataMapps
                });
            } else{
                data.push({
                    id:survey?.id,
                    created_at : survey?.createdAt ? new Date(survey?.createdAt) : "---------",
                    agent: survey?.user?.lastName +" "+ survey?.user?.firstName,
                    status: displayStatusName(survey?.status?.id),
                    ...newData, // Ajoute les propriétés de "newData" dynamiquement à l'objet retourné
                    ...newDataMapps
                });
            }
        }
    });

    console.log('data', data)

    return data
}

const formatedHeadersXlsxAndCsv = (project) => {
    if(project){
        let headers;
        if(project?.configuration?.useExternalProject){
            const fieldNameExternalProject = project?.configuration?.externalProject?.fieldName

            headers = project && project?.configuration?.useExternalProject && [
                { label: "Identifiant", key: "id" },
                ...project?.form?.fields?.map((el, index)=>(
                    { label: el.label, key: el.name }
                )),
                
                ...project?.form?.mapps?.map((el, index) =>(
                    { label: el.label, key: el.name }
                )),

                {label: fieldNameExternalProject, key: String(fieldNameExternalProject).toLocaleLowerCase().replaceAll(" ", "-")},
                { label: "Creation date", key: "created_at" },
                { label: "Agent", key: "agent" },
                { label: "Status", key: "status" },
            ];
        
            return headers
        } else {
            console.log('not fieldNameExternalProject')
            headers = project && [
                { label: "Identifiant", key: "id" },
                ...project?.form?.fields?.map((el, index)=>(
                    { label: el.label, key: el.name }
                )),
                
                ...project?.form?.mapps?.map((el, index) =>(
                    { label: el.label, key: el.name }
                )),
                
                { label: "Creation date", key: "created_at" },
                { label: "Agent", key: "agent" },
                { label: "Status", key: "status" },
            ];
        
            return headers
        }
        
    } else {
        return []
    }
    
}


const transformData = (data) => {
    const result = {};
    
    if(data){
        data.forEach((item) => {
            const key = item.name;
        
            if (!result[key]) {
              result[key] = item.coordinates.map((coord) => ({
                altitude: coord.altitude,
                dateHourSended: coord.dateHourSended,
                latitude: coord.latitude,
                longitude: coord.longitude,
              }));
            } else {
              result[key] = result[key].concat(
                item.coordinates.map((coord) => ({
                  altitude: coord.altitude,
                  dateHourSended: coord.dateHourSended,
                  latitude: coord.latitude,
                  longitude: coord.longitude,
                }))
              );
            }
        });
    }
  
    return result;
  };

  

export {
    formatedDataForGeolocationFile,
    formatedDataForCsvAndXlsxFile,
    formatedHeadersXlsxAndCsv,
    formatedDataForGeolocationFileForModuleMapping
}


