import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag, FormikSelect } from '../../../../../components/Kits'
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft, BsCheckLg } from 'react-icons/bs'
import { Modal } from '../../../../../components/Modals'
import { useState, Fragment, useEffect } from "react";
import {
    formActions,
    formTypesActions,
    projectActions,
    projectRolesActions,
    userActions,
} from '../../../../../redux/actions'
import { useToaster } from "../../../../../contexts/toaster";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import http from '../../../../../http-common'
import { filterAndReplaceNames } from '../../../../../utils/filterAndReplaceNames'

export default function ChooseMembers({item, t, project, projectId, accessControl}){
    const newUserToAddInitialState = {
        projectUsers: [],
      };
    const toaster = useToaster()
    const dispatch = useDispatch();
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
    const [showBoxSearch, setShowBoxSearch] = useState(false)
    const [searchContent, setSearchContent] = useState()
    const projectRoles = filterAndReplaceNames(useSelector((state) => state.projectRoles));

    const [searchUser, setSearchUser] = useState({ email: "" });
    const [roleSelected, setRoleSelected]= useState({userId: '', role:[]})
    const [resultSearch, setResultSearch] = useState(null)
    const [projectUsersSelected, setProjectUsersSelected] = useState([]);
    const [getGroupData, setGetGroupData] = useState(false)
    const user = useSelector((state) => state.user);
    const [newUserToAdd, setnewUserToAdd] = useState(
        newUserToAddInitialState
      );
    const [isLoading, setIsLoading] = useState(false)
    const [isConf, setIsConf] = useState(false)
    const [newRoleToAdd, setNewRoleToAdd] = useState([])

    const [getUser, setGetUser] = useState(null)
    const [elementToDelete, setElementToDelete] = useState(null)

    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])
 
    useEffect(() => {
        dispatch(projectRolesActions.projectRoles());
        dispatch(userActions.plateFormUsers());
    }, []);

    const handleSearchUser = (e) => {
        console.log('launch')
      e.preventDefault();
  
      dispatch(userActions.searchUser(searchUser, setResultSearch));
    };
  
    useEffect(()=>{
      if(searchUser?.email == ''){
          setResultSearch(null)
      }
    }, [searchUser])

    const removeOrAddUserOnProject = (userSearch, idx, key) => {
          const userFiltered = projectUsersSelected.find((u) => u.userId === userSearch.id);
    
          console.log('first userFiltered', userFiltered)
          console.log('first user', user)
          console.log('first userSearch', userSearch)

        
          if (userFiltered === undefined) {
            if(userSearch.id === user?.userId){
                toaster.show({
                    title: 'Infos !',
                    message: `You can't add your own account to the project`,
                    greedy: true,
                    type: 'info'
                });
            } else if(itemData?.projectUsers.some(el => el.userId === userSearch.id)){
                toaster.show({
                    title: 'Infos !',
                    message: `User already exist in this project`,
                    greedy: true,
                    type: 'info'
                });
            }else{
                setProjectUsersSelected([...projectUsersSelected, {...userSearch, userId:userSearch.id}]);
                setnewUserToAdd((prevState) => ({
                ...prevState,
                projectUsers: [...prevState.projectUsers, { userId: userSearch.id }],
                }));
            }
            
          } else {
            if(userFiltered?.userId === user?.userId){
                toaster.show({
                    title: 'Infos !',
                    message: `You can't add your own account to the project`,
                    greedy: true,
                    type: 'info'
                  });
              }else{
                setProjectUsersSelected(
                    projectUsersSelected.filter((u) => u.userId != userSearch.id)
                  );
                  setnewUserToAdd((prevState) => ({
                    ...prevState,
                    projectUsers: prevState.projectUsers.filter((u) => u.userId != userSearch.id),
                  }));
              }
            
          }
          
        
      };

    const toggleModal = ()=>{
        if(isOpen){
            setnewUserToAdd(newUserToAddInitialState)
            setProjectUsersSelected([])
            // setNewRoleToAdd(null)
            setElementToDelete(null)
            setItemData(
                {
                    name: project?.name,
                    description: project?.description,
                    formId: project?.form?.id ? project?.form?.id : "",
                    billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                    startDate: project?.startDate,
                    endDate: project?.endDate,
                    projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                    executeZone: project?.executeZone,
                    executeFrom: project?.executeFrom,
                    activeLocationExecution: project?.activeLocationExecution,
                    isImpacting: project?.isImpacting,
                    projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                        {
                            userId: user?.userId,
                            roles: user?.roles?.map(el => (el?.id))
                        }
                    ))
                }
            )
            setIsOpen(false)
        }else{
            setIsOpen(true)
        }
        
    }

    useEffect(()=>{
        if(itemData?.projectUsers){
            setItemData((prevState) => ({
                ...prevState,
                projectUsers: [...prevState.projectUsers, ...newUserToAdd.projectUsers],
            }));
        }
       
        console.log('qwertyui')
    },[newUserToAdd])

    useEffect(()=>{
        let findIndex = itemData?.projectUsers?.findIndex(
            (u) => u?.userId === getUser?.userId
        );
        
        let projectUser

        if(findIndex >= 0 && findIndex !=undefined && findIndex!=null){
            projectUser = itemData?.projectUsers[findIndex];
        }
        
        if(projectUser){
            projectUser.roles = newRoleToAdd
        }
        

        console.log('findIndex', findIndex)
        console.log('findIndex', projectUser)
        if(findIndex >= 0 && findIndex !=undefined && findIndex!=null){
            itemData.projectUsers[findIndex]=projectUser
        }
        

    },[newRoleToAdd])

    useEffect(()=>{
        if(itemData?.projectUsers){
            setItemData((prevState) => ({
                ...prevState,
                projectUsers: prevState.projectUsers.filter(x => x.userId !== elementToDelete?.userId),
            }));
        }
    },[elementToDelete])

    console.log('itemData', itemData)
   

    const handleSubmitProject = async() => {
        // e.preventDefault();
        if(isLoading===false){
            setIsLoading(true)
            await http.put(`/projects/${projectId}/`, itemData)
            .then(function (response) {
                if(response.status === 200){
                    setIsLoading(false)
                    toaster.show({
                        title: 'Success !',
                        message: `Your project has modified successfully`,
                        greedy: true,
                        type: 'success'
                    });
                    window.location.reload()
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                toaster.show({
                    title: 'Oups !',
                    message: error,
                    greedy: true,
                    type: 'error'
                });
            });
        }
        
        // dispatch(projectActions.update(item?.id, itemData, setIsLoading, toaster, isConf));
      };


    return(
        <>
            <Disclosure className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
                {({ open }) => (
                    <div className={`rounded-lg overflow-hidden } `}>
                        <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                            <div className={`flex items-center justify-between gap-3 w-full`}>
                                <span className={`
                                    text-[15px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                }`}>
                                    {t("project:projectteammembers")}
                                </span>
                                <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                            <div className={`col-span-1`}>
                                {
                                    (accessControl?.maintainer || accessControl?.owner) && (
                                        <div className='flex justify-end mb-[10px]'>
                                            <ButtonWithBg 
                                                title={t('project:addmembers')}
                                                type='button'
                                                bg={'secondary'}
                                                isSmall={true}
                                                onClick={() => {
                                                    setModalMode('add_user')
                                                    toggleModal()
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                
                                <div  tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] !focus:outline-none overflow-auto tableLand:overflow-visible pb-[100px]  scrollbar mb-6'>
                                    <div className="w-full">
                                        <table className="w-full text-[#484964]">
                                            <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                                                <tr className=" text-left text-opacity-90 h-[40px]">
                                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                        N
                                                    </th>
                                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                        {t("project:membername")}
                                                    </th>
                                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                        {t("project:role")}
                                                    </th>
                                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                        {t("project:invitationaccepted")}
                                                    </th>
                                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                        {t("project:activemember")}
                                                    </th>
                                                    {
                                                        (accessControl?.maintainer || accessControl?.owner) && (
                                                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                                Action
                                                            </th>
                                                        )
                                                    }
                                                    
                                                </tr>
                                            </thead>
                                            <tbody className={`relative divide-y`}>
                                                {
                                                    project?.projectUsers?.map((user, idx) =>(
                                                        <tr  className='text-left border-b  border-[#EFEFEF]'>
                                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                                {idx + 1 < 10 ? `0${idx+1}` : idx+1}
                                                            </td>
                                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                                <div className="flex items-center gap-2 py-2">
                                                                    <div className="h-[30px] w-[30px] flex-shrink-0 rounded-full overflow-hidden">
                                                                        <img
                                                                            src="/images/avatar.png"
                                                                            alt="profil"
                                                                            className="h-full w-full object-cover"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h4 className="text-[12px] text-black whitespace-nowrap  leading-[15px]">{user?.firstName +" "+ user?.lastName}</h4>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-1 whitespace-nowrap  text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                                {
                                                                    user?.roles[0]?.name=='Project Owner' ?
                                                                    <Tag
                                                                        title={t(`project:${user?.roles[0]?.name?.toLowerCase()?.replaceAll(" ", "")}`)}
                                                                        color={"text-[#000]"}
                                                                        bg={ "bg-[#F6F7FB]" }
                                                                    />
                                                                    :
                                                                    <span 
                                                                        className='text-primary-blue-500 cursor-pointer'
                                                                        onClick={() => {
                                                                            setModalMode('role')
                                                                            setGetUser(user)
                                                                            toggleModal()
                                                                        }}
                                                                    >
                                                                        {t("project:role")}s
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                                <span className={`${user?.acceptanceStatus?.name == 'Accept' ? 'text-secondary-green-500' : 'text-red-500' }`}>
                                                                    {user?.acceptanceStatus?.name == 'Accept' ? t("common:yes") : t("common:no")}
                                                                </span>
                                                            </td>
                                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                                <span className='text-red-500'>
                                                                    {(user?.acceptanceStatus?.name == 'Accept' && user?.enable) ? t("common:yes") : t("common:no")}
                                                                </span>
                                                            </td>
                                                            { (accessControl?.maintainer || accessControl?.owner) && (
                                                            <td className="px-4 py-2 whitespace-nowrap">
                                                                {
                                                                    user?.roles[0]?.name !=='Project Owner' &&
                                                                    <div className="">
                                                                        <Menu as="div" className="relative inline-block text-left">
                                                                            <Menu.Button className="text-sm ">
                                                                                <div className="border px-3 py-2 rounded-[4px] text-[12px] font-poppins font-[500] flex items-center gap-6">
                                                                                    <span className="text-[#676D7C] ">Actions</span>
                                                                                    <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                    </svg>
                                                                                </div>
                                                                            </Menu.Button>
                                                                            <Transition
                                                                                as={Fragment}
                                                                                enter="transition ease-out duration-100"
                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                enterTo="transform opacity-100 scale-100"
                                                                                leave="transition ease-in duration-75"
                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                leaveTo="transform opacity-0 scale-95"
                                                                            >
                                                                                <Menu.Items className="absolute z-20 right-0 min-w-32  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                    <div className="px-1 py-1">
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <button
                                                                                                    className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                                        } group flex rounded-md items-center w-full px-2 py-2 text-[12px] hidden`}
                                                                                                >
                                                                                                    {"Disabled user"}
                                                                                                </button>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <button
                                                                                                    onClick={() => {
                                                                                                        setModalMode('delete')
                                                                                                        setElementToDelete(user)
                                                                                                        toggleModal()
                                                                                                    }}
                                                                                                    className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                                        } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                                                >
                                                                                                    {t("project:removemember")}
                                                                                                </button>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    </div>
                                                                                </Menu.Items>
                                                                            </Transition>
                                                                        </Menu>
                                                                    </div>
                                                                }
                                                            </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>

            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal} resetOverflow>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode == 'add_user' ? t("project:addmembers") :
                            modalMode == 'role' ? t("project:role") : 
                            modalMode == 'delete' ? t("project:deleteuser") : 
                            null
                        }
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                {  modalMode === 'add_user' &&
                    <>
                        <div className="p-6">
                        <form onSubmit={handleSearchUser} className="mb-[30px] w-[450px] relative">
                            <Input 
                                label={t("project:selectuser")}
                                placeholder={"ex: @mael124"}
                                type={'search'}
                                smallLab={true}
                                value={searchUser.email}
                                onChange={(e)=> {
                                    setShowBoxSearch(true)
                                    // setSearchContent(e.target.value)
                                    setSearchUser({ email: e.target.value })
                                }}
                            />
                            
                            {
                                (showBoxSearch && resultSearch && searchUser.email !== '') &&
                                <ul className="w-full border rounded-[6px] h-[150px] absolute top-[120%] left-0 bg-white overflow-x-hidden z-30">
                                    <li 
                                        onClick={(e) => {
                                            removeOrAddUserOnProject(resultSearch)
                                            setSearchUser({email: ''})
                                            setShowBoxSearch(false)
                                            setResultSearch(null)
                                        }}
                                        className="flex items-center gap-2 p-2 border-b border-dashed cursor-pointer">
                                        <div className="h-[40px] w-[40px] rounded-full overflow-hidden">
                                            <img
                                                src="/images/avatar.png"
                                                alt="profil"
                                                className="h-full w-full object-cover" 
                                            />
                                        </div>
                                        <div>
                                            <h4 className="text-[14px] text-black leading-[10px]">{resultSearch?.firstName +" "+ resultSearch?.lastName}</h4>
                                            <span className="text-[12px] text-black text-opacity-50">{resultSearch?.email}</span>
                                        </div>
                                    </li>
                                        
                                </ul>
                            }
                        </form>
                            <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-y-hidden scrollbar pb-[200px] mb-[30px]'>
                                <div className="w-full ">
                                    <table className="w-full text-[#484964]">
                                        <thead className=" border-b border-[rgb(239,239,239)] bg-tertiary-gray-500">
                                            <tr className=" text-left text-opacity-90 h-[60px]">
                                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                    {t("project:account")}
                                                </th>
                                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                    {t("project:role")}
                                                </th>
                                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={`relative divide-y`}>
                                            {
                                                projectUsersSelected?.map((user, idx) =>(
                                                    <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                                        <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] w-[500px]">
                                                            <div className="flex items-center gap-2 py-2">
                                                                <div className="h-[40px] w-[40px] rounded-full overflow-hidden shrink-0">
                                                                    <img
                                                                        src="/images/avatar.png"
                                                                        alt="profil"
                                                                        className="h-full w-full object-cover"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h4 className="text-[14px] text-black leading-[24px]">{user?.firstName +" "+ user?.lastName}</h4>
                                                                    <span className="text-[12px] text-black text-opacity-50">{user?.email}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] align-middle w-[300px]">
                                                            <FormikSelect 
                                                                name={user.id}
                                                                options= {projectRoles?.filter(x => x?.id !== 'byIhVMF5lVxU529Wlpkg')?.map((role, idx) =>(
                                                                    {
                                                                        label: t(`project:${role?.name?.toLowerCase()?.replaceAll(" ", "")}`),
                                                                        value: role.id
                                                                    }
                                                                ))}
                                                                isMulti
                                                                onChange={(e) => {
                                                                    let findIndex = newUserToAdd.projectUsers.findIndex(
                                                                    (u) => u.userId === user.id
                                                                    );
                                                                    let projectUser = newUserToAdd.projectUsers[findIndex];
                                                                    setGetGroupData(!getGroupData)
                                                                    projectUser.roles = e.map(option => option?.value);
                                                                    // projectUser.roleId = e.value;
                                                                    newUserToAdd.projectUsers[findIndex] = projectUser;
                                                                    
                                                                }}
                                                                value={
                                                                    projectRoles?.filter(item => newUserToAdd?.projectUsers[idx]?.roles?.includes(item?.id))?.map((role)=>(
                                                                        {
                                                                            label: t(`project:${role?.name?.toLowerCase()?.replaceAll(" ", "")}`),
                                                                            value: role.id
                                                                        }
                                                                    ))
                                                                }
                                                            />
                                                        </td>
                                                        <td className="px-4 py-2   whitespace-nowrap">
                                                            <div className="w-[150px]">
                                                                <button 
                                                                    onClick={() =>
                                                                        removeOrAddUserOnProject(user, null, idx)
                                                                    }
                                                                    className="bg-white border border-red-500 px-3 py-2 rounded-[6px] text-[12px] font-[500] flex items-center gap-6">
                                                                    <span className="text-red-500 ">{t("project:removemember")}</span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("project:savechange")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => handleSubmitProject()}
                            />
                        </div>
                    </>
                }
                {  modalMode === 'role' &&
                    <>
                        <div className="p-6 min-h-[300px] overflow-x-hidden">
                            <FormikSelect 
                                options= {projectRoles?.filter(x => x?.id !== 'byIhVMF5lVxU529Wlpkg')?.map((role, idx) =>(
                                    {
                                        label: t(`project:${role?.name?.toLowerCase()?.replaceAll(" ", "")}`),
                                        value: role.id
                                    }
                                ))}
                                isMulti
                                onChange={(e) => setNewRoleToAdd(e.map(opt => opt.value))}
                                defaultValue={filterAndReplaceNames(getUser?.roles)?.map(x => (
                                    {
                                        value: x.id,
                                        label: t(`project:${x?.name?.toLowerCase()?.replaceAll(" ", "")}`),
                                    }
                                ))}
                                isDisabled={!accessControl?.maintainer && !accessControl?.owner}
                            />
                        </div>
                        {
                            (accessControl?.maintainer || accessControl?.owner) && (
                                <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                                    <ButtonWithBg 
                                        title={isLoading ? t("common:inprogress") : t("common:savechange")}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                        onClick={() => handleSubmitProject()}
                                    />
                                </div>
                            )
                        }
                    </>
                }
                
                {  modalMode == 'delete' &&
                    <>
                        <div className="p-6 min-h-[200px] overflow-x-hidden">
                            <span className='text-[16px] text-black text-opacity-50'>{t("project:deleteuserwarn")}</span>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("common:delete")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => handleSubmitProject()}
                            />
                        </div>
                    </>
                }
                
            </Modal>
        </>
    )
}


const projectUsers = [
    {
        userId: "736bdbf0-1d4a-4ff1-8deb-ad78a8c09a38", 
        roles : []
    }
]