import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/Kits";
import { ButtonWithBg } from "../../components/Kits/Buttons";
import { userActions } from "../../redux/actions";
// import { userActions } from '../../redux/actions/user.actions'
import { uiConstants } from "../../redux/constants";
import { authLogic } from "../../utils";
import { useToaster } from "../../contexts/toaster";
import {FiAlertTriangle} from "react-icons/fi"

export default function LoginPage() {
  let initialState = {
    email: "",
    password: "",
  };
  const toaster = useToaster()
  const [credentials, setCredentials] = useState(initialState);
  const loading = useSelector((state) => state.UI.loading);
  const token = useSelector((state) => state.auth.token);
  const [errorText, setErrorText] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("post");

    dispatch(userActions.login(credentials, navigate, toaster, setErrorText));
  };

  useEffect(() => {
    if (authLogic.isAuthenticated(token) === true) {
      return navigate("/adm/survey-console", { replace: true });
    }
  }, []);

  return (
    <div className="h-[100vh] flex">
      <div className="h-full bg-white w-full lg:w-[45%] flex items-center justify-center">
        <div className="w-[450px] mx-auto px-4">
          <h1 className="text-[30px] font-medium font-ibm-plex-sans leading-[24px] text-primary-blue-500 mb-4">
            Welcome to Terros
          </h1>
          <span className="text-[16px] leading-[24px] text-black text-opacity-50">
            Login to your account
          </span>
          { errorText &&
            <div className="p-[10px] rounded-[6px] bg-red-100 text-red-500 text-[14px] mt-[50px] flex items-center gap-[10px]">
              <FiAlertTriangle className="text-red-500 text-[24px]" />
              {errorText}
            </div>
          }
          <form className="mt-[70px]" onSubmit={handleSubmit}>
            <div className="mb-[30px]">
              <Input
                label={"E-mail"}
                placeholder={"info@gmail.com"}
                type={"email"}
                name={credentials.email}
                onChange={(e) =>
                  setCredentials({ ...credentials, email: e.target.value })
                }
              />
            </div>
            <div className="mb-[30px]">
              <Input
                label={"Password"}
                placeholder={"**********"}
                type={"password"}
                name={credentials.password}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
              />
            </div>
            <div className="text-right mb-[30px]">
              <Link to="/forget-password" className="text-[14px] text-primary-blue-500">
                Forget password ?
              </Link>
            </div>
            <div>
              <ButtonWithBg title={ loading ? "Connexion..." : "Login"} type="sumbit" bg={"primary"} />
            </div>
            <p className="text-[14px] leading-[24px] text-black text-opacity-50 mt-[30px]">
              {"Don’t have account ? "}
              <Link
                to="/register"
                className="text-opacity-100 text-secondary-green-500"
              >
                Sign up
              </Link>
            </p>
          </form>
        </div>
      </div>
      <div className="h-full bg-gray-200 flex-1 overflow-hidden relative hidden lg:block">
        <img
          src="/images/illustration-login.png"
          alt="img"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
}
