import { appSettingConstants } from '../constants'

const initialState = null

export default function (userStudyArea = initialState, action) {
  const { type, payload } = action
  if (type === appSettingConstants.RETRIEVE_USER_STUDY_AREA) {
    return payload
  } else if(type === appSettingConstants.UPDATE_DATA_AREAS){
    return payload
  }
  else {
    return userStudyArea
  }
}
