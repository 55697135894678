import { Link } from "react-router-dom";
import { Tag } from "../../../components/Kits";
import CardBlockSkeleton from "../../../components/Skeletons/CardBlock";
import { ProjectCard } from "../../../components/Cards";

export default function BlockView({projects, t, loadingProject, length, user, toggleOpenAcceptanceModal, setProject}){

    return(
        <>
            {
                loadingProject ?
                <CardBlockSkeleton isProject={true}  length={length}/>
                :
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {
                        projects?.map((item, idx)=>(
                            <ProjectCard 
                                key={idx}
                                item={item}
                                user={user} 
                                toggleOpenAcceptanceModal={toggleOpenAcceptanceModal} 
                                setProject={setProject}
                            />
                        ))
                    }
                </div>
            }
        </>
    )
}