import { projectStatusConstants as constants } from '../constants'

const initialState = []

export default function (status = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case constants.RETRIEVE_PROJECT_STATUS:
      return payload
    default:
      return status
  }
}
