
import Select from "react-select"

const customStyles = {
	control: () => ({
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		border: '1px solid #E3E5EB',
		paddingTop: '3px',
		paddingBottom: '3px',
		borderRadius: '5px',
		backgroundColor:'#fff',
        marginTop: '7px',
		color: '#676D7C',
		fontSize: '14px',
	}),
	input: () => ({
		margin: '2px',
		paddingBottom: '2px',
		paddingTop: '2px',
		visibility: 'visible',
		color: '#676D7C',
		width: '100%',
		gridArea: '1/1/2/3',
		gridTemplateColumns: '0 min-content',
		height: '31px',
		fontSize: '14px',
	}),
	menu: () => ({
		width: "100%",
		position: "absolute",
		backgroundColor: "#fff",
		top: "50px",
		borderRadius: "5px",
		border: '1px solid #e4e7eb',
		zIndex: 50,
		overflow: "hidden",
		boxShadow: "0 0.3rem 0.94rem rgba(128,128,128,0.2)"
	}),
	menuList: () => ({
		overflowX: 'hidden',
		fontSize: '13px',
		lineHeight: "14px",
		zIndex: 50,
		// textTransform: 'lowercase',
		height: '10rem',
		width: '100%',
		"::-webkit-scrollbar": {
			width: "5px"
		},
		"::-webkit-scrollbar-track": {
			background: "#F8F8F8",
			borderRadius: "50px"
		},
		"::-webkit-scrollbar-thumb": {
			background: "#b8b6b6"
		}
	}),
	option: (base, state) => ({
		...base,
		backgroundColor: state.isSelected ? "#00c28947" : "#fff",
		color: state.isSelected && "#black",
		"&:hover": {
			backgroundColor:'#00c28947',
			color:'black'
		}
	}),
	indicatorSeparator: () => ({
		display:'none'
	})
};

function FormikSelect({
    styles,
    ...props
  }) {
    return (
      <Select
        styles={styles ?? customStyles}
        {...props}     
      />
    );
  }

export default FormikSelect