import Base from "../../../layout/adm/base";
import { Content } from "../../../section/admin/surveyConsole";

export default function SurveyConsole(){

    return(
        <Base>
            <Content />
        </Base>
    )
}