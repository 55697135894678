export default function Tag({color, title, bg, isHfull, ...props}){

    return(
        <div 
            {...props}
            className={`min-h-[25px] inline-flex items-center justify-center rounded-[6px] px-4 py-1 text-[12px] ${isHfull ? "w-full" : null} ${bg} ${color}`}
        >
            {title}
        </div>
    )
}