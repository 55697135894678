import { Link } from "react-router-dom";
import { Tag, ButtonWithBg } from "../../../components/Kits";
import { useTranslation } from "react-i18next";
import CardBlockSkeleton from "../../../components/Skeletons/CardBlock";
import { ProjectCard } from "../../../components/Cards";
import { isUserAccepted } from "../../../utils/checkAcceptanceProjectStatus";

export default function Projects({projects, loadingProject, length, toggleOpenAcceptanceModal, setProject}) {
  const { t } = useTranslation(["common", "dashboard"]);

  return (
    <div>
      <div className="flex items-center justify-between gap-4 mb-8">
        <h2 className="text-[18px] text-black font-ibm-plex-sans font-medium">
          {t("dashboard:recentsprojects")}
        </h2>
        <Link to={"/adm/projects/create-project"}>
          <ButtonWithBg
            title={t("common:createproject")}
            type="button"
            bg={"primary"}
            s
            isSmall={true}
          />
        </Link>
      </div>
      {
        loadingProject ?
        <CardBlockSkeleton isProject={true} length={length} /> :

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {
            projects?.map((item, idx)=>(
              <ProjectCard 
                key={idx}
                item={item}
                toggleOpenAcceptanceModal={toggleOpenAcceptanceModal}
                setProject={setProject}
                // isUserAccepted={isUserAccepted(item, user?.userId)}
              />
            ))
          }
        </div>
      }
    </div>
  );
}
