import { motion } from "framer-motion"

export default function Partners(){
    const partners =  [
        {
            id: 1,
            logo: '/images/home/part1.png'
        },
        {
            id: 2,
            logo: '/images/home/part2.png'
        },
        {
            id: 3,
            logo: '/images/home/part3.png'
        },
        {
            id: 4,
            logo: '/images/home/part4.png'
        },
        {
            id: 5,
            logo: '/images/home/part5.png'
        },
        {
            id: 6,
            logo: '/images/home/part6.png'
        },
    ]

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.15,
          },
        },
      };
    
      const logoVariants = {
        hidden: { x: -100, opacity: 0 },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            stiffness: 120,
            damping: 12,
          },
        },
      };

    return(
        <div className="max-w-[1440px] mx-auto lg:px-[80px] px-[16px] relative">
            <div className="border-b lg:py-[96px] py-[64px]">
                <span className="block text-center text-[16px] leading-[24px] font-[500] text-[#475467] mb-[36px]">
                    {`Join 4,000+ companies already growing`}
                </span>
                <div className="flex items-center justify-center flex-wrap gap-[24px]">
                    {
                        partners.map((item, idx) => (
                            <div key={idx} className="lg:w-[170px]lg:h-[48px] w-[127px] h-[36px] shrink-0">
                                <img 
                                    src={item.logo}
                                    alt="part1"
                                    className="h-full w-full object-contain"
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}