import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
// import { ChevronUpDownIcon } from '@heroicons/react/solid'
import {FiCode} from 'react-icons/fi'
import { countries } from '../../../constantes/countries'

const people = [
  { id: 1, name: 'Wade Cooper' },
  { id: 2, name: 'Arlene Mccoy' },
  { id: 3, name: 'Devon Webb' },
  { id: 4, name: 'Tom Cook' },
  { id: 5, name: 'Tanya Fox' },
  { id: 6, name: 'Hellen Schmidt' },
]

export default function ComboboxSelectCountry({label, setAddressInfo, addressInfo, addressInfos}) {
    const [selected, setSelected] = useState(countries.find(x => x.value === addressInfos?.state))
    const [query, setQuery] = useState('')

    // useEffect(()=>{
    //     if(addressInfos){
    //         setSelected(countries.find(x => x.value === addressInfos.state))
    //     }
    // }, [addressInfos])

    useEffect(()=>{
        console.log('selected country', selected)
        setAddressInfo({...addressInfo, state:selected?.value})
    }, [selected])

    console.log('selected country addressInfo', addressInfo)

    const filteredPeople =
        query === ''
        ? countries
        : countries.filter((country) =>
            country.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
       
        <Combobox value={selected} onChange={setSelected}>
            <div className="relative">
            <span className={`block z-20 bg-white absolute left-4 -top-3 px-2  min-w-[80px] text-[14px]`}>
                {label}
            </span>
            <div className="border bg-white border-black disabled:bg-gray-100 border-opacity-10 rounded-[6px] h-[50px] flex items-center w-full focus:outline-none px-4 text-[14px] text-black placeholder:text-black placeholder:text-opacity-50">
                <Combobox.Input
                    className="w-full border-none"
                    displayValue={(country) => country?.name}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <FiCode
                    className="h-4 w-4 text-gray-400 rotate-90"
                    aria-hidden="true"
                />
                </Combobox.Button>
            </div>
            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
            >
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredPeople.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                        Nothing found.
                    </div>
                ) : (
                    filteredPeople.map((country) => (
                    <Combobox.Option
                        key={country.id}
                        className={({ active }) =>
                        `relative cursor-default select-none py-2 px-4 ${
                            active ? 'bg-secondary-green-500/20 text-gray-900' : 'text-gray-900'
                        }`
                        }
                        value={country}
                    >
                        {({ selected, active }) => (
                        <div className='flex items-center gap-4'>
                            <div className='h-[20px] w-[20px]'>
                                <img 
                                    src={`/images/countries-flag/${country.value}.png`} 
                                    alt='flag'
                                    className='h-full w-full object-contain'
                                />
                            </div>
                            <span
                            className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                            }`}
                            >
                            {country.name}
                            </span>
                        </div>
                        )}
                    </Combobox.Option>
                    ))
                )}
                </Combobox.Options>
            </Transition>
            </div>
        </Combobox>
    )
}
