import Base from "../../../layout/adm/base";
import { FormsList } from "../../../section/admin/forms";
import { All } from "../../../section/admin/Database";
import { Tab } from "@headlessui/react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

export default function AllForms(){
    const { t } = useTranslation(["common", "dataSource", "form"]);

    return(
        <Base>
            <Tab.Group>
                <Tab.List className="inline-flex bg-white p-2 gap-2 rounded-[6px]">
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                            "focus:outline-none",
                            selected
                                ? "bg-primary-blue-500 text-white"
                                : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                            )
                        }
                        >
                        {t("common:forms")}
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            classNames(
                            "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                            "focus:outline-none",
                            selected
                                ? "bg-primary-blue-500 text-white"
                                : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                            )
                        }
                        >
                        {t("dataSource:datasource")}
                    </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2 pt-[30px]">
                    <Tab.Panel className={classNames("focus:outline-none")}>
                        <FormsList />
                    </Tab.Panel>
                    <Tab.Panel className={classNames("focus:outline-none")}>
                        <All />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </Base>
    )
}