import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function convertToXLSX(datas, headers, filename) {
  console.log("datas export excel", datas)
  const workbook = XLSX.utils.book_new();
  const worksheetData = [];

  worksheetData.push(headers.map(header => header?.label));

  datas.forEach(row => {
    const rowData = headers.map(header => {
      const value = row[header.key];
      if (Array.isArray(value)) {
        // Si la valeur est un tableau d'objets, convertissez-le en chaîne JSON
        return value.map(obj => JSON.stringify(obj)).join(', ');
      }
      return value ? value : '';
    });
    worksheetData.push(rowData);
  });

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

  const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(data, `${filename}.xlsx`);
}

// export default function convertToXLSX(datas,headers, filename) {
//   const workbook = XLSX.utils.book_new();
//   const worksheetData = [];
//   worksheetData.push(headers.map(header => header?.label));
//   datas.forEach(row => {
//     const rowData = headers.map(header => row[header.key] ? row[header.key] : '');
//     worksheetData.push(rowData);
//   });

//   const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

//   console.log('worksheet datas', datas)
//   console.log('worksheet ', worksheet)
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');
  
//   const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
//   const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   saveAs(data, `${filename}.xlsx`);
// }