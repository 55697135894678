import { Link } from "react-router-dom";
import { Tag, ButtonWithBg } from "../../../components/Kits";
import { useTranslation } from "react-i18next";
import CardBlockSkeleton from "../../../components/Skeletons/CardBlock";
import { FormCard } from "../../../components/Cards";

export default function Forms({forms, loadingForms, length}){
    const { t } = useTranslation(["common", "dashboard"]);

    return(
        <div>
            <div className="flex items-center justify-between gap-4 mb-8">
                <h2 className="text-[18px] text-black font-ibm-plex-sans font-medium">
                    {t("dashboard:recentsforms")}
                </h2>
                <Link to={'/adm/forms/create-form'}>
                    <ButtonWithBg 
                        title={t('common:createform')}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                    />
                </Link>
            </div>
            {   loadingForms ?
                <CardBlockSkeleton isProject={false} length={length} /> :
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {
                        forms?.map((item, idx) => (
                            <FormCard 
                                key={idx}
                                item={item}
                            />
                        ))
                    }
                </div>
            }
        </div>
    )
}