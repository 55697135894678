import { Link, NavLink } from "react-router-dom";
import { Menu, Transition, Disclosure } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineSetting, AiOutlineInfoCircle, AiOutlineClose } from "react-icons/ai";
import { HiOutlineLogout, HiOutlineMenuAlt1 } from "react-icons/hi";
import { FaChevronDown } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { authLogic } from "../../utils";
import PageLoader from "../../loaders/PageLoader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Modal } from "../../components/Modals";
import { SelectField, ButtonWithBg } from "../../components/Kits";
import { appSettingActions } from "../../redux/actions";
import { useToaster } from "../../contexts/toaster";

export default function Base({ children, withoutPadd }) {
  const dispatch = useDispatch()
  const toaster = useToaster()
  const [isOpen, setIsOpen] = useState(false);
  const [isRotate, setIsRotate] = useState(false);
  const loading = useSelector((state) => state.UI.loading)
  const user = useSelector((state) => state.user);
  const languageOptions = useSelector((state) => state.languageOptions)
  const userLang = useSelector((state) => state.userLang)
  let router = window.location.pathname;
  let [isOpenLang, setIsOpenLang] = useState(false)
  const [isUpdateLang, setIsUpdateLang] = useState(false)
  const [userApplang, setUserApplang] = useState({
    lang: userLang?.lang,
  })

  const toggleModal = ()=>{
    setIsOpenLang(!isOpenLang)
}

  const { i18n, t } = useTranslation(["common"]);

	useEffect(() => {
		// if (localStorage.getItem("i18nextLng")?.length > 2) {
			i18next.changeLanguage(userLang?.lang);
		// }
	}, [userLang]);

  const handleLanguageChange = (e) => {
		i18n.changeLanguage(e.target.value);
    setIsOpenLang(!isOpenLang)
	};

  const handleUpdatedAppLang = () => {
    dispatch(appSettingActions.updateAppLang(userApplang, toaster, setIsUpdateLang, toggleModal));
}


  const navs = [
    {
      title: t("surveyconsole"),
      href: "/adm/survey-console",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.4312 13.291C12.6059 13.291 13.562 14.1959 13.562 15.3082V18.1689C13.562 18.4079 13.7629 18.5995 14.0205 18.6051H15.88C17.3454 18.6051 18.5366 17.4835 18.5366 16.1052V7.99183C18.5298 7.51753 18.2927 7.07113 17.8859 6.77445L11.4537 1.88448C10.5902 1.23255 9.38244 1.23255 8.5161 1.88634L2.1278 6.77259C1.70537 7.07857 1.46829 7.52497 1.46341 8.00764V16.1052C1.46341 17.4835 2.65463 18.6051 4.12 18.6051H5.99707C6.26146 18.6051 6.4761 18.4051 6.4761 18.1596C6.4761 18.1056 6.48293 18.0517 6.49463 18.0005V15.3082C6.49463 14.2024 7.44488 13.2984 8.61073 13.291H11.4312ZM15.88 20.0001H14.0029C12.9278 19.9759 12.0985 19.1714 12.0985 18.1689V15.3082C12.0985 14.965 11.799 14.686 11.4312 14.686H8.61561C8.25561 14.6879 7.95805 14.9678 7.95805 15.3082V18.1596C7.95805 18.2293 7.94829 18.2963 7.92781 18.3595C7.82244 19.2802 6.99707 20.0001 5.99707 20.0001H4.12C1.8478 20.0001 0 18.2526 0 16.1052V8.00113C0.0097561 7.07671 0.456585 6.2304 1.22829 5.67333L7.6039 0.795448C9.0078 -0.264758 10.9639 -0.264758 12.3649 0.793588L18.7863 5.67612C19.5405 6.22389 19.9873 7.06834 20 7.9816V16.1052C20 18.2526 18.1522 20.0001 15.88 20.0001Z"
            fill="#1E1E1E"
            fill-opacity="0.5"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.4312 13.291C12.6059 13.291 13.562 14.1959 13.562 15.3082V18.1689C13.562 18.4079 13.7629 18.5995 14.0205 18.6051H15.88C17.3454 18.6051 18.5366 17.4835 18.5366 16.1052V7.99183C18.5298 7.51753 18.2927 7.07113 17.8859 6.77445L11.4537 1.88448C10.5902 1.23255 9.38244 1.23255 8.5161 1.88634L2.1278 6.77259C1.70537 7.07857 1.46829 7.52497 1.46341 8.00764V16.1052C1.46341 17.4835 2.65463 18.6051 4.12 18.6051H5.99707C6.26146 18.6051 6.4761 18.4051 6.4761 18.1596C6.4761 18.1056 6.48293 18.0517 6.49463 18.0005V15.3082C6.49463 14.2024 7.44488 13.2984 8.61073 13.291H11.4312ZM15.88 20.0001H14.0029C12.9278 19.9759 12.0985 19.1714 12.0985 18.1689V15.3082C12.0985 14.965 11.799 14.686 11.4312 14.686H8.61561C8.25561 14.6879 7.95805 14.9678 7.95805 15.3082V18.1596C7.95805 18.2293 7.94829 18.2963 7.92781 18.3595C7.82244 19.2802 6.99707 20.0001 5.99707 20.0001H4.12C1.8478 20.0001 0 18.2526 0 16.1052V8.00113C0.0097561 7.07671 0.456585 6.2304 1.22829 5.67333L7.6039 0.795448C9.0078 -0.264758 10.9639 -0.264758 12.3649 0.793588L18.7863 5.67612C19.5405 6.22389 19.9873 7.06834 20 7.9816V16.1052C20 18.2526 18.1522 20.0001 15.88 20.0001Z"
            fill="#0B449A"
          />
        </svg>
      ),
    },
    {
      title: t("projects"),
      href: "/adm/projects/all",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.38561 0C8.30459 0.000967633 9.18007 0.440273 9.72895 1.17471L10.6093 2.34748C10.8885 2.71905 11.3321 2.94064 11.7959 2.9416H14.5296C18.2616 2.9416 20 4.85655 20 8.96802L19.9729 13.7743C19.972 17.6138 17.589 20 13.7527 20H6.23279C2.38875 20 0 17.6129 0 13.7713V6.22478C0 2.09396 1.83505 0 5.45296 0H7.38561ZM7.38465 1.45145H5.45296C2.64676 1.45145 1.44949 2.87967 1.44949 6.22478V13.7713C1.44949 16.8523 3.14828 18.5486 6.23279 18.5486H13.7527C16.8295 18.5486 18.5235 16.8523 18.5235 13.7713V13.7684L18.5505 8.96415C18.5505 5.67517 17.4238 4.39305 14.5296 4.39305H11.7949C10.8779 4.39208 10.0024 3.95375 9.45161 3.22028L8.56936 2.04558C8.29202 1.67304 7.84848 1.45242 7.38465 1.45145ZM14.2206 11.8175C14.6207 11.8175 14.9454 12.1426 14.9454 12.5432C14.9454 12.9438 14.6207 13.269 14.2206 13.269H5.77978C5.37972 13.269 5.05503 12.9438 5.05503 12.5432C5.05503 12.1426 5.37972 11.8175 5.77978 11.8175H14.2206Z"
            fill="#1E1E1E"
            fill-opacity="0.5"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.38561 0C8.30459 0.000967633 9.18007 0.440273 9.72895 1.17471L10.6093 2.34748C10.8885 2.71905 11.3321 2.94064 11.7959 2.9416H14.5296C18.2616 2.9416 20 4.85655 20 8.96802L19.9729 13.7743C19.972 17.6138 17.589 20 13.7527 20H6.23279C2.38875 20 0 17.6129 0 13.7713V6.22478C0 2.09396 1.83505 0 5.45296 0H7.38561ZM7.38465 1.45145H5.45296C2.64676 1.45145 1.44949 2.87967 1.44949 6.22478V13.7713C1.44949 16.8523 3.14828 18.5486 6.23279 18.5486H13.7527C16.8295 18.5486 18.5235 16.8523 18.5235 13.7713V13.7684L18.5505 8.96415C18.5505 5.67517 17.4238 4.39305 14.5296 4.39305H11.7949C10.8779 4.39208 10.0024 3.95375 9.45161 3.22028L8.56936 2.04558C8.29202 1.67304 7.84848 1.45242 7.38465 1.45145ZM14.2206 11.8175C14.6207 11.8175 14.9454 12.1426 14.9454 12.5432C14.9454 12.9438 14.6207 13.269 14.2206 13.269H5.77978C5.37972 13.269 5.05503 12.9438 5.05503 12.5432C5.05503 12.1426 5.37972 11.8175 5.77978 11.8175H14.2206Z"
            fill="#0B449A"
          />
        </svg>
      ),
    },
    {
      title: t("forms"),
      href: "/adm/forms/all",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8711 0C12.9215 0 12.9709 0.00364063 13.019 0.0106319L13.1795 0.0114772C13.4188 0.0114772 13.6475 0.0950396 13.814 0.243036L19.7546 5.55579C19.9117 5.69573 19.9999 5.884 19.9999 6.07831V15.3064C20.0208 17.8324 17.729 19.8963 14.7816 19.999L5.37642 20H5.24858C2.37512 19.9443 0.0705625 17.9494 0.000354007 15.5069L0 4.52082C0.0680262 2.02301 2.47123 0.0114772 5.36 0.0114772L12.7233 0.0106319C12.7714 0.00364063 12.8208 0 12.8711 0ZM11.9914 1.52124L5.36234 1.52164C3.4189 1.52164 1.80504 2.87273 1.7593 4.53895V15.3064C1.7159 17.031 3.29927 18.4516 5.28845 18.4898H14.7464C16.704 18.4204 18.2545 17.024 18.2405 15.3125L18.2404 7.03031L15.8831 7.03132C13.7367 7.02629 11.9915 5.52418 11.9915 3.68379L11.9914 1.52124ZM12.6521 12.6935C13.1376 12.6935 13.5317 13.0318 13.5317 13.4486C13.5317 13.8654 13.1376 14.2037 12.6521 14.2037H6.31858C5.83301 14.2037 5.43893 13.8654 5.43893 13.4486C5.43893 13.0318 5.83301 12.6935 6.31858 12.6935H12.6521ZM10.2537 8.9161C10.7392 8.9161 11.1333 9.25438 11.1333 9.67119C11.1333 10.088 10.7392 10.4263 10.2537 10.4263H6.31753C5.83196 10.4263 5.43788 10.088 5.43788 9.67119C5.43788 9.25438 5.83196 8.9161 6.31753 8.9161H10.2537ZM13.7507 2.36794L13.7508 3.68379C13.7508 4.69459 14.709 5.51814 15.8854 5.52116L17.2763 5.52015L13.7507 2.36794Z"
            fill="#1E1E1E"
            fill-opacity="0.5"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8711 0C12.9215 0 12.9709 0.00364063 13.019 0.0106319L13.1795 0.0114772C13.4188 0.0114772 13.6475 0.0950396 13.814 0.243036L19.7546 5.55579C19.9117 5.69573 19.9999 5.884 19.9999 6.07831V15.3064C20.0208 17.8324 17.729 19.8963 14.7816 19.999L5.37642 20H5.24858C2.37512 19.9443 0.0705625 17.9494 0.000354007 15.5069L0 4.52082C0.0680262 2.02301 2.47123 0.0114772 5.36 0.0114772L12.7233 0.0106319C12.7714 0.00364063 12.8208 0 12.8711 0ZM11.9914 1.52124L5.36234 1.52164C3.4189 1.52164 1.80504 2.87273 1.7593 4.53895V15.3064C1.7159 17.031 3.29927 18.4516 5.28845 18.4898H14.7464C16.704 18.4204 18.2545 17.024 18.2405 15.3125L18.2404 7.03031L15.8831 7.03132C13.7367 7.02629 11.9915 5.52418 11.9915 3.68379L11.9914 1.52124ZM12.6521 12.6935C13.1376 12.6935 13.5317 13.0318 13.5317 13.4486C13.5317 13.8654 13.1376 14.2037 12.6521 14.2037H6.31858C5.83301 14.2037 5.43893 13.8654 5.43893 13.4486C5.43893 13.0318 5.83301 12.6935 6.31858 12.6935H12.6521ZM10.2537 8.9161C10.7392 8.9161 11.1333 9.25438 11.1333 9.67119C11.1333 10.088 10.7392 10.4263 10.2537 10.4263H6.31753C5.83196 10.4263 5.43788 10.088 5.43788 9.67119C5.43788 9.25438 5.83196 8.9161 6.31753 8.9161H10.2537ZM13.7507 2.36794L13.7508 3.68379C13.7508 4.69459 14.709 5.51814 15.8854 5.52116L17.2763 5.52015L13.7507 2.36794Z"
            fill="#0B449A"
          />
        </svg>
      ),
    },
    {
      title: t("datahub"),
      href: "/adm/data-hub/all",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.213 0C17.6747 0 20 2.16273 20 5.38122V14.6188C20 17.8654 17.7462 19.9769 14.2582 19.997L5.78805 20C2.32645 20 0 17.8373 0 14.6188V5.38122C0 2.1336 2.25379 0.023104 5.74181 0.00401808L14.2119 0H14.213ZM14.213 1.50678L5.74732 1.5108C3.18415 1.52486 1.65153 2.97137 1.65153 5.38122V14.6188C1.65153 17.0447 3.19846 18.4932 5.78695 18.4932L14.2527 18.4902C16.8159 18.4761 18.3485 17.0276 18.3485 14.6188V5.38122C18.3485 2.9553 16.8026 1.50678 14.213 1.50678ZM14.0007 13.5346C14.4565 13.5346 14.8264 13.8721 14.8264 14.288C14.8264 14.7039 14.4565 15.0414 14.0007 15.0414H6.05131C5.59549 15.0414 5.22554 14.7039 5.22554 14.288C5.22554 13.8721 5.59549 13.5346 6.05131 13.5346H14.0007ZM14.0007 9.32918C14.4565 9.32918 14.8264 9.6667 14.8264 10.0826C14.8264 10.4984 14.4565 10.836 14.0007 10.836H6.05131C5.59549 10.836 5.22554 10.4984 5.22554 10.0826C5.22554 9.6667 5.59549 9.32918 6.05131 9.32918H14.0007ZM9.08428 5.1335C9.54011 5.1335 9.91005 5.47102 9.91005 5.88689C9.91005 6.30276 9.54011 6.64028 9.08428 6.64028H6.05098C5.59516 6.64028 5.22521 6.30276 5.22521 5.88689C5.22521 5.47102 5.59516 5.1335 6.05098 5.1335H9.08428Z"
            fill="#1E1E1E"
            fill-opacity="0.5"
          />
        </svg>
      ),
      activeIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.213 0C17.6747 0 20 2.16273 20 5.38122V14.6188C20 17.8654 17.7462 19.9769 14.2582 19.997L5.78805 20C2.32645 20 0 17.8373 0 14.6188V5.38122C0 2.1336 2.25379 0.023104 5.74181 0.00401808L14.2119 0H14.213ZM14.213 1.50678L5.74732 1.5108C3.18415 1.52486 1.65153 2.97137 1.65153 5.38122V14.6188C1.65153 17.0447 3.19846 18.4932 5.78695 18.4932L14.2527 18.4902C16.8159 18.4761 18.3485 17.0276 18.3485 14.6188V5.38122C18.3485 2.9553 16.8026 1.50678 14.213 1.50678ZM14.0007 13.5346C14.4565 13.5346 14.8264 13.8721 14.8264 14.288C14.8264 14.7039 14.4565 15.0414 14.0007 15.0414H6.05131C5.59549 15.0414 5.22554 14.7039 5.22554 14.288C5.22554 13.8721 5.59549 13.5346 6.05131 13.5346H14.0007ZM14.0007 9.32918C14.4565 9.32918 14.8264 9.6667 14.8264 10.0826C14.8264 10.4984 14.4565 10.836 14.0007 10.836H6.05131C5.59549 10.836 5.22554 10.4984 5.22554 10.0826C5.22554 9.6667 5.59549 9.32918 6.05131 9.32918H14.0007ZM9.08428 5.1335C9.54011 5.1335 9.91005 5.47102 9.91005 5.88689C9.91005 6.30276 9.54011 6.64028 9.08428 6.64028H6.05098C5.59516 6.64028 5.22521 6.30276 5.22521 5.88689C5.22521 5.47102 5.59516 5.1335 6.05098 5.1335H9.08428Z"
            fill="#0B449A"
          />
        </svg>
      ),
    },
    // {
    //   title: t("Database"),
    //   href: "/adm/databse_source/all",
    //   icon: (
    //     <svg
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         clip-rule="evenodd"
    //         d="M14.213 0C17.6747 0 20 2.16273 20 5.38122V14.6188C20 17.8654 17.7462 19.9769 14.2582 19.997L5.78805 20C2.32645 20 0 17.8373 0 14.6188V5.38122C0 2.1336 2.25379 0.023104 5.74181 0.00401808L14.2119 0H14.213ZM14.213 1.50678L5.74732 1.5108C3.18415 1.52486 1.65153 2.97137 1.65153 5.38122V14.6188C1.65153 17.0447 3.19846 18.4932 5.78695 18.4932L14.2527 18.4902C16.8159 18.4761 18.3485 17.0276 18.3485 14.6188V5.38122C18.3485 2.9553 16.8026 1.50678 14.213 1.50678ZM14.0007 13.5346C14.4565 13.5346 14.8264 13.8721 14.8264 14.288C14.8264 14.7039 14.4565 15.0414 14.0007 15.0414H6.05131C5.59549 15.0414 5.22554 14.7039 5.22554 14.288C5.22554 13.8721 5.59549 13.5346 6.05131 13.5346H14.0007ZM14.0007 9.32918C14.4565 9.32918 14.8264 9.6667 14.8264 10.0826C14.8264 10.4984 14.4565 10.836 14.0007 10.836H6.05131C5.59549 10.836 5.22554 10.4984 5.22554 10.0826C5.22554 9.6667 5.59549 9.32918 6.05131 9.32918H14.0007ZM9.08428 5.1335C9.54011 5.1335 9.91005 5.47102 9.91005 5.88689C9.91005 6.30276 9.54011 6.64028 9.08428 6.64028H6.05098C5.59516 6.64028 5.22521 6.30276 5.22521 5.88689C5.22521 5.47102 5.59516 5.1335 6.05098 5.1335H9.08428Z"
    //         fill="#1E1E1E"
    //         fill-opacity="0.5"
    //       />
    //     </svg>
    //   ),
    //   activeIcon: (
    //     <svg
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         clip-rule="evenodd"
    //         d="M14.213 0C17.6747 0 20 2.16273 20 5.38122V14.6188C20 17.8654 17.7462 19.9769 14.2582 19.997L5.78805 20C2.32645 20 0 17.8373 0 14.6188V5.38122C0 2.1336 2.25379 0.023104 5.74181 0.00401808L14.2119 0H14.213ZM14.213 1.50678L5.74732 1.5108C3.18415 1.52486 1.65153 2.97137 1.65153 5.38122V14.6188C1.65153 17.0447 3.19846 18.4932 5.78695 18.4932L14.2527 18.4902C16.8159 18.4761 18.3485 17.0276 18.3485 14.6188V5.38122C18.3485 2.9553 16.8026 1.50678 14.213 1.50678ZM14.0007 13.5346C14.4565 13.5346 14.8264 13.8721 14.8264 14.288C14.8264 14.7039 14.4565 15.0414 14.0007 15.0414H6.05131C5.59549 15.0414 5.22554 14.7039 5.22554 14.288C5.22554 13.8721 5.59549 13.5346 6.05131 13.5346H14.0007ZM14.0007 9.32918C14.4565 9.32918 14.8264 9.6667 14.8264 10.0826C14.8264 10.4984 14.4565 10.836 14.0007 10.836H6.05131C5.59549 10.836 5.22554 10.4984 5.22554 10.0826C5.22554 9.6667 5.59549 9.32918 6.05131 9.32918H14.0007ZM9.08428 5.1335C9.54011 5.1335 9.91005 5.47102 9.91005 5.88689C9.91005 6.30276 9.54011 6.64028 9.08428 6.64028H6.05098C5.59516 6.64028 5.22521 6.30276 5.22521 5.88689C5.22521 5.47102 5.59516 5.1335 6.05098 5.1335H9.08428Z"
    //         fill="#0B449A"
    //       />
    //     </svg>
    //   ),
    // },
  ];

  useEffect(()=>{
    if(withoutPadd){
      setIsOpen(true)
    } else{
      setIsOpen(false)
    }
  }, [withoutPadd])


  return (
    <div className="h-screen flex">
      <div
        className={`w-[300px] z-[60] h-full px-[30px] py-[20px] flex flex-col justify-between bg-white xl:!relative !fixed top-0 transition-all duration-200 ease-out group ${
          isOpen ? "left-0 xl:w-[100px]" : "-left-full xl:left-0 xl:w-[300px]"
        }`}
      >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="xl:hidden bg-[#fff] border cursor-pointer absolute -right-4 rounded-full flex items-center justify-center h-[40px] w-[40px]"
      >
        <HiOutlineMenuAlt1  className='text-[20px] text-primary-blue-500 rotate-180' />
      </div>
        <div>
          <Link to='/adm/survey-console'>
            <div className="relative flex items-center gap-[0px] mb-6">
                <div className="relative flex h-[40px] w-[40px] shrink-0">
                  <img 
                    src={'/logo/terros-logomark-green.png'}
                    alt='logo'
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className={`${isOpen ? "block xl:hidden" : "block"}`}>
                    <span className="block text-[25px] font-ibm-plex-sans text-[#02250e] whitespace-nowrap ">Terros</span>
                </div>
            </div>
          </Link>
          <ul className="flex flex-col gap-[10px]">
            {navs?.map((x, i) => (
              <li key={i}>
                <Link to={x.href}>
                  <a
                    className={`flex items-center whitespace-nowrap gap-[10px] bg-white bg-opacity-10 py-[10px] px-[10px] rounded-[6px] ${
                      router?.includes(x?.href.split("/")[2])
                        ? "bg-primary-blue-500"
                        : ""
                    }`}
                  >
                    {router?.includes(x?.href.split("/")[2])
                      ? x.activeIcon
                      : x.icon}
                    <span
                      className={`text-[14px] ${
                        router?.includes(x?.href.split("/")[2])
                          ? "text-primary-blue-500"
                          : "text-black text-opacity-50"
                      } ${isOpen ? "block xl:hidden" : "block"}`
                    }
                    >
                      {x.title}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
            <li>
            <Disclosure>
              {({ open }) => (
                <div className="rounded-lg overflow-hidden">
                  <Disclosure.Button className={`flex w-full  items-center justify-between ${(router?.includes('billing-account') || router?.includes('address-billing/all') || router?.includes('payments-method/all') || open) ? 'bg-primary-blue-500' : ''} bg-opacity-10 px-[10px] py-[10px] text-left text-sm text-black text-opacity-50 font-medium focus:outline-none`}>
                    <div className="flex items-center gap-[10px]">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8267 0.255127C18.0313 0.255127 20.638 3.06116 20.638 6.51184V13.9984C20.638 17.4481 18.0313 20.2551 14.8267 20.2551H6.4503C3.24569 20.2551 0.638 17.4481 0.638 13.9984V6.51184C0.638 3.06116 3.24569 0.255127 6.4503 0.255127H14.8267ZM14.8267 1.81983H6.4503C4.04708 1.81983 2.09156 3.92487 2.09156 6.51184V13.9984C2.09156 16.5854 4.04708 18.6904 6.4503 18.6904H14.8267C17.2299 18.6904 19.1844 16.5854 19.1844 13.9984V13.7063L15.9886 13.707C14.1504 13.707 12.6542 12.0974 12.6532 10.1196C12.6532 8.13976 14.1494 6.52916 15.9886 6.52811L19.1844 6.52749V6.51184C19.1844 3.92487 17.2299 1.81983 14.8267 1.81983ZM19.1844 8.09219L15.9886 8.09281C14.9508 8.09386 14.1068 9.00243 14.1068 10.1186C14.1068 11.2337 14.9518 12.1423 15.9886 12.1423L19.1844 12.1416V8.09219ZM16.4321 9.27156C16.8333 9.27156 17.1589 9.62205 17.1589 10.0539C17.1589 10.4858 16.8333 10.8363 16.4321 10.8363H16.1297C15.7286 10.8363 15.403 10.4858 15.403 10.0539C15.403 9.62205 15.7286 9.27156 16.1297 9.27156H16.4321ZM10.9922 4.98897C11.3934 4.98897 11.719 5.33946 11.719 5.77132C11.719 6.20318 11.3934 6.55367 10.9922 6.55367H5.76034C5.35916 6.55367 5.03356 6.20318 5.03356 5.77132C5.03356 5.33946 5.35916 4.98897 5.76034 4.98897H10.9922Z" fill="#1E1E1E" fill-opacity="0.5"/>
                      </svg>
                      <span>Billing</span>
                    </div>
                    <FaChevronDown
                      className={`${
                        open ? '-rotate-0 transform' : ''
                      } text-[12px] -rotate-90 transition-transform duration-300`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm bg-tertiary-gray-500">
                    <ul>
                      <li className={`hover:text-primary-blue-500 mb-4 w-fit ${router?.includes('billing-account/all') ? 'text-primary-blue-500' : 'text-black text-opacity-50'}`}>
                        <Link to='/adm/billing-account/all'>
                          <span>Billing Account</span>
                        </Link>
                      </li>
                      <li className={`hover:text-primary-blue-500 mb-4 w-fit ${router?.includes('address-billing/all') ? 'text-primary-blue-500' : 'text-black text-opacity-50'}`}>
                        <Link to='/adm/address-billing/all'>
                          <span>Billing Address</span>
                        </Link>
                      </li>
                      <li className={`hover:text-primary-blue-500 mb-4 w-fit ${router?.includes('payments-method/all') ? 'text-primary-blue-500' : 'text-black text-opacity-50'}`}>
                        <Link to='/adm/payments-method/all'>
                          <span>Payments method</span>
                        </Link>
                      </li>
                      {/* <li className={`hover:text-primary-blue-500 mb-4 w-fit ${router?.includes('billing-account/address') ? 'text-primary-blue-500' : 'text-black text-opacity-50'}`}>
                        <Link to='/adm/billing-account/all'>
                          <span>Invoices - Payments</span>
                        </Link>
                      </li> */}
                    </ul>
                  </Disclosure.Panel>
                </div>
              )}
          </Disclosure>
            </li>
          </ul>
        </div>
        <div>
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button
                onClick={() => setIsRotate(!isRotate)}
                disabled={isOpen ? true : false}
                className={`flex text-left items-center justify-between flex-nowrap gap-3 w-full h-[60px] group-hover:flex  ${isOpen ? "w-full xl:w-[50px]" : "flex"} rounded-md bg-secondary-green-500 px-2 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <div className="flex items-center gap-x-2">
                  <div className="h-[30px] w-[30px] flex-shrink-0 bg-white rounded-full overflow-hidden">
                    <img
                      src="/images/avatar.png"
                      alt="profil"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <h4 className={`text-[14px] limit-1  ${isOpen ? "block xl:hidden" : "block"}`}>
                    {user && user?.firstName + " " + user?.lastName}
                  </h4>
                </div>
                <FaChevronDown
                  className={`text-white text-[16px] ${isOpen ? "block xl:hidden" : "block"} font-bold transition-all ease-out duration-200 ${
                    isRotate ? "-rotate-180" : "rotate-0"
                  }`}
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute bottom-[120%] right-0 mt-2 w-full origin-bottom-right divide-y divide-dashed divide-black divide-opacity-10 rounded-md bg-white border border-black border-opacity-10 focus:outline-none">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link to={'/adm/account/profil'}>
                        <button
                          className={`${
                            active ? "" : "text-black text-opacity-50"
                          } group flex w-full items-center gap-[10px] rounded-md px-2 py-2 text-[14px] `}
                        >
                          <AiOutlineSetting className="text-[23px] " />
                          <span className="">
                            {t("Manageaccount")}
                          </span>
                        </button>
                      </Link>
                      
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                          onClick={() => toggleModal()}
                          className={`${
                            active ? "" : "text-black text-opacity-50"
                          } group flex w-full items-center gap-[10px] rounded-md px-2 py-2 text-[14px] `}
                        >
                          <div className='h-[20px] w-[20px] overflow-hidden rounded-full'>
                            <img 
                              src={t("langsrc")}
                              className='h-full w-full object-cover'
                            />
                          </div>
                          <span className="">
                            {t("languagesetting")}
                          </span>
                        </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "" : "text-black text-opacity-50"
                        } group flex w-full items-center gap-[10px] rounded-md px-2 py-2 text-[14px] `}
                      >
                        <AiOutlineInfoCircle className="text-[23px] rotate-[180deg] " />
                        <span className="">
                          {t("Help")}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => authLogic.logout()}
                        className={`${
                          active ? "" : "text-red-500"
                        } group flex w-full items-center gap-[10px] rounded-md px-2 py-2 text-[14px]`}
                      >
                        <HiOutlineLogout
                          className="text-[25px] group-hover:text-red-500"
                        />
                        <span className="group-hover:text-red-500">
                          {t("logout")}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <div className={`${withoutPadd ? "px-0" : "p-[20px] bg-tertiary-gray-500 overflow-x-hidden"} flex-1  relative`}>
        <div className={`flex items-center justify-between ${withoutPadd ? "p-[10px] absolute left-0 top-[10px] z-50" : "" }`}>
          <div className="flex items-center gap-4">
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="cursor-pointer bg-white rounded-full flex items-center justify-center h-[40px] w-[40px] mb-[20px]"
            >
              <HiOutlineMenuAlt1  className='text-[20px] text-primary-blue-500' />
            </div>
          </div>
        </div>
        {children}
      </div>

      <Modal show={isOpenLang} toggleModal={toggleModal}>
            <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                <h4>
                  {t("languagesetting")}
                </h4>
                <div 
                    onClick={()=>{toggleModal()}}
                    className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                    <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                </div>
            </div>
            <div className="p-6 min-h-[150px] overflow-x-hidden">
            
              <SelectField 
                label={t("chooselanguage")}
                options= {[
                  ...languageOptions?.map(x => (
                      {
                          value: x?.value,
                          name: x?.name
                      }
                  ))
                ]}
                value={userApplang.lang}
                onChange={(e) => {
                  setUserApplang({...userApplang, lang: e.target.value})
                }}
                // options={[
                //   {
                //     value: "en",
                //     name: t('lv1')
                //   },
                //   {
                //     value: "fr",
                //     name: t('lv2')
                //   }
                // ]} 
                // label={t("chooselanguage")}
                // value={localStorage.getItem("i18nextLng")}
                // onChange={handleLanguageChange}
              />
            </div>
            <div className='flex justify-end mt-[30px] p-6 pt-0'>
                <ButtonWithBg 
                    title={isUpdateLang ? t("common:inprogress") : t("manageAccount:Saveupdate")}
                    type='submit'
                    bg={'primary'}
                    isSmall={true}
                    onClick={() => handleUpdatedAppLang()}
                />
            </div>
        </Modal>
    </div>
  );
}
