import Base from "../../../layout/adm/base";
import { SuiteConf } from "../../../section/admin/projects";

export default function ConfProject(){

    return(
        <Base>
            <SuiteConf />
        </Base>
    )
}