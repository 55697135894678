import { Link } from "react-router-dom";
import { InputSearch, Heading, ButtonWithBg, Tag } from "../../../components/Kits";
import { Modal } from "../../../components/Modals";
import { useDispatch, useSelector } from "react-redux";
import { useState, Fragment } from "react";
import { AiOutlineClose } from 'react-icons/ai'
import { Menu, Transition} from '@headlessui/react'
import { BsThreeDotsVertical } from "react-icons/bs"
import { databaseSourceActions } from "../../../redux/actions";
import { useToaster } from "../../../contexts/toaster";
import { useTranslation } from "react-i18next";


export default function All(){
    const { t } = useTranslation(["common", "dataSource"]);
    const dispatch = useDispatch()
    const toaster = useToaster()
    const databaseSources = useSelector((state) => state.databaseSources);
    const [isLoading, setIsLoading] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }
    const [getId, setGetId] = useState(null)

    const handleDeleteFormDataSources = () => {
        dispatch(databaseSourceActions.deleteDataBase(getId, toaster, setIsLoading, null, toggleModal));
    };

    return(
        <>
            <div className="flex items-center justify-between mb-[50px]">
                <div className="max-w-[40%]">
                    <Heading title={t("dataSource:datasource")} mb={"10px"} />
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <div className="w-[300px]">
                        <InputSearch 
                            placeholder="Search database..."
                        />
                    </div>
                </div>
                <Link to={'/adm/databse_source/add'}>
                    <ButtonWithBg 
                        title={t("dataSource:newdatasource")}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                    />
                </Link>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-[20px]">
                {
                    databaseSources.map((item, idx) =>(
                        <div key={idx} className="relative bg-white rounded-[6px] p-[15px] h-full cursor-pointer">
                            <Link  to='/adm/databse_source/details' state={{ item: item }}>
                                <div className=" h-full flex flex-col justify-between">
                                    { 
                                        item?.number && 
                                        <span className="text-[12px] text-primary-blue-500 font-bold block mb-1">{`#ID: ${item?.number}`}</span>
                                    }
                                    <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4 max-w-[80%]">
                                        {item?.name}
                                    </h4>
                                    {
                                        typeof item?.description === 'string' && 
                                        <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4">
                                            {item?.description}
                                        </p>
                                    }
                                    <div className="flex items-center justify-between gap-4">
                                        <div className="flex items-center gap-2">
                                            &nbsp;
                                        </div>
                                        <Tag 
                                            title={'Private'}
                                            color={'text-[#6BCB77]'}
                                            bg={'bg-[#f0faf1]'}
                                        />
                                    </div>
                                </div>
                            </Link>
                            <div onClick={(e) => e.stopPropagation()} className='absolute top-2 right-2'>
                                <Menu as="div" className="relative">
                                    <div>
                                        <Menu.Button className="h-[30px] w-[30px] bg-gray-100  rounded-full  focus:outline-none flex items-center justify-center">
                                            <BsThreeDotsVertical  className='text-[14px]'/>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                    <Menu.Items className="absolute z-50 overflow-hidden right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    setGetId(item?.id)
                                                    toggleModal()
                                                }}
                                                className={`${
                                                active ? 'bg-neutral-variant-xl-400 ' : ''
                                                } group flex w-full rounded-md items-center px-2 py-2 text-[14px] text-neutral-variant-l-700 hover:bg-red-100 hover:text-red-500 transition-all ease-out duration-200`}
                                            >
                                            
                                                {t("common:delete")}
                                            </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    )
                )}
            </div>

            <Modal show={isOpen} toggleModal={toggleModal} resetOverflow>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {"Delete"}
                    </h4>
                    <div 
                        onClick={()=>{
                            toggleModal() 
                            setGetId(null)
                        }}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                <div className="max-h-[400px] overflow-x-hidden">
                    <div className="p-6">
                        <h4 className='text-[16px] font-bold mb-4'>
                            {`Attention this action is irreversible`}
                        </h4>
                        <p className='text-[14px] text-black text-opacity-70'>
                            {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}
                        </p>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                        <ButtonWithBg 
                            title={isLoading ? 'Deleting...' : 'Delete'}
                            disabled={isLoading}
                            type='submit'
                            bg={'red'}
                            isSmall={true}
                            onClick={() => handleDeleteFormDataSources()}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}