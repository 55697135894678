import { Link } from "react-router-dom";
import { motion } from "framer-motion"

export default function Header(){

    return (
        <div className="relative bg-[#F9FAFB]">
            <div className="absolute top-0 left-0 h-full w-full">
                <img 
                    src="/images/home/bg-pattern.png"
                    alt="bg-pattern"
                    className="h-full w-full object-cover"
                />
            </div>
            <div className="max-w-[1440px] mx-auto lg:px-[80px] px-[16px] relative z-20">
                <div className="flex items-center justify-between py-[20px]">
                    <div className="h-[40px] w-[100px] bg-black"></div>
                    <div className="flex items-center gap-4">
                        <Link to={`/login`}>
                            <button className="bg-transparent px-[16px] py-[10px] rounded-md text-[#475467] hover:text-primary-blue-500 text-[14px]">
                                {`Login`}
                            </button>
                        </Link>
                        <Link to={`/register`}>
                            <button className="bg-primary-blue-500 px-[16px] py-[10px] rounded-md text-white text-[14px]">
                                {`Sign up`}
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Header text */}
                <div className="max-w-[1024px] mx-auto lg:py-[96px] py-[64px]">
                    <motion.h1 
                        initial={{ opacity: 0, display: 'hidden', y: 15 }}
                        whileInView={{ opacity: 1, display: 'block', y: 0 }}
                        transition={{ duration: 1}}
                        className="lg:text-[60px] text-[36px] lg:leading-[72px] leading-[44px] font-[600] text-center lg:mb-[24px] mb-[16px]"
                    >
                        {`Beautiful analytics to grow smarter`}
                    </motion.h1>
                    <motion.span 
                        initial={{ opacity: 0, display: 'hidden', y: 15 }}
                        whileInView={{ opacity: 1, display: 'block', y: 0 }}
                        transition={{ duration: 1, delay: .5}}
                        className="lg:text-[20px] text-[18px] text-[#475467] lg:leading-[30px] leading-[28px] font-[400] block max-w-[768px] mx-auto text-center"
                    >
                        {`Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.`}
                    </motion.span>
                    <motion.div 
                        initial={{ opacity:0, y: 15 }}
                        whileInView={{ opacity: 1, display: 'block', y: 0 }}
                        transition={{ duration: 1, delay: 1}}
                        className="lg:h-[448px] lg:w-[768px] sm:w-full sm:h-[300px] h-[202px] mx-auto mt-[70px]"
                    >
                        <motion.img
                            initial={{ opacity:0, y: 15 }}
                            whileInView={{ opacity: 1, display: 'block', y: 0 }}
                            transition={{ duration: 1, delay: 1}}
                            src="/images/home/mockup1.png"
                            alt="mockup"
                            className="h-full w-full lg:object-cover object-contain"
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}