import { projectTypesConstants } from '../constants'

const initialState = []

export default function (types = initialState, action) {
  const { type, payload } = action

  console.log('payload type projert', payload)

  switch (type) {
    case projectTypesConstants.RETRIEVE_PROJECT_TYPES:
      return payload
    default:
      return types
  }
}
