import { formTypesConstants } from '../constants'

const initialState = []

export default function (formTypes = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case formTypesConstants.RETRIEVE_FORM_TYPES:
      return payload
    default:
      return formTypes
  }
}
