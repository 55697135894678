import { userConstants } from '../constants'

const initialState = []

export default function (roles = initialState, action) {
  const { type, payload } = action

  switch (type) {
    // case CREATE_PROJECT:
    //   console.log(payload)
    //   return [...projects, payload]
    case userConstants.RETRIEVE_ROLES:
      return payload
    // case UPDATE_PROJECT:
    //   return projects.map((project) => {
    //     if (project.uid === payload.uid) {
    //       return {
    //         ...project,
    //         ...payload,
    //       }
    //     } else {
    //       return project
    //     }
    //   })
    // case DELETE_PROJECT:
    //   return projects.filter(({ id }) => id !== payload.id)
    // case DELETE_ALL_PROJECT:
    //   return []
    default:
      return roles
  }
}
