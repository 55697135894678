import Base from "../../../layout/adm/base";
import Profil from "../../../section/admin/Profil";

export default function ManageAccount(){

    return(
        <Base>
            <Profil />
        </Base>
    )
}