import { useState, Fragment, useEffect } from "react";
import { Tag, ButtonWithBg, Paragraph, Textarea } from "../../../components/Kits";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { Modal } from "../../../components/Modals";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft} from 'react-icons/bs'
import { useSelector, useDispatch } from "react-redux";
import {
    projectActions,
    surveyDatasRequestedActions,
    surveyDatasStatusActions,
  } from "../../../redux/actions";
import { useLocation } from "react-router-dom";
import { useToaster } from "../../../contexts/toaster";
import { Link } from "react-router-dom";
import { AiOutlineCopy } from 'react-icons/ai'
import LocationMappModal from "../LocationData/LocationDataModal";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import { getAreaOfPolygon, getPathLength } from "../../../utils/geolibCalcul";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ModuleMappingTable({
    item, 
    t, 
    isLoadedDataMaps, 
    currentPageDataMaps, 
    setCurrentPageDataMaps, 
    elementToListDataMaps, 
    setElementToListDataMaps, 
    paginationDataMaps, 
    nextPage, 
    prevPage, 
    dataPage,
    userStudyArea,
    userLang
}){
    
    const toaster = useToaster()
    const dispatch = useDispatch()
    const location = useLocation();
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
 
    const [dataMapps, setDataMapps] = useState({})

    console.log("itemooooo", item)

    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    const handleCopy = (alpha, type) =>{
        let tab
        if(type == "arr"){
            tab = [...alpha?.map((data) =>(
                { lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude)}
            ))]
        }else{
            tab = alpha
        }
        const text = JSON.stringify(tab);
        navigator.clipboard.writeText(text)
        .then(() => toaster.show({
            title: 'Copied !',
            message: `Data successfully Copied`,
            greedy: true,
            type: 'info'
          }))
        .catch(() => toaster.show({
            title: 'Oups Error !',
            message: `Something went wrong please try again `,
            greedy: true,
            type: 'info'
          }));
    }


    return(
        <>
        <div className='flex items-center justify-between mt-[30px]'>
            <div className="flex justify-between md:justify-start items-center gap-3">
                <div className="flex justify-between shrink-0 md:justify-start items-center gap-3">
                    <span className="text-[14px] block text-[#676D7C] font-poppins font-regular">Show items</span>
                    <Listbox 
                        value={elementToListDataMaps} 
                        onChange={(e) => {
                            setElementToListDataMaps(e)
                            setCurrentPageDataMaps(1)
                        }}>
                        <div className="relative">
                            <Listbox.Button className="inline-flex h-[40px] min-w-[80px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[4px] bg-[#fff] border border-[#EFEFEF] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <span className="block text-[12px] text-[#676D7C] mr-4 font-title font-medium">{elementToListDataMaps.name}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute left-0 w-full mt-2 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {dataPage?.map((item, itemIdx) => (
                                        <Listbox.Option
                                            key={itemIdx}
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                    active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                }`
                                            }
                                            value={item}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block text-[12px] font-poppins ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {item.name}
                                                    </span>
                                                    
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
                <div className="inline-flex h-[40px] min-w-[80px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[4px] bg-[#fff] border border-[#EFEFEF]">
                    <span className="block w-full text-[12px] text-[#676D7C] font-title font-medium text-center">
                        {isLoadedDataMaps ? (
                            <div className="animate-pulse text-left border-b border-dashed border-[#EFEFEF]">
                                <div className={`h-4 w-full bg-black/10 rounded-[2px]`}></div>
                            </div>
                            ) : (
                                <>
                                    <strong className="text-black">{paginationDataMaps?.totalCount}</strong> saisie(e)
                                </>
                            )
                        }
                    </span>
                </div>
            </div>
            {
                paginationDataMaps.total > 1 &&
                <div className="flex items-center justify-between gap-4">
                    <span className="text-[14px] text-black text-opacity-50">
                        {`Page ${currentPageDataMaps} sur ${paginationDataMaps.total}`}
                    </span>
                    <div className="flex items-center justify-end gap-3">
                        <div 
                            onClick={()=>prevPage("dataMaps")} 
                            className={`bg-primary-blue-500 bg-opacity-10 h-[30px] w-[30px] rounded-[6px] flex items-center justify-center cursor-pointer ${currentPageDataMaps < 2 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        >
                            <BsChevronLeft className="text-[16px] text-primary-blue-500" />
                        </div>
                        <div 
                            onClick={()=>nextPage("dataMaps")}  
                            className={`bg-primary-blue-500 bg-opacity-10 h-[30px] w-[30px] rounded-[6px] flex items-center justify-center cursor-pointer ${currentPageDataMaps == paginationDataMaps.total ? 'cursor-not-allowed' : 'cursor-pointer'}`}    
                        >
                            <BsChevronRight className="text-[16px] text-primary-blue-500" />
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className='relative bg-white rounded-[6px] pb-[300px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6 mt-[20px]'>
            <div className="w-full">
                <table className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[40px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                N
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Name"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Description"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"GPS used for Mapping"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Device name"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Mapping type"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                {"Mapping data"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                {"Center"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {`Surface  en ( ${userStudyArea?.area} )`}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {`Périmètre en ( ${userStudyArea?.perimeter} )`}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Creation date"}
                            </th>
                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                    {isLoadedDataMaps ? <TableSkeleton length={10} columns={7} /> :
                        <>
                            {
                                item?.length > 0 &&
                                item?.map((el, idx) => (
                                    <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                            <td className="px-4 py-1  text-[14px] text-[#000]  font-[500] ">
                                                {idx + 1 < 10 ? `0${idx+1}` : idx+1 }
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.name}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.description}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.gpsType}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.deviceName ? el?.deviceName : '-------------'}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.mappingType}
                                                </div>
                                            </td>
                                            
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-primary-blue-500 text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    <div 
                                                        className='flex-1'
                                                        onClick={() => {
                                                            setModalMode("locationMapps")
                                                            setDataMapps({ 
                                                                type : el?.mappingType ? el?.mappingType?.toLowerCase() : '', 
                                                                name: el?.name,  
                                                                coordinates: el?.mappings,
                                                                centerDataMapps: {
                                                                    lat: el?.mappingCenter?.latitude, 
                                                                    lng: el?.mappingCenter?.longitude
                                                                } 
                                                            })
                                                            toggleModal()
                                                        }}
                                                    >
                                                        {
                                                            el?.mappings ? "@h"+el?.mappings?.[0]?.latitude?.substring(0, 4) + "k" + el?.mappings?.[0]?.longitude?.substring(0, 6) : ''
                                                            
                                                        }
                                                    </div>
                                                    <div 
                                                        className='h-[30px] w-[30px] rounded-[4px] cursor-pointer bg-primary-blue-500 bg-opacity-20 flex items-center justify-center' 
                                                        onClick={()=> handleCopy(el?.mappings, "arr")}
                                                    >
                                                        <AiOutlineCopy className='text-primary-blue-500 text-[16px]' />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-primary-blue-500 text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    <div 
                                                        className='flex-1'
                                                    >
                                                        {
                                                            el?.mappingCenter?.latitude ? "@h"+el?.mappingCenter?.latitude?.toString()?.substring(0, 4) + "k" + el.mappingCenter.longitude?.toString()?.substring(0, 6) : ''
                                                            
                                                        }
                                                    </div>
                                                    <div 
                                                        className='h-[30px] w-[30px] rounded-[4px] cursor-pointer bg-primary-blue-500 bg-opacity-20 flex items-center justify-center' 
                                                        onClick={()=> handleCopy(el?.mappingCenter, "")}
                                                    >
                                                        <AiOutlineCopy className='text-primary-blue-500 text-[16px]' />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.mappingType !== 'One position' ? getAreaOfPolygon(el?.mappings, userStudyArea?.area) : '-------'}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {el?.mappingType !== 'One position' ? getPathLength(el?.mappings, userStudyArea?.perimeter) : '-------'}
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 whitespace-nowrap  text-[14px] text-[#000] text-opacity-90  font-[500] align-middle ">
                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                    {new Date(el?.createdAt).toLocaleDateString().replaceAll("/", "-")}
                                                </div>
                                            </td>
                                        </tr>
                                ))
                            }
                        </>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    
    

        {/** Modal */}
        <Modal show={isOpen} toggleModal={toggleModal}>
            <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                <h4>
                    {
                        modalMode == 'update_history' ? "Update history" :
                        modalMode == 'view_data' ? t("project:viewdatainfo") : 
                        modalMode == 'export_data' ? t("project:exportdata") : 
                        modalMode == 'order' ? t("project:order") : 
                        modalMode == 'otherState' ? t("project:warning") : 
                        modalMode == 'locationMapps' ? "Location mapps" : ''
                    }
                </h4>
                <div 
                    onClick={()=>{toggleModal()}}
                    className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                    <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                </div>
            </div>
            
            {
                modalMode=='locationMapps' && 
                <div>
                    <LocationMappModal state={dataMapps} />
                </div>
            }
        </Modal>
        </>
    )
}