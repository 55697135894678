import http from '../http-common'

const getAll = (qs) => {
  return http.get(`/billing-address`)
}

const get = (id) => {
  return http.get(`/billing-address/${id}`)
}

const create = (data) => {
  return http.post('/billing-address', data)
}

const update = (id, data) => {
  return http.put(`/billing-address/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/billing-address/${id}`)
}



const billingAddressService = {
  getAll,
  get,
  create,
  update,
  remove,
}

export default billingAddressService
