import http from '../http-common'

const getAppsArea = () => {
  return http.get('/app/study-areas')
}

const getUserStudyArea = () => {
  return http.get('/user/study-area')
}
const getUserLang = () => {
  return http.get('/user/language')
}

const getLang = () => {
  return http.get('/app/languages')
}

const updateLang = (data) => {
  return http.put(`/user/set-language/`, data)
}

const updateAppArea = (data) => {
  return http.put(`/user/set-study-area/`, data)
}

export const appSettingService = {
    getAppsArea,
    getLang,
    updateLang,
    updateAppArea,
    getUserStudyArea,
    getUserLang
}
