import { formatDate, formatDateIntl } from "../../utils/format-date";
import { Tag } from "../Kits";
import { useTranslation } from "react-i18next";

export default function InvoiceTable({invoices}){
    const { t } = useTranslation(["common", "project", "form"]);

    return (
        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6 mt-[20px]'>
            <div className="w-full">
                <table className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[40px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                {t("project:periode")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                {t("project:invoicedate")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:amount")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:paymentmethod")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Status"}
                            </th>
                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                        {
                            invoices?.map((invoice, index) => (
                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                    <td className="px-4 py-3 capitalize whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {formatDateIntl(invoice?.createdAt)}
                                    </td>
                                    <td className="px-4 py-3 capitalize whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {formatDate(invoice?.createdAt)}
                                    </td>
                                    <td className="px-4 py-3 lowercase whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {`${invoice?.totalAmount ? invoice?.totalAmount : 0} fcfa`}
                                    </td>
                                    <td className="px-4 py-3 whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        {"Visa - card"}
                                    </td>
                                    <td className="px-4 py-3 whitespace-nowrap text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                        <Tag 
                                            title={t("project:paid")}
                                            color={'text-secondary-green-500'}
                                            bg={'bg-secondary-green-500 bg-opacity-10'}
                                            isHfull={true}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                        
                        {/* <tr  className='text-left border-b  border-[#EFEFEF]'>
                            <td className="px-4 py-3 capitalize whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"Sep 2022"}
                            </td>
                            <td className="px-4 py-3 capitalize whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"10-10-2022"}
                            </td>
                            <td className="px-4 py-3 lowercase whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"27000 fcfa"}
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"Visa - card"}
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                <Tag 
                                    title={t("project:pending")}
                                    color={'text-[#F99B10]'}
                                    bg={'bg-[#F99B10] bg-opacity-10'}
                                    isHfull={true}
                                />
                            </td>
                        </tr>
                        <tr  className='text-left border-b  border-[#EFEFEF]'>
                            <td className="px-4 py-3 capitalize whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"Sep 2022"}
                            </td>
                            <td className="px-4 py-3 capitalize whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"10-10-2022"}
                            </td>
                            <td className="px-4 py-3 lowercase whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"27000 fcfa"}
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap lg:whitespace-normal text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                {"Visa - card"}
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                <Tag 
                                    title={t("project:overdue")}
                                    color={'text-[#FF0505]'}
                                    bg={'bg-[#FF0505] bg-opacity-10'}
                                    isHfull={true}
                                />
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}