import { GoogleMap, useLoadScript, Marker, Polygon } from "@react-google-maps/api"
import { useLocation } from "react-router";

export default function LocationMapp(){
    const location = useLocation();
    const { dataMapps, typeData }= location?.state;

    console.log('dataMapps', dataMapps)

    const center = typeData == 'userLocation' ? {lat: dataMapps?.latitude, lng: dataMapps?.longitude} : {lat: parseFloat(dataMapps?.[0]?.latitude), lng: parseFloat(dataMapps?.[0]?.longitude)}

    const paths = (typeData !== 'userLocation' && dataMapps?.length > 1) ? [...dataMapps?.map((data) =>(
        { lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude)}
    ))] : ''
      
    const options = {
        fillColor: "lightblue",
        fillOpacity: 1,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }

    

    const {isLoaded} = useLoadScript({
        // googleMapsApiKey : 'AIzaSyDCaVGNPuQLr9RxlwwVNLzRzIojUpxq_Cs'
        googleMapsApiKey : 'AIzaSyD5N5H922bRZG_OuPBAv18tM9xNqiMpzRg'
    })

    function Mapp () {
    
        return(
            <GoogleMap 
                zoom={(typeData !== 'userLocation' && dataMapps?.length > 1) ? 20 : 15}
                animation= {window.google.maps.Animation.DROP}
                center={ center }
                mapContainerClassName="map-container"
                options={{
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControlOptions: {
                        position: window.google.maps.ControlPosition.BOTTOM_LEFT,
                    },
                }}
            >
                {
                    typeData == 'userLocation' ? 
                    <Marker position={center} title={"Bonjour"} />
                    :
                    (typeData !== 'userLocation' && dataMapps?.length == 1) ?
                    <Marker position={center} title={"Bonjour"} />
                    :
                    <Polygon
                        // onLoad={onLoad}
                        paths={paths}
                        options={options}
                    />
                }
                
            </GoogleMap>
        )
    }

    if(!isLoaded) return <div>Loading mapp...</div>

    return (
        <Mapp 
        // dataMapps={dataMapps} 
    
        />
    )
    
}

