import Base from "../../../layout/adm/base";
import PaymentsMethods from "../../../section/admin/BillingAccount/PaymentsMethod";

export default function AllPaymentsMethod(){

    return(
        <Base>
            <PaymentsMethods />
        </Base>
    )
}