import { useState, Fragment, createRef, useEffect, } from "react";
import {Transition, Listbox, Dialog, Menu } from '@headlessui/react';
import { Input,Textarea, SelectField, InputRadio, Heading, Paragraph,ButtonWithBg,Tag } from "../../../components/Kits";
import { AiOutlineInfoCircle , AiOutlineClose, AiOutlinePlus, AiOutlineDelete} from 'react-icons/ai'
import { FiChevronDown } from 'react-icons/fi'
import { Modal } from "../../../components/Modals";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useToaster } from "../../../contexts/toaster";
import { Link, useLocation } from "react-router-dom";
import {
    formActions,
    formTypesActions,
    projectActions,
    projectRolesActions,
    userActions,
  } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import { checkSelectValues, processArray } from "../../../utils/formatFormsData";
window.jQuery = $;
window.$ = $;


require("jquery-ui-sortable");
require("formBuilder");


export default function DetailsForm(){
    const databaseSources = useSelector((state) => state.databaseSources);
    const modifyTypeInFields = (originalAaa) => {
        const modifiedAaa = { ...originalAaa.item };
      
        modifiedAaa.fields = modifiedAaa.fields.map(field => {
          if (field.type === 'select-data-source') {
            return { 
                ...field, 
                type: 'select',
                values: [
                    ...field.values,
                    ...databaseSources.filter(x => x.id !==field.values[0]?.id).map((data, idx) => (
                        {
                            id: data.id,
                            label: data?.name,
                            // value: `option-${idx + 1}`,
                            selected: false,
                            
                        }
                    ))
                ]
            };
          }
          return field;
        });
      
        return {item: modifiedAaa};
    };

    const { t } = useTranslation(["common", "project", "form"]);
    const location = useLocation();
    const { item } = modifyTypeInFields(location.state);

    console.log('item====>', location.state)

    const formInitialState = {
        formTypeId: "",
        forProjectTypeId: item.forProjectType?.id,
        name: item?.name,
        useMapping: item?.useMapping,
        mappingType: null,
        description: item?.description,
        fields: [],
        mapps:[]
    };
    const navigate = useNavigate();
    const toaster = useToaster()
    const [formBuild, setFormBuild] = useState(formInitialState);
    const [formBuilder, setFotmBuilder] = useState(null);
    const formTypes = useSelector((state) => state.formTypes);
    const [loading, setLoading] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)

    const projectType = useSelector((state) => state.projectTypes)

    const hasRunningOrCompleted = item?.projects?.some(project => {
        const { status } = project;
        return status.name === 'Running' || status.name === 'Completed' || status.name === 'Archived';
    });
      

    const dispatch = useDispatch();

    const [options, setOptions] = useState({
        dataType: "json",
        disabledActionButtons: ["data", "clear", "save"],
        disableFields: ["autocomplete", "header", "hidden", "paragraph", "button"],
        editOnAdd: true,
        // prepend: true,
        // fieldRemoveWarn: false,
        defaultFields:[
            ...item?.fields
        ],
        onAddField: function (fieldId, field) {
            setFormBuild((prevState) => ({
              formTypeId: prevState.formTypeId,
              forProjectTypeId: prevState.forProjectTypeId,
              name: prevState.name,
              description: prevState.description,
              useMapping: prevState.useMapping,
              mappingType: prevState.mappingType,
            //   fields: [...prevState.fields, { fieldId: field }],
            }));
        },
        inputSets: [
            {
                label: 'Select data source',
                name: 'select-data-source', // optional - one will be generated from the label if name not supplied
                showHeader: false, // optional - Use the label as the header for this set of inputs
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-database" viewBox="0 0 16 16">
                    <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z"/>
                </svg>`,
                id: 'block-select-data-source',
                fields: [
                    {
                        type: 'select',
                        label: 'Select data source',
                        className: 'form-control select-data-source',
                        values: databaseSources.map((data, idx) => (
                            {
                                id: data.id,
                                label: data?.name,
                                selected: false,
                                
                            }
                        )),
                    }
                ]
            }
        ]
      });

      

    let fb = createRef();
  
    useEffect(() => {
      dispatch(formActions.forms());
  
      setFormBuild({
        ...formBuild,
        formTypeId: formTypes?.filter(x => x?.name == "Private")[0]?.id
      });
    }, []);

  
    useEffect(() => {
      setFotmBuilder($(fb.current).formBuilder(options));
    }, []);
  
    //Submit form creation
  const handleSubmitForm = () => {
    // e.preventDefault();
    dispatch(formActions.updateForms(item?.id ,formBuild, toaster, setLoading, setCanSubmit, navigate));
  };

  const mappingType = [
      {
        value: "onePosition",
        name: "One position"
      },
      {
        value: "polygone",
        name: "Polygone"
      },
      {
        value: "continuous",
        name: "Continuous"
      },
  ]

    


    const [inputs, setInputs] = useState([...item?.mapps]);
    const [showEr, setShowErr] = useState(false)
    const [err, setErr] = useState('')


    const handleClick = () => {
        
        if (inputs[inputs.length - 1].label.trim() !== "") {
            const labelExist = inputs.slice(0,-1).findIndex(input => input.label.trim() === inputs[inputs.length - 1].label.trim());
            if(labelExist !== -1){
                setShowErr(true)
                setErr("The label must be different from the other labels");
            }else{
                setShowErr(false)
                const newInput = {
                    label: inputs[inputs.length - 1].label.trim(),
                    type:mappingType[0].value,
                    name: generateName(inputs[inputs.length - 1].label.trim())
                };
                setInputs([...inputs, newInput]);
            }
          } else {
            setShowErr(true)
            setErr("Please enter a value for the current input before duplicating it.")
          }
    };

    function generateName(label) {
        let name = label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, "").trim();
        name = name.replace(/\s/g, "_");
        return name;
    }

    const handleChange = ({label,type, index}) => {
        const newInputs = [...inputs]

        if(type) newInputs[index]["type"] = type;
        // if (label)newInputs[index]["label"] = label;
        setInputs(newInputs);
    };

    const handleDelete = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const getData = () => {
        const data = JSON.parse(formBuilder.actions.getData('json'))

        if(checkSelectValues(data)){
            setFormBuild({
                ...formBuild,
                fields: processArray(data),
                mapps: inputs
            })
            setCanSubmit(true)
        }else{
            toaster.show({
                title: 'Oups error !',
                message: `Assurez vous d'avoir selection une seule option pour chaque champs de type select data source contenant des données dynamiques`,
                greedy: true,
                type: 'error'
            });
            setCanSubmit(false)
        }
        // setFormBuild({
        //     ...formBuild,
        //     fields: JSON.parse(formBuilder.actions.getData('json')),
        //     mapps: inputs
        // })
        // setCanSubmit(true)
    }

    useEffect(()=>{
        if(canSubmit === true){
            handleSubmitForm()
        }
    }, [canSubmit])
   
    console.log("inputs", inputs)
    console.log('formBuild', formBuild)

//   console.log('formBuild ===>', formBuild)
//   console.log('formBuilder ===>', formBuilder?.actions?.getData('json', true) ? formBuilder?.actions?.getData('json', true) : 'pafff')

    return(
        <div>
            <div className="max-w-[40%] mb-[50px]">
                <Heading title={t("form:detailsform")} mb={"10px"} />
            </div>
            <div className="mb-[30px]">
                <h4 className="text-[15px] mb-[2px]">{t("form:projectsassociatedwiththisform")}</h4>
                {
                    (item?.projects && item?.projects?.length > 0) ? 
                    <Menu as="div" className="relative w-[300px]">
                        <div>
                            <Menu.Button className="inline-flex h-[40px] border w-[300px] justify-between items-center px-2 py-2 text-sm text-gray-900 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <span>{t("form:projectslist")}</span>
                                <FiChevronDown className="text-[20px]" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                        <Menu.Items className="absolute z-50 h-[300px] scrollbar-v overflow-x-hidden right-0 mt-2 w-full origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {
                                item?.projects?.map((project, index) =>(
                                    <Menu.Item key={index} className=' block p-2 !text-[14px] text-gray-500'>
                                        {({ active }) => (
                                        <span
                                            className={`${
                                            active ? 'bg-neutral-variant-xl-400 ' : ''
                                            } group flex w-full items-center px-2 py-3  text-neutral-variant-l-700`}
                                        >
                                            {project?.name}
                                        </span>
                                        )}
                                    </Menu.Item>
                                ))
                            }
                        </Menu.Items>
                        </Transition>
                    </Menu>
                    :
                    <Tag 
                        title={t("form:noassociatedproject")}
                        color={"text-[#0B449A]"}
                        bg={"bg-[#e7ecf5]"}
                    />
                }
            </div>
            <form onSubmit={(e)=> e.preventDefault()}>
                <span className="text-[16px] text-black mb-[10px] block">{t("form:generalinfo")}</span>
                <div className="bg-white px-4 pt-8 pb-4  rounded-[6px] mb-[50px]">
                    { 
                        item?.number && 
                        <div className="mb-[30px]">
                            <Input 
                                label={"#ID"}
                                smallLab={true}
                                placeholder={"ex: form name"}
                                type={'text'}
                                value={item?.number}
                                disabled={true}
                            />
                            {/* <div className="border bg-gray-50 border-black border-opacity-10 rounded-[6px] h-[50px] flex items-center w-full focus:outline-none px-4 text-[14px] text-black placeholder:text-black placeholder:text-opacity-50">
                                {item?.number}
                            </div> */}
                        </div>
                     
                    }
                    <div className="gap-4">
                        <div className="mb-[30px]">
                            <Input 
                                label={t("form:formname")}
                                smallLab={true}
                                placeholder={"ex: form name"}
                                type={'text'}
                                value={formBuild.name}
                                disabled={hasRunningOrCompleted}
                                onChange={(e) =>{
                                    setFormBuild({
                                        ...formBuild,
                                        name: e.target.value,
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="mb-[30px] mt-[10px]">
                        <Textarea 
                            label={'Description'}
                            smallLab={true}
                            placeholder={"ex: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took"}
                            type={'text'}
                            value={formBuild.description}
                            disabled={hasRunningOrCompleted}
                            onChange={(e) =>{
                                setFormBuild({
                                    ...formBuild,
                                    description: e.target.value,
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="hidden">
                    <span className="text-[16px] text-black mb-[10px] block">{`Form types`}:</span>
                    <div className="bg-white p-4 rounded-[6px] mb-[50px] grid grid-cols-2 gap-8">
                        {
                            projectType?.map((item, index) =>(
                                <div 
                                    key={index} 
                                    className={`border rounded-lg flex items-start gap-4 p-4 cursor-pointer hover:bg-secondary-green-500/10 hover:border-secondary-green-500 transition-all ease-linear duration-200
                                    ${formBuild.forProjectTypeId === item?.id ? 'bg-secondary-green-500/10 border-secondary-green-500' : 'border-gray-100' }`}
                                    onClick={()=>{
                                        setFormBuild({
                                        ...formBuild,
                                        forProjectTypeId: item?.id,
                                    })
                                    }}
                                >
                                    <div className={`h-[20px] w-[20px] border rounded shrink-0 flex items-center justify-center
                                        ${formBuild.forProjectTypeId === item?.id ? 'bg-secondary-green-500/10 border-secondary-green-500' : 'border-gray-300'}`}
                                    >
                                        { formBuild.forProjectTypeId === item?.id && <div className="h-[15px] w-[15px] rounded bg-secondary-green-500"></div>}
                                    </div>
                                    <div>
                                        <h4 className="text-[16px] font-ibm-plex-sans mb-1">{item?.name}</h4>
                                        <p className="text-[14px] text-black/50">
                                            {item?.description}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="">
                    <span className="text-[16px] text-black mb-[10px] block">{t("form:locationformfields")}:</span>
                    <div className="bg-white p-4 rounded-[6px] mb-[50px]">
                        <div className="mb-[30px]">
                            <span className="block text-[14px]">
                                {t("form:usemapping")}
                            </span>
                            <InputRadio 
                                label={t("common:no")}
                                name="useMapping"
                                checked={formBuild.useMapping === false ? true : false }
                                disabled={hasRunningOrCompleted}
                                onChange={(e) => {
                                    setFormBuild({
                                        ...formBuild,
                                        useMapping: false,
                                        mappingType: null
                                    })
                                }}
                            />
                            <InputRadio 
                                label={t("common:yes")}
                                name="useMapping"
                                checked={formBuild.useMapping === true ? true : false }
                                disabled={hasRunningOrCompleted}
                                onChange={(e) => {
                                    setFormBuild({
                                        ...formBuild,
                                        useMapping: true,
                                        mappingType: 'onePosition'
                                    })
                                }}
                            />
                        </div>
                        {
                            formBuild?.useMapping &&
                            <div className="">
                                <span className="block text-[14px] mb-[30px]">
                                    {t("form:definethegeolocationfields")}
                                </span>
                                <div className="grid grid-cols-12 gap-2">
                                    {
                                        inputs.map((input, index) =>(
                                            <Fragment key={index}>
                                                <div className="col-span-6 mb-[30px]">
                                                    <Input 
                                                        label={t("form:label")}
                                                        smallLab={true}
                                                        placeholder={"ex: form name"}
                                                        type={'text'}
                                                        value={input.label}
                                                        disabled={hasRunningOrCompleted}
                                                        // onChange={(e) =>{
                                                        //     handleChange({value:e.target.value, index})
                                                        // }}
                                                        onChange={(e) => {
                                                            const newInputs = [...inputs];
                                                            newInputs[index].label = e.target.value;
                                                            newInputs[index].name = generateName(e.target.value);
                                                            setInputs(newInputs);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-5 mb-[15px]">
                                                    <SelectField 
                                                        label={t("form:mappingtype")}
                                                        smallLab={true}
                                                        value={input.type}
                                                        disabled={hasRunningOrCompleted}
                                                        options={mappingType}
                                                        onChange={(e) =>{
                                                            handleChange({type:e.target.value, index})
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    index > 0 &&
                                                    <div onClick={() => handleDelete(index)} className={`h-[50px] w-[50px] bg-red-500 bg-opacity-30 rounded-full ${hasRunningOrCompleted ? "hidden" : "flex"} items-center justify-center cursor-pointer ml-auto`}>
                                                        <AiOutlineDelete className="text-red-500 text-[20px]" />
                                                    </div>
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </div>
                                {
                                    showEr &&
                                    <span className="block text-[12px] text-red-500 -mt-5 mb-5">
                                        { err }
                                    </span>
                                }
                                
                                <div onClick={handleClick} className={`h-[50px] w-[50px] bg-primary-blue-500 rounded-full ${hasRunningOrCompleted ? "hidden" : "flex"} items-center justify-center cursor-pointer mb-[30px]`}>
                                    <AiOutlinePlus className="text-white text-[20px]" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="mb-8 relative">
                    <span className="text-[16px] text-black mb-[10px] block">{t("form:standardformfields")}:</span>
                    <div className="h-full w-full" id="fb-editor" ref={fb} />
                    {
                        hasRunningOrCompleted &&
                        <div className="absolute top-0 left-0 z-10 bg-transparent w-full h-full"></div>
                    }
                    
                </div>
                {
                    hasRunningOrCompleted ? null : 
                    <div className="flex items-center gap-4">
                        <ButtonWithBg 
                            title={t("common:cancel")}
                            type='button'
                            bg={'primary-opacity'}
                            isSmall={true}
                            onClick={()=> {
                                setFormBuild(formInitialState)
                                setInputs([{ label: "", type:mappingType[0].value }])
                                formBuilder.actions.clearFields()
                            }}
                        />
                        <ButtonWithBg 
                            title={loading ? t("common:inprogress") : t("common:save")}
                            type='button'
                            bg={'primary'}
                            isSmall={true}
                            form="handleSubmitForm"
                            onClick={() => {getData()}}
                        />
                    </div>
                    
                }
                
            </form>
        </div>
    )
}