import Base from "../../../layout/adm/base";
import { AccountList } from "../../../section/admin/BillingAccount";

export default function AllBilling(){

    return(
        <Base>
            <AccountList />
        </Base>
    )
}