import { billingAddressConstants, uiConstants } from "../constants";
import billingAddressService from "../../services/billingAddress.service";

const create = (billingAddress, toaster, setLoading, toggleModal, setModalMode, setIsCompleted, isCompleted, setBillingAddressId) => (dispatch) => {
  console.log('gtgtgtgt')
  setLoading(true)
  dispatch({ type: uiConstants.LOADING_UI });
  billingAddressService
    .create(billingAddress)
    .then((response) => {
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAddressConstants.CREATE_BILLINGADDRESS,
        payload: response.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your billing address has created successfully"`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      if(isCompleted){
        toggleModal()
        // setIsCompleted(false)
      }else{
        setModalMode("choose_billing_address")
        setBillingAddressId(response.data.data.id)
        // setIsCompleted(false)
      }
    })
    .catch((response) => {
      // toast.error(response.response.data.message);
      console.log('response', response)
      toaster.show({
        title: 'Oups error !',
        message: `${response.message}`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const getAllBillingAddress = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  billingAddressService
    .getAll()
    .then((response) => {
      console.log('response ekvqek====>', response)
      dispatch({
        type: billingAddressConstants.RETRIEVE_BILLINGADDRESSS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      if (response.data !== undefined) {
        dispatch({
          type: uiConstants.SET_ERRORS,
          payload: response.data.message,
        });
      }
    });
};

const update = (id, data, setLoading, toaster, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    const res = await billingAddressService.update(id, data);
    console.log('resres', res)
    if(res.status === 200){
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAddressConstants.UPDATE_BILLINGADDRESS,
        payload: res.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleModal()
    }
    return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
};

const deletedBillingAddress = (id, setLoading,  toaster, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    const res = await billingAddressService.remove(id);
    if(res.status === 200){
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAddressConstants.DELETE_BILLINGADDRESS,
        payload: {id},
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has deleted successfully`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleModal()
    }
    return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
}

export const billingAddressActions = {
  create,
  getAllBillingAddress,
  update,
  deletedBillingAddress
};
