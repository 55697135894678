export default function ButtonWithBg({title, bg, type, isSmall, ...props}){

    return(
        <button 
            className={`font-normal text-white disabled:bg-gray-300 disabled:cursor-not-allowed 
            ${ isSmall ? "w-fit px-4 h-[40px] text-[14px]" : "w-full h-[50px]"} 
            rounded-[6px] 
            ${
                bg === 'primary' ? "bg-primary-blue-500 border-none" 
                : 
                bg === 'gray' ? "bg-tertiary-gray-500 !text-black !border" 
                : 
                bg==='red' ? 'bg-red-500 border-none' 
                : 
                bg==='secondray' ? "bg-secondary-green-500 border-none" 
                : 
                bg==='primary-opacity' ? 'bg-primary-blue-500 bg-opacity-10 !text-primary-blue-500 border-none' 
                : 'bg-secondary-green-500 bg-opacity-10 text-secondary-green-500 border-none'}
            `}
            type={type}
            {...props}
        >
            {title}
        </button>
    )
}