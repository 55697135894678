const isUserAccepted = (projet, userId) => {

    return projet?.projectUsers.find(x=> x?.userId === userId) && projet?.projectUsers?.find(x=> x?.userId === userId)?.acceptanceStatus.name !== "Accept"

}

const getAcceptanceCode = (projet, userId) => {
    return  projet?.projectUsers?.find(x=> x?.userId === userId)?.acceptanceCode
}

export {
    isUserAccepted,
    getAcceptanceCode
}