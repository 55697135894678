import { billingAddressConstants } from '../constants'

const initialState = []

// eslint-disable-next-line import/no-anonymous-default-export
export default function(billingAddress = initialState, action){
  const { type, payload } = action

  switch (type) {
    case billingAddressConstants.CREATE_BILLINGADDRESS:
      console.log(payload)
      return [payload, ...billingAddress]
    case billingAddressConstants.RETRIEVE_BILLINGADDRESSS:
      return payload
    case billingAddressConstants.UPDATE_BILLINGADDRESS:
      return billingAddress.map((billingAddr) => {
        // console.log('aaaaannn billing', payload)
        if (billingAddr.id === payload.id) {
          // console.log('aaaaa billing', payload)
          return {
            ...billingAddr,
            ...payload,
          }
        } else {
          // console.log('bbbbbb billing')
          return billingAddr
        }
      })
    case billingAddressConstants.DELETE_BILLINGADDRESS:
      return billingAddress.filter(({ id }) => id !== payload.id)
    default:
      return billingAddress
  }
}
