import React, { useEffect, useState } from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../redux/actions'
import { accountTypesActions } from '../../redux/actions/account.type.actions'
import { useNavigate } from 'react-router-dom'
import { Input, ButtonWithBg, InputRadio, Paragraph } from "../../components/Kits";
// import PageLoader from '../../loaders/PageLoader'
// import ButtonLoader from '../../loaders/ButtonLoader'

export default function CompleteRegistration({}) {
  const [typeAccount, setTypeAccount] = useState({
    type: '',
    name: '',
    address: '',
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accountTypes = useSelector((state) => state.accountTypes)
  const loading = useSelector((state) => state.UI.loading)


  useEffect(() => {
    dispatch(accountTypesActions.getTypes())
  }, [])

    const handleClick = (e) => {
            e.preventDefault()
            dispatch(userActions.completeRegistration(typeAccount, navigate))
    }
    return (
        <div className="flex items-center justify-center p-6 h-screen">
            <div className='w-[400px]'>
                <div className='text-center mb-[50px]'>
                    <h2 className='font-bold text-[25px] font-ibm-plex-sans mb-2'>Complete your registration</h2>
                    <Paragraph 
                        content={"Lorem Ipsum is simply dummy text of the printing and typesetting "}
                    />
                </div>
                <form onSubmit={handleClick}>
                    <div className="mb-[50px]">
                        <span className="block text-[15px]">
                            Choose an account type
                        </span>
                        <div className='grid grid-cols-2 mt-4'>
                            <InputRadio 
                                label={accountTypes[0]?.name}
                                name="account_type"
                                value={accountTypes[0]?.id}
                                onChange={(e) =>
                                    setTypeAccount({ ...typeAccount, type: e.target.value })
                                }
                            />
                            <InputRadio 
                                label={accountTypes[1]?.name}
                                name="account_type"
                                value={accountTypes[1]?.id}
                                onChange={(e) =>
                                    setTypeAccount({ ...typeAccount, type: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="mb-[30px]">
                        <Input
                            label={"Organization name"}
                            smallLab={true}
                            placeholder={"ex: Terros Tech"}
                            type={"text"}
                            name={"name"}
                            onChange={(e) =>
                                setTypeAccount({ ...typeAccount, name: e.target.value })
                            }
                        />
                    </div>
                    <div className="mb-[30px]">
                        <Input
                            label={"Organization's address"}
                            smallLab={true}
                            placeholder={"ex: Rue K6, Cocody, Abidjan, CI"}
                            type={"text"}
                            name={"address"}
                            onChange={(e) =>
                                setTypeAccount({
                                ...typeAccount,
                                address: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div>
                        <ButtonWithBg title={ loading ? "Loading..." : "Completed"} type="sumbit" bg={"primary"} />
                    </div>
                </form>
            </div>
        </div>
    )
}
