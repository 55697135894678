import { accountTypesService } from "../../services";
import { accountTypeConstants as constants, uiConstants } from "../constants";

const getTypes = () => (dispatch) => {
  dispatch({
    type: uiConstants.LOADING_UI,
  });
  accountTypesService
    .getAll()
    .then((res) => {
      dispatch({
        type: constants.RETRIEVE_ACCOUNT_TYPES,
        payload: res.data.data,
      });
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    })
    .catch((error) => {
      dispatch({
        type: uiConstants.SET_ERRORS,
        payload: error.response.message,
      });
    });
};

export const accountTypesActions = { getTypes };
