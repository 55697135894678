export const formatDataSurvey = (data) => {
    console.log('fieldsInfoToShow dataInSurvey', data)
    let res = {
        ...data?.survey,
        agent: {value: data?.user?.firstName + " " + data?.user?.lastName},
        status: {value: data?.status?.name},
        number: {value : data?.number},
        createdAt: {value: new Date(data?.createdAt)?.toLocaleDateString()?.replaceAll("/", "-")},
        id: {value: data.id}
    }

    return res
}