import { Menu, Transition, Dialog, Disclosure, Listbox } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Tag } from '../../../components/Kits';
import { Link } from "react-router-dom";


export default function TablePaymentMethod({list, t, setModalMode, toggleModal, setAddressInfos, setBillingId}){

    return(
        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] scrollbar mb-6 mt-[30px]'>
            <div className="w-full">
                <table  className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[60px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"N"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                               {t("billing:cardname")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                {t("billing:cardtype")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                {t("billing:expirein")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("billing:billingaccounts")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("billing:invoices")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                        {   list?.length > 0 ?
                            list?.map((item, idx) => (
                                <tr  className='text-left border-b  border-[#EFEFEF]'>
                                    <td className="px-4 py-1 whitespace-nowrap text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {idx + 1}
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {item?.cardholderName}
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {item?.cardBrand}
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {item?.expMonth +"/"+ item?.expYear}
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {
                                            item?.billingAccounts?.length > 0 ? (
                                                <span  
                                                    className='text-primary-blue-500 cursor-pointer'
                                                    onClick={() => {
                                                        setAddressInfos(item)
                                                        setModalMode("listBillingAccount")
                                                        toggleModal()
                                                    }}
                                                >
                                                    {t("billing:viewmore")}
                                                </span>
                                            ) : (
                                                <span  
                                                    className='text-gray-500 cursor-not-allowed'
                                                >
                                                    {t("billing:nobillingaccount")}
                                                </span>
                                            )
                                        }
                                        
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[13px] text-[#000] text-opacity-50  font-[500] ">
                                        {
                                            item?.invoices?.length > 0 ? (
                                                <span  
                                                    className='text-primary-blue-500 cursor-pointer'
                                                    onClick={() => {
                                                        setAddressInfos(item)
                                                        setModalMode("listInvoices")
                                                        toggleModal()
                                                    }}
                                                >
                                                    {t("billing:viewmore")}
                                                </span>
                                            ) : (
                                                <span  
                                                    className='text-gray-500 cursor-not-allowed'
                                                >
                                                    {t("billing:noinvoice")}
                                                </span>
                                            )
                                        }
                                        
                                    </td>
                                    <td className="px-4 py-2   whitespace-nowrap">
                                    
                                        <Menu as="div" className="relative inline-block text-left">
                                            <Menu.Button disabled={(item?.billingAddress?.length > 0 && item?.tokenCard) ?true : false} className={`text-sm ${(item?.billingAddress?.length > 0 && item?.tokenCard) ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                <div className="border px-3 py-2 rounded-[4px] text-[12px] font-poppins font-[500] flex items-center gap-6">
                                                    <span className="text-[#676D7C] ">Actions</span>
                                                    <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </div>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute z-20 right-0 min-w-[140px]  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="px-1 py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() => {
                                                                        toggleModal()
                                                                    }}
                                                                    className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                        } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                >
                                                                    {t("common:update")}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() => {
                                                                        setAddressInfos(item)
                                                                        setModalMode("delete")
                                                                        toggleModal()
                                                                    }}
                                                                    disabled={(item?.billingAccounts?.length > 0) ?true : false}
                                                                    className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                        } ${(item?.billingAccounts?.length > 0) ? "cursor-not-allowed" : "cursor-pointer"} group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                >
                                                                    {t("common:delete")}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </td>
                                </tr>
                            ))
                            :
                            <tr className='text-left border-b  border-[#EFEFEF]'>
                                <td className='text-center py-10 text-[14px] text-black' colSpan={6}> {"No data found"} </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}