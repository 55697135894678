import Base from "../../../layout/adm/base";
import LocationMapp from "../../../section/admin/LocationData";

export default function LocationData(){

    return(
        <Base withoutPadd={true}>
            <LocationMapp />
        </Base>
    )
}