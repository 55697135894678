export default function Testimonial(){
    const partners =  [
        {
            id: 1,
            logo: '/images/home/part1.png'
        },
        {
            id: 2,
            logo: '/images/home/part2.png'
        },
        {
            id: 3,
            logo: '/images/home/part3.png'
        },
        {
            id: 4,
            logo: '/images/home/part4.png'
        },
        {
            id: 5,
            logo: '/images/home/part5.png'
        },
        {
            id: 6,
            logo: '/images/home/part6.png'
        },
    ]

    return (
        <div className="bg-[#F9FAFB] max-w-[1440px] mx-auto lg:px-[80px] px-[16px] lg:py-[96px] py-[64px] relative">
            <div className="text-center">
                <h2 className="text-[#101828] lg:text-[48px] text-[30px] lg:leading-[60px] leading-[40px] font-[500]">
                    {`Untitled has saved us thousands of hours of work and has unlock data insights we never thought possible.`}
                </h2>
                <div className="flex flex-col items-center justify-center mt-[32px]">
                    <div className="h-[64px] w-[64px] rounded-full overflow-hidden mb-[16px]">
                        <img 
                            src="/images/home/user-1.jpg"
                            alt="testimonial"
                            className="h-full w-full object-cover"
                        />
                    </div>
                    <div>
                        <h4 className="text-[18px] text-[#101828] leading-[28px] font-[600] mb-1">John Doe</h4>
                        <p className="text-[16px] text-[#475467] leading-[24px] font-[400]">
                            {`Director of terros`}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center flex-wrap gap-[32px] mt-[48px]">
                {
                    partners.map((item, idx) => (
                        <div key={idx} className="lg:w-[170px]lg:h-[48px] w-[127px] h-[36px] shrink-0">
                            <img 
                                src={item.logo}
                                alt="part1"
                                className="h-full w-full object-contain"
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}