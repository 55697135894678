import http from '../http-common'

const getAll = (qs) => {
  return http.get(`/payment-methods`)
}

const get = (id) => {
  return http.get(`/payment-methods/${id}`)
}

const create = (data) => {
  return http.post('/payment-methods', data)
}

const update = (id, data) => {
  return http.put(`/payment-methods/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/payment-methods/${id}`)
}



const paymentsMethodService = {
  getAll,
  get,
  create,
  update,
  remove,
}

export default paymentsMethodService
