import Modal from "./Modal"
import { AiOutlineClose } from "react-icons/ai"
import { ButtonWithBg } from "../Kits"

export default function ModalAcceptedProject({
    openAcceptanceModal,
    toggleOpenAcceptanceModal,
    loading,
    loadingReject,
    handleAcceptProject,
    handleRejectProject
}){

    return (
        <Modal show={openAcceptanceModal} toggleModal={toggleOpenAcceptanceModal} resetOverflow>
            <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
            <h4>
                {"Accepted project"}
            </h4>
            <div 
                onClick={()=>{
                    toggleOpenAcceptanceModal() 
                }}
                className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                <AiOutlineClose className="text-[20px] font-bold text-red-500" />
            </div>
            </div>
            <div className="max-h-[400px] overflow-x-hidden">
            <div className="p-6">
                <h4 className='text-[16px] font-bold mb-4'>
                    {`You must first accept the project before you can access it`}
                </h4>
                <p className='text-[14px] text-black text-opacity-70'>
                    {`Please click on the button below to participate in the project.`}
                </p>
            </div>
            <div className='flex justify-end mb-[10px] px-6 pt-3 border-t gap-4'>
                <ButtonWithBg 
                    title={loadingReject ? 'In progress...' : 'Reject'}
                    disabled={loadingReject}
                    type='submit'
                    bg={'red'}
                    isSmall={true}
                    onClick={() => handleRejectProject()}
                />
                <ButtonWithBg 
                    title={loading ? 'In progress...' : 'Accept'}
                    disabled={loading}
                    type='submit'
                    bg={'primary'}
                    isSmall={true}
                    onClick={() => handleAcceptProject()}
                />
            </div>
            </div>
        </Modal>
    )
}