import { toast } from "react-toastify";
import { databaseSourceService } from "../../services";
import { databaseSourceConstants, uiConstants } from "../constants";

const create = (data, navigate, toaster, setLoading, setCanSubmit) => (dispatch) => {
  setLoading(true)
  if (data.fields.length === 0) {
    dispatch({
      type: uiConstants.SET_ERRORS,
      payload: "Form fields required",
    });
    toaster.show({
      title: 'Oups error !',
      message: `Form fields required`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    setCanSubmit(false)
  } else {
    dispatch({
      type: uiConstants.CLEAR_SUCCESS,
    });
    dispatch({ type: uiConstants.LOADING_UI });
    databaseSourceService
      .createDatabaseSource(data)
      .then((response) => {
        toaster.show({
          title: 'Success !',
          message: `Form successfully created`,
          greedy: true,
          type: 'success'
        });
        setLoading(false)
        setCanSubmit(false)
        dispatch({
          type: databaseSourceConstants.CREATE_DATABASESOURCE,
          payload: response.data.data,
        });
        // toast.success(response.data.message)
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
        navigate("/adm/forms/all", { replace: false });
      })
      .catch((response) => {
        // toast.error(response.response.data.message);
        toaster.show({
          title: 'Oups error !',
          message: `${response.response.data.message}`,
          greedy: true,
          type: 'error'
        });
        setLoading(false)
        setCanSubmit(false)
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  }
};

const databases = () => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    databaseSourceService
      .getAll()
      .then((response) => {
        dispatch({
          type: databaseSourceConstants.RETRIEVE_DATABASESOURCE,
          payload: response.data.data,
        });
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .then(() => {
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .catch((response) => {
        toast.error(response.data.message);
        dispatch({
          type: uiConstants.CLEAR_ERRORS,
        });
      });
  };
};

export const updateDatabase = (id, data, toaster, setLoading, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    const res = await databaseSourceService.update(id, data);
    if(res.status === 200){
      console.log("res ====>", res)
      toaster.show({
        title: 'Success !',
        message: `Form successfully updated`,
        greedy: true,
        type: 'success'
      });
      
      dispatch({
        type: databaseSourceConstants.UPDATE_DATABASESOURCE,
        payload: res.data.data,
      });
      setLoading(false)
      toggleModal()
    }
    

    return Promise.resolve(res.data.data);
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    return Promise.reject(err);
  }
};

export const deleteDataBase = (id, toaster,setIsLoading, navigate, toggleModal) => async (dispatch) => {
  setIsLoading(true)
  try {
    const res = await databaseSourceService.remove(id);
    if(res.status === 200){
      toaster.show({
        title: 'Success !',
        message: `Form successfully deleted`,
        greedy: true,
        type: 'success'
      });
      setIsLoading(false)
      if(navigate){
        navigate("/adm/forms/all", { replace: false });
      }
      if(toggleModal){
        toggleModal()
      }
    }
    dispatch({
      type: databaseSourceConstants.DELETE_DATABASESOURCE,
      payload: { id },
    });
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setIsLoading(false)
    return Promise.reject(err);
  }
};


const createData = (data, toaster, setLoading, toggleModal, dataSource, setDataSource) => (dispatch) => {
  setLoading(true)
  dispatch({
    type: uiConstants.CLEAR_SUCCESS,
  });
  dispatch({ type: uiConstants.LOADING_UI });
  databaseSourceService
    .postData(data)
    .then((response) => {
      console.log('response create data', response)
      const oldData = dataSource
      setDataSource([response.data.data, ...oldData])
      toaster.show({
        title: 'Success !',
        message: `Data was successfully created`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      toggleModal()
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    })
    .catch((response) => {
      toaster.show({
        title: 'Oups error !',
        message: `${response.response.data.message}`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const updateData = (id, data, toaster, setLoading, toggleModal, setDataSource) => async (dispatch) => {
  setLoading(true)
  try {
    const res = await databaseSourceService.updateData(id, data);
    if(res.status === 200){
      console.log("res ====>", res)
      toaster.show({
        title: 'Success !',
        message: `Form successfully updated`,
        greedy: true,
        type: 'success'
      });
      setLoading(false)
      setDataSource((tmp) => {
        return tmp.map((el) =>
          el?.id === id
            ? {
                ...el,
                ...res.data.data
              }
            : el
        );
      });
      toggleModal()
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    }
    

    return Promise.resolve(res.data.data);
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setLoading(false)
    return Promise.reject(err);
  }
};

const deleteData = (id, toaster, setIsLoading, toggleModal, dataSource, setDataSource) => async (dispatch) => {
  setIsLoading(true)
  try {
    const res = await databaseSourceService.deleteData(id);
    if(res.status === 200){
      toaster.show({
        title: 'Success !',
        message: `Form successfully deleted`,
        greedy: true,
        type: 'success'
      });
      let data = dataSource.filter(x => x?.id !== id)
      setDataSource(data)
      setIsLoading(false)
      toggleModal()
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    }
    

    return Promise.resolve(res.data.data);
  } catch (err) {
    toaster.show({
      title: 'Oups error !',
      message: `${err.response.data.message}`,
      greedy: true,
      type: 'error'
    });
    setIsLoading(false)
    return Promise.reject(err);
  }
};

export const databaseSourceActions = {
  create,
  databases,
  deleteDataBase,
  updateDatabase,
  createData,
  updateData,
  deleteData
};
