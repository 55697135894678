// import { useToaster } from '@contexts/toaster';
import { useToaster } from '../../contexts/toaster';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon
} from '@heroicons/react/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function NotificationSnackbar({ notification }) {
  const toaster = useToaster();

  return (
    <div
      className={classNames(
        'relative flex items-center px-4 py-3 mt-6 text-[12px] text-white cursor-pointer w-80 rounded-[12px] shadow-md',
        {
          'bg-[#00c289]': notification.type === 'success',
          'bg-[#EF4444]': notification.type === 'error',
          'bg-[#eeac1e]': notification.type === 'warning',
          'bg-[#4B7BDF]': notification.type === 'info'
        }
      )}
      onClick={() => toaster.hide(notification)}
      onMouseOver={() =>
        !notification.persistent ? toaster.stopTimer(notification) : null
      }
      onMouseOut={() =>
        !notification.persistent ? toaster.resetTimer(notification) : null
      }
    >
      <svg
        viewBox="0 0 24 24"
        role="presentation"
        className="inline-flex flex-shrink-0 w-6 h-6 mr-4"
      >
        {notification.type === 'success' && <CheckCircleIcon />}
        {notification.type === 'error' && <ExclamationCircleIcon />}
        {notification.type === 'info' && <InformationCircleIcon />}
        {notification.type === 'warning' && <ExclamationIcon />}
      </svg>
      <div>
        {notification.title && (
          <h3 className="font-bold text-white text-[14px]">{notification.title}</h3>
        )}
        <p className='text-white text-[14px]'>{notification.message}</p>
      </div>
      {notification.showCloseButton && (
        <div className="ml-auto">
          <button
            type="button"
            className={classNames(
              'inline-flex items-center justify-center transition duration-200 focus:outline-none',
              {
                'rounded-full bg-black bg-opacity-5 hover:bg-opacity-6 absolute right-2 top-2 h-6 w-6':
                  notification.title.length,
                'text-white hover:text-white': !notification.title.length
              }
            )}
            onClick={() => toaster.hide(notification)}
          >
            <span className="sr-only">Close</span>
            <XIcon className="w-4 h-4" />
          </button>
        </div>
      )}
    </div>
  );
}

NotificationSnackbar.propTypes = {
  notification: PropTypes.exact({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    duration: PropTypes.number,
    delay: PropTypes.number,
    timer: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
    persistent: PropTypes.bool,
    greedy: PropTypes.bool,
    showCloseButton: PropTypes.bool
  }).isRequired
};
