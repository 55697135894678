import { projectStatusService } from "../../services";
import { uiConstants, projectStatusConstants as constants } from "../constants";

const status = () => {
  return (dispatch) => {
    dispatch({
      type: uiConstants.LOADING_UI,
    });
    projectStatusService
      .getProjectStatus()
      .then((response) => {
        dispatch({
          type: constants.RETRIEVE_PROJECT_STATUS,
          payload: response.data.data,
        });
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .then(() => {
        dispatch({ type: uiConstants.CLEAR_ERRORS });
      })
      .catch((response) => {
        console.error(response);
        if (response.data !== undefined) {
          dispatch({
            type: uiConstants.SET_ERRORS,
            payload: response.message,
          });
        }
      });
  };
};

export const projectStatusActions = { status };
