import { Disclosure } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag, SelectField } from '../../../../../components/Kits'
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft, BsCheckLg } from 'react-icons/bs'
import { BiCurrentLocation } from 'react-icons/bi'
import { Modal } from '../../../../../components/Modals'
import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToaster } from '../../../../../contexts/toaster'
import { projectActions } from '../../../../../redux/actions'
import http from '../../../../../http-common'

export default function ChooseForm({item, t, project, projectId, accessControl}){

    let [isOpen, setIsOpen] = useState(false)
    const user = useSelector((state) => state.user);
    const forms = useSelector((state) => state.forms);
    const toaster = useToaster()
   
    const [isLoading, setIsLoading] = useState(false)
    const [isConf, setIsConf] = useState(false)

    // const [forms, setForms] = useState([])
    const [billingAccounts, setBillingAccounts] = useState([])

    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])

    // async function refreshed(what) {
    //     await http.get( what === "forms" ? `/forms` : 
    //     what === "billingAccounts" ? `/billing-accounts` : 
    //     `/${what}?limit=0&columns=["name"]&populates=[]`)
    //     .then(function (response) {
    //         console.log('response 00000', response)
    //         if(response.status === 200) {
    //             what === "forms" ? setForms(response.data.data) :
    //             what === "billingAccounts" ? setBillingAccounts(response.data.data) :
    //             console.log("")
    //         }
    //     })
    //     .catch(function (error) {
    //         console.log(error)
    //     });
    // };

    // useEffect(() => {
    //     refreshed("forms")
    // }, [])


    const dispatch = useDispatch();
    
    const handleSubmitProject = async (e) => {
        e.preventDefault();
        if(isLoading===false){
            setIsLoading(true)
            await http.put(`/projects/${projectId}/`, itemData)
            .then(function (response) {
                if(response.status === 200){
                    setIsLoading(false)
                    toaster.show({
                        title: 'Success !',
                        message: `Your project has modified successfully`,
                        greedy: true,
                        type: 'success'
                    });
                    window.location.reload()
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                toaster.show({
                    title: 'Oups !',
                    message: error,
                    greedy: true,
                    type: 'error'
                });
            });
        }

        // console.log("itemData====>", itemData);
        // dispatch(projectActions.update(item?.id, itemData, setIsLoading, toaster, isConf));
    };
    


    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    // console.log('forms', forms?.filter(x => x?.id === project?.form?.id))

    return(
        <>
            <Disclosure className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
                {({ open }) => (
                    <div className={`rounded-lg overflow-hidden } `}>
                        <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                            <div className={`flex items-center justify-between gap-3 w-full`}>
                                <span className={`
                                    text-[15px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                }`}>
                                    {t("project:formofthesurvey")}
                                </span>
                                <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                            <div className={`col-span-1`}>
                                <div className='grid grid-cols-2 gap-4'>
                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{t("form:formname")}:</span>
                                        <span className='block text-[14px] text-black'>{project?.form?.name}</span>
                                    </div>
                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>{t("form:formtype")}:</span>
                                        <span className='block text-[14px] text-black'>{t("form:private")}</span>
                                    </div>
                                </div>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mt-4 text-[14px] text-black text-opacity-50 mb-[20px]'>{t("form:mappsfields")}:</span>
                                    <div className='grid grid-cols-2 gap-4'>
                                        {
                                            project?.form?.mapps?.map((field, k) =>(
                                                field?.label &&
                                                <div className="mb-[30px] flex items-center gap-2">
                                                    <BiCurrentLocation className='text-[25px] text-primary-blue-500' />
                                                    <div>
                                                        <span className='text-[14px] font-normal block'>{field?.label}</span>
                                                        <span className='text-[14px] font-normal block text-black text-opacity-50'>
                                                            type: 
                                                            &nbsp;
                                                            <span className='text-black text-opacity-100'>
                                                                {
                                                                    field?.type==='polygone' ? "Polygone" :
                                                                    field?.type==='onePosition' ? "One position" :
                                                                    field?.type==='continuous' ? "Continuous" : ""
                                                                }
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div>
                                    <span className='block mt-4 text-[14px] text-black text-opacity-50 mb-[20px]'>{t("form:formfields")}:</span>
                                    {
                                        project?.form?.fields?.map((field, k) =>(
                                            field?.type === 'select' ?
                                            <div className="mb-[30px]">
                                                <SelectField 
                                                    label={field?.label}
                                                    type={'number'}
                                                    smallLab={true}
                                                    options= {
                                                        field?.values?.map(x => (
                                                            {
                                                                value: x?.value,
                                                                name: x?.label
                                                            }
                                                        ))
                                                    }
                                                />
                                            </div>
                                            :
                                            field?.type === 'radio-group' || field?.type == 'checkbox-group' ?
                                            <div className="mb-[30px]">
                                                <span className="block text-[14px] text-black">
                                                    {field?.label}
                                                </span>
                                                {
                                                    field?.values?.map(x => (
                                                        <InputRadio 
                                                            label={x?.label}
                                                            value={x?.value}
                                                            name={field?.name}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            :
                                            field?.type === 'textarea' ?
                                            <div className="mb-[30px]">
                                                <Textarea 
                                                    label={field?.label}
                                                    smallLab={true}
                                                    type={'text'}
                                                    disabled
                                                />
                                            </div>
                                            :
                                            <div className="mb-[30px]">
                                                <Input 
                                                    label={field?.label}
                                                    type={field?.type}
                                                    smallLab={true}
                                                    disabled
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    (accessControl?.maintainer || accessControl?.owner) && (
                                        <div className='flex justify-end mb-[10px]'>
                                            {
                                                (project?.status?.name === 'Created' || project?.status?.name === 'Configuring') ? 
                                                <ButtonWithBg 
                                                    title={t("common:update")}
                                                    type='submit'
                                                    bg={'primary'}
                                                    isSmall={true}
                                                    onClick={() => toggleModal()}
                                                />
                                                :
                                                null
                                            }
                                        </div> 
                                    )
                                }
                                
                                
                            </div>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>

            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal}>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {t("project:choosenewform")}
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                <form onSubmit={(e) => handleSubmitProject(e)}>
                    <div className="p-6 max-h-[400px] overflow-x-hidden">
                        <div className="grid grid-cols-2 gap-4 mb-[30px]">
                            {forms?.filter(x => x?.id != project?.form?.id)?.map((item, idx) => (
                                <label>
                                    <input 
                                        type='radio' 
                                        name="form" 
                                        value={item?.id} 
                                        onChange={(e)=> setItemData({...itemData, formId:e.target.value})} 
                                        className="input-radio hidden" 
                                    />
                                    <div className="bg-white rounded-[6px] cursor-pointer p-[15px] border relative form-card">
                                        <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[30px] w-[30px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                            <BsCheckLg className='text-white text-[14px]' />
                                        </div>
                                        <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                                            {item?.name}
                                        </h4>
                                        <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4">
                                            {item?.description}
                                        </p>
                                        <div className="flex items-center justify-between gap-4">
                                            <div 
                                                className="flex items-center gap-2" 
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    // setModalMode("view_field")
                                                    // toggleModal()
                                                }}
                                            >
                                                {/* <span className="text-primary-blue-500 text-[14px] cursor-pointer">See field</span> */}
                                            </div>
                                            <Tag 
                                                title={item?.formType?.name}
                                                color={item?.formType?.name==='Private' ? 'text-[#6BCB77]' : "text-[#0B449A]"}
                                                bg={item?.formType?.name==='Private' ? 'bg-[#f0faf1]' : "bg-[#e7ecf5]"}
                                            />
                                        </div>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                        <ButtonWithBg 
                            title={isLoading ? t("common:inprogress") : t("common:savechange")}
                            type='submit'
                            bg={'primary'}
                            isSmall={true}
                        />
                    </div>
                </form>
            </Modal>
        </>
    )
}