const removeEmpty = (obj) => {
    Object.entries(obj).forEach(([key, val])  =>
      (val && typeof val === 'object') && removeEmpty(val) ||
      (val === null || val === undefined || val === "") && delete obj[key]
    );
    return obj;
};


const objectToQueryString = (obj) => {
    if(undefined === obj || Object.keys(obj).length === 0) {
        return '';
    } else {
        let qs = '?';
        const object = removeEmpty(obj);
        for (const property in object) {
            qs += `${property}=${object[property]}&`;
        }
        qs = qs.slice(0, -1);
        return qs;
    }
}

export { objectToQueryString, removeEmpty }