import { Disclosure } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag } from '../../../../../components/Kits'
import {BsChevronRight} from 'react-icons/bs'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from '../../../../../redux/actions'
import { useToaster } from '../../../../../contexts/toaster'
import http from '../../../../../http-common';

export default function RulesPolicies({item, t, project, projectId, accessControl}){
    const toaster = useToaster()
    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [isConf, setIsConf] = useState(false)

    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])

    const handleSubmitProject = async (e) => {
        e.preventDefault();
        if(isLoading===false){
            setIsLoading(true)
            await http.put(`/projects/${projectId}/`, itemData)
            .then(function (response) {
                if(response.status === 200){
                    setIsLoading(false)
                    toaster.show({
                        title: 'Success !',
                        message: `Your project has modified successfully`,
                        greedy: true,
                        type: 'success'
                    });
                    window.location.reload()
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                toaster.show({
                    title: 'Oups !',
                    message: error,
                    greedy: true,
                    type: 'error'
                });
            });
        }

        // console.log("itemData====>", itemData);
        // dispatch(projectActions.update(item?.id, itemData, setIsLoading, toaster, isConf));
      };

    return(
        <Disclosure className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
            {({ open }) => (
                <div className={`rounded-lg overflow-hidden } `}>
                    <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                        <div className={`flex items-center justify-between gap-3 w-full`}>
                            <span className={`
                                text-[15px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                            }`}>
                                {t("project:ruleandpolicies")}
                            </span>
                            <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                        <form onSubmit={(e) => handleSubmitProject(e)} className={`col-span-1`}>
                            <div className="grid grid-cols-2 gap-4 mt-[20px]">
                                <div className="mb-[30px]">
                                    <Input 
                                        label={t("project:surveystartdate")}
                                        placeholder={"ex: project name"}
                                        type={'date'}
                                        value={itemData?.startDate}
                                        onChange={(e)=>{
                                            setItemData({...itemData, startDate:e.target.value})
                                        }}
                                        smallLab={true}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                </div>
                                <div className="mb-[30px]">
                                    <Input 
                                        label={t("project:surevyenddate")}
                                        type={'date'}
                                        value={itemData?.endDate}
                                        onChange={(e)=>{
                                            setItemData({...itemData, endDate:e.target.value})
                                        }}
                                        smallLab={true}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mb-[30px]">
                                <div className="">
                                    <span className="block text-[14px]">
                                        {t("project:wheretoexecutesurvey")}
                                    </span>
                                    <InputRadio 
                                        label={t("project:anywhere")}
                                        name= 'area'
                                        value='anywhere'
                                        smallLab={true}
                                        checked={itemData?.executeZone=='anywhere' ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, executeZone:e.target.value})
                                        }}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                    <InputRadio 
                                        label={t("project:precisearea")}
                                        name= 'area'
                                        value='preciseArea'
                                        smallLab={true}
                                        disabled
                                        checked={itemData?.executeZone !=='anywhere' ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, executeZone:e.target.value})
                                        }}
                                    />
                                </div>
                                <div className="">
                                    <span className="block text-[14px]">
                                        {t("project:activateLocation")}
                                    </span>
                                    <InputRadio 
                                        label={t("common:no")}
                                        name= 'activeArea'
                                        value={false}
                                        smallLab={true}
                                        checked={itemData?.activeLocationExecution != true ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, activeLocationExecution:e.target.value})
                                        }}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                    <InputRadio 
                                        label={t("common:yes")}
                                        name= 'activeArea'
                                        value={true}
                                        smallLab={true}
                                        checked={itemData?.activeLocationExecution ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, activeLocationExecution:e.target.value})
                                        }}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                </div>
                                <div className="">
                                    <span className="block text-[14px]">
                                        {t("project:surveyexecutablefrom")}
                                    </span>
                                    <InputRadio 
                                        label={'Web'}
                                        name= 'surveyExecutable'
                                        value='Web'
                                        smallLab={true}
                                        disabled
                                        checked={itemData?.executeFrom=='Web' ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, executeFrom:e.target.value})
                                        }}
                                    />
                                    <InputRadio 
                                        label={'Mobile'}
                                        name= 'surveyExecutable'
                                        smallLab={true}
                                        value='Mobile'
                                        checked={itemData?.executeFrom=='Mobile' ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, executeFrom:e.target.value})
                                        }}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                </div>
                                <div className="">
                                    <span className="block text-[14px]">
                                        {t("project:doeschange")}
                                    </span>
                                    <InputRadio 
                                        label={t("common:no")}
                                        name= 'needFormalValidate'
                                        smallLab={true}
                                        value={false}
                                        checked={itemData?.isImpacting !== true ? true : false}
                                        onChange={(e)=>{
                                            setItemData({...itemData, isImpacting:e.target.value})
                                        }}
                                        disabled={!accessControl?.maintainer && !accessControl?.owner}
                                    />
                                    <InputRadio 
                                        label={t("common:yes")}
                                        name= 'needFormalValidate'
                                        smallLab={true}
                                        disabled
                                    />
                                </div>
                            </div>
                            {
                                (accessControl?.maintainer || accessControl?.owner) && (
                                <div className='flex justify-end mb-[10px]'>
                                    <ButtonWithBg 
                                        title={isLoading ?t("common:inprogress") : t('common:update')}
                                        type='submit'
                                        bg={'primary'}
                                        isSmall={true}
                                    />
                                </div>
                                )
                            }

                        </form>
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}