import React from 'react'
import { useSelector } from 'react-redux'
import CompleteAccount from '../pages/CompleteAccount'
// import WebSite from '../pages/landing'
import AccountVerified from '../pages/AccountVerified'
import Login from '../pages/auth/login'
import { authLogic } from './'

export const PrivateRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.token)
  const user = useSelector((state) => state.user)

  // console.log(user)
  if (user === null) {
    changeURL('/')
    return <Login />
  }

  if (user && !authLogic.isAuthenticated(token)) {
    changeURL('/')
    return <Login />
  }

  if (user && user.accountIsConfirmed === false) {
    changeURL('/verify-account')
    return <AccountVerified />
  }

  if (user && user.registrationIsCompleted === false) {
    changeURL('/complete-registration')
    return <CompleteAccount />
  }

  return children
}

function changeURL(url) {
  window.history.replaceState({}, null, url)
}
