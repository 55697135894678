import { projectTypesService } from "../../services/project.types.service";
import { projectTypesConstants, uiConstants } from "../constants";

const getAll = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  projectTypesService
    .getAll()
    .then((response) => {
      dispatch({
        type: projectTypesConstants.RETRIEVE_PROJECT_TYPES,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      dispatch({
        type: uiConstants.SET_ERRORS,
        // payload: response.response.data.message,
      });
    });
};

export const projectTypesActions = {
  getAll,
};
