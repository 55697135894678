import Base from "../../../layout/adm/base";
import { ProjectDetails } from "../../../section/admin/projects";

export default function ProjectOverview(){

    return(
        <Base>
            <ProjectDetails />
        </Base>
    )
}