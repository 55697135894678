import { InputSearch, Heading, Paragraph, ButtonWithBg, Input } from "../../../components/Kits";
import { Link } from "react-router-dom";
import Table from "./TableBillingAddress";
import { Modal } from "../../../components/Modals";
import { useState, useMemo, useEffect, Fragment } from "react";
import {Transition,  Listbox } from '@headlessui/react';
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { useToaster } from "../../../contexts/toaster";
import { useSelector, useDispatch } from "react-redux";
import { objectToQueryString } from "../../../utils/table-manager";

import {
    billingAddressActions
} from '../../../redux/actions'
import { useNavigate } from "react-router";
import http from "../../../http-common";
import ComboboxSelectCountry from "../../../components/Kits/Form/ComboBoxSelect";
import { useTranslation } from "react-i18next";

export default function BillingAddress(){
    const { t } = useTranslation(["common", "project", "form", "billing"]);
    const accountInitialState = {
        name: "",
        email: "",
        phone: ""
    };
    
    const addressInitialState = {
        address: "",
        city: "",
        state: "",
    }

    const toaster = useToaster()
    const dispatch = useDispatch();  
    const navigate = useNavigate();
    const billingAddress = useSelector((state) => state.billingAddress);
    const billingTypes = useSelector((state) => state.billingTypes);
    const [billingId, setBillingId] = useState(null)
    const [addressInfos, setAddressInfos] = useState(null)
    const [addressInfo, setAddressInfo] = useState(addressInitialState)
    const [isLoading, setLoading] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const [modalMode, setModalMode] = useState('')
    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }
    const [currentPageForms, setCurrentPageForms] = useState(1);
    const [paginationForms, setPaginationForms] = useState({
        pageSize: 9,
        total : 0,
      });
    const [loadingForms, setLoadingForms] = useState(false)
    const [errorsAdressInfoField, setErrorsAdressInfoField] = useState([])
    const [errorsBillingDataField, setErrorsBillingDataField] = useState([])
    let emptyAdressInfoKey = []
    let emptyBillingDataKey = []
    const [getId, setGetId] = useState(null)
    const [isCompleted, setIsCompleted] = useState(false)

    useEffect(()=>{
        dispatch(billingAddressActions.getAllBillingAddress());
    }, [])

    console.log('addressInfos', addressInfos)


    const createAddressBilling = async (e) => {
        if(isLoading === false){
            console.log('qqqqqqqqq', addressInfo)
            e.preventDefault()
            emptyAdressInfoKey = Object.entries(addressInfo).filter(([key]) => addressInfo[key] === '').map(([key]) => key)
            setErrorsAdressInfoField(emptyAdressInfoKey)
            if (emptyAdressInfoKey.length === 0) {
                console.log('bsbw')
                dispatch(billingAddressActions.create(addressInfo, toaster, setLoading, toggleModal, setModalMode, setIsCompleted, isCompleted))
                // await http.post(`/billing-address`, addressInfo).then((response) => {
                //     console.log(`post address`, response)
                // }).catch((err) => {
                //     console.log(`err`, err)
                // })
            }
        }
    }

    const updateAddressBilling = async (e) => {
        
        let values = {
            address: addressInfos?.address, 
            city: addressInfos?.city, 
            state: addressInfos?.state, 
        }
        e.preventDefault()
        emptyAdressInfoKey = Object.entries(addressInfos).filter(([key]) => ((key === 'address' && addressInfos[key] === '') || (key === 'state' && addressInfos[key] === '') || (key === 'city' && addressInfos[key] === ''))).map(([key]) => key)
        console.log('emptyAdressInfoKey', emptyAdressInfoKey)
        setErrorsAdressInfoField(emptyAdressInfoKey)
        if (emptyAdressInfoKey.length === 0) {
            console.log('qqqqqqqqq', values)
            dispatch(billingAddressActions.update(addressInfos?.id, values, setLoading, toaster, toggleModal))
        }
    }

    const deleteAddressBilling = async (e) => {
        dispatch(billingAddressActions.deletedBillingAddress(addressInfos?.id, setLoading, toaster, toggleModal))
    }



    return(
        <div>
            <div className="flex items-center justify-between mb-[50px]">
                <div className="max-w-[40%]">
                    <Heading title={t("billing:billingaddress")} mb={"10px"} />
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <div className="w-[300px]">
                        <InputSearch 
                            placeholder="Search projects, forms..."
                        />
                    </div>
                </div>
                <ButtonWithBg 
                    title={t("billing:addaddress")}
                    type='button'
                    bg={'primary'}
                    isSmall={true}
                    onClick={()=>{
                        setIsCompleted(true)
                        setModalMode("billing_adress")
                        toggleModal()
                    }}
                />
            </div>
            <Table 
                list={billingAddress} 
                setModalMode={setModalMode} 
                toggleModal={toggleModal} 
                setAddressInfos={setAddressInfos} 
                setBillingId={setBillingId} 
                t={t}
            />
            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal} resetOverflow>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode === 'billing_adress' ? t("billing:billingaddress") :
                            modalMode === 'view_address' ? t("billing:billingaddress") :
                            modalMode === 'updateBillingAddress' ? t("billing:changebillingaddress") :
                            modalMode === 'listBillingAccount' ? t("billing:billingaccounts") :
                            modalMode === 'delete' ? t("billing:deletebillingaddress") :
                            null
                        }
                    </h4>
                    <div 
                        onClick={()=>{
                            toggleModal() 
                            setErrorsAdressInfoField([])
                            setErrorsBillingDataField([])
                        }}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                {  modalMode === 'billing_adress' &&
                    <form onSubmit={(e) => createAddressBilling(e)}>
                        <div className="max-h-[400px]">
                            <div className="p-6">
                                <Input 
                                    label={t("billing:address")}
                                    type={'text'}
                                    value={addressInfo?.address}
                                    onChange={(e)=>{
                                        setAddressInfo({...addressInfo, address:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("address") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                <Input 
                                    label={t("billing:city")}
                                    type={'text'}
                                    value={addressInfo?.city}
                                    onChange={(e)=>{
                                        setAddressInfo({...addressInfo, city:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("city") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                {/* <Input 
                                    label={'Country'}
                                    type={'text'}
                                    value={addressInfo?.state}
                                    onChange={(e)=>{
                                        setAddressInfo({...addressInfo, state:e.target.value})
                                    }}
                                    smallLab={true}
                                /> */}
                                <ComboboxSelectCountry 
                                    label={t("billing:country")}
                                    setAddressInfo={setAddressInfo}
                                    addressInfo={addressInfo}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("state") && "**This field is required"
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("common:add")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                            />
                        </div>
                    </form>
                }
                {  modalMode === 'updateBillingAddress' &&
                    <form onSubmit={(e) => updateAddressBilling(e)}>
                        <div className="max-h-[400px] overflow-x-hidden">
                            <div className="p-6">
                                <Input 
                                    label={t("billing:address")}
                                    type={'text'}
                                    value={addressInfos?.address}
                                    onChange={(e)=>{
                                        setAddressInfos({...addressInfos, address:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("address") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                <Input 
                                    label={t("billing:city")}
                                    type={'text'}
                                    value={addressInfos?.city}
                                    onChange={(e)=>{
                                        setAddressInfos({...addressInfos, city:e.target.value})
                                    }}
                                    smallLab={true}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("city") && "**This field is required"
                                    }
                                </span>
                            </div>
                            <div className="p-6">
                                {/* <Input 
                                    label={'Country'}
                                    type={'text'}
                                    value={addressInfos?.state}
                                    onChange={(e)=>{
                                        setAddressInfos({...addressInfos, state:e.target.value})
                                    }}
                                    smallLab={true}
                                /> */}
                                <ComboboxSelectCountry 
                                    label={t("billing:country")}
                                    setAddressInfo={setAddressInfos}
                                    addressInfo={addressInfos}
                                    addressInfos={addressInfos}
                                />
                                <span className='text-[12px] text-red-500'>
                                    {
                                        errorsAdressInfoField?.includes("state") && "**This field is required"
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? t("common:inprogress") : t("common:update")}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                            />
                        </div>
                    </form>
                }
                {
                    modalMode === "listBillingAccount" &&
                    <div className="max-h-[400px] overflow-x-hidden p-6">
                        <div className="">
                            <h3 className="text-[15px] text-gray-700 mb-[8px]">{`Billing account name's`}</h3>
                            {
                                addressInfos?.billingAccounts?.map((account, idx) => (
                                    <div className="text-[14px] text-gray-500 border-t py-[8px]">
                                        {`${idx + 1}- ${account?.name}`}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    modalMode === 'delete' &&
                    <div className="max-h-[400px] overflow-x-hidden">
                        <div className="p-6">
                            <h4 className='text-[16px] font-bold mb-4'>
                                {`Attention this action is irreversible`}
                            </h4>
                            <p className='text-[14px] text-black text-opacity-70'>
                                {`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}
                            </p>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={isLoading ? 'Deleting...' : 'Delete'}
                                type='submit'
                                bg={'red'}
                                isSmall={true}
                                onClick={() => deleteAddressBilling(addressInfo?.id)}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </div>
    )
}