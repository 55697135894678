export const retirerId = (alpha) => {
    if(typeof alpha === 'string'){
        let formatArray = alpha?.split(';')
        let newAlpha = formatArray.filter(item => !item.startsWith('id='))
        return newAlpha
    } else{
        return alpha
    }
};

export const getSurveyId = (alpha) => {
    if(typeof alpha === 'string'){
        let formatArray = alpha?.split(';')
        let newAlpha = formatArray.filter(item => item.startsWith('id='))

        if(newAlpha?.length > 0){
            let newSplit = newAlpha[0].split('id=')[1]
            return newSplit
        } else {
            return alpha
        }
    } else{
        return alpha
    }
};