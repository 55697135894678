import http from '../http-common'

const getAll = () => {
  return http.get(`/projects?page=1&limit=100`)
}

const get = (id) => {
  return http.get(`/projects/${id}`)
}

const create = (data) => {
  return http.post('/projects', data)
}

const update = (id, data) => {
  return http.put(`/projects/${id}`, data)
}

const updateProjectStatus = (id, data) => {
  return http.put(`/projects/update-status/${id}`, data)
}

const remove = (id, projectNumber) => {
  return http.delete(`/projects?projectId=${id}&projectNumber=${projectNumber}`)
}

const removeAll = () => {
  return http.delete('/projects')
}

const findByTitlte = (title) => {
  return http.get(`/projects?title=${title}`)
}

const projectService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitlte,
  updateProjectStatus
}

export default projectService
