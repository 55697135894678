import { formConstants } from '../constants'

const initialState = []

export default function (forms = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case formConstants.CREATE_FORMS:
      return [payload, ...forms]
    case formConstants.RETRIEVE_FORMS:
      return payload
    case formConstants.UPDATE_FORMS:
      return forms.map((form) => {
        if (form.id === payload.id) {
          return {
            ...form,
            ...payload,
          }
        } else {
          return form
        }
      })
    case formConstants.DELETE_FORMS:
      console.log('payload.id', payload.id)
      return forms.filter(({ id }) => id !== payload.id)
    case formConstants.DELETE_ALL_FORMS:
      return []
    default:
      return forms
  }
}
