import { billingTypesConstants } from '../constants'

const initialState = []

export default function (billingTypes = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case billingTypesConstants.RETRIEVE_BILLING_TYPES:
      return payload
    default:
      return billingTypes
  }
}
