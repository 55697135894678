import { billingAccountConstants, uiConstants } from "../constants";
import { toast } from "react-toastify";
import billingAccountService from "../../services/billingAccount.service";

const create = (billingAccount, navigate, toaster, setLoading, setBillingId, setModalMode) => (dispatch) => {
  setLoading(true)
  dispatch({ type: uiConstants.LOADING_UI });
  billingAccountService
    .create(billingAccount)
    .then((response) => {
      // console.log('response billing', response)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAccountConstants.CREATE_BILLINGACCOUNT,
        payload: response.data.data,
      });
      toaster.show({
        title: 'Success !',
        message: `Your billing account has created successfully"`,
        greedy: true,
        type: 'success'
      });
      setBillingId(response.data.data.id)
      setModalMode("choose_payment_method")
      setLoading(false)
    })
    .catch((response) => {
      console.log('response', response)
      toaster.show({
        title: 'Oups error !',
        message: `${response.message}`,
        greedy: true,
        type: 'error'
      });
      setLoading(false)
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    });
};

const getAllBillingAccount = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  billingAccountService
    .getAll()
    .then((response) => {
      dispatch({
        type: billingAccountConstants.RETRIEVE_BILLINGACCOUNTS,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      if (response.data !== undefined) {
        dispatch({
          type: uiConstants.SET_ERRORS,
          payload: response.data.message,
        });
      }
    });
};

const update = (id, data, setLoading, toaster, toggleModal, setBillingFilter) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await billingAccountService.update(id, data);
    if(res.status === 201){
      setLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAccountConstants.UPDATE_BILLINGACCOUNT,
        payload: res.data.data,
      });
      // setBillingFilter((tmp) => {
      //   return tmp.map((el) =>
      //     el?.id === id
      //       ? {
      //           ...el,
      //           ...res.data.data
      //         }
      //       : el
      //   );
      // })
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      toggleModal()
    }

    return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
};

const addStripeTokenToBillingAccount = (data, setLoading, toaster, setModalMode) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await billingAccountService.addStripeTokenToBillingAccount(data);
    if(res.status == 200){
      setLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      setModalMode("choose_billing_address")
      // getAllBillingAccount()
      // dispatch({
      //   type: billingAccountConstants.UPDATE_BILLINGACCOUNT,
      //   payload: res.data.data,
      // });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      
    }

    // return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err", err)
    // toaster.show({
    //   title: 'Oups error !',
    //   message: `${err.response.data.message}`,
    //   greedy: true,
    //   type: 'error'
    // });
    return Promise.reject(err);
  }
};

const addAdresssBillingAccount = (data, setLoading, toaster, setModalMode, toggleModal) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await billingAccountService.addAdresssBillingAccount(data);
    if(res.status === 201){
      setLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      // getAllBillingAccount()
      window.location.reload()
      toggleModal()
      // dispatch({
      //   type: billingAccountConstants.UPDATE_BILLINGACCOUNT,
      //   payload: res.data.data,
      // });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      
    }

    // return Promise.resolve(res.data.data);
  } catch (err) {
    setLoading(false)
    console.log("err bhhh", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
};

const bindBillingAddressToBillingAccount = (data, setLoading, toaster, setModalMode, toggleModal, ) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await billingAccountService.bindBillingAddressToBillingAccount(data);
    if(res.status === 200){
      setLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAccountConstants.UPDATE_BILLINGACCOUNT,
        payload: {
          id: data?.billingAccountId,
          billingAddress: [res.data.data],
          status: res?.data?.data?.billingAccount?.status,
        }
      });
      // window.location.reload()
      toggleModal()
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
    }
  } catch (err) {
    setLoading(false)
    console.log("err bhhh", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
};

const bindPaymentMethodToBillingAccount = (data, setLoading, toaster, setModalMode, toggleModal, setIsCompleted, isCompleted) => async (dispatch) => {
  setLoading(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
      // payload: data,
    });
    const res = await billingAccountService.bindPaymentMethodToBillingAccount(data);
    if(res.status === 200){
      setLoading(false)
      console.log('resres', res)
      dispatch({ type: uiConstants.CLEAR_ERRORS });
      dispatch({
        type: billingAccountConstants.UPDATE_BILLINGACCOUNT,
        payload: {
          id: data?.billingAccountId,
          paymentMethodeId: data?.paymentMethodeId
        },
      });
      toaster.show({
        title: 'Success !',
        message: `Your project has modified successfully`,
        greedy: true,
        type: 'success'
      });
      if(isCompleted){
        // window.location.reload()
        toggleModal()
      }else{
        setModalMode("choose_billing_address")
        setIsCompleted(false)
      }
    }
  } catch (err) {
    setLoading(false)
    console.log("err bhhh", err)
    toaster.show({
      title: 'Oups error !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    return Promise.reject(err);
  }
};


const remove = (id, toaster, setIsDeleted, toggleModal) => async (dispatch) => {
  setIsDeleted(true)
  try {
    dispatch({
      type: uiConstants.LOADING_UI,
    });

    const res = await billingAccountService.remove(id);

    if(res.status == 200){
      toaster.show({
        title: 'Success !',
        message: `Billing account successfully deleted`,
        greedy: true,
        type: 'success'
      });
      setIsDeleted(false)
      toggleModal()
    }

    dispatch({
      type: billingAccountConstants.DELETE_BILLINGACCOUNT,
      payload: { id },
    });
  } catch (err) {
    toaster.show({
      title: 'Oups !',
      message: `Something was wrong`,
      greedy: true,
      type: 'error'
    });
    setIsDeleted(false)
    return Promise.reject(err);
  }
};

export const billingAccountActions = {
  create,
  getAllBillingAccount,
  update,
  remove,
  addStripeTokenToBillingAccount,
  addAdresssBillingAccount,
  bindBillingAddressToBillingAccount,
  bindPaymentMethodToBillingAccount
};
