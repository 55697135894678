import Base from "../../../layout/adm/base";
import DataHub from "../../../section/admin/DataHub";
import { ProjectDetails } from "../../../section/admin/projects";

export default function All(){

    return(
        <Base>
            <DataHub />
        </Base>
    )
}