import http from '../http-common'

const getAll = () => {
  return http.get('/form-data-sources?page=1&limit=1000')
}

const get = (id) => {
  return http.get(`/form-data-sources/${id}`)
}

const createDatabaseSource = (data) => {
  return http.post('/form-data-sources', data)
}

const postData = (data) => {
  return http.post('/data-sources', data)
}
const updateData = (id, data) => {
  return http.put(`/data-sources/${id}`, data)
}
const deleteData = (id) => {
  return http.delete(`/data-sources/${id}`)
}

const update = (id, data) => {
  return http.put(`/form-data-sources/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/form-data-sources/${id}`)
}

const getDataSourceById = (id) => {
  return http.get(`/datas-sources-by-form-data-source-id/${id}`)
}

export const databaseSourceService = {
  getAll,
  get,
  createDatabaseSource,
  update,
  remove,
  postData,
  getDataSourceById,
  updateData,
  deleteData
}
