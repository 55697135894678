import { Heading, InputSearch, Paragraph, ButtonWithBg } from "../../../components/Kits";
import { uiConstants } from "../../../redux/constants";
import { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import Projects from "./Projects";
import Forms from "./Forms";
import { useSelector, useDispatch } from "react-redux";
import {
  formActions,
  formTypesActions,
  moduleMappingActions,
  projectActions,
  projectTypesActions,
  surveyDatasStatusActions,
  userActions,
  billingAccountActions,
  billingTypesActions,
  billingAddressActions,
  databaseSourceActions,
  paymentsMethodActions,
  appSettingActions,
  projectRolesActions
} from "../../../redux/actions";
import { projectStatusActions } from "../../../redux/actions/project.status.actions";
import { useTranslation } from "react-i18next";
import { objectToQueryString } from "../../../utils/table-manager";
import http from "../../../http-common";
import { ModalAcceptedProject } from "../../../components/Modals";
import { AiOutlineClose } from 'react-icons/ai'
import { useToaster } from "../../../contexts/toaster";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Content() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const toaster = useToaster()
  const projects = useSelector((state) => state.projects);
  const projectStatus = useSelector((state) => state.projectStatus);
  const forms = useSelector((state) => state.forms);
  const { t } = useTranslation(["common"]);

  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingReject, setLoadingReject] = useState(false)
  const [openAcceptanceModal, setOpenAcceptanceModal] = useState(false)
  const toggleOpenAcceptanceModal = () => {
    setOpenAcceptanceModal(!openAcceptanceModal)
  }

  const handleAcceptProject = () => {
    dispatch(projectActions.acceptProject(project, user?.userId, setLoading, toaster, toggleOpenAcceptanceModal))
  }

  const handleRejectProject = () => {
    dispatch(projectActions.rejectProject(project, user?.userId, setLoadingReject, toaster, toggleOpenAcceptanceModal))
  }


  useEffect(() => {
    dispatch(projectRolesActions.projectRoles())
    dispatch(projectActions.projects());
    dispatch(billingAccountActions.getAllBillingAccount());
    dispatch(billingAddressActions.getAllBillingAddress());
    dispatch(projectStatusActions.status());
    dispatch(formTypesActions.getTypes());
    dispatch(billingTypesActions.getTypes());
    dispatch(surveyDatasStatusActions.getAll());
    dispatch(formActions.forms());
    dispatch(userActions.plateFormUsers());
    dispatch(userActions.getUserLang());
    dispatch(userActions.getUserStudyArea());
    dispatch(moduleMappingActions.getDataMap());
    dispatch(projectTypesActions.getAll());
    dispatch(databaseSourceActions.databases());
    dispatch(paymentsMethodActions.getAllPaymentsMethod());
    dispatch(appSettingActions.getAppsAreas());
    dispatch(appSettingActions.getAppLang());
  }, []);


  return (
    <>
      <div className="flex items-center justify-between">
        <div className="max-w-[40%]">
          <Heading title={t("common:surveyconsole")} mb={"10px"} />
        </div>
        <div className="w-[400px] hidden">
          <InputSearch placeholder="Search projects, forms..." />
        </div>
      </div>
      <div className="mt-[50px]">
        <Tab.Group>
          <Tab.List className="inline-flex bg-white p-2 gap-2 rounded-[6px]">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-[6px] flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                  "focus:outline-none",
                  selected
                    ? "bg-primary-blue-500 text-white"
                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                )
              }
            >
              {t("common:projects")}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-[6px] flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                  "focus:outline-none",
                  selected
                    ? "bg-primary-blue-500 text-white"
                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                )
              }
            >
              {t("common:forms")}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2 pt-[30px]">
            <Tab.Panel className={classNames("focus:outline-none")}>
              <Projects 
                projects={projects} 
                loadingProject={false} 
                length={9} 
                user={user} 
                toggleOpenAcceptanceModal={toggleOpenAcceptanceModal} 
                setProject={setProject}
              />
            </Tab.Panel>
            <Tab.Panel className={classNames("focus:outline-none")}>
              <Forms forms={forms} loadingForms={false} length={9} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      
      <ModalAcceptedProject 
        openAcceptanceModal={openAcceptanceModal}
        toggleOpenAcceptanceModal={toggleOpenAcceptanceModal}
        loading={loading}
        loadingReject={loadingReject}
        handleAcceptProject={handleAcceptProject}
        handleRejectProject={handleRejectProject}
      />
    </>
  );
}
