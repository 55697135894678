import { projectRolesService } from "../../services/project.roles.service";
import { projectRolesConstants, uiConstants } from "../constants";

const projectRoles = () => (dispatch) => {
  dispatch({ type: uiConstants.LOADING_UI });
  projectRolesService
    .getAll()
    .then((response) => {
      dispatch({
        type: projectRolesConstants.RETRIEVE_PROJECT_ROLES,
        payload: response.data.data,
      });
      dispatch({ type: uiConstants.CLEAR_ERRORS });
    })
    .catch((response) => {
      dispatch({
        type: uiConstants.SET_ERRORS,
        // payload: response.response.data.message,
      });
    });
};

export const projectRolesActions = {
  projectRoles,
};
