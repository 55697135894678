import { Link } from "react-router-dom";
import { Tag } from "../Kits";
import { useSelector } from "react-redux";
import { isUserAccepted } from "../../utils/checkAcceptanceProjectStatus";


export default function ProjectCard({item, toggleOpenAcceptanceModal, setProject, ...rest}){
    const user = useSelector((state) => state.user);
    // isUserAccepted(item, user?.userId)
    return (
        <>
            {
                isUserAccepted(item, user?.userId) ? (
                    <div 
                        onClick={() => {
                            setProject(item)
                            toggleOpenAcceptanceModal()
                        }} 
                        className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between cursor-pointer"
                    >
                        { 
                            item?.number && 
                            <span className="text-[12px] text-primary-blue-500 font-bold block mb-1">{`#ID: ${item?.number}`}</span>
                        }
                        <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                            {item?.name}
                        </h4>
                        <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4 lowercase">
                            {
                                item?.description
                            }
                        </p>
                        <div className="flex items-center justify-between gap-4">
                            <div className="flex items-center gap-2">
                                {
                                item?.projectUsers?.length == 0 ? 
                                <span className="text-[12px] text-black text-opacity-50">
                                    Aucun member
                                </span>
                                :
                                <>
                                    <div className="flex items-center">
                                    { item?.projectUsers?.length > 0 &&
                                        <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                        <img
                                            src="/images/user_default.png"
                                            alt="member"
                                            className="h-full w-full object-cover"
                                        />
                                        </div>
                                    }
                                    { item?.projectUsers?.length > 1 &&
                                        <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                        <img
                                        src="/images/woman.png"
                                        alt="member"
                                        className="h-full w-full object-cover"
                                        />
                                        </div>
                                    }
                                    { item?.projectUsers?.length > 2 &&
                                        <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                        <img
                                            src="/images/user_default.png"
                                            alt="member"
                                            className="h-full w-full object-cover"
                                        />
                                        </div>
                                    }
                                    </div>
                                    { item?.projectUsers?.length > 3 &&
                                    <span className="text-[12px] text-black text-opacity-50">
                                        and {item?.projectUsers?.length - 3} others
                                    </span>
                                    }
                                
                                </>
                                }
                            </div>
                            <Tag
                                title={'Pending'}
                                color={"text-[#FFA63A]"}
                                bg={"bg-[#FFE6B0]"}
                            />
                        </div>
                        <div className="flex items-center justify-between gap-4 mt-4">
                            <span className="text-[12px] text-black">{item?.startDate ? new Date(item?.startDate).toLocaleDateString() : '----------'}</span>
                            <span className="text-[12px] text-black">{item?.endDate ? new Date(item?.endDate).toLocaleDateString() : '----------'}</span>
                        </div>
                    </div>
                ) :(
                <Link {...rest} to={ item?.status?.name !== "Configuring" ? "/adm/projects/details-projects" : "/adm/projects/config-project"} state={{ item: item }}>
                    <div className="bg-white rounded-[6px] p-[15px] h-full flex flex-col justify-between">
                        { 
                            item?.number && 
                            <span className="text-[12px] text-primary-blue-500 font-bold block mb-1">{`#ID: ${item?.number}`}</span>
                        }
                        <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-4">
                            {item?.name}
                        </h4>
                        <p className="text-[12px] text-black text-opacity-50 limit-2 mb-4 lowercase">
                        {
                        item?.description
                        }
                        </p>
                        <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-2">
                            {
                            item?.projectUsers?.length == 0 ? 
                            <span className="text-[12px] text-black text-opacity-50">
                                Aucun member
                            </span>
                            :
                            <>
                                <div className="flex items-center">
                                { item?.projectUsers?.length > 0 &&
                                    <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                    <img
                                        src="/images/user_default.png"
                                        alt="member"
                                        className="h-full w-full object-cover"
                                    />
                                    </div>
                                }
                                { item?.projectUsers?.length > 1 &&
                                    <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                    <img
                                    src="/images/woman.png"
                                    alt="member"
                                    className="h-full w-full object-cover"
                                    />
                                    </div>
                                }
                                { item?.projectUsers?.length > 2 &&
                                    <div className="border border-secondary-green-500 rounded-full overflow-hidden h-[30px] w-[30px] first:ml-0 -ml-3">
                                    <img
                                        src="/images/user_default.png"
                                        alt="member"
                                        className="h-full w-full object-cover"
                                    />
                                    </div>
                                }
                                </div>
                                { item?.projectUsers?.length > 3 &&
                                <span className="text-[12px] text-black text-opacity-50">
                                    and {item?.projectUsers?.length - 3} others
                                </span>
                                }
                            
                            </>
                            }
                        </div>
                        <Tag
                            title={item?.status?.name}
                            color={
                            item?.status?.name == 'Completed' ? "text-[#0B449A]" : 
                            item?.status?.name == 'Running' ? "text-[#6BCB77]" : 
                            "text-[#000]"
                            }
                            bg={
                            item?.status?.name == 'Completed' ? "bg-[#e7ecf5]" : 
                            item?.status?.name == 'Running' ? "bg-[#f0faf1]" : 
                            "bg-[#F6F7FB]"
                            }
                        />
                        </div>
                        <div className="flex items-center justify-between gap-4 mt-4">
                            <span className="text-[12px] text-black">{item?.startDate ? new Date(item?.startDate).toLocaleDateString() : '----------'}</span>
                            <span className="text-[12px] text-black">{item?.endDate ? new Date(item?.endDate).toLocaleDateString() : '----------'}</span>
                        </div>
                    </div>
                </Link>
                )
            }
        </>
    )
}