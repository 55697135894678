import { Modal } from "../../../../components/Modals"
import { AiOutlineClose } from "react-icons/ai"
import { BiCurrentLocation } from "react-icons/bi"
import { 
    SelectField,
    InputRadio,
    Textarea,
    Input
} from "../../../../components/Kits"

export default function ViewForm({
    isOpen,
    toggleModal,
    getFields,
    t
}){

    return (
        <Modal show={isOpen} toggleModal={toggleModal}>
            <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                <h4>
                    {t("project:seefields")}
                </h4>
                <div 
                    onClick={()=>{toggleModal()}}
                    className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                    <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                </div>
            </div>
            <div className="p-6 max-h-[400px] overflow-x-hidden">
                <div className={`pb-2 border-b border-dashed border-black border-opacity-10 ${getFields?.mapps?.length==0 || (getFields?.mapps?.length==1 && getFields?.mapps?.[0]?.label == '') ? "hidden" : "block"}`}>
                    <span className='block mt-4 text-[14px] text-black text-opacity-50 mb-[20px]'>{t("form:mappsfields")}:</span>
                    <div className='grid grid-cols-2 gap-4'>
                        {
                            getFields?.mapps?.map((field, k) =>(
                                field?.label &&
                                <div className="mb-[30px] flex items-center gap-2">
                                    <BiCurrentLocation className='text-[25px] text-primary-blue-500' />
                                    <div>
                                        <span className='text-[14px] font-normal block'>{field?.label}</span>
                                        <span className='text-[14px] font-normal block text-black text-opacity-50'>
                                            type: 
                                            &nbsp;
                                            <span className='text-black text-opacity-100'>
                                                {
                                                    field?.type=='polygone' ? "Polygone" :
                                                    field?.type=='onePosition' ? "One position" :
                                                    field?.type=='continuous' ? "Continuous" : ""
                                                }
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className=''>
                    <span className='block mt-4 text-[14px] text-black text-opacity-50 mb-[20px]'>{t("form:formfields")}:</span>
                    {
                        getFields?.fields?.map((field, k) =>(
                            field?.type == 'select' ?
                            <div className="mb-[30px]">
                                <SelectField
                                    label={field?.label}
                                    type={'number'}
                                    smallLab={true}
                                    options= {
                                        field?.values?.map(x => (
                                            {
                                                value: x?.value,
                                                name: x?.label
                                            }
                                        ))
                                    }
                                />
                            </div>
                            :
                            field?.type == 'radio-group' || field?.type == 'checkbox-group' ?
                            <div className="mb-[30px]">
                                <span className="block text-[14px] text-black">
                                    {field?.label}
                                </span>
                                {
                                    field?.values?.map(x => (
                                        <InputRadio 
                                            label={x?.label}
                                            value={x?.value}
                                            name={field?.name}
                                        />
                                    ))
                                }
                            </div>
                            :
                            field?.type == 'textarea' ?
                            <div className="mb-[30px]">
                                <Textarea 
                                    label={field?.label}
                                    smallLab={true}
                                    type={'text'}
                                    disabled
                                />
                            </div>
                            :
                            <div className="mb-[30px]">
                                <Input 
                                    label={field?.label}
                                    type={field?.type}
                                    smallLab={true}
                                    disabled
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}