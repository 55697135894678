import { useState, Fragment, useEffect } from "react";
import { t } from "i18next";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { ButtonWithBg, FormikSelect } from "../Kits";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ModalExportData({
    downloadInfo, 
    setDownloadInfo, 
    exportData,
    exportDataGeoLocation,

    item,
    surveyDatasStatus,
    externalProject, 

    pagination
}){
    const start = (downloadInfo.page - 1) * pagination.pageSize + 1;
    const end = Math.min(downloadInfo.page * pagination.pageSize, pagination.totalCount);

    console.log('item', item)
    console.log('externalProject item', externalProject)
    console.log('downloadInfo', downloadInfo)
    console.log('pagination', pagination)


    // {
    //     id:survey?.id,
    //                 created_at : survey?.createdAt ? new Date(survey?.createdAt) : "---------",
    //                 agent: survey?.user?.lastName +" "+ survey?.user?.firstName,
    //                 status: displayStatusName(survey?.status?.id),
    // }

    const baseFields = [
        {
            label: `Id (Mapping)`,
            value: `number`,
        },
        {
            label: `Date de création`,
            value: `createdAt`,
        },
        {
            label: `Agent`,
            value: `user`,
        },
        {
            label: `Status de la saisie`,
            value: `status`,
        }
    ]

    return(
        <>
            <div className="p-6 min-h-[450px] overflow-x-hidden">
                <Tab.Group>
                    <Tab.List className="inline-flex gap-2 overflow-y-hidden">
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                "focus:outline-none",
                                selected
                                    ? "bg-primary-blue-500 text-white"
                                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                )
                            }
                            onClick={(e)=>{
                                setDownloadInfo({
                                    ...downloadInfo,
                                    type: "table",
                                    format: 'excel',
                                    concernedField: '',
                                    surveysStatus: [],
                                    fileName: []
                                })
                            }}
                        >
                            {t("project:table")}
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                "focus:outline-none",
                                selected
                                    ? "bg-primary-blue-500 text-white"
                                    : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                )
                            }
                            onClick={(e)=>{
                                setDownloadInfo({
                                    ...downloadInfo,
                                    type: "geolocation",
                                    format: 'gpx',
                                    concernedField: '',
                                    surveysStatus: [],
                                    fileName: []
                                })
                            }}
                        >
                            {t("project:geolocation")}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className="mt-2 pt-[30px]">
                        <Tab.Panel className={classNames("focus:outline-none")}>
                            <form>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{t("project:formattoexport")}</label>
                                    <select 
                                        value={downloadInfo?.format} 
                                        onChange={(e)=>{
                                            setDownloadInfo({
                                                ...downloadInfo,
                                                format: e.target.value
                                            })
                                        }}
                                        className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                    >
                                        <option value='excel'>Excel</option>
                                        <option value='csv'>Csv</option>
                                    </select>
                                </div>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{`Sélectionner la page que vous exporter`}</label>
                                    <select 
                                        value={downloadInfo?.page} 
                                        onChange={(e)=>{
                                            setDownloadInfo({
                                                ...downloadInfo,
                                                page: parseFloat(e.target.value)
                                            })
                                        }}
                                        className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                    >
                                       {Array.from({ length: pagination.total }, (_, i) => (
                                            <option key={i + 1} value={i + 1}>
                                                Page {i + 1}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="mt-2 text-[12px] text-black">
                                        {`infos: Export de ${start} à ${end}`}
                                    </p>
                                </div>
                            </form>
                        </Tab.Panel>
                        <Tab.Panel className={classNames("focus:outline-none")}>
                            <form>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{t("project:formattoexport")}</label>
                                    <select 
                                        value={downloadInfo?.format} 
                                        onChange={(e)=>{
                                            setDownloadInfo({
                                                ...downloadInfo,
                                                format: e.target.value
                                            })
                                        }}
                                        className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1">
                                        <option value='gpx'>GPX Waypoint</option>
                                        <option value='kml'>KML</option>
                                        <option value='gpxTrack'>GPX Track</option>
                                    </select>
                                </div>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{`Sélectionner la page que vous exporter`}</label>
                                    <select 
                                        value={downloadInfo?.page} 
                                        onChange={(e)=>{
                                            setDownloadInfo({
                                                ...downloadInfo,
                                                page: parseFloat(e.target.value)
                                            })
                                        }}
                                        className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                    >
                                       {Array.from({ length: pagination.total }, (_, i) => (
                                            <option key={i + 1} value={i + 1}>
                                                Page {i + 1}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="mt-2 text-[12px] text-black">
                                        {`infos: Export de ${start} à ${end}`}
                                    </p>
                                </div>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{`Champ concerné`}</label>
                                    <FormikSelect 
                                        options= {item?.form?.mapps?.map((el) => (
                                            {
                                                label: el?.label,
                                                value: el?.name
                                            }
                                        ))}
                                        onChange={(e) => {
                                            // console.log('formik log', {...downloadInfo, concernedField: e.value})
                                            setDownloadInfo({...downloadInfo, concernedField: e.value})
                                        }}
                                    />
                                </div>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{`Status de saisies concernés`}</label>
                                    <FormikSelect 
                                        isMulti
                                        options= {surveyDatasStatus?.map((el) => (
                                            {
                                                label: el?.name,
                                                value: el?.id
                                            }
                                        ))}
                                        onChange={(e) => {
                                            // console.log('formik log', {...downloadInfo, surveysStatus: e.map(k => k.value)})
                                            setDownloadInfo({...downloadInfo, surveysStatus: e.map(k => k.value)})
                                        }}
                                    />
                                </div>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{`Nommage des fichiers`}</label>
                                    <FormikSelect 
                                        isMulti
                                        options= {(item?.configuration?.useExternalProject && externalProject) ? [
                                            ...baseFields,
                                            ...item?.form?.fields?.map(k => (
                                                {
                                                    label: k?.label,
                                                    value: k?.name
                                                }
                                            )),
                                            ...externalProject.form.fields.map(el => (
                                                {
                                                    label: `${el.label} (${capitalizeFirstLetter(item?.configuration?.externalProject?.fieldName)})`,
                                                    value: `${item?.configuration?.externalProject?.fieldName}_${el.name}`
                                                }
                                            ))
                                            
                                        ] : [
                                            ...baseFields,
                                            ...item?.form?.fields?.map(k => (
                                                {
                                                    label: k?.label,
                                                    value: k?.name
                                                }
                                            ))
                                        ]
                                        }
                                        onChange={(e) => {
                                            // console.log('formik log', {...downloadInfo, fileName: e.map(k => k.value)})
                                            setDownloadInfo({...downloadInfo, fileName: e.map(k => k.value)})
                                        }}
                                    />
                                </div>
                            </form>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                <ButtonWithBg 
                    title={t('project:export')}
                    type='button'
                    bg={'primary'}
                    isSmall={true}
                    onClick={() => {
                        (downloadInfo?.format === 'csv' || downloadInfo?.format === 'excel') ?  exportData() : exportDataGeoLocation()
                    }}
                />
            </div>
        </>
    )
}