import { databaseSourceConstants } from '../constants'

const initialState = []

export default function (databaseSources = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case databaseSourceConstants.CREATE_DATABASESOURCE:
      return [payload, ...databaseSources]
    case databaseSourceConstants.RETRIEVE_DATABASESOURCE:
      return payload
    case databaseSourceConstants.UPDATE_DATABASESOURCE:
      return databaseSources.map((databaseSource) => {
        if (databaseSource.id === payload.id) {
          return {
            ...databaseSource,
            ...payload,
          }
        } else {
          return databaseSource
        }
      })
    case databaseSourceConstants.DELETE_DATABASESOURCE:
      return databaseSources.filter(({ id }) => id !== payload.id)
    case databaseSourceConstants.DELETE_ALL_DATABASESOURCE:
      return []
    default:
      return databaseSources
  }
}
