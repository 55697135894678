import JSZip from 'jszip';
import { retirerId } from './retireId';

const formatDataSurvey = (data, project) => {
    let res
    if(project?.configuration?.externalProject?.fieldName){
        res = {
            ...data?.survey,
            agent: {value: data?.user?.firstName + " " + data?.user?.lastName},
            status: {value: data?.status?.name},
            number: {value : data?.number},
            createdAt: {value: new Date(data?.createdAt)?.toLocaleDateString()?.replaceAll("/", "-")},
            id: {value: data.id},
            [String(project?.configuration?.externalProject?.fieldName).toLowerCase()] : {
                value: data?.survey?.externalProjectData
            }
        }
    } else {
        res = {
            ...data?.survey,
            agent: {value: data?.user?.firstName + " " + data?.user?.lastName},
            status: {value: data?.status?.name},
            number: {value : data?.number},
            createdAt: {value: new Date(data?.createdAt)?.toLocaleDateString()?.replaceAll("/", "-")},
            id: {value: data.id}
        }
    }
    

    return res
}

const handleClick = (formatDataGeolocation, type, fieldsUsedForRenameFile, project) => {
    const gpxData = formatDataGeolocation;

    console.log('fieldsUsedForRenameFile', fieldsUsedForRenameFile)

    const zip = new JSZip();
    let folderZipName = 'folderZip'
    gpxData.forEach((data, indx) =>{
        const folderName = `Survey ${indx + 1}`;
        
        if(data){
            data.forEach((item, i) => {
                let newObjectSrv = formatDataSurvey(item?.srv, project)

                let nameFile = fieldsUsedForRenameFile?.map(el => {
                    if(el?.value?.includes(project?.configuration?.externalProject?.fieldName)){
                        let fieldExternalProjName = project?.configuration?.externalProject?.fieldName
                        let objExternalProj = newObjectSrv[String(fieldExternalProjName).toLowerCase()]?.value
                        let valueConcernedField = objExternalProj[el?.value.split("_")[1]]?.value

                        return retirerId(valueConcernedField)
                    } else {
                        return retirerId(newObjectSrv[el.value]?.value)
                    }
                }) ?? []
                
                let gpxContent
                let formatNameFile = nameFile.join(' - ')
                if(type === 'kml'){
                    folderZipName = `kml_${formatNameFile}`
                    gpxContent = generateKMLContent(item, formatNameFile);
                }else if(type==='gpxTrack'){
                    folderZipName = `gpxTrack_${formatNameFile}`
                    gpxContent = generateGPXTRACKContent(item, formatNameFile)
                }else{
                    folderZipName = `gpxWaypoints_${formatNameFile}`
                    gpxContent = generateGPXContent(item, formatNameFile)
                }
                zip.file(`${formatNameFile}/${formatNameFile}.${type === 'kml' ? type : "gpx"}`, gpxContent);
            })
        }
    })

    const zipFilename = `${folderZipName}.zip`;
    downloadZipFile(zipFilename, zip);
};

function handleClickForUniq(formatDataGeolocation, type){
    const gpxData = formatDataGeolocation;
    const zip = new JSZip();
    let folderZipName = 'folderZip'
    let folderName = 'projectName'

    let gpxContent
    if(type === 'kml'){
        folderZipName = 'kml_geolocation'
        gpxContent = generateKMLContent(gpxData);
    }else if(type==='gpxTrack'){
        folderZipName = 'gpxTrack_geolocation'
        gpxContent = generateGPXTRACKContent(gpxData)
    }else{
        folderZipName = 'gpxWaypoints_geolocation'
        gpxContent = generateGPXContent(gpxData)
    }
    zip.file(`${folderName}/{surv}.${type === 'kml' ? type : "gpx"}`, gpxContent);

    const zipFilename = `${folderZipName}.zip`;
    downloadZipFile(zipFilename, zip);
}

// https://chat.openai.com/share/f97acd41-bdb8-4884-b681-203d49c7c6ba

function generateKMLContent(kmlData, nameFile) {
    // Générer le contenu du fichier KML en utilisant les données fournies
    const waypoints  = kmlData;

    console.log("waypoints---->", waypoints)

    let kmlContent = `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2">
      <Document>
        <name>${nameFile}</name>`;
            if (waypoints?.type === 'polygone' || waypoints?.type === 'continuous') {
                kmlContent += `
                <Folder>
                <name>${nameFile}</name>
                <open>1</open>
                <Placemark>
                    <name>${nameFile}</name>
                    <Polygon>
                    <tessellate>1</tessellate>
                    <outerBoundaryIs>
                        <LinearRing>
                            <coordinates>${generateCoordinatesString(waypoints.coordinates, waypoints?.type)}
                            </coordinates>
                        </LinearRing>
                    </outerBoundaryIs>
                    </Polygon>
                </Placemark>
                </Folder>`;
            } else if (waypoints.type === 'onePosition' || waypoints.type === 'userLocation') {
                kmlContent += `
                <Folder>
                <name>${nameFile}</name>
                <open>1</open>
                <Placemark>
                    <name>${nameFile}</name>
                    <Point>
                        <coordinates>${generateCoordinatesString(waypoints.coordinates, waypoints?.type)}
                        </coordinates>
                    </Point>
                </Placemark>
                </Folder>`;
            }
        kmlContent += `
      </Document>
    </kml>`;

    return kmlContent;
}

function generateGPXContent(kmlData, nameFile) {
    const waypoints  = kmlData;
    console.log("waypoints---->", waypoints);

    let gpxContent = `<?xml version="1.0" encoding="UTF-8"?>
    <gpx version="1.1" creator="Terros - https.terros.tech" xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">
    <metadata>
        <name>${nameFile}</name>
    </metadata>`;

    gpxContent += `
    ${generateCoordinatesGpxWAYPOINTS(waypoints.coordinates, waypoints?.type)}`;
       
    gpxContent += `
    </gpx>`;

    return gpxContent;
}

function generateGPXTRACKContent(kmlData, nameFile) {
    const waypoints  = kmlData;
    console.log("waypoints---->", waypoints);

    let gpxContent = `<?xml version="1.0"encoding="UTF-8"standalone="no" ?>
    <gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:gpxx="http://www.garmin.com/xmlschemas/GpxExtensions/v3" xmlns:gpxtrkx="http://www.garmin.com/xmlschemas/TrackStatsExtension/v1" xmlns:wptx1="http://www.garmin.com/xmlschemas/WaypointExtension/v1" xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1" creator="GPSMAP 64" version="1.1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www8.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TrackStatsExtension/v1 http://www8.garmin.com/xmlschemas/TrackStatsExtension.xsd http://www.garmin.com/xmlschemas/WaypointExtension/v1 http://www8.garmin.com/xmlschemas/WaypointExtensionv1.xsd http://www.garmin.com/xmlschemas/TrackPointExtension/v1 http://www.garmin.com/xmlschemas/TrackPointExtensionv1.xsd">
    <metadata>
		<link href="http://www.terros-tech.com">
			<text>${nameFile}</text>
		</link>
		<time>${new Date().toISOString()}</time>
	</metadata>`;
            gpxContent += `
    <trk>
        <name>${nameFile}</name>
        <trkseg>${generateCoordinatesGpxTrack(waypoints.coordinates, waypoints?.type)}
        </trkseg>
    </trk>`;
    
    gpxContent += `
    </gpx>`;

    return gpxContent;
}

function createFolder(zip, folderName){
    const folder = zip.folder(folderName);
    return folder;
  };
  
  function addFileToFolder(folder, filename, content){
    folder.file(filename, content);
  };

function downloadGPX(content, filename) {
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/xml' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

function downloadZipFile (filename, zip) {
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(content);
        element.download = filename;
        element.click();
    });
  };

function generateCoordinatesString(coordinates, type) {
    let coordinatesString = '';
    if(type === 'userLocation'){
      coordinatesString += `
                            ${coordinates.longitude},${coordinates.latitude},0`;
    }else{
    coordinates.forEach(({ latitude, longitude }) => {
      coordinatesString += `
                            ${longitude},${latitude},0`;
    })};

    return coordinatesString; // Supprimer les espaces inutiles au début et à la fin
}

function generateCoordinatesGpxTrack(coordinates, type) {
    let coordinatesString = '';

    if(type === 'userLocation'){
    coordinatesString += `
            <trkpt lat="${coordinates?.latitude}" lon="${coordinates?.longitude}">
                <name>${1}</name>
                <ele>${coordinates.altitude ? coordinates.altitude : 0}</ele>
            </trkpt>`;
    } else{
    coordinates.forEach(({ latitude, longitude, altitude, dateHourSended }, idx) => {
      coordinatesString += `
            <trkpt lat="${latitude}" lon="${longitude}">
                <name>${idx + 1}</name>
                <ele>${altitude ? altitude : 0}</ele>
                <time>${new Date(dateHourSended).toISOString()}</time>
            </trkpt>`;
    })};

    return coordinatesString; // Supprimer les espaces inutiles au début et à la fin
}

function generateCoordinatesGpxWAYPOINTS(coordinates, type) {
    let coordinatesString = '';

    if(type === 'userLocation'){
    coordinatesString += `
        <wpt lat="${coordinates.latitude}" lon="${coordinates.longitude}">
            <name>${1}</name>
        </wpt>`;
    } else{
    coordinates.forEach(({ latitude, longitude, altitude, dateHourSended }, idx) => {
      coordinatesString += `
        <wpt lat="${latitude}" lon="${longitude}">
            <name>${idx + 1}</name>
        </wpt>`;
    })};

    return coordinatesString; // Supprimer les espaces inutiles au début et à la fin
}

export{
    handleClick,
    handleClickForUniq
} 