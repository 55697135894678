import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/Kits";
import { ButtonWithBg } from "../../components/Kits/Buttons";
import { userActions } from "../../redux/actions";
// import { userActions } from '../../redux/actions/user.actions'
import { uiConstants } from "../../redux/constants";
import { authLogic } from "../../utils";
import { useToaster } from "../../contexts/toaster";
import http from "../../http-common";

export default function ForgetPassword() {
  let initialState = {
    email: "",
  };
  const toaster = useToaster()
  const [credentials, setCredentials] = useState(initialState);
  const loading = useSelector((state) => state.UI.loading);
  const token = useSelector((state) => state.auth.token);
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("post");
    if(isLoading === false){
      setIsLoading(true)
      await http.post(`/reset-password-by-link`, credentials)
        .then(function (response) {
            if(response.status === 200){
                setIsLoading(false)
                toaster.show({
                    title: 'Success !',
                    message: "Email sent please check your mailbox",
                    greedy: true,
                    type: 'success'
                });
                navigate("/", { replace: true });
            }
        })
        .catch(function (error) {
            setIsLoading(false)
            console.log("error", error)
            toaster.show({
                title: 'Oups !',
                message: 'Verify the email address you entered',
                greedy: true,
                type: 'error'
            });
        }); 
    }
  };

  useEffect(() => {
    if (authLogic.isAuthenticated(token) === true) {
      return navigate("/adm/survey-console", { replace: true });
    }
  }, []);

  return (
    <div className="h-[100vh] flex">
      <div className="h-full bg-gray-200 flex-1 overflow-hidden relative hidden lg:block">
        <img src="/images/fgmdp.png" alt="fgndp" className="w-full h-full object-cover" />
      </div>
      <div className="h-full bg-white w-full lg:w-[45%] flex items-center justify-center">
        <div className="w-[450px] mx-auto px-4">
          <h1 className="text-[30px] font-medium font-ibm-plex-sans leading-[24px] text-primary-blue-500 mb-4">
            Reset your password
          </h1>
          <span className="text-[16px] leading-[24px] text-black text-opacity-50">
            Enter your email address to reset your password
          </span>
          <form className="mt-[70px]" onSubmit={handleSubmit}>
            <div className="mb-[30px]">
              <Input
                label={"E-mail"}
                placeholder={"info@gmail.com"}
                type={"email"}
                name={credentials.email}
                onChange={(e) =>
                  setCredentials({ email: e.target.value })
                }
              />
            </div>
            <div>
              <ButtonWithBg title={ isLoading ? "In progress..." : "Reset password"} type="sumbit" bg={"primary"} />
            </div>
            <p className="text-[14px] leading-[24px] text-black text-opacity-50 mt-[30px]">
              {"Don’t have account ? "}
              <Link
                to="/register"
                className="text-opacity-100 text-secondary-green-500"
              >
                Sign up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
