import { projectConstants } from '../constants'

const initialState = []

export default function(projects = initialState, action){
  const { type, payload } = action

  switch (type) {
    case projectConstants.CREATE_PROJECT:
      console.log(payload)
      return [payload, ...projects]
    case projectConstants.RETRIEVE_PROJECTS:
      return payload
    case projectConstants.UPDATE_PROJECT:
      return projects?.map((project) => {
        console.log('aaaaannn', payload)
        if (project.id === payload.id) {
          console.log('aaaaa', payload)
          return {
            ...payload
          }
        } else {
          console.log('bbbbbb')
          return project
        }
      })
    case projectConstants.DELETE_PROJECT:
      return projects.filter(({ id }) => id !== payload.id)
    case projectConstants.DELETE_ALL_PROJECT:
      return []
    default:
      return projects
  }
}
