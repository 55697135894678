import React, { useState, useEffect } from "react";
import { Tag } from "../../../../components/Kits";
import { ChooseForm, ChooseMembers, GeneralInfo, PaymentMethod, RulesPolicies, ConfigProject } from "./ProjectConfig";
import { useSelector } from "react-redux/es/exports";
import {BsCheckLg} from 'react-icons/bs'
import http from "../../../../http-common";

export default function Configuration({project,item, t, accessControl}){
    const user = useSelector((state) => state.user);
    const [ProjectOwner, setProjectOwner] = useState(null)
    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        isImpacting: project?.isImpacting,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    const displayRole = () => {
        if (project?.projectUsers?.length > 0) {
        let userFounded = project?.projectUsers?.find((u) => u?.userId === user?.userId);

        return userFounded?.roles[0]?.name;
        }
    };

    useEffect(()=>{
        setProjectOwner(project?.projectUsers?.filter(x => x?.roles[0]?.name == "Project Owner")[0])
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                isImpacting: project?.isImpacting,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])

    console.log('project huun', project)
    return(
        <div className="grid lg:grid-cols-3 gap-8">
            <div className="">
                <ul>
                    <li  className="flex items-center justify-between py-4 border-b">
                        <span className="text-[14px] text-black text-opacity-50">Status</span>
                        <Tag 
                            title={t(`project:${project?.status?.name}`)}
                            color={
                                project?.status?.name === 'Completed' ? "text-[#0B449A]" : 
                                project?.status?.name === 'Running' ? "text-[#6BCB77]" : 
                                "text-[#000]"
                            }
                            bg={
                                project?.status?.name === 'Completed' ? "bg-[#e7ecf5]" : 
                                project?.status?.name === 'Running' ? "bg-[#f0faf1]" : 
                                "bg-[#fff]"
                            }
                        />
                    </li>
                    <li  className="flex items-center justify-between py-4 border-b">
                        <span className="text-[14px] text-black text-opacity-50">{t("project:yourrole")}</span>
                        <Tag 
                            title={displayRole()}
                            color={ 'text-primary-blue-500'}
                            bg={'bg-primary-blue-500 bg-opacity-10'}
                        />
                    </li>
                    <li  className="flex items-center justify-between py-4 border-b gap-4">
                        <span className="text-[14px] text-black text-opacity-50 whitespace-nowrap">{t("project:projectowner")}</span>
                        <Tag 
                            title={ProjectOwner?.firstName +" "+ ProjectOwner?.lastName}
                            color={ 'text-primary-blue-500'}
                            bg={'bg-primary-blue-500 bg-opacity-10'}
                        />
                    </li>
                </ul>
                <div className="mt-[30px] lg:block hidden">
                    <h4 className="text-[16px] text-black mb-4">{t("project:timelineoftheproject")}:</h4>
                    <ul>
                        <li className="flex items-center mb-[33px] justify-between gap-4">
                            <div className="flex items-center gap-2">
                                <div className={`h-[20px] w-[20px] rounded-full border-2 ${project?.status?.name == 'Configuring' ? "border-secondary-green-500 bg-white" : 'bg-secondary-green-500 border-none'} flex items-center justify-center relative after:absolute after:h-[40px] after:w-[1px] after:bg-black after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}>
                                    {
                                        project?.status?.name !== 'Configuring' && <BsCheckLg className="text-[10px] text-white" />
                                    }
                                </div>
                                <span className="text-[14px] text-black text-opacity-50">{t("project:Configuring")}</span>
                            </div>
                            <span className="text-black text-[14px] font-medium hidden">12-12-2020</span>
                        </li>
                        <li className="flex items-center mb-[33px] justify-between gap-4">
                            <div className="flex items-center gap-2">
                                <div className={`h-[20px] w-[20px] rounded-full border-2 ${project?.status?.name == 'Created' ? "border-secondary-green-500 bg-white" : project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring' ? 'bg-secondary-green-500 border-none' : "bg-white"} flex items-center justify-center relative after:absolute after:h-[40px] after:w-[1px] after:bg-black after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}>
                                    {
                                        project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring' && <BsCheckLg className="text-[10px] text-white" />
                                    }
                                </div>
                                <span className="text-[14px] text-black text-opacity-50">{t("project:Created")}</span>
                            </div>
                            <span className="text-black text-[14px] font-medium hidden">12-12-2020</span>
                        </li>
                        <li className="flex items-center mb-[33px] justify-between gap-4">
                            <div className="flex items-center gap-2">
                                <div className={`h-[20px] w-[20px] rounded-full border-2 ${project?.status?.name == 'Running' ? "border-secondary-green-500 bg-white" : project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring' && project?.status?.name !== 'Running' ? 'bg-secondary-green-500 border-none' : "bg-white"} flex items-center justify-center relative after:absolute after:h-[40px] after:w-[1px] after:bg-black after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}>
                                    {
                                        project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring' && project?.status?.name !== 'Running' && <BsCheckLg className="text-[10px] text-white" />
                                    }
                                </div>
                                <span className="text-[14px] text-black text-opacity-50">{t("project:Running")}</span>
                            </div>
                            <span className="text-black text-[14px] font-medium hidden">12-12-2020</span>
                        </li>
                        <li className="flex items-center mb-[33px] justify-between gap-4">
                            <div className="flex items-center gap-2">
                                <div className={`h-[20px] w-[20px] rounded-full border-2 ${project?.status?.name == 'Completed' ? "border-secondary-green-500 bg-white" : project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring' && project?.status?.name !== 'Running'  && project?.status?.name !== 'Completed' ? 'bg-secondary-green-500 border-none' : "bg-white"} flex items-center justify-center relative after:absolute after:h-[40px] after:w-[1px] after:bg-black after:bg-opacity-10 after:left-1/2 after:-translate-x-1/2 after:top-full`}>
                                    {
                                        project?.status?.name !== 'Created' && project?.status?.name !== 'Configuring' && project?.status?.name !== 'Running' && project?.status?.name !== 'Completed' && <BsCheckLg className="text-[10px] text-white" />
                                    }
                                </div>
                                <span className="text-[14px] text-black text-opacity-50">{t("project:Completed")}</span>
                            </div>
                            <span className="text-black text-[14px] font-medium"></span>
                        </li>
                        <li className="flex items-center mb-[33px] justify-between gap-4">
                            <div className="flex items-center gap-2">
                                <div className={`h-[20px] w-[20px] bg-white rounded-full border-2 ${project?.status?.name == 'Archived' ? 'border-secondary-green-500' : ''} flex items-center justify-center relative`}>
                                
                                </div>
                                <span className="text-[14px] text-black text-opacity-50">{t("project:Archived")}</span>
                            </div>
                            <span className="text-black text-[14px] font-medium"></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="lg:col-span-2">
                <GeneralInfo project={project} projectId={project?.id} item={item} t={t} accessControl={accessControl} />
                <ConfigProject project={project} projectId={project?.id} item={item} t={t} accessControl={accessControl} />
                <ChooseForm project={project} projectId={project?.id} item={item} t={t} accessControl={accessControl} />
                <ChooseMembers project={project} projectId={project?.id} item={item} t={t} accessControl={accessControl} />
                <RulesPolicies project={project} projectId={project?.id} item={item} t={t}  accessControl={accessControl}/>
                <PaymentMethod project={project} projectId={project?.id} item={item} t={t} accessControl={accessControl} />
            </div>
        </div>
    )
}