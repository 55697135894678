import { Disclosure } from '@headlessui/react'
import { Input,Textarea, InputRadio, Heading, Paragraph,ButtonWithBg,Tag } from '../../../../../components/Kits'
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft, BsCheckLg } from 'react-icons/bs'
import { Modal } from '../../../../../components/Modals'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { projectActions, billingAccountActions } from '../../../../../redux/actions'
import { useToaster } from '../../../../../contexts/toaster'
import http from '../../../../../http-common'

export default function PaymentMethod({item, t, project, projectId, accessControl}){
    const toaster = useToaster()
    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [isConf, setIsConf] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()

    const [forms, setForms] = useState([])
    const [billingAccounts, setBillingAccounts] = useState([])

    const [itemData, setItemData] = useState({
        name: project?.name,
        description: project?.description,
        formId: project?.form?.id ? project?.form?.id : "",
        projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
        billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
        startDate: project?.startDate,
        endDate: project?.endDate,
        executeZone: project?.executeZone,
        executeFrom: project?.executeFrom,
        activeLocationExecution: project?.activeLocationExecution,
        projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
            {
                userId: user?.userId,
                roles: user?.roles?.map(el => (el?.id))
            }
        )),
        configuration: project?.configuration
    })

    useEffect(()=>{
        setItemData(
            {
                name: project?.name,
                description: project?.description,
                formId: project?.form?.id ? project?.form?.id : "",
                projectTypeId: project?.projectType?.id ? project?.projectType?.id : '',
                billingAccountId: project?.billingAccount?.id ? project?.billingAccount?.id : "",
                startDate: project?.startDate,
                endDate: project?.endDate,
                executeZone: project?.executeZone,
                executeFrom: project?.executeFrom,
                activeLocationExecution: project?.activeLocationExecution,
                projectUsers: project?.projectUsers?.filter(x => x?.userId !== user?.userId)?.map((user) =>(
                    {
                        userId: user?.userId,
                        roles: user?.roles?.map(el => (el?.id))
                    }
                )),
                configuration: project?.configuration
            }
        )
    },[project])

    async function refreshed(what) {
        await http.get( what === "forms" ? `/forms` : 
        what === "billingAccounts" ? `/billing-accounts` : 
        `/${what}?limit=0&columns=["name"]&populates=[]`)
        .then(function (response) {
            console.log('response', response)
            if(response.status === 200) {
                what === "forms" ? setForms(response.data.data) :
                what === "billingAccounts" ? setBillingAccounts(response.data.data) :
                console.log("")
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    };

    useEffect(() => {
        refreshed("billingAccounts")
    }, [])

    const handleSubmitProject = async (e) => {
        e.preventDefault();
        if(isLoading===false){
            setIsLoading(true)
            await http.put(`/projects/${projectId}/`, itemData)
            .then(function (response) {
                if(response.status === 200){
                    setIsLoading(false)
                    toaster.show({
                        title: 'Success !',
                        message: `Your project has modified successfully`,
                        greedy: true,
                        type: 'success'
                    });
                    window.location.reload()
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                toaster.show({
                    title: 'Oups !',
                    message: error,
                    greedy: true,
                    type: 'error'
                });
            });
        }

        // console.log("itemData====>", itemData);
        // dispatch(projectActions.update(item?.id, itemData, setIsLoading, toaster, isConf));
      };

    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    return(
        <>
            <Disclosure className='bg-white rounded-[6px] shadow-[0_0.3rem_0.94rem_rgba(128,128,128,0.06)] border border-gray-100 mb-3'>
                {({ open }) => (
                    <div className={`rounded-lg overflow-hidden } `}>
                        <Disclosure.Button className={`transition px-5 ease-in duration-400 flex items-center justify-between py-4 cursor-pointer w-full border-b ${open ? 'border-myFirstGray' : 'border-transparent'}`}>
                            <div className={`flex items-center justify-between gap-3 w-full`}>
                                <span className={`
                                    text-[15px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                }`}>
                                    {t("project:paymentbilling")}
                                </span>
                                <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className=' py-4 px-5 bg-opacity-70'>
                            <div className={`col-span-1`}>
                                <div className='grid grid-cols-2'>
                                    {   project?.billingAccount &&
                                        <div className="bg-white rounded-[6px] p-[15px] border relative form-card cursor-pointer">
                                            <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[30px] w-[30px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                                <BsCheckLg className='text-white text-[14px]' />
                                            </div>
                                            <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-[65px]">
                                                {project?.billingAccount?.name}
                                            </h4>
                                            {   project?.billingAccount?.billingAccountTypeId === 'ZyDyc9A6R0Lw3ygXKfis' ? (
                                                <div className="flex items-center justify-between gap-4">
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-primary-blue-500 text-[14px] cursor-pointer">{new Date( project?.billingAccount?.startDate).toLocaleDateString()}</span>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-primary-blue-500 text-[14px] cursor-pointer">{new Date( project?.billingAccount?.endDate).toLocaleDateString()}</span>
                                                    </div>
                                                </div>
                                                ) : (
                                                    <div className="flex items-center justify-between gap-4">
                                                        <div className="flex items-center gap-2">
                                                            <span className="text-primary-blue-500 text-[14px] cursor-pointer">{"..." + project?.billingAccount?.accountNumber?.split("-")[2]}</span>
                                                        </div>
                                                        <Tag 
                                                            title={'Visa'}
                                                            color={'text-[#6BCB77]'}
                                                            bg={'bg-[#f0faf1]'}
                                                        />
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    }
                                </div>
                                {
                                    (accessControl?.maintainer || accessControl?.owner) && (
                                        <div className='flex justify-end mb-[10px]'>
                                            <ButtonWithBg 
                                                title={t("common:update")}
                                                type='submit'
                                                bg={'primary'}
                                                isSmall={true}
                                                onClick={() => toggleModal()}
                                            />
                                        </div>
                                    )
                                }
                                
                            </div>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>

            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal}>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {t("project:choosepaymentmethod")}
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                <form onSubmit={(e) => handleSubmitProject(e)}>
                    <div className="p-6 max-h-[400px] overflow-x-hidden">
                        <div className="grid grid-cols-2 gap-4 mb-[30px]">
                        {
                            billingAccounts?.filter(x => x?.id !== project?.billingAccount?.id && x?.status === 'Active')?.map((el, index) => (
                                <label key={index}>
                                    <input 
                                        type='radio' 
                                        name="paymentMethod" 
                                        value={el?.id} 
                                        onChange={(e) =>{ setItemData((prevState) => ({
                                            ...prevState,
                                            billingAccountId: el?.id,
                                        }));
                                    }} 
                                    className="input-radio hidden" 
                                    />
                                    <div className="bg-white rounded-[6px] p-[15px] border relative form-card cursor-pointer">
                                        <div className="invisible opacity-0 check transition-all ease-out duration-200 h-[30px] w-[30px] bg-secondary-green-500 rounded-full flex items-center justify-center absolute top-2 right-4">
                                            <BsCheckLg className='text-white text-[14px]' />
                                        </div>
                                        <h4 className="text-black text-[16px] font-ibm-plex-sans font-medium mb-[65px]">
                                            {el?.name}
                                        </h4>
                                        {
                                            el?.billingAccountType?.name?.toLowerCase().includes('pb') ? (
                                                <div className="flex items-center justify-between gap-4">
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-primary-blue-500 text-[14px] cursor-pointer">{new Date(el?.startDate).toLocaleDateString()}</span>
                                                    </div>
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-primary-blue-500 text-[14px] cursor-pointer">{new Date(el?.endDate).toLocaleDateString()}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-between gap-4">
                                                    <div className="flex items-center gap-2">
                                                        <span className="text-primary-blue-500 text-[14px] cursor-pointer">{"..."+ el?.accountNumber?.split("-")[2]}</span>
                                                    </div>
                                                    <Tag 
                                                        title={'Visa'}
                                                        color={'text-[#6BCB77]'}
                                                        bg={'bg-[#f0faf1]'}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </label>
                            ))
                        }
                        </div>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                        <ButtonWithBg 
                            title={isLoading ? t("common:inprogress") : t("common:savechange")}
                            type='submit'
                            bg={'primary'}
                            isSmall={true}
                        />
                    </div>
                </form>
            </Modal>
        </>
    )
}