import { surveyDatasStatus } from "../../services";
import { datasMapStatus as constants, uiConstants } from "../constants";

const getAll = () => (dispatch) => {
  dispatch({
    type: uiConstants.LOADING_UI,
  });
  surveyDatasStatus
    .getAll()
    .then((res) => {
      const order = ['Received', 'Validated', 'To update', 'Archived', 'Deleted'];
        let statusSorted = res.data.data.sort((a, b) => {
          return order.indexOf(a.name) - order.indexOf(b.name);
        });
      dispatch({
        type: constants.RETRIEVE_DATAS_MAP_STATUS,
        payload: statusSorted,
      });
      dispatch({
        type: uiConstants.CLEAR_ERRORS,
      });
    })
    .catch((error) => {
      dispatch({
        type: uiConstants.SET_ERRORS,
        payload: error.response.message,
      });
    });
};

export const surveyDatasStatusActions = { getAll };
