import http from '../http-common'

const getAll = () => {
  return http.get('/datas-map')
}

const moduleMappingService = {
  getAll,
}

export default moduleMappingService
