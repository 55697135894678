import { Menu, Transition, Dialog, Disclosure, Listbox } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import { Tag } from '../../../components/Kits';
import { Link } from "react-router-dom";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";

export default function Table({forms, t, loadingForms, length}){

    return(
        <div tabIndex="0" className='relative bg-white rounded-[6px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6'>
            <div className="w-full">
                <table  className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[60px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"#ID"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("form:formname")}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                Description
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Status"}
                            </th>
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                        {   loadingForms ?
                            <TableSkeleton length={length}  columns={4} /> 
                            :
                            <>
                                {
                                    forms?.map((item, idx)=>(
                                        <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                {item?.number ? item?.number : '----------'}
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                {item?.name}
                                            </td>
                                            <td className="px-4 py-3 lowercase    text-[12px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                                <div className='limit-3'>
                                                    {item?.description}
                                                </div>
                                            </td>
                                            <td className="px-4 py-1 whitespace-nowrap lg:whitespace-normal text-[12px] text-[#000] text-opacity-50  font-[500] ">
                                                <Tag 
                                                    title={t(`form:${item?.formType?.name}`)}
                                                    color={item?.formType?.name==='Private' ? 'text-[#6BCB77]' : "text-[#0B449A]"}
                                                    bg={item?.formType?.name==='Private' ? 'bg-[#f0faf1]' : "bg-[#e7ecf5]"}
                                                />
                                            </td>
                                            <td className="px-4 py-2   whitespace-nowrap">
                                                <div className="w-[150px]">
                                                    <Link  to='/adm/forms/details-form' state={{ item: item }}>
                                                        <button className="bg-tertiary-gray-500 px-3 py-2 rounded-[4px] text-[12px] font-[500] flex items-center gap-6">
                                                            <span className="text-[#000] ">{t("common:viewdetails")}</span>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}